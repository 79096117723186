/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import {useSelector} from 'utils/redux';
import Selector from './Selector';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {useDeepCompareMemo} from 'utils/react';

const defaultSelectorProps = {
  maxColumns: 3,
  noActiveIndicator: true,
  noCombinedCategory: true,
  placeholder: intl('Common.SelectLabelsLabelGroups'),
};

LabelSelector.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  group: PropTypes.bool,

  /** Configuration passed to `labelsAndLabelGroups` preset function */
  config: PropTypes.object,
  labelsResourceModifier: PropTypes.object,

  onChange: PropTypes.func,
};

const defaultLabels = [];
const defaultLabelsResourceModifier = {};
export default function LabelSelector({
  value: labels = defaultLabels,
  config: configProps,
  group = false,
  onChange,
  labelsResourceModifier = defaultLabelsResourceModifier,
  ...selectorProps
}) {
  const labelTypeInitialRegExp = useSelector(getTypeInitialRegExp);
  const labelTypesNameObj = useSelector(getDisplayNames);
  const resourceId = 'labelsAndLabelGroups';

  const config = useDeepCompareMemo(configProps);

  const categories = useMemo(() => {
    return [
      _.merge(
        Selector.categoryPresets.labelsAndLabelGroups({
          resourceId,
          labelTypesNameObj,
          labelTypeInitialRegExp,
          hasLabelGroups: group,
          allowMultipleSelection: false,
          hasAll: selectorProps.hasAll ?? true,
          ...config,
        }),
        {
          resources: {
            labelsAndLabelGroups: labelsResourceModifier,
          },
        },
      ),
    ];
  }, [config, group, labelTypesNameObj, labelTypeInitialRegExp, labelsResourceModifier, selectorProps.hasAll]);

  const handleSelectionChange = useCallback(
    valuesMap => {
      const labels = valuesMap.get(resourceId) ?? [];

      onChange?.(labels);
    },
    [onChange],
  );

  Object.assign(selectorProps, {
    values: new Map(labels.length ? [[resourceId, labels]] : []),
    onSelectionChange: handleSelectionChange,
    categories,
  });

  _.defaults(selectorProps, defaultSelectorProps);

  return <Selector {...selectorProps} />;
}
