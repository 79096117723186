/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {Button, StatusIcon} from 'components';

/**
 [{
  id: string,
  header: string,

  cellKey: string | Function,
  cellValue: string | Function,
  cellFormat: Function,
  sortValue: Function,
  unsortable: boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = {
  id: 'venlibrary',
  sort: 'release',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    default: {
      header: intl('Common.Default'),
      value: ({row}) => (row.default ? <StatusIcon status="check" /> : null),
    },
    release: {
      header: intl('Workloads.Release'),
      value: ({row}) => row.release,
    },
    ven_type: {
      header: intl('VEN.EnforcementNodeType'),
      value: ({row}) => row.ven_type,
    },
    filename: {
      header: intl('VEN.Filename'),
      value: ({row}) => row.filename,
    },
    distribution: {
      header: intl('VEN.Distribution'),
      value: ({row}) => row.distribution,
    },
    architecture: {
      header: intl('VEN.Architecture'),
      value: ({row}) => row.architecture,
    },
    osVersion: {
      header: intl('VEN.OSVersion'),
      value: ({row}) => row.osVersion,
      sort: GridUtils.lowerNumberCase,
    },
    download: {
      header: intl('Common.Download'),
      sortable: false,
      value: ({row}) => <Button.Link link={{href: row.downloadPath}} icon="download" disabled={row.userIsReadOnly} />,
    },
  },

  templates: [
    [
      {columns: ['default'], size: 'max-content'},
      {columns: ['release'], size: 'minmax(100px, auto)'},
      {columns: ['ven_type'], size: 'minmax(100px, auto)'},
      {columns: ['filename'], size: 'minmax(220px, auto)'},
      {columns: ['distribution'], size: 'max-content'},
      {columns: ['architecture'], size: 'max-content'},
      {columns: ['osVersion'], size: 'max-content'},
      {columns: ['download'], size: 'max-content'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['default'], size: 'max-content'},
            {columns: ['release'], size: 'minmax(100px, auto)'},
            {columns: ['ven_type'], size: 'minmax(100px, auto)'},
            {columns: ['filename'], size: 'minmax(220px, auto)'},
            {columns: ['distribution', 'architecture'], size: 'max-content'},
            {columns: ['osVersion'], size: 'max-content'},
            {columns: ['download'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['default'], size: 'max-content'},
          {columns: ['release'], size: 'minmax(100px, auto)'},
          {columns: ['ven_type'], size: 'minmax(100px, auto)'},
          {columns: ['filename'], size: 'minmax(220px, auto)'},
          {columns: ['distribution', 'architecture'], size: 'max-content'},
          {columns: ['osVersion'], size: 'max-content'},
          {columns: ['download'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['default', 'download'], size: 'max-content'},
            {columns: ['release', 'filename', 'ven_type'], size: 'minmax(100px, auto)'},
            {columns: ['distribution', 'architecture', 'osVersion'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['default', 'download'], size: 'max-content'},
          {columns: ['release', 'filename', 'ven_type'], size: 'minmax(100px, auto)'},
          {columns: ['distribution', 'architecture', 'osVersion'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['default', 'download', 'release', 'filename', 'ven_type'], size: 'minmax(100px, auto)'},
            {columns: ['distribution', 'architecture', 'osVersion'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['default', 'download', 'release', 'filename', 'ven_type'], size: 'minmax(100px, auto)'},
          {columns: ['distribution', 'architecture', 'osVersion'], size: 'minmax(80px, auto)'},
        ];
      },
    },
  ],
};
