/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import Selectors from '../Selectors';
import styles from '../Selectors.css';
import {PureComponent} from 'react';
import {connect} from 'utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {createNewObject} from 'containers/Selectors/SelectorSaga';
import {getSelectorMatches, isLoadingMatches} from 'containers/Selectors/SelectorState';

const labelKeys = new Set(['role', 'app', 'env', 'loc']);

@connect((state, props) => {
  if (!props.objects || !props.objects.length) {
    return {};
  }

  return {
    loading: isLoadingMatches(state),
    matches: getSelectorMatches(state, props.objects[0].type, props.objects[0].key),
  };
})
export default class SingleItemSelect extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    initialItems: PropTypes.array, //These are the selected values not the list
    disabled: PropTypes.bool,
    objects: PropTypes.array,
    customPickers: PropTypes.object,
    categories: PropTypes.array.isRequired,
    statics: PropTypes.object,
    partials: PropTypes.array,
    all: PropTypes.bool,
    addValue: PropTypes.func,
    placeholder: PropTypes.string,
    maxResults: PropTypes.number,
    allowCreateTypes: PropTypes.array,
    renderOption: PropTypes.func,
    showMatchingResults: PropTypes.bool,
    footer: PropTypes.any,
    onSelectionChange: PropTypes.func,
    filterMatches: PropTypes.func, //Filter Items after obtaining drodown list, similar to customListItem in legacy
    selectedLabels: PropTypes.array, //Sending query param selected_labels to filter facet based on role, env, loc, app (RAEL)
    resourceType: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    showMatchingResults: false,
    onSelectionChange: _.noop,
    selectedLabels: [],
  };

  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.getStaticValues = this.getStaticValues.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  getDropdownValues(matches = []) {
    const type = this.props.objects?.[0]?.type;

    return matches.map(match => {
      if (typeof match === 'string') {
        return {categoryKey: type, value: match};
      }

      return {categoryKey: type, ...match};
    });
  }

  getFooter(count) {
    const {footer, loading, showResultsFooter} = this.props;

    if (footer) {
      return footer;
    }

    if (showResultsFooter && !loading) {
      return (
        <li className={styles.count} data-tid="comp-select-results-item" disabled>
          {intl('ObjectSelector.NumMatchingResults', {count})}
        </li>
      );
    }
  }

  getStaticValues() {
    if (this.props.statics) {
      return Object.entries(this.props.statics).reduce((result, [key, value]) => {
        result[key] = value.map(value => ({categoryKey: key, value}));

        return result;
      }, {});
    }

    return;
  }

  handleInputChange(input, object) {
    this.fetchData(input, object);
  }

  async handleCreate(input, object) {
    const {type, key} = object;
    const data = {key, value: input};
    const query = {query: '', key};

    // if type is labels, send empty params object to override default: pversion: 'draft'
    const params = object?.type === 'labels' ? {} : undefined;

    const created = await this.context.store.runSaga(createNewObject, type, data, query, params).toPromise();

    this.props.onSelectionChange([{key: created.key, value: created.value, href: created.href}]);
  }

  fetchData(input, object) {
    if (!object || !object.type) {
      return;
    }

    const {type, key, ...params} = object;
    const query = {query: input, key};
    const {fetchFilter, selectedLabels, resourceType} = this.props;

    if (selectedLabels.length > 0 && type === 'labels') {
      query.selected_scope = _.orderBy(
        selectedLabels.filter(
          label => labelKeys.has(label?.key) && label.key !== key && label.href && !label.href.includes('exists'),
        ),
        'key',
      ).map(({href}) => ({
        ...(href.includes('label_group') ? {label_group: {href}} : {label: {href}}),
      }));
    }

    if (resourceType) {
      query.resource_type = resourceType;
    }

    this.context.dispatch({
      type: `${type.toUpperCase()}_MATCHES_REQUEST`,
      object: type,
      query,
      params,
      filter: fetchFilter,
    });
  }

  render() {
    const {
      initialItems,
      allowCreateTypes,
      placeholder,
      loading,
      customPickers,
      maxResults,
      partials,
      disabled,
      objects,
      categories,
      showResultsFooter,
      renderOption,
      clearItemsOnSelect,
      filterMatches,
      tid,
      errorMessage,
      onFocus,
    } = this.props;
    const matches = filterMatches
      ? this.getDropdownValues(this.props.matches).filter(filterMatches)
      : this.getDropdownValues(this.props.matches);
    const type = objects?.[0]?.type;
    const count = matches.length;

    const props = {
      clearItemsOnSelect,
      initialItems,
      placeholder,
      categories,
      objects,
      disabled,
      customPickers,
      statics: this.getStaticValues(),
      partials,
      activeCategoryKey: categories[0].categoryKey,
      matches: {[type]: {matches, count}},
      footer: this.getFooter(count),
      allowCreateTypes,
      onCreate: this.handleCreate,
      loading,
      maxResults,
      renderOption,
      showTotal: true,
      showContainerTitle: false,
      showResultsFooter,
      onInputChange: this.handleInputChange,
      onSelectionChange: this.props.onSelectionChange,
      onFocus,
      tid,
      errorMessage,
    };

    return <Selectors {...props} />;
  }
}
