/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {
  type ComponentPropsWithoutRef,
  type ComponentPropsWithRef,
  createRef,
  type MutableRefObject,
  PureComponent,
} from 'react';
import {tidUtils} from 'utils';
import styles from './Icon.css';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {Tooltip} from 'components';
import {fileNames, getTitle, tidByName} from './IconUtils';
import type {ReactStrictNode} from 'utils/types';
import type {IconName} from './IconName';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import {AppContext, type AppContextValue} from 'containers/App/AppUtils';

export type {IconName};

const iconNameRegex = /^.*\/([\w-]+)\.svg$/i;

const classicIconsMap: Record<string, IconName> = {
  'add': 'add-diff-classic',
  'remove': 'remove-diff-classic',
  'add-diff': 'add-diff-classic',
  'back': 'back-classic',
  'next': 'next-classic',
  'up': 'up-classic',
  'down': 'down-classic',
  'check': 'check-classic',
  'close': 'close-classic',
  'comment': 'comment-classic',
  'dashboard': 'dashboard-classic',
  'disabled-status': 'disabled-status-classic',
  'edit': 'edit-classic',
  'error': 'error-classic',
  'grab-handle': 'grab-handle-classic',
  'help': 'help-classic',
  'info': 'info-classic',
  'inuse': 'inuse-classic',
  'provision': 'provision-classic',
  'right': 'right-classic',
  'refresh': 'refresh-classic',
  'remove-diff': 'remove-diff-classic',
  'search': 'search-classic',
  'settings': 'settings-classic',
  'warning': 'warning-classic',
};

export const icons = fileNames.reduce((acc: IconName[], name: string) => {
  const match = name.match(iconNameRegex);

  if (match) {
    acc.push(match[1] as IconName);
  }

  return acc;
}, []);

export interface IconProps extends ComponentPropsWithoutRef<'span'>, ThemeProps {
  /** Id of svg symbol */
  name: IconName;

  hidden?: boolean;

  autoTooltip?: boolean;
  title?: string; // Custom title, name is used if not specified

  labelGroup?: boolean; // label group

  /**
   * Additional tid that will be added to default one (that already includes name)
   * For instance, if name is 'user' and tid is 'hoho': 'comp-icon comp-icon-user comp-icon-hoho'
   */
  tid?: string;

  tooltip?: ReactStrictNode;
  tooltipProps?: TooltipProps;

  position?: 'after' | 'before' | 'inbetween';
}

export default class Icon extends PureComponent<IconProps> {
  static defaultProps = {
    autoTooltip: false,
  };

  static contextType = AppContext;

  // eslint-disable-next-line react/static-property-placement
  declare context: AppContextValue;

  element: HTMLSpanElement | null = null;
  iconRef: MutableRefObject<Element | null>;

  constructor(props: IconProps) {
    super(props);

    this.iconRef = createRef();
    this.saveRef = this.saveRef.bind(this);
  }

  static getTitle = getTitle;

  private saveRef(element: HTMLSpanElement | null) {
    this.element = element;
    this.iconRef.current = element;
  }

  render() {
    const {
      name: nameFromProps,
      position,
      hidden,
      autoTooltip,
      title,
      tid,
      theme,
      tooltip,
      tooltipProps,
      labelGroup,
      ...rest
    } = mixThemeWithProps(styles, this.props);
    const name = this.context.isNewUI ? nameFromProps : classicIconsMap[nameFromProps] ?? nameFromProps;
    const tooltipText = autoTooltip ? getTitle(name) : tooltip;
    let tooltipElement = null;

    if (tooltipText) {
      const updatedProps: TooltipProps = {...tooltipProps};

      if (name === 'info' || name === 'info-classic') {
        updatedProps.instant ??= true;
      }

      tooltipElement = <Tooltip content={tooltipText} {...updatedProps} reference={this.iconRef} />;
    }

    const elementProps: ComponentPropsWithRef<'span'> = rest;

    elementProps.className = cx(theme.icon, {
      [theme.hidden]: hidden,
      [theme.before]: position === 'before',
      [theme.after]: position === 'after',
      [theme.inbetween]: position === 'inbetween',
    });

    // set aria-label on icon
    if (typeof tooltip === 'string') {
      elementProps['aria-label'] = tooltip;
    } else if (title) {
      elementProps['aria-label'] = title;
    } else {
      elementProps['aria-label'] = getTitle(name);
    }

    elementProps.ref = this.saveRef;
    elementProps['data-tid'] = tid
      ? tidUtils.getTid('comp-icon', [nameFromProps, tid])
      : tidByName(icons).get(nameFromProps);

    return (
      <>
        <span {...elementProps}>
          <svg className={theme.svg}>
            <use xlinkHref={`#${name}`} />
          </svg>
        </span>
        {tooltipElement}
      </>
    );
  }
}

// Check that all icons have default title in dev
if (__DEV__) {
  for (const name of icons) {
    if (!getTitle(name)) {
      console.log(`%cNo default title for icon %c${name}`, 'color:orange', 'color:red');
    }
  }
}
