/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {connect, useSelector} from 'utils/redux';
import {useRef, useContext, useMemo} from 'react';
import {Icon, Link, Modal} from 'components';
import {getPCEBuild, getVersion, isEdge} from 'containers/App/AppState';
import styles from './ASPVersion.css';
import stylesUtils from 'utils.css';
import {AppContext} from 'containers/App/AppUtils';
import {getWhatsNewDocUrl} from 'containers/Popup/WhatsNewPopup/WhatsNewPopupState';
import cx from 'classnames';

const linkToPatents = 'https://www.illumio.com/patents';
const linkToTerms = 'https://www.illumio.com/eula';
const linkToPrivacy = 'https://www.illumio.com/privacy-policy';

export default connect(state => ({
  ASPVersion: getVersion(state),
  PCEBuild: getPCEBuild(state),
  isEdge: isEdge(state),
}))(ASPVersion);

ASPVersion.propTypes = {
  // Handler on close
  onClose: PropTypes.func,
  // Or link on close
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function ASPVersion({link, onClose, ASPVersion, PCEBuild, isEdge}) {
  const buttonRef = useRef();
  const whatsNewDocUrl = useSelector(getWhatsNewDocUrl);
  const {navigate, isNewUI} = useContext(AppContext);

  const handleClose = () => {
    if (link) {
      navigate(link);
    }

    onClose?.();
  };

  const versionSection = useMemo(
    () => (
      <div className={isNewUI ? stylesUtils.gapSmall : stylesUtils.gapXSmall}>
        <div
          className={cx(stylesUtils.centerFlexAlign, {
            [stylesUtils.gapHorizontal]: isNewUI,
            [stylesUtils.gapXLarge]: isNewUI,
          })}
        >
          <div className={styles.version}>
            {intl('VersionMismatch.PCE')}
            {isEdge ? `: ${intl('Edge.ProductName')}` : ''} {ASPVersion}-{PCEBuild}
          </div>
          {isNewUI && !__ANTMAN__ && (
            <Link href={whatsNewDocUrl} target="_blank" theme={styles} themeCompose="merge">
              {intl('Common.WhatsNew')}
            </Link>
          )}
        </div>
        <div className={styles.version}>
          {intl('VersionMismatch.UI')}
          {isEdge ? `: ${intl('Edge.ProductName')}` : ''} {process.env.UI_VERSION}
        </div>
      </div>
    ),
    [isNewUI, whatsNewDocUrl, isEdge, ASPVersion, PCEBuild],
  );

  const contentClassicUI = useMemo(
    () => (
      <>
        <div>
          {intl(
            'Illumio.Patent',
            {
              link: (
                <Link href={linkToPatents} target="_blank" theme={styles} themeCompose="merge">
                  {linkToPatents}
                </Link>
              ),
            },
            {jsx: true},
          )}
        </div>
        <div>
          {intl(
            'Illumio.Terms',
            {
              link: (
                <Link href={linkToTerms} target="_blank" theme={styles} themeCompose="merge">
                  {linkToTerms}
                </Link>
              ),
            },
            {jsx: true},
          )}
        </div>
        <div>
          {intl(
            'Illumio.Privacy',
            {
              link: (
                <Link href={linkToPrivacy} target="_blank" theme={styles} themeCompose="merge">
                  {linkToPrivacy}
                </Link>
              ),
            },
            {jsx: true},
          )}
        </div>
      </>
    ),
    [],
  );

  const contentNewUI = useMemo(
    () => (
      <div className={stylesUtils.gapSmall}>
        <Link href={linkToPatents} target="_blank" theme={styles} themeCompose="merge">
          {intl('Common.Patents')}
        </Link>
        <Link href={linkToTerms} target="_blank" theme={styles} themeCompose="merge">
          {intl('Common.TermsAndAgreements')}
        </Link>
        <Link href={linkToPrivacy} target="_blank" theme={styles} themeCompose="merge">
          {intl('Common.PrivacyPolicy')}
        </Link>
      </div>
    ),
    [],
  );

  return (
    <Modal.Alert
      large={!isNewUI}
      gap="gapLarge"
      closeRef={buttonRef}
      buttonProps={{tid: 'ok', text: intl('Common.OK'), onClick: handleClose, ref: buttonRef}}
      title={
        isNewUI ? (
          intl('Common.AboutThisProduct')
        ) : (
          <img className={styles.logo} src="images/logo-color.svg" alt={intl('Illumio.Logo')} />
        )
      }
    >
      {isNewUI && <Icon name="illumio-logo" theme={styles} />}
      {versionSection}
      <div className={isNewUI ? stylesUtils.gapXLarge : stylesUtils.gapMedium}>
        {isNewUI ? contentNewUI : contentClassicUI}
        <div className={stylesUtils.gapMedium}>{intl('Illumio.Copyright')}</div>
      </div>
    </Modal.Alert>
  );
}
