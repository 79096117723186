/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {createSelector} from 'reselect';
import {Link, Pill, StatusIcon, Tooltip, Icon} from 'components';
import {getConsumerProviderArrowDirection} from 'containers/App/AppUtils';
import {reverseProviderConsumer, getIsNewUI} from 'containers/App/AppState';
import {getProtectionSchemaIcon} from './ServersLabelingUtils';
import {getRuleOptionsPills} from 'containers/Ruleset/Item/RulesetItemUtils';
import {venStatuses} from 'containers/Ven/VenUtils';
import styles from './ServerLabeling.css';
import antmanStyles from 'antman/containers/styles.css';
import stylesUtils from 'utils.css';

export const serverLabelingListGridSettings = createSelector([getIsNewUI], isNewUI => ({
  id: 'serverLabelingList',
  columns: {
    itemNumber: {
      header: intl('Common.NumberSign'),
      value: ({row}) => row.data.itemNumber,
    },
    hostname: {
      header: intl('Common.Hostname'),
      value: ({row}) => row.data.hostname,
    },
    status: {
      header: intl('Common.Status'),
      value: ({row}) => row.data.status.status,
      format: ({value}) => venStatuses()[value],
      sortable: false,
      tid: 'comp-grid-column-state',
    },
    protectionSchema: {
      header: intl('Antman.Servers.Labeling.ProtectionSchema'),
      format: ({row: {data}, component: {handleProtectionSchemaModalToggle, protectionSchemasMap}}) => {
        const isWindows = data.os === 'win-x86_64-server';
        const {serverRoleTemplates = [], selectedProtectionSchema, href, recommendedPolicyName} = data;

        let suffix = '';
        let whyText = '';
        const recommendations = [];

        const uiValue = selectedProtectionSchema.length
          ? selectedProtectionSchema
              .map(value => {
                const isRecommended = serverRoleTemplates.includes(value);

                if (isRecommended) {
                  recommendations.push(protectionSchemasMap.get(value)?.name);
                }

                return `${protectionSchemasMap.get(value)?.name}${
                  isRecommended ? ` ${intl('Antman.Servers.Labeling.Recommended')}` : ''
                }`;
              })
              .join('\n')
          : recommendedPolicyName;

        if (serverRoleTemplates.length && uiValue === 'None') {
          suffix = intl('Antman.Servers.Labeling.Manual');
        }

        if (recommendations.length) {
          whyText = intl('Antman.Servers.Labeling.Why');
        }

        return (
          <div className={cx(stylesUtils.gapMedium, stylesUtils.gapHorizontal, stylesUtils.centerFlexAlign)}>
            <div className={styles.gridSchemaIcon}>
              <Icon name={getProtectionSchemaIcon(selectedProtectionSchema)} />
            </div>
            <div>
              <span data-tid={isWindows ? 'windows-protection-schema' : 'non-windows-protection-schema'}>
                {`${uiValue} ${suffix}`.trim()}
              </span>
              <div className={cx(stylesUtils.gapHorizontalWrap, stylesUtils.gapMedium)}>
                {isWindows && (
                  <Link
                    theme={isNewUI ? undefined : {link: antmanStyles.oldLink}}
                    data-tid="protection-schema-change-link"
                    onClick={_.partial(handleProtectionSchemaModalToggle, {
                      href,
                      serverRoleTemplates,
                      selectedProtectionSchema,
                      recommendedPolicyName,
                    })}
                  >
                    {intl('Common.Change')}
                  </Link>
                )}
                {whyText ? (
                  <Tooltip
                    content={intl('Antman.Servers.Labeling.WhyTooltip', {
                      protection: recommendations.join(' + '),
                      count: recommendations.length,
                    })}
                    instant
                  >
                    <span className={isNewUI ? antmanStyles.link : antmanStyles.oldLink}>{whyText}</span>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>
        );
      },
    },
    policies: {
      header: intl('Common.Policies'),
      format: ({row: {data}, component: {handleShowRulePreviewClick}}) => {
        const {serverPolicy: serverPolicies} = data;

        return serverPolicies?.length ? (
          <div className={stylesUtils.gapSmall}>
            {serverPolicies.map((serverPolicy, index) => {
              return serverPolicy.rules ? (
                <Link
                  onClick={_.partial(handleShowRulePreviewClick, serverPolicy.rules)}
                  theme={isNewUI ? undefined : {link: antmanStyles.link}}
                  tid="rules-summary"
                  data-tid={`elem-link-${serverPolicy.rules.external_data_reference.toLowerCase()}`}
                  key={index}
                >
                  {intl('Antman.Servers.RulesThatApplyDescription', {
                    count: serverPolicy?.count,
                    ruleset: serverPolicy.rules.name,
                  })}
                </Link>
              ) : null;
            })}
          </div>
        ) : null;
      },
      sortable: false,
    },
  },
  templates: [
    {
      template() {
        return [
          {columns: ['itemNumber'], size: 'max-content'},
          {columns: ['status'], size: 'max-content'},
          {columns: ['hostname'], size: 'minmax(var(--100px), 2fr)'},
          {columns: ['protectionSchema'], size: '4fr'},
          {columns: ['policies'], size: '5fr'},
        ];
      },
    },
  ],
}));

export const rulePreviewGridSettings = createSelector(reverseProviderConsumer, reverseProviderConsumer => ({
  id: 'rulePreviewGrid',
  columns: {
    status: {
      header: intl('Common.Status'),
      value: ({row}) => row.enabled,
      format: ({value: enabled}) => (
        <StatusIcon
          position="before"
          status={enabled ? 'inuse' : 'disabled-status'}
          label={<span className={stylesUtils.bold}>{enabled ? intl('Common.Enabled') : intl('Common.Disabled')}</span>}
        />
      ),
      sortable: false,
      tid: 'comp-grid-column-state',
    },
    providers: {
      header: intl('Common.Destinations'),
      value: ({row}) => row,
      format: ({value}) => {
        return <Pill.Endpoint value={value} type="providers" />;
      },
      sortable: false,
    },
    ingress_services: {
      header: intl('Rulesets.Rules.DestinationServices'),
      value: ({row}) => row,
      format: ({value: {ingress_services, sec_connect}}) => {
        const pills = ingress_services.map((service, index) => (
          <Pill.ServiceDiff insensitive value={service} key={index} showPorts="all" />
        ));

        if (sec_connect) {
          pills.push(
            <Pill.Diff
              key="sec_connect"
              value={[
                {
                  key: intl('Common.SecureConnect'),
                  pill: <Pill icon="secure-connect">{intl('Common.SecureConnect')}</Pill>,
                },
              ]}
            />,
          );
        }

        return <div className={cx(stylesUtils.gapXSmall, stylesUtils.gapHorizontalWrap)}>{pills}</div>;
      },
      sortable: false,
    },
    arrow: {
      value: null,
    },
    consumers: {
      header: intl('Common.Sources'),
      value: ({row}) => row,
      format: ({value}) => {
        return <Pill.Endpoint value={value} type="consumers" />;
      },
      sortable: false,
    },
    ruleOptions: {
      header: intl('Rulesets.Rules.RuleOptions'),
      value: ({row}) => row,
      format: ({value}) => <Pill.Diff noDiff value={getRuleOptionsPills(value)} />,
      sortable: false,
    },
  },
  templates: [
    {
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const consumers = [{columns: ['consumers'], size: '1fr'}];
        const providers = [
          {columns: ['providers'], size: '1fr'},
          {columns: ['ingress_services'], size: '1fr'},
        ];

        return [
          {columns: ['status'], size: 'calc(11 * var(--10px))'},
          ...(reverseProviderConsumer ? consumers : providers),
          {columns: ['arrow'], size: 'max-content'},
          ...(reverseProviderConsumer ? providers : consumers),
          {columns: ['ruleOptions'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 760,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const consumers = [{columns: ['consumers'], size: 'minmax(150px, 1fr)'}];
        const providers = [{columns: ['providers', 'ingress_services'], size: 'minmax(150px, 1fr)'}];

        return [
          {columns: ['status'], size: 'calc(11 * var(--10px))'},
          ...(reverseProviderConsumer ? consumers : providers),
          {columns: ['arrow'], size: 'max-content'},
          ...(reverseProviderConsumer ? providers : consumers),
          {columns: ['ruleOptions'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 540,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const consumers = ['consumers'];
        const providers = ['providers', 'ingress_services'];

        return [
          {
            columns: [
              'status',
              ...(reverseProviderConsumer ? consumers : providers),
              'arrow',
              ...(reverseProviderConsumer ? providers : consumers),
              'ruleOptions',
            ],
            size: 'auto',
          },
        ];
      },
    },
  ],
}));
