/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useMemo, useCallback, useContext, useState} from 'react';
import {useSelector} from 'utils/redux';
import {Selector} from 'containers';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {AppContext} from 'containers/App/AppUtils';
import {populateCombinedCategory, getAllResourcesObject, combinedCategoryId} from './SelectorUtils';
import styles from './Selector.css';
import {updateSelectorHistory} from './SelectorSaga';
import {getUserSelectorHistory} from 'containers/User/UserState';

const getActiveCategoryId = (query, prevActiveCategoryId) =>
  query.trim().length === 0 ? combinedCategoryId : prevActiveCategoryId;

export default function MultiModeSelector(props) {
  const {
    categories: categoriesProps,
    advancedEnabled: advancedEnabledProp,
    activeCategoryId,
    combinedCategoryProps = {},
    combinedResourceIds: combinedResourceIdsProp = [],
    ...restProps
  } = props;
  const {fetcher} = useContext(AppContext);
  const {flags = {}} = useSelector(getUserSelectorHistory);

  const theme = composeThemeFromProps(styles, props);
  const [advancedEnabled, setAdvancedEnabled] = useState(advancedEnabledProp || flags.advancedEnabled);

  const categories = useMemo(
    () => (typeof categoriesProps === 'function' ? categoriesProps(advancedEnabled) : categoriesProps),
    [categoriesProps, advancedEnabled],
  );

  const allResources = useMemo(
    // A category is an object with resources nested object, we need to flatten and combine resources in a single object
    // and, also assign the resource id and category id to its value
    () => getAllResourcesObject(categories),
    [categories],
  );

  const combinedResourceIds = useMemo(
    () => combinedResourceIdsProp.filter(id => allResources[id] && !allResources[id].hidden()),
    [allResources, combinedResourceIdsProp],
  );

  const computedCategories = useMemo(() => {
    if (advancedEnabled) {
      return categories;
    }

    const combinedCategory = _.merge(
      populateCombinedCategory({categories, combinedResourceIds, theme}),
      combinedCategoryProps,
    );

    return [combinedCategory, ...categories];
  }, [advancedEnabled, categories, theme, combinedResourceIds, combinedCategoryProps]);

  const handleAdvancedOptionsToggle = useCallback(
    advancedEnabled => {
      fetcher.spawn(updateSelectorHistory, {data: {flags: {advancedEnabled}}});
      setAdvancedEnabled(advancedEnabled);
    },
    [fetcher],
  );

  return (
    <Selector
      stickyCategoryPosition="bottom"
      hideCombinedCategory
      hideCategoryPanel={!advancedEnabled}
      noActiveIndicator={advancedEnabled}
      activeCategoryId={advancedEnabled ? activeCategoryId : getActiveCategoryId}
      categories={computedCategories}
      footerProps={{advancedEnabled, onAdvanceEnabled: handleAdvancedOptionsToggle}}
      {...restProps}
    />
  );
}
