/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Button, Pill, Link, StatusIcon} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';
import styles from './LabelSettings.css';
import {isUserOwner} from 'containers/User/UserState';

export const StyleMap = {
  initial: intl('LabelSettings.InitialLetter'),
  icon: intl('LabelSettings.Icon'),
  icon_prefix: intl('LabelSettings.IconPrefix'),
  icon_suffix: intl('LabelSettings.IconSuffix'),
};

export const formatHeader = areas => (
  <>
    <div className={styles.usageHeader}>{intl('Common.InUseBy')}</div>
    {areas}
  </>
);

export const gridSettings = createSelector([isUserOwner], isUserOwner => ({
  id: 'labelsettingslist',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  showColumns: true,
  showCapacity: true,
  columns: {
    ...(isUserOwner && {
      dragbar: {
        sortable: false,
        header: <StatusIcon status="info" tooltip={intl('LabelSettings.DragAndDropTooltip')} />,
      },
    }),
    checkboxes: {},
    style: {
      required: true,
      header: intl('Common.Style'),
      value: 'display_name',
      format: ({row, value}) => (
        <Pill.Label type={row.data.key} noContextualMenu>
          {value}
        </Pill.Label>
      ),
      sort: ({row}) => row.data.display_name,
    },
    type: {
      required: true,
      header: intl('Common.Name'),
      value: 'display_name',
    },
    labelkey: {
      required: true,
      header: intl('PairingProfiles.Key'),
      value: 'key',
    },
    initial: {
      required: true,
      header: intl('LabelSettings.LabelInitial'),
      value: ({row}) => row.data.display_info.initial,
    },
    labelInUse: {
      required: true,
      header: intl('LabelSettings.LabelInUse'),
      value: ({row}) => row.data.usage.labels,
      format: ({row, value, clickableRef}) => {
        return value ? (
          <>
            <StatusIcon position="before" status="inuse" />
            <Link
              to="labels.list"
              params={{labellist: {filter: {key: [{value: row.data.key, text: row.data.display_name}]}}}}
              ref={clickableRef}
            >
              {intl('Common.Labels')}
            </Link>
          </>
        ) : null;
      },
    },
    labelGroupInUse: {
      required: true,
      header: intl('LabelSettings.LabelGroupInUse'),
      value: ({row}) => row.data.usage.label_groups,
      format: ({row, value, clickableRef}) => {
        return value ? (
          <>
            <StatusIcon position="before" status="inuse" />
            <Link
              to="labelGroups.list"
              params={{labelgrouplist: {filter: {key: [{value: row.data.key, text: row.data.display_name}]}}}}
              ref={clickableRef}
            >
              {intl('Labels.Groups')}
            </Link>
          </>
        ) : null;
      },
    },
    edit: {
      manager: false,
      sortable: false,
      value: 'is_editable',
      format: ({component, row}) => {
        return (
          <Button
            color="standard"
            noFill
            tid="edit"
            icon="edit"
            onClick={component && _.partial(component.onEditClick, row)}
          />
        );
      },
    },
  },

  templates: [
    [
      ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['style'], size: 'minmax(100px, auto)'},
      {columns: ['type'], size: 'minmax(50px, auto)'},
      {columns: ['labelkey'], size: 'minmax(100px, auto)'},
      {columns: ['initial'], size: 'max-content'},
      {
        columns: ['labelInUse', 'labelGroupInUse'],
        size: 'minmax(100px, auto)',
        header: formatHeader,
      },
      {columns: ['edit'], size: 'minmax(50px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['style'], size: 'minmax(100px, auto)'},
            {columns: ['type'], size: 'minmax(50px, auto)'},
            {columns: ['initial'], size: 'max-content'},
            {columns: ['labelkey'], size: 'minmax(100px, auto)'},
            {
              columns: ['labelInUse', 'labelGroupInUse'],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
            {columns: ['edit'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['style'], size: 'minmax(100px, auto)'},
          {columns: ['type'], size: 'minmax(50px, auto)'},
          {columns: ['labelkey'], size: 'minmax(100px, auto)'},
          {columns: ['initial'], size: 'max-content'},
          {
            columns: ['labelInUse', 'labelGroupInUse'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['style'], size: 'minmax(100px, auto)'},
            {columns: ['type'], size: 'minmax(50px, auto)'},
            {columns: ['labelkey'], size: 'minmax(100px, auto)'},
            {columns: ['initial'], size: 'max-content'},
            {
              columns: ['labelInUse', 'labelGroupInUse'],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
            {columns: ['edit'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['style'], size: 'minmax(100px, auto)'},
          {columns: ['type'], size: 'minmax(50px, auto)'},
          {columns: ['labelkey'], size: 'minmax(100px, auto)'},
          {columns: ['initial'], size: 'max-content'},
          {
            columns: ['labelInUse', 'labelGroupInUse'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['style'], size: 'minmax(100px, auto)'},
            {columns: ['labelkey', 'type', 'initial'], size: 'minmax(100px, auto)'},
            {
              columns: ['labelInUse', 'labelGroupInUse'],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
            {columns: ['edit'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['style'], size: 'minmax(100px, auto)'},
          {columns: ['labelkey', 'type', 'initial'], size: 'minmax(100px, auto)'},
          {
            columns: ['labelInUse', 'labelGroupInUse'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['style'], size: 'minmax(100px, auto)'},
            {columns: ['labelkey', 'type', 'initial'], size: 'minmax(100px, auto)'},
            {
              columns: ['labelInUse', 'labelGroupInUse'],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
            {columns: ['edit'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['style'], size: 'minmax(100px, auto)'},
          {columns: ['labelkey', 'type', 'initial'], size: 'minmax(100px, auto)'},
          {
            columns: ['labelInUse', 'labelGroupInUse'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['labelkey', 'type', 'style', 'initial'], size: 'minmax(100px, auto)'},
            {
              columns: ['labelInUse', 'labelGroupInUse'],
              size: 'minmax(100px, auto)',
              header: formatHeader,
            },
            {columns: ['edit'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          ...(isUserOwner ? [{columns: ['dragbar'], size: 'var(--40px)'}] : []),
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['labelkey', 'type', 'style', 'initial'], size: 'minmax(100px, auto)'},
          {
            columns: ['labelInUse', 'labelGroupInUse'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {columns: ['edit'], size: 'minmax(50px, auto)'},
        ];
      },
    },
  ],
}));
