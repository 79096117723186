/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteCurrent} from 'containers/App/AppState';
import {isAPIAvailable} from 'api/apiUtils';
import {getVenLibraries, getFilters} from 'containers/Ven/Library/VenLibraryState';
import {getVensPage} from 'containers/Ven/List/VenListState';
import intl from 'intl';

export default {
  targets(state = [], action) {
    // intended set of vens to upgrade
    switch (action.type) {
      case 'VENS_GET_TARGETS':
        return action.data.list;
      default:
        return state;
    }
  },
  targetCount(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_TARGETS':
        return action.data.count;
      default:
        return state;
    }
  },
  errors(state = [], action) {
    switch (action.type) {
      case 'VENS_UPGRADE': // dry_run?
        return action.data.errors;
      default:
        return state;
    }
  },

  endpointVENCount(state = 0, action) {
    switch (action.type) {
      case 'VENS_VEN_TYPE_ENDPOINT':
        return action.data;
      default:
        return state;
    }
  },

  // there are no batches/jobs. status is pieced together from any number of upgrade calls
  conditionTimeout(state = 0, action) {
    switch (action.type) {
      case 'VENS_CONDITION_AGENT_UPGRADE_TIME_OUT':
        return action.data;
      default:
        return state;
    }
  },
  conditionMissingHeartbeats(state = 0, action) {
    switch (action.type) {
      case 'VENS_CONDITION_AGENT_MISSING_HEARTBEATS_AFTER_UPGRADE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVensIntended = state => state.ven.targets;
export const getVensIntendedCount = state => state.ven.targetCount;

export const getErrors = state => state.ven.errors;
export const getVensUpgradeRequestedTrue = state => state.ven.requested;
export const getVensConditionTimeout = state => state.ven.conditionTimeout;
export const getVensMissingHeartbeats = state => state.ven.conditionMissingHeartbeats;

export const getVensVenTypeEndpoint = state => state.ven.endpointVENCount;

export const getVenUpgrade = createSelector(
  [getRouteCurrent, getFilters, getVenLibraries, getVensIntended, getVensIntendedCount, getVensPage],
  (route, calculatedFilterMap, libraries, targets, targetCount, {rowsMap}) => {
    const venTypes = [];

    targets.forEach(venHref => {
      if (venHref && !venTypes.includes(rowsMap.get(venHref.href)?.data.ven_type)) {
        venTypes.push(rowsMap.get(venHref.href)?.data?.ven_type);
      }
    });

    const filteredReleases = libraries
      ?.sort((a, b) => (a.release < b.release ? 1 : -1))
      .reduce((result, r) => {
        const venType =
          r.ven_types?.includes('server') && r.ven_types?.includes('endpoint')
            ? intl('VEN.ServerEndpoint')
            : r.ven_types?.includes('endpoint')
            ? intl('VEN.EndpointOnly')
            : intl('VEN.ServerOnly');

        if (
          venType === intl('VEN.ServerEndpoint') ||
          (venType === intl('VEN.ServerOnly') && venTypes.includes('server')) ||
          (venType === intl('VEN.EndpointOnly') && venTypes.includes('endpoint'))
        ) {
          result.push({
            label: `${r.release} ${r.default ? intl('CoreServices.Default') : ''} (${venType})`,
            value: r.release,
          });
        }

        return result;
      }, []);

    return {
      calculatedFilterMap,
      route,
      libraries: filteredReleases,
      targets,
      targetCount,
      venTypes,
      dispatchEvent: false,
      isVensUpgradeAvailable: isAPIAvailable('vens.upgrade'),
    };
  },
);

export const getVenUpgradeErrors = createSelector([getErrors], errors => ({errors}));

export const getVenUpgradeStatus = createSelector([getVensUpgradeRequestedTrue], requested => ({...requested}));
