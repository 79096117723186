/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {getLoadBalancers, getLoadBalancersCount, getGridSettings, getFilterMap} from './LoadBalancerListState';
import {fetchAllVirtualServers} from 'containers/VirtualServer/List/VirtualServerListSaga';
import gridSaga from 'components/Grid/GridSaga';
import {hrefUtils} from 'utils';
import {isLoadBalancerEnabled} from '../LoadBalancerState';
import {RedirectError} from 'errors';
import {isEdge} from 'containers/App/AppState';

export function* fetchLoadBalancers({filter, force = false} = {}) {
  if (!(yield select(isLoadBalancerEnabled))) {
    return {list: [], count: 0};
  }

  const query = {max_results: 500};

  if (filter) {
    for (const [name, values] of Object.entries(filter)) {
      const value = values[0];

      query[name] = typeof value === 'object' ? (value.isPartial ? value.value : value.id) : value;
    }
  }

  return yield call(apiSaga, 'slbs.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getLoadBalancers)) || count !== (yield select(getLoadBalancersCount))) {
        yield put({type: 'SLB_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removeLoadBalancer({href}) {
  yield call(apiSaga, 'slb.delete', {
    params: {slb_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* fetchDeviceTypes({force = false} = {}) {
  yield call(apiSaga, 'nfcs.get_collection', {
    ignoreCodes: [404],
    cache: !force,
    *afterFetch({data}) {
      yield put({type: 'NFCS_GET_LIST', data});
    },
  });
}

export function* fetchLoadBalancerList(route, refetch = false) {
  yield call(fetchDeviceTypes);

  const loadBalancerIsEnabled = yield select(isLoadBalancerEnabled);
  const edgeEnabled = yield select(isEdge);

  if (!loadBalancerIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap,
    *onSaga({filterParams}) {
      const {list} = yield call(fetchLoadBalancers, {
        filter: filterParams.isEmpty ? undefined : filterParams.valid,
        force: refetch,
      });

      yield all([call(fetchAllVirtualServers, {force: refetch}), call(fetchDeviceTypes, {force: refetch})]);

      return list.length;
    },
  });
}
