/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {
  getRouteParams,
  getAdvancedRulesetDisplay,
  getAppGroupLabelTypes,
  getRoutePreviousParams,
} from 'containers/App/AppState';
import {getAllUsersMap, getUserSelectorHistory, isUserScoped} from 'containers/User/UserState';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';
import {isScopeRequired} from '../List/RulesetListState';
import {rulesGridSettings, ipTablesGridSettings} from './Configs/RulesetItemConfig';
import {fillPolicyObjUserInfo, getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {getId} from 'utils/href';
import {getRulesetSummaryObj, getPolicyGeneratorId, areActionButtonsDisabled} from './RulesetItemUtils';
import {moveItemInArray} from 'intl/utils';
import produce from 'immer';
import {isOnboardingObject} from 'antman/utils/general';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_ITEM':
        return action.data;
      case 'RULESET_ITEM_REORDER':
        return produce(state, draftState => {
          const {from, to, pversion, type} = action.data;
          const {active, draft} = draftState;
          const key = type === 'overrideDeny' || type === 'deny' ? 'deny_rules' : 'rules';

          if (pversion === 'draft' || (draft && !draft.update_type)) {
            draft[key] = moveItemInArray(draft[key], from, to);
          } else {
            active[key] = moveItemInArray(active[key], from, to);
          }
        });
      default:
        return state;
    }
  },
  pceUpgradeRuleset(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_PCE_UPGRADE_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getRulesetDetail = state => state.ruleset.detail;
export const getPCEUpgradeRuleset = state => state.ruleset.pceUpgradeRuleset;

export const getRulesetVersions = createSelector([getRulesetDetail, getRouteParams], (rulesetDetail, {pversion}) =>
  getPolicyVersions(rulesetDetail, pversion),
);

export const getRulesetItem = createSelector(
  [
    ipTablesGridSettings,
    rulesGridSettings,
    getRulesetVersions,
    getRouteParams,
    getAllUsersMap,
    getAdvancedRulesetDisplay,
    isUserScoped,
    isScopeRequired,
    getAppGroupLabelTypes,
    getRoutePreviousParams,
    getUserSelectorHistory,
    getDisplayNames,
  ],
  (
    ipTablesGridSettings,
    rulesGridSettings,
    {versions, pversionObjIsDeleted, isOldVersion},
    {pversion, tab},
    usersMap,
    advancedRulesetDisplay,
    userIsScoped,
    scopeIsRequired,
    appGroupsType,
    prevRouteParams,
  ) => {
    const provisionIsDisabled = !versions.draft?.caps.includes('provision');
    const rulesetId = getId(versions.pversionObj?.href);

    const versionsWithUserInfo = fillPolicyObjUserInfo(versions, usersMap);

    const policyGeneratorId = getPolicyGeneratorId(versions.pversionObj?.scopes[0], appGroupsType);
    const actionButtonsDisabled = areActionButtonsDisabled(versions);

    return {
      ipTablesGridSettings,
      rulesGridSettings,
      versions: versionsWithUserInfo,
      pversionObjIsDeleted,
      pversion,
      tab,
      provisionIsDisabled,
      rulesetId,
      isOldVersion,
      summaryObj: getRulesetSummaryObj(versionsWithUserInfo),
      isAdditionPending: versions.draft?.update_type === 'create',
      advancedRulesetDisplay,
      userIsScoped,
      scopeIsRequired,
      policyGeneratorId,
      prevRouteParams,
      actionButtonsDisabled,
      isXpressOnboardingObject: __ANTMAN__ && isOnboardingObject(versions.pversionObj?.external_data_reference),
    };
  },
);
