/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {ReverseProtocolMap} from 'containers/Service/ServiceUtils';

//convert UI datastructure to API ready datastructure
export const getWriteableFlowCollectionsData = (flowCollectionData, initialValues, isEdit) => {
  const diff = _.reduce(
    initialValues,
    (result, value, key) => (_.isEqual(value, flowCollectionData[key]) ? result : result.concat(key)),
    [],
  );

  const result = isEdit ? _.pick(flowCollectionData, diff) : flowCollectionData;

  const data = _.pick(result, ['action', 'transmission']);

  data.data_source = flowCollectionData.source;
  data.network = flowCollectionData.network;

  if (flowCollectionData.action === 'drop') {
    data.target = {};

    Object.keys(result).forEach(item => {
      if (item === 'protocol') {
        const protoValue = typeof result.protocol === 'string' ? result.protocol : result[item][0]?.value;
        const supportedProtocols = new Set(['TCP', 'UDP', 'ICMP', 'ICMPv6']);

        if (supportedProtocols.has(protoValue)) {
          data.target.proto = ReverseProtocolMap[protoValue.toUpperCase()];
        }
      } else if (item === 'destport' && result[item] && !isNaN(result[item])) {
        data.target.dst_port = Number(result[item]);
      } else if (item === 'destip' && result[item]) {
        data.target.dst_ip = isEdit && !result[item] ? '0.0.0.0/0' : result[item];
      } else if (item === 'srcport' && result[item] && !isNaN(result[item])) {
        data.target.src_port = Number(result[item]);
      } else if (item === 'srcip' && result[item]) {
        data.target.src_ip = isEdit && !result[item] ? '0.0.0.0/0' : result[item];
      } else if (isEdit && !result[item]) {
        if (item === 'destip') {
          data.target.dst_ip = '0.0.0.0/0';
        } else if (item === 'srcip') {
          data.target.src_ip = '0.0.0.0/0';
        } else {
          data.target.dst_port = -1;
        }
      }
    });
  }

  return data;
};

export const portIP = {
  destPort: 'dst_port',
  destIP: 'dst_ip',
  srcPort: 'src_port',
  srcIP: 'src_ip',
};

export const sourceMap = {
  any: intl('Protocol.Any'),
  server: intl('VEN.VEN'),
  endpoint: intl('VEN.EVEN'),
};

export const networkMap = {
  any: intl('Protocol.Any'),
  corporate: intl('Common.Corporate'),
  external: intl('Common.External'),
};
