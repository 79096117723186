/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React, {Component} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {Button, Modal, Form, ModalMachineAuto, Notifications} from 'components';
import {object, number} from 'yup';
import {updateUserSettings} from 'containers/User/Settings/SettingsSaga';
import _ from 'lodash';
import {getUserSettings} from 'containers/User/Settings/SettingsState';
import {connect} from 'utils/redux';
import styles from './MapFilterResultSettingsModal.css';
import type {FormikProps} from 'formik';
import {MAX_NUMBER_CONNS_DB, MAX_NUMBER_CONNS_EXPLORER, MIN_NUMBER_CONNS} from 'containers/User/Profile/MyProfile';

export const MAX_NUMBER_CONNS_EXPLORER_MAX_RESULTS_DEFAULT = 5000;
export const MAX_NUMBER_CONNS_EXPLORER_MAX_DOWNLOAD_RESULTS_DEFAULT = 10_000;

interface FormValue {
  explorerMaxResults: string;
  explorerMaxDownloadResults: string;
}

export type ResultsSettingsDialogProps = {
  userSettings?: {explorerMaxResults?: number; explorerMaxDownloadResults?: number; defaultPolicyVersion?: string};
  clusters?: unknown[];
  onClose: () => void;
};

export type ResultsSettingsDialogState = {
  saveInProgress: boolean;
  saveError: unknown;
};

class ResultsSettingsDialog extends Component<ResultsSettingsDialogProps, ResultsSettingsDialogState> {
  static contextType = AppContext;
  // eslint-disable-next-line react/static-property-placement
  declare context: React.ContextType<typeof AppContext>;

  initialValues: FormValue;

  schemas = object({
    explorerMaxResults: number()
      .typeError(intl('PasswordPolicy.Errors.Number'))
      .integer(intl('PasswordPolicy.Errors.Integer'))
      .required(intl('PasswordPolicy.Errors.Required'))
      .min(MIN_NUMBER_CONNS, intl('Explorer.WrongValidRange'))
      .max(MAX_NUMBER_CONNS_EXPLORER, intl('Explorer.WrongValidRange')),
    explorerMaxDownloadResults: number()
      .typeError(intl('PasswordPolicy.Errors.Number'))
      .integer(intl('PasswordPolicy.Errors.Integer'))
      .required(intl('PasswordPolicy.Errors.Required'))
      .min(MIN_NUMBER_CONNS, intl('Explorer.WrongValidRange'))
      .max(MAX_NUMBER_CONNS_DB, intl('Explorer.WrongValidRange')),
  });

  formik?: FormikProps<FormValue>;

  constructor(props: ResultsSettingsDialogProps) {
    super(props);

    this.state = {saveInProgress: false, saveError: null};
    this.initialValues = {
      explorerMaxResults: String(
        props.userSettings?.explorerMaxResults ?? MAX_NUMBER_CONNS_EXPLORER_MAX_RESULTS_DEFAULT,
      ),
      explorerMaxDownloadResults: String(
        props.userSettings?.explorerMaxDownloadResults ?? MAX_NUMBER_CONNS_EXPLORER_MAX_DOWNLOAD_RESULTS_DEFAULT,
      ),
    };

    this.renderForm = this.renderForm.bind(this);
    this.renderCustomFooter = this.renderCustomFooter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(): Promise<void> {
    const newSettings = {
      explorerMaxResults: parseInt(this.formik!.values.explorerMaxResults, 10),
      explorerMaxDownloadResults: parseInt(this.formik!.values.explorerMaxDownloadResults, 10),
    };

    this.setState({saveInProgress: true, saveError: null});

    try {
      await this.context.fetcher.spawn(updateUserSettings, {settings: newSettings});
    } catch (error) {
      this.setState({saveError: error});
    } finally {
      this.setState({saveInProgress: false});
      this.props.onClose();
    }
  }

  renderForm(_context: unknown, _send: unknown, formik: FormikProps<FormValue>): JSX.Element {
    this.formik = formik;

    const numOfClusters = this.props.clusters?.length ?? 1;
    const fieldTitles = {
      explorerMaxResults: this.context.isNewUI
        ? intl('Explorer.DisplayedInTraffic')
        : intl('Explorer.DisplayedInIlluminationPlus'),
      explorerMaxDownloadResults: intl('Explorer.ReturnedFromDB', {numOfClusters}),
    };

    return (
      <>
        <Notifications>{[{type: 'instruction', message: intl('Explorer.ResultsSettingsInfoMessage')}]}</Notifications>
        <h3 className={styles.maxConnectionsTitle}>{intl('Explorer.MaxNumberOfConnections')}</h3>
        <div className={styles.formFields}>
          <Form.Label theme={styles} name="explorerMaxResults" title={fieldTitles.explorerMaxResults} />
          <Form.Input theme={styles} tid="explorer-max-results" name="explorerMaxResults" />
          <span className={styles.fieldValidRange}>
            {intl('Explorer.ValidRange', {min: MIN_NUMBER_CONNS, max: MAX_NUMBER_CONNS_EXPLORER})}
          </span>
          <Form.Label theme={styles} name="explorerMaxDownloadResults" title={fieldTitles.explorerMaxDownloadResults} />
          <Form.Input theme={styles} tid="explorer-max-download-results" name="explorerMaxDownloadResults" />
          <span className={styles.fieldValidRange}>
            {intl('Explorer.ValidRange', {min: MIN_NUMBER_CONNS, max: MAX_NUMBER_CONNS_DB})}
          </span>
        </div>
      </>
    );
  }

  renderCustomFooter(send: ({}: {type: string}) => void): JSX.Element {
    const saveDisabled = !_.isEmpty(this.formik?.errors);

    return (
      <Modal.Footer>
        <div className={styles.modalActions}>
          <Button
            key="cancel"
            tid="cancel"
            color="standard"
            text={intl('Common.Cancel')}
            onClick={_.partial(send, {type: 'CANCEL'})}
          />
          <Button
            key="submit"
            tid="submit"
            icon="check"
            text={intl('Common.OK')}
            disabled={saveDisabled}
            onClick={_.partial(send, {type: 'SUBMIT'})}
          />
        </div>
      </Modal.Footer>
    );
  }

  render() {
    return (
      <ModalMachineAuto
        onClose={this.props.onClose}
        saga={this.onSubmit}
        modalProps={{large: true, notResizable: true, dontRestrainChildren: true}}
        contentProps={{notScrollable: true}}
      >
        {{
          title: intl('Explorer.ResultsSettings'),
          formProps: {
            schemas: this.schemas,
            initialValues: this.initialValues,
            allowLeaveOnDirty: true,
          },
          confirmMessage: this.renderForm,
          customFooter: this.renderCustomFooter,
        }}
      </ModalMachineAuto>
    );
  }
}

const connector = connect(state => ({userSettings: getUserSettings(state)}));

export default connector(ResultsSettingsDialog);
