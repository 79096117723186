/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {string} from 'yup';
import {Component, createRef} from 'react';
import type {MouseEvent, MutableRefObject, ChangeEvent, ComponentPropsWithRef, ComponentPropsWithoutRef} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {tidUtils} from 'utils';
import {randomString} from '@thor/utils';
import styles from './Radio.css';
import stylesUtils from 'utils.css';
import {RadioContext} from './RadioGroup';
import type {ReactStrictNode} from 'utils/types';

type RadioProps = ComponentPropsWithoutRef<'div'> &
  ThemeProps & {
    tid?: string;
    name?: string;
    value: string;

    // Whether radio should have error stroke color
    error?: boolean;
    // Whether radio should be disabled, also makes it insensitive
    disabled?: boolean;
    // Whether radio should not be sensitive to click (pointer-events: none)
    insensitive?: boolean;
    // Whether radio should be not visible, but still occupy its space
    hidden?: boolean;
    // By default radio input is _uncontrolled_, that means dom will be automatically updated by component itself on label click.
    // You can pass 'checked' attribute to make it _controlled_ by your parent component,
    // in which case click on the radio will just invoke onChange handler where parent can decide to rerender radio with new value
    checked?: boolean;
    // When radio should be hoverable but not clickable, because parent will handle the click event
    // Useful in case of controlled component, when clicking on parent element. Like MenuItem handles click, not radio itself
    notChangeable?: boolean;

    // Optional label text regardless of checked/unchecked state. Is bold when `subLabel` is specified
    label?: ReactStrictNode;
    // Optional text on the next line below label, only shown if label is shown
    subLabel?: ReactStrictNode;
    // Optional label for the checked state. Useful in uncontrolled version, to avoid tracking state in parent component
    // Is bold when `subLabelChecked` is specified
    labelSelected?: ReactStrictNode;
    // Optional text on the next line below label for the checked state, only shown if labelChecked is shown
    subLabelSelected?: ReactStrictNode;
    // Any markup that should go after label/sublabel.
    // Useful for showing some nested dependent form element aligned with the label text
    nested?: ReactStrictNode;
    // The same on selected state
    nestedChecked?: ReactStrictNode;
    // Custom props for a hidden <input/> radio, for instance for specifying custom data-tid
    inputProps?: ComponentPropsWithRef<'input'>;
    // Custom props for a <label> around the input, for instance for specifying custom data-tid
    labelProps?: ComponentPropsWithRef<'label'>;
    // preventInsensitive - When we set disable: true but also want a mouse event on the label input
    preventInsensitive?: boolean;

    labelChecked?: string | undefined;
    subLabelChecked?: string | undefined;
  };

type RadioState = {
  checked: boolean;
};

export default class Radio extends Component<RadioProps, RadioState> {
  static schema = string().label('radio');
  static contextType = RadioContext;

  // TODO fix when refactoring RadioGroup.js to TS
  // declare context: ContextType<typeof RadioContext>;

  radio: MutableRefObject<HTMLInputElement | null>;
  id: string;
  labelWasClicked: boolean;

  constructor(props: RadioProps) {
    super(props);

    this.state = {checked: false};
    this.radio = createRef();
    this.labelWasClicked = false;
    // Generate unique id to tied input and text labels
    this.id = randomString(5, true);

    this.handleLabelClick = this.handleLabelClick.bind(this);
    this.handleRadioClick = this.handleRadioClick.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps: RadioProps, prevState: RadioState) {
    const controlled = typeof nextProps.checked === 'boolean';

    if (controlled && nextProps.checked !== prevState.checked) {
      return {checked: nextProps.checked};
    }

    return null;
  }

  handleLabelClick(evt: MouseEvent): void {
    const {notChangeable} = this.props;

    // If component is notChangeable don't change value and exit handler to let parent's handler do the job if needed
    if (notChangeable) {
      return evt.preventDefault();
    }

    this.labelWasClicked = true;
    evt.stopPropagation(); // To prevent parent click event
  }

  handleRadioClick(evt: MouseEvent): void {
    // To prevent 'handleLabelClick' one more time in case of Firefox manual click call on shift+click
    if (this.labelWasClicked) {
      evt.stopPropagation();
      this.labelWasClicked = false;
    }
  }

  handleRadioChange(evt: ChangeEvent<HTMLInputElement>): void {
    const {
      props: {notChangeable},
    } = this;

    if (notChangeable) {
      return;
    }

    // TODO fix when refactoring RadioGroup.js to TS
    // @ts-ignore
    const onChange = this.props.onChange || this.context?.onChange;

    if (onChange) {
      onChange(evt, this.props);
    } else {
      this.setState({checked: this.radio.current?.checked ?? false});
    }

    this.labelWasClicked = false;
  }

  render() {
    const {
      name,
      value,
      tid,
      error = false,
      disabled = false,
      insensitive = false,
      hidden = false,
      notChangeable = false,
      label,
      subLabel,
      labelChecked,
      subLabelChecked,
      nested,
      nestedChecked,
      theme,
      preventInsensitive,
      // Custom input properties
      inputProps: {...inputProps} = {},
      labelProps: {...labelProps} = {},
      // Other label wrapper properties
      ...radioProps
    } = mixThemeWithProps(styles, this.props);

    // @ts-ignore
    const checked = this.context ? value === this.context.value : this.state.checked;
    const text = checked && labelChecked !== undefined ? labelChecked : label;
    const subText = checked && subLabelChecked !== undefined ? subLabelChecked : subLabel;
    const nestedElements = checked && nestedChecked !== undefined ? nestedChecked : nested;
    const showSide = Boolean(text || subText || nestedElements);
    const box = <div className={cx(theme.box, {[theme.boxFilled]: checked})} />;

    radioProps.className = cx(theme.radio, {
      [theme.error]: error,
      [theme.hidden]: hidden,
      [theme.checked]: checked,
      [theme.disabled]: disabled,
      [stylesUtils.insensitive]: insensitive || (disabled && !preventInsensitive),
    });

    labelProps.className = cx(theme.labelBox, {[theme.noText]: !showSide});
    labelProps.onClick = this.handleLabelClick;

    labelProps['data-tid'] ||= 'radio-clickable';

    // TODO fix when refactoring RadioGroup.js to TS
    // @ts-ignore
    inputProps.name = name || (this.context && this.context.name);
    inputProps.value = value;
    inputProps.type = 'radio';
    inputProps.checked = checked;
    inputProps.disabled = disabled;
    inputProps.ref = this.radio;
    inputProps.className = theme.input;
    inputProps.onClick = this.handleRadioClick;
    inputProps.onChange = this.handleRadioChange;

    inputProps['data-tid'] ||= 'radio-input';

    inputProps.id ||= this.id;

    radioProps['data-tid'] = tidUtils.getTid('radio', tid);

    return (
      <div {...radioProps}>
        <label {...labelProps}>
          <input {...inputProps} />
          {box}
        </label>

        {showSide && (
          <div className={theme.side}>
            {text ? (
              <label
                htmlFor={inputProps.id}
                className={cx(theme.labelText, {[stylesUtils.bold]: subText})}
                onClick={this.handleLabelClick}
                data-tid="radio-text"
              >
                {text}
              </label>
            ) : null}
            {subText ? (
              <label
                htmlFor={inputProps.id}
                className={theme.labelSubText}
                onClick={this.handleLabelClick}
                data-tid="radio-subtext"
              >
                {subText}
              </label>
            ) : null}
            {nestedElements ? <div className={theme.nestedElements}>{nestedElements}</div> : null}
          </div>
        )}
      </div>
    );
  }
}
