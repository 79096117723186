/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import Selectors from '../Selectors';
import {PureComponent} from 'react';
import {connect} from 'utils/redux';
import {getSelectorFacets, getFacetCount, getFacetName, isLoadingFacets} from 'containers/Selectors/SelectorState';

@connect((state, props) => {
  if (!props.facets || !props.facets.length) {
    return {};
  }

  return {
    loading: isLoadingFacets(state),
    matches: getSelectorFacets(state, props.object.type),
    count: getFacetCount(state, props.object.type),
    facet: getFacetName(state, props.object.type),
  };
})
export default class MultiGroupSingleItemSelect extends PureComponent {
  static propTypes = {
    initialItems: PropTypes.array,
    disabled: PropTypes.bool,
    facets: PropTypes.array,
    statics: PropTypes.object,
    customPickers: PropTypes.object,
    categories: PropTypes.array.isRequired,
    hiddenCategories: PropTypes.array,
    object: PropTypes.any,
    addValue: PropTypes.func,
    partials: PropTypes.array,
    maxResults: PropTypes.number,
    placeholder: PropTypes.string,
    footer: PropTypes.any,
    onSelectionChange: PropTypes.func,
    skipOptions: PropTypes.array,
  };

  static defaultProps = {
    facets: [],
    disabled: false,
    skipOptions: [],
  };

  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpenCategory = this.handleOpenCategory.bind(this);
  }

  getDropdownValues() {
    const {matches, facet, skipOptions} = this.props;

    if (!matches) {
      return [];
    }

    let finalMatches = [];

    if (skipOptions.length === 0) {
      finalMatches = matches;
    } else {
      matches.forEach(match => {
        let skipSign = false;

        skipOptions.forEach(skipOption => {
          if (skipOption.facet === facet && skipOption.value === match) {
            skipSign = true;
          }
        });

        if (!skipSign) {
          finalMatches.push(match);
        }

        skipSign = false;
      });
    }

    return finalMatches.map(match => {
      if (typeof match === 'string') {
        return {categoryKey: facet, value: match};
      }

      return {categoryKey: facet, ...match};
    });
  }

  getStaticValues() {
    if (this.props.statics) {
      return Object.entries(this.props.statics).reduce((result, [key, value]) => {
        result[key] = value.map(value => ({
          categoryKey: key,
          value: typeof value === 'object' ? value.value : value,
          desc: value.desc,
        }));

        return result;
      }, {});
    }

    return;
  }

  handleOpenCategory(facet) {
    const {object, facets} = this.props;

    if (facets.includes(facet)) {
      this.fetchData('', object, facet);
    }
  }

  handleInputChange(input, object, facet) {
    const {facets} = this.props;

    if (facets.includes(facet)) {
      this.fetchData(input, object, facet);
    }
  }

  fetchData(input, object, facet) {
    const {type, ...params} = object;
    const query = {query: input, facet};

    this.props.dispatch({type: `${type.toUpperCase()}_FACETS_REQUEST`, object: type, query, params});
  }

  render() {
    const {
      initialItems,
      placeholder,
      hiddenCategories,
      loading,
      disabled,
      statics,
      customPickers,
      object,
      count,
      partials,
      footer,
      maxResults,
      skipOptions,
    } = this.props;
    const matches = this.getDropdownValues();
    const finalCount = count - skipOptions.length;
    const categories = [...this.props.categories];

    if (hiddenCategories && hiddenCategories.length) {
      categories.push({categoryKey: 'show_all_categories', value: intl('ObjectSelector.ShowAllCategories')});
    }

    const props = {
      initialItems,
      placeholder,
      activeCategoryKey: categories[0].categoryKey,
      disabled,
      objects: [object],
      categories,
      clearAll: true,
      hiddenCategories,
      partials,
      statics: this.getStaticValues(statics),
      customPickers,
      loading,
      matches: {matches, finalCount},
      footer,
      maxResults,
      allowMultipleItems: true,
      hideCategory: true,
      showContainerTitle: true,
      showFacetName: true,
      onInputChange: this.handleInputChange,
      onOpenCategory: this.handleOpenCategory,
      onSelectionChange: this.props.onSelectionChange,
    };

    return <Selectors {...props} />;
  }
}
