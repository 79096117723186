/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
/* eslint-disable react/jsx-no-bind */
import {partial} from 'lodash';
import intl from 'intl';
import {Pill, Icon, ExpandableList, StatusIcon, Tooltip} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import styles from './MapList.css';
import {getEnforcementMode} from '../Utils/MapTrafficQueryResponseUtils';
import {getPort, lookupICMPCode} from 'containers/Service/ServiceUtils';
import {getPolicyDecisionSubtext} from 'containers/IlluminationMap/MapPolicyUtils';
import {isManagedEndpoint, isUnmanagedEndpoint, isUnmanagedWorkload} from '../MapTypes';
import cx from 'classnames';
import stylesUtils from 'utils.css';
import {
  getContainerWorkloadPill,
  getIpAddressOrIpCountPill,
  getIPListPill,
  getServiceMenuItems,
  getVirtualServicePill,
  getWorkloadPill,
  getServicePill,
  getProviderAndConsumerMenuItems,
} from './MapListPillUtils';

export function getGridData(links, useIndex, policy = 'reported', isCSFrame = false) {
  return (links || []).map(link => ({
    key: useIndex ? link.index : link.aggregationKey,
    selectable:
      policy === 'draft' &&
      link.caps?.has('write') &&
      (isCSFrame ? link.policy?.reported?.decision !== 'unknown' : true),
    sortable: true,
    data: {
      ...link,
      source: {
        ...link.source,
      },
      target: {
        ...link.target,
      },
    },
  }));
}

export const sortCheck = ({rowA, rowB, valueA, valueB, sortFactor}) => {
  if (valueA === valueB) {
    valueA = rowA.data.connectionKey;
    valueB = rowB.data.connectionKey;
  }

  return valueB > valueA ? sortFactor : valueB < valueA ? -1 * sortFactor : 0;
};

export const handleViewRuleClick = (policy, component) => {
  component.handleViewRule(policy);
};

export const doesRowHaveDeletedWorkloads = data =>
  Boolean(
    data?.source?.deletedWorkloadIps?.size ||
      data?.target?.deletedWorkloadIps?.size ||
      data?.source?.details.subType === 'deleted' ||
      data?.target?.details.subType === 'deleted',
  );

export const formatPolicyDecision = (
  data,
  policyVersion,
  connection,
  colorDeficiency,
  component,
  isCSFrame = false,
) => {
  const edge = false;
  const policy = data.policy[policyVersion];
  const deleted = doesRowHaveDeletedWorkloads(data);

  let policyClassNames = colorDeficiency === 'normal' ? policy.decision : `${policy.decision}-deficiency`;
  const isFlowNA = isCSFrame && data.policy.reported.decision === 'unknown';
  const externalNetwork = data.network?.name === 'External';

  let name = policy.name;
  let subtextSegments = [getPolicyDecisionSubtext(policy.decision, isCSFrame)];

  if (policyVersion === 'reported' && policy.decision !== 'unknown' && connection !== 'aggregated') {
    subtextSegments.push(
      intl('Common.ByEndpoint', {
        endpoint: data.direction === 'outbound' ? intl('Common.Consumer') : intl('Common.Provider'),
      }),
    );
  }

  // Handle Impervius Non Azure firewall traffic case
  if (isFlowNA) {
    policyClassNames = 'unknown';
    name = intl('Common.NA');
    subtextSegments = [intl('Common.AzureNSGFlow')];
  }

  // Handle Edge Special Cases
  if (externalNetwork) {
    subtextSegments = [intl('Explorer.ExternalPolicy')];
  } else if (edge && data.direction === 'outbound' && data.policy.draft.rules) {
    // Edge outbound default policy
    name = intl('Common.Allowed');
    subtextSegments = [intl('Explorer.DefaultPolicy')];
  }

  if (
    policyVersion === 'draft' &&
    (policy.decision?.includes('allowed') || policy.decision?.includes('Boundary')) &&
    !isFlowNA
  ) {
    name = (
      <Tooltip content={intl('Explorer.ViewPolicy')}>
        <div className={styles.link} onClick={partial(handleViewRuleClick, policy, component)}>
          {policy.name}
        </div>
      </Tooltip>
    );
  }

  if (policyVersion !== 'reported' && deleted) {
    name = intl('Map.LegendPanel.UnknownRuleStatus');
    subtextSegments = [];
  }

  const subtext = subtextSegments.filter(segment => Boolean(segment)).join(' ');
  const classNames = cx({
    [styles[policyClassNames]]: policyVersion === 'reported' || !deleted,
  });

  return (
    <div className={styles.policy}>
      <div className={classNames}>{name}</div>
      <div className={styles.subText}>{subtext}</div>
    </div>
  );
};

export const getManagedEndpointPill = (endpoint, component) => {
  const endpointDetails = endpoint.details;

  switch (endpoint.type) {
    case 'virtualService':
      return getVirtualServicePill(endpoint, component);
    case 'virtualServer':
      return (
        <Pill.VirtualServer
          key={endpointDetails.href}
          id={endpointDetails.href}
          value={endpointDetails}
          contextualMenuFullIp={endpoint.fullIp}
        />
      );
    case 'workload':
      if (endpointDetails.subType === 'deleted') {
        return <Pill icon="workload">{endpointDetails.name}</Pill>;
      }

      if (endpointDetails.subType === 'containerWorkload') {
        return getContainerWorkloadPill(endpoint, component);
      }

      return getWorkloadPill(endpoint, component);
    default:
      return null;
  }
};

export const getTooltipTable = tooltipData => {
  const SUBSET_MAX_ELEMENTS = 40;
  let tooltipArray = [];

  if (tooltipData.length > SUBSET_MAX_ELEMENTS) {
    const extraElements = tooltipData.length - SUBSET_MAX_ELEMENTS;
    const subsetElements = tooltipData.slice(0, SUBSET_MAX_ELEMENTS);

    subsetElements.push(`+${extraElements} more`);

    tooltipArray = subsetElements;
  } else {
    tooltipArray = tooltipData;
  }

  const numOfCols = Math.max(Math.trunc(Math.sqrt(tooltipArray.length)) - 2, 1);
  const numOfRows = Math.ceil(tooltipArray.length / numOfCols);

  const rows = [];
  let tooltipArrayIndex = 0;

  for (let row = 0; row < numOfRows; ++row) {
    const cell = [];

    for (let col = 0; col < numOfCols && tooltipArrayIndex < tooltipArray.length; ++col) {
      cell.push(<td key={`row${row}col${col}`}>{tooltipArray[tooltipArrayIndex++]} </td>);
    }

    rows.push(<tr key={`row${row}`}>{cell}</tr>);
  }

  return (
    <table>
      <tbody>{rows}</tbody>
    </table>
  );
};

export const getIpAddressOrIpCount = (endpoint, type, connection, component) => {
  let value = null;

  if (connection === 'aggregated') {
    const count = endpoint.ips?.size;
    const deletedWorkloadCount = endpoint.deletedWorkloadIps.size;
    let toolTipText = null;

    if (deletedWorkloadCount > 0) {
      value = intl('Common.DeletedWorkloadCount', {count: deletedWorkloadCount});
      toolTipText = [...endpoint.deletedWorkloadIps];
    } else {
      value = type === 'source' ? intl('Explorer.ConsumerCount', {count}) : intl('Explorer.ProviderCount', {count});
      toolTipText = [...endpoint.ips];
    }

    return (
      <div className={styles.ipCounts}>
        {value}
        <StatusIcon
          key="info"
          status="info"
          pointer
          position="after"
          tooltip={getTooltipTable(toolTipText)}
          tooltipProps={{top: true, fast: true, maxWidth: 1000}}
        />
      </div>
    );
  }

  value = endpoint.ip;

  return getIpAddressOrIpCountPill(value, value, component);
};

export const getFQDN = endpoint => {
  return isUnmanagedEndpoint(endpoint) ? endpoint.details.fqdn : null;
};

export const getEnforcement = (endpoint, connection) => {
  let value;

  if (isManagedEndpoint(endpoint) && !isUnmanagedWorkload(endpoint)) {
    if (connection === 'aggregated') {
      const uniqueMode = [...endpoint.modes];

      value = getEnforcementMode(uniqueMode.length > 1 ? 'mixed' : uniqueMode[0]);
    } else {
      value = getEnforcementMode(endpoint.details.mode);
    }
  }

  return value || null;
};

export const getAggregatedItems = (row, type) => {
  return [...row.links].reduce((result, item) => {
    const endpoint = item[type];

    if (isManagedEndpoint(endpoint)) {
      const name = endpoint.details.name;
      const hostname = endpoint.details.hostname;
      const key = name || hostname;

      if (key !== 'Deleted Workload') {
        result[key] = {...item};
      }
    }

    return result;
  }, {});
};

export const getIpListsOrAggregatedLists = (endpoint, row, type, connection, anyList, component) => {
  let values = [];
  let itemFunc = null;
  const node = endpoint.details;

  //create ipList pills in aggregated and individual connections
  if (node.ipLists && node.ipLists.length) {
    values = node.ipLists;
    itemFunc = ipList => {
      return (
        <Pill.IPList
          key={ipList.href}
          value={ipList}
          contextualMenuIpAddresses={connection === 'aggregated' ? [...endpoint.fullIps] : [endpoint.fullIp]}
          contextualType={intl('Common.IPList')}
          contextualMenu={defaultMenuItems => {
            return getProviderAndConsumerMenuItems({
              filterCategory: 'ip_list',
              labels: [ipList],
              copyText: ipList.name,
              defaultMenuItems,
              component,
            });
          }}
        />
      );
    };
  }

  if (connection === 'aggregated') {
    const aggregatedItems = getAggregatedItems(row, type); // it will either be object of workloads or ipList

    if (isManagedEndpoint(endpoint)) {
      values = Object.keys(aggregatedItems);
      itemFunc = item => {
        const endpoint = aggregatedItems[item][type];

        return getManagedEndpointPill(endpoint, component) || item;
      };
    }
  }

  if (values?.length) {
    return (
      <ExpandableList
        values={values}
        showInModalFrom={50}
        title={type === 'target' ? intl('Common.Provider') : intl('Common.Consumer')}
      >
        {itemFunc}
      </ExpandableList>
    );
  }

  if (anyList) {
    if (connection === 'individual') {
      return endpoint.fullIp;
    }

    return (
      <Tooltip
        content={[...endpoint.fullIps].map(ip => (
          <div key={ip}>{ip}</div>
        ))}
      >
        {getIPListPill(anyList, component)}
      </Tooltip>
    );
  }

  return null;
};

export const getUserServices = (services, summary, component) => {
  return (
    <ExpandableList
      values={services || []}
      initialCount={summary ? 1 : 3}
      title={intl('Common.Services')}
      showInModalFrom={50}
    >
      {service => getServicePill(service.href, service, component)}
    </ExpandableList>
  );
};

const getColumnKeyName = (direction, value) => {
  return direction + value.charAt(0).toUpperCase() + value.slice(1);
};

export const getEndpointLabels = (direction, labelTypes, summary, connection, anyList) => {
  if (!labelTypes?.length) {
    return;
  }

  const templates = labelTypes.map(label => getColumnKeyName(direction, label.key));

  const columns = labelTypes.reduce((labelColumn, labelType) => {
    const endpointValue = labelType.key;
    const columnKeyName = getColumnKeyName(direction, endpointValue);
    const endpointHeader = labelType.display_name;
    const getColumnValue = ({row}) => {
      const endpoint = row.data[direction];

      return (isManagedEndpoint(endpoint) && endpoint.details.labelObject?.[endpointValue]) || null;
    };

    labelColumn[columnKeyName] = {
      noPadding: true,
      header: endpointHeader,
      ...GridUtils.clickableLabelColumn,
      value: getColumnValue,
    };

    return labelColumn;
  }, {});

  if (summary) {
    templates.push('name');
    columns.name = {
      format: ({row, component}) => {
        const endpoint = row.data[direction];

        if (isManagedEndpoint(endpoint)) {
          if (connection === 'individual' || !endpoint.details.labels?.length) {
            return <Tooltip content={endpoint.fullIp}>{getManagedEndpointPill(endpoint, component)}</Tooltip>;
          }

          return null;
        }
      },
      header: intl('Common.Name'),
    };

    templates.push('ipList');
    columns.ipList = {
      format: ({row, component}) => {
        const endpoint = row.data[direction];

        return (
          !isManagedEndpoint(endpoint) &&
          getIpListsOrAggregatedLists(endpoint, row.data, 'source', connection, anyList, component)
        );
      },
      header: intl('Common.IPLists'),
    };
  }

  return {columns, templates, horizontal: true};
};

export const getUnmanagedWorkloadLabels = (direction, labelTypes, connection, anyList) => {
  if (!labelTypes?.length) {
    return;
  }

  const templates = [];
  const columns = {};

  templates.push('ipList');
  columns.ipList = {
    format: ({row, component}) => {
      const endpoint = row.data[direction];

      return (
        !isManagedEndpoint(endpoint) &&
        getIpListsOrAggregatedLists(endpoint, row.data, 'source', connection, anyList, component)
      );
    },
    header: intl('Common.IPLists'),
  };

  return {columns, templates, horizontal: true};
};

function getPortProtocolPill(service, pillText, component) {
  const labels = [
    {
      value: pillText,
      detail: {
        port: service.port,
        proto: service.protocolNum,
      },
    },
  ];

  return (
    <Pill.Service
      key={pillText}
      contextualType={intl('Common.PortAndOrProtocol')}
      contextualMenu={defaultMenuItems => {
        return getServiceMenuItems({
          filterCategory: 'port_proto',
          labels,
          copyText: pillText,
          pillText,
          defaultMenuItems,
          component,
        });
      }}
      value={service}
    />
  );
}

export const formatPortProtocol = (service, component) => {
  const portProtocol = `${getPort(service) || ''} ${service.protocol}`.trim();

  return (
    <>
      {getPortProtocolPill(service, portProtocol, component)}
      {isNaN(service.icmpType)
        ? null
        : getPortProtocolPill(
            service,
            lookupICMPCode(service.icmpType, service.protocol) || intl('Explorer.ICMPType', {type: service.icmpType}),
            component,
          )}
      {service.icmpCode && (isNaN(service.icmpType) || service.icmpType !== service.icmpCode)
        ? getPortProtocolPill(
            service,
            portProtocol,
            lookupICMPCode(service.icmpCode, service.protocol) || intl('Explorer.ICMPCode', {type: service.icmpCode}),
            component,
          )
        : null}
    </>
  );
};

export const formatProcess = (service, component) =>
  service?.processName ? (
    <Pill
      key={service.processName}
      contextualType={intl('Common.Process')}
      contextualMenu={defaultMenuItems => {
        return getServiceMenuItems({
          filterCategory: 'process_name',
          labels: [service.processName],
          copyText: service.processName,
          defaultMenuItems,
          component,
        });
      }}
    >
      {service.processName}
    </Pill>
  ) : null;

export const formatWindowsService = (service, component) =>
  service?.windowsService ? (
    <Pill
      key={service.windowsService}
      contextualType={intl('Common.WinService')}
      contextualMenu={defaultMenuItems => {
        return getServiceMenuItems({
          filterCategory: 'windows_service_name',
          labels: [service.windowsService],
          copyText: service.windowsService,
          defaultMenuItems,
          component,
        });
      }}
    >
      {service.windowsService}
    </Pill>
  ) : null;

export const formatUserName = service =>
  service?.username ? (
    <div key={service.username} className={styles.subItem}>
      <Icon name="user" position="before" /> {service.username}
    </div>
  ) : null;

export const formatHeader = (header, user) => (
  <>
    {header}
    {user && (
      <div>
        <Icon name="user" position="before" />
        {user}
      </div>
    )}
  </>
);

export const formatNetworkProfile = profile => {
  const networkIcon = profile === 'Corporate' ? 'corporate' : 'non-corporate';

  return profile ? (
    <div key={profile} className={styles.subItem}>
      <Icon name={networkIcon} /> {profile}
    </div>
  ) : null;
};

export const formatArrow = (row, policyVersion, providerConsumerOrder, colorDeficiency, isCSFrame = false) => {
  const data = row?.data || row;
  const isFlowNA = isCSFrame && data?.policy.reported?.decision === 'unknown';
  let policy = data?.policy[policyVersion]?.decision || 'unknown';

  if (isFlowNA) {
    policy = 'unknown';
  }

  const arrowDirection = providerConsumerOrder === 'consumerFirst' ? 'right' : 'left';
  const boundaryDirection = arrowDirection === 'left' ? '-rtl' : '';
  const deleted = doesRowHaveDeletedWorkloads(data) && policyVersion === 'draft';
  const policyClass = !deleted && (colorDeficiency === 'normal' ? styles[policy] : `deficiency-${policy}-icon`);

  const policyPrefix = colorDeficiency === 'normal' ? null : `deficiency-${policy}-icon-`;

  let iconName;

  if (policy === 'allowedAcrossBoundary' && !deleted) {
    iconName = `across-enf-boundary${boundaryDirection}`;
  } else if (policy.toLowerCase().includes('boundary') && !deleted) {
    iconName = `enf-boundary${boundaryDirection}`;
  } else {
    iconName = `arrow-${arrowDirection}-long`;
  }

  if (arrowDirection) {
    return (
      <span className={`${policyClass} ${styles.iconXLarge}`}>
        <Icon name={iconName} size="xlarge" theme={styles} themePrefix={policyPrefix} />
      </span>
    );
  }
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const formatConnectionCount = value => {
  return value ? <div>{intl('PolicyGenerator.Connections', {count: value})}</div> : null;
};

export const formatFlowsAndBytes = row => {
  return (
    <>
      <div>{intl('Explorer.FlowCount', {count: row.flows})}</div>
      {row.byteOut ? (
        <div>
          {String(formatBytes(row.byteOut, 1))}
          <Icon name="arrow-right" tooltip="Bytes Out" position="after" size="large" />
        </div>
      ) : null}
      {row.byteIn ? (
        <div>
          {String(formatBytes(row.byteIn, 1))}
          <Icon name="arrow-left" tooltip="Bytes In" position="after" size="large" />
        </div>
      ) : null}
    </>
  );
};

// Replace commas in label with blank spaces
function formatLabel(label) {
  if (!label) {
    return '';
  }

  return label.replaceAll(',', ' ');
}

function getSrcOrDstLabels(labelObject, labelTypes) {
  const labels = [];

  if (labelObject) {
    labelTypes.forEach(labelType => labels.push(formatLabel(labelObject[labelType.key]?.value) || ''));
  }

  return labels;
}

function formatLabelTypes(sortedLabelTypes, isProvider) {
  return (sortedLabelTypes || []).map(
    type => `${isProvider ? intl('Common.Provider') : intl('Common.Consumer')} ${type}`,
  );
}

function formatSummaryPolicy({policy, component, colorDeficiency, policyVersion, isFlowNA = false}) {
  let subtext = policyVersion === 'draft' ? getPolicyDecisionSubtext(policy.decision) : null;
  let policyClassNames = colorDeficiency === 'normal' ? policy.decision : `${policy.decision}-deficiency`;
  const linkToPolicy = (policy.decision || '').includes('allowed') || (policy.decision || '').includes('Boundary');

  // Handle Impervius Non Azure firewall traffic case
  if (isFlowNA) {
    policyClassNames = 'unknown';
    policy.name = intl('Common.NA');
    subtext = intl('Common.AzureNSGFlow');
  }

  if (linkToPolicy && policyVersion === 'draft' && !isFlowNA) {
    return (
      <>
        <span
          className={cx(styles[policyClassNames], styles.link)}
          onClick={partial(component.handleViewRules, policy)}
        >
          {policy.name}
        </span>
        {subtext}
      </>
    );
  }

  return (
    <>
      <span className={cx(styles[policyClassNames])}>{policy.name}</span>
      {subtext}
    </>
  );
}

export function getLinkPolicy(
  row,
  policyVersion,
  providerConsumerOrder,
  colorDeficiency,
  component,
  deleted,
  isCSFrame,
) {
  const isFlowNA = isCSFrame && row.data.policy.reported.decision === 'unknown';
  const policy = row.data.policy[policyVersion];

  if (row.data.ipLists?.length) {
    const ipLists = [...new Set(row.data.ipLists.map(list => list.href))];

    return (
      <>
        {policyVersion === 'reported' && policy && !deleted && (
          <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap)}>
            {formatSummaryPolicy({policy, component, colorDeficiency, policyVersion})}
          </div>
        )}
        {ipLists.map(href => {
          const list = row.data.ipLists.find(item => item.href === href);

          if (policyVersion === 'draft') {
            const ipListPolicy = row.data.policy.draft.ipLists?.[href];

            if (ipListPolicy) {
              return (
                <div
                  className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap, stylesUtils.centerFlexAlign)}
                  key={`${getPort(row.data)}-${row.data.protocol}-${href}`}
                >
                  <div className={styles.ipList}>{getIPListPill(list, component)}</div>
                  {!deleted && (
                    <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap)}>
                      {formatSummaryPolicy({policy: ipListPolicy, component, colorDeficiency, policyVersion})}
                    </div>
                  )}
                </div>
              );
            }
          }

          return getIPListPill(list, component);
        })}
      </>
    );
  }

  if (policy) {
    return (
      <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap)}>
        {formatSummaryPolicy({policy, component, colorDeficiency, policyVersion, isFlowNA})}
      </div>
    );
  }
}

export function getCSVData(tableData, dnsAddresses, policyVersion, labelTypes) {
  const sortedLabelsTypes = [...labelTypes].sort((a, b) =>
    a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase()),
  );
  const sortedLabelNames = sortedLabelsTypes.map(type => type.display_name);

  const formatedSrcLabelsNames = formatLabelTypes(sortedLabelNames, false);
  const formatedDstLabelsNames = formatLabelTypes(sortedLabelNames, true);

  const links = tableData.map(link => {
    const linkData = [
      link.source.ip,
      link.source.details.ipLists?.map(ipList => ipList.name).join('; ') ?? '',
      link.source.details.name && link.source.details.subType === 'workload' ? `"${link.source.details.name}"` : null,
      link.source.details.hostname ? `"${link.source.details.hostname}"` : null,
      getEnforcementMode(link.source.details.mode),
      ...getSrcOrDstLabels(link.source.details.labelObject || {}, sortedLabelsTypes),
      link.source.details.fqdn,
      link.service.outbound?.processName,
      link.service.outbound?.windowsService,
      link.service.outbound?.username,
      link.target.ip,
      link.target.details.ipLists?.map(ipList => ipList.name).join('; ') ?? '',
      link.target.details.name && link.target.details.subType === 'workload' ? `"${link.target.details.name}"` : null,
      link.target.details.hostname ? `"${link.target.details.hostname}"` : null,
      getEnforcementMode(link.target.details.mode),
      ...getSrcOrDstLabels(link.target.details.labelObject || {}, sortedLabelsTypes),
      link.target.details.fqdn,
      link.target.transmission,
      link.service.port,
      link.service.protocol,
      link.service.inbound?.processName,
      link.service.inbound?.windowsService,
      link.service.inbound?.username,
      link.flows,
      String(formatBytes(link.byteIn, 1)),
      String(formatBytes(link.byteOut, 1)),
      link.state === 'unknown' ? null : link.state,
      ...(policyVersion === 'draft' ? [link.policy.draft?.name || ''] : []),
      link.policy.reported?.name || '',
      link.policy.reported?.decision === 'unknown'
        ? ''
        : link.direction === 'outbound'
        ? intl('Common.Consumer')
        : intl('Common.Provider'),
      intl.date(new Date(link.firstDetected), 'L_HH_mm_ss').replace(',', ' '),
      intl.date(new Date(link.lastDetected), 'L_HH_mm_ss').replace(',', ' '),
    ];

    return linkData.join(',');
  });

  const header = [
    intl('Explorer.ConsumerIP'),
    intl('Explorer.ConsumerIPList'),
    intl('Explorer.ConsumerName'),
    intl('Explorer.ConsumerHostname'),
    intl('Explorer.ConsumerEnforcement'),
    ...formatedSrcLabelsNames,
    intl('Common.ConsumerFqdn'),
    intl('Rulesets.Rules.ConsumingProcess'),
    intl('Rulesets.Rules.ConsumingService'),
    intl('Rulesets.Rules.ConsumingUsername'),
    intl('Explorer.ProviderIP'),
    intl('Explorer.ProviderIPList'),
    intl('Explorer.ProviderName'),
    intl('Explorer.ProviderHostname'),
    intl('Explorer.ProviderEnforcement'),
    ...formatedDstLabelsNames,
    intl('Common.ProviderFqdn'),
    intl('Components.Transmission'),
    intl('Port.Port'),
    intl('Common.Protocol'),
    intl('Common.Process'),
    intl('Common.Service'),
    intl('Common.Username'),
    intl('Explorer.NumFlows'),
    intl('Common.BytesIn'),
    intl('Common.BytesOut'),
    intl('Common.ConnectionState'),
    ...(policyVersion === 'draft' ? [intl('Common.DraftPolicy')] : []),
    intl('Common.ReportedPolicy'),
    intl('Explorer.ReportedBy'),
    intl('Explorer.FirstDetected'),
    intl('BlockedTraffic.List.LastDetected'),
  ];

  links.unshift(header.join(','));

  return links.join('\r\n');
}

export const getSummaryColumn = (direction, connection, summary, templateAllHasValue, anyList) => {
  const columns = {};

  if (summary) {
    if (connection === 'individual') {
      columns.name = {
        format: ({row, component}) => {
          const endpoint = row.data[direction];

          if (isManagedEndpoint(endpoint)) {
            if (endpoint.transmission === 'Broadcast' || endpoint.transmission === 'Multicast') {
              return getManagedEndpointPill(endpoint, component);
            }

            return getManagedEndpointPill(endpoint, component);
          }
        },
        header: intl('Common.Name'),
      };
    } else if (!templateAllHasValue) {
      columns.name = {
        format: ({row, component}) => {
          const rowEndpoint = row.data[direction];

          if (!rowEndpoint.details.labels?.length) {
            const endpointHrefs = {};

            const endpoints = Array.from(row.data.links).reduce((result, link) => {
              const endpoint = link[direction];
              let href = endpoint.details.href;

              if (endpoint.details.subType === 'deleted') {
                href = 'deleted';
              }

              if (isManagedEndpoint(endpoint) && !endpointHrefs[href]) {
                endpointHrefs[href] = endpoint;

                result.push(
                  <Tooltip
                    key={href}
                    content={
                      href === 'deleted'
                        ? [...(rowEndpoint.deletedWorkloadIps || [])].map(ip => <div>{ip}</div>)
                        : endpoint.fullIp
                    }
                  >
                    {getManagedEndpointPill(endpoint, component)}
                  </Tooltip>,
                );
              }

              return result;
            }, []);

            return (
              <ExpandableList
                values={endpoints}
                title={direction === 'source' ? intl('Common.Consumers') : intl('Common.Providers')}
                showInModalFrom={20}
              />
            );
          }
        },
        header: intl('Common.Name'),
      };
    }

    if (direction === 'target') {
      columns.transmission = {
        header: intl('Common.TransmissionMode'),
        value: 'target',
        format: ({value}) => {
          if (value.transmission === 'Unicast') {
            return null;
          }

          if (connection === 'individual') {
            return (
              <Tooltip key={value.fullIp} content={value.fullIp}>
                {value.transmission}
              </Tooltip>
            );
          }

          return <div>{value.transmission}</div>;
        },
      };
    }

    columns.ipList = {
      format: ({row, component}) => {
        const endpoint = row.data[direction];

        return (
          !isManagedEndpoint(endpoint) &&
          getIpListsOrAggregatedLists(endpoint, row.data, 'source', connection, anyList, component)
        );
      },
      header: intl('Common.IPLists'),
    };
  }

  return columns;
};
