/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import produce from 'immer';
import {PureComponent} from 'react';
import {MenuDelimiter, MenuInfo, Pill, type PillProps} from 'components';
import {hrefUtils} from 'utils';
import {getUpdateTypeProps} from '../PillUtils';
import type {IPList as IPListData} from 'illumio';
import type {IP_IP} from 'utils/ip';

export type IPListProps = PillProps & {
  value: IPListData;

  // policy version
  pversion?: number | string;

  noIcon?: boolean;
  contextualMenuIpAddresses?: IP_IP[];
  contextualMenuShowMaxIpAddresses?: number;
};

export default class IPList extends PureComponent<IPListProps> {
  element: HTMLElement | null | undefined = null;
  constructor(props: IPListProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  renderContextualMenu: PillProps['contextualMenu'] = (items, {menuStyles, ...options}) => {
    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      if (firstInfo && this.props.contextualMenuIpAddresses?.length) {
        // TODO: use ExpandableList here
        const {contextualMenuIpAddresses, contextualMenuShowMaxIpAddresses = 5} = this.props;
        const plusMore = contextualMenuIpAddresses.length - contextualMenuShowMaxIpAddresses;

        draft.splice(
          draft.indexOf(firstInfo) + 1,
          0,
          {type: MenuDelimiter, props: {key: 'delimeter2'}},
          {
            type: MenuInfo,
            props: {
              key: 'ipList',
              children: (
                <>
                  {contextualMenuIpAddresses.slice(0, contextualMenuShowMaxIpAddresses).map(ipAddress => (
                    <div className={menuStyles.menuInfoSidebar} key={ipAddress}>
                      {ipAddress}
                    </div>
                  ))}
                  {plusMore > 0 && <div>{intl('Common.CountMore', {count: plusMore})}</div>}
                </>
              ),
            },
          },
        );
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, {menuStyles, ...options}) : menu;
  };

  render() {
    const {
      value,
      pversion = 'draft',
      updateTypeTooltip,
      noIcon,
      contextualMenuIpAddresses,
      contextualMenuShowMaxIpAddresses,
      ...pillProps
    } = this.props;

    Object.assign(pillProps, {
      ref: this.saveRef,
      icon: noIcon ? null : value.fqdn ? 'domain' : 'allowlist',
      link:
        pillProps.onClick || (!value.id && !value.href)
          ? null
          : {to: 'iplists.item.view', params: {pversion, id: value.id ?? hrefUtils.getId(value.href)}},
      ...getUpdateTypeProps({
        object: 'iplist',
        updateType: value.update_type,
        updateTypeTooltip,
        deleted: pillProps.deleted,
        pversion,
      }),
    });

    if (!pillProps.noContextualMenu) {
      pillProps.contextualMenu = this.renderContextualMenu;
      pillProps.contextualType ??= intl('Common.IPList');
      pillProps.contextualCopyValue ??= value.name;
    }

    return <Pill {...pillProps}>{value.name}</Pill>;
  }
}
