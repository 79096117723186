/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {ExpandableList, type ExpandableListProps} from 'components';
import {mixThemeWithProps} from '@css-modules-theme/react';
import styles from './ExpandableLabelList.css';
import intl from 'intl';
import {getSortedLabelsAndLabelGroups} from 'containers/Label/LabelSettings/LabelSettingsUtils';
import ExpandableLabelListShort from 'components/ExpandableLabelList/ExpandableLabelListShort';

export interface ExpandableLabelListProps<Value> extends Omit<ExpandableListProps<Value>, 'values'> {
  values: Value[] | Value[][];
  labelTypeAccessor?: string;
  labelGroupTypeAccessor?: string;
}

export default function ExpandableLabelList<T>(props: ExpandableLabelListProps<T>): JSX.Element {
  const {labelTypeAccessor, labelGroupTypeAccessor, values, ...listProps} = mixThemeWithProps(styles, props);

  listProps.initialCount ??= 20;
  listProps.showInModalFrom ??= 50;
  listProps.title ??= intl('Common.Labels');

  const sortedValues = getSortedLabelsAndLabelGroups(values, labelTypeAccessor, labelGroupTypeAccessor);

  return <ExpandableList {...listProps} values={sortedValues} />;
}

ExpandableLabelList.Short = ExpandableLabelListShort;
