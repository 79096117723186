/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useState} from 'react';
import {motion, MotionConfig, AnimatePresence} from 'framer-motion';
import type {ReactStrictNode} from 'utils/types';
import styles from './Loading.css';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';

export interface LoadingProps extends ThemeProps {
  /** whether is in loading state */
  on: boolean;

  /** element to render when in loading state */
  placeholder?: ReactStrictNode;
  children?: ReactStrictNode;
}

export default function Loading(props: LoadingProps): JSX.Element {
  const {on, children, placeholder} = props;
  const [animating, setAnimating] = useState(true);
  const theme = composeThemeFromProps(styles, props);

  const handleAnimationStart = useCallback(() => {
    setAnimating(true);
  }, []);
  const handleAnimationComplete = useCallback(() => {
    setAnimating(false);
  }, []);

  if (!animating && !on) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <div className={theme.container}>
      <MotionConfig transition={{duration: 0.5}}>
        <AnimatePresence>
          {on && (
            <motion.div
              className={theme.loadingBox}
              initial={{opacity: 1}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              onAnimationStart={handleAnimationStart}
            >
              {placeholder}
            </motion.div>
          )}
        </AnimatePresence>
        {!on && (
          <motion.div
            className={theme.loadingBox}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            onAnimationComplete={handleAnimationComplete}
          >
            {children}
          </motion.div>
        )}
      </MotionConfig>
    </div>
  );
}
