/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {UserName} from 'containers';
import {createSelector} from 'reselect';
import {populateFacetCategory} from 'containers/Selector/GridFilter/GridFilterUtils';

export const categories = createSelector([], () => {
  const resourceType = 'networks';

  return [
    populateFacetCategory({resourceType, id: 'name', name: intl('Common.Name')}),
    populateFacetCategory({resourceType, id: 'data_center', name: intl('Settings.DataCenter')}),
    populateFacetCategory({resourceType, id: 'description', name: intl('Common.Description')}),
  ];
});

/**
[{
  header: string | Function,
  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/
export const gridSettings = createSelector([], () => ({
  id: 'networklist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => (row.data.name === 'External' ? intl('Common.ExternalNonCorporate') : row.data.name),
    },
    type: {
      header: intl('Common.Type'),
      value: ({
        row: {
          data: {user_managed},
        },
      }) => (user_managed ? intl('Network.UserDefined') : intl('Common.System')),
    },
    dataCenter: {
      header: intl('Settings.DataCenter'),
      value: 'data_center',
    },
    ipVersion: {
      header: intl('Rulesets.Rules.IpTables.Form.IpVersion'),
      sort: ({
        row: {
          data: {ip_version},
        },
      }) => ip_version,
      format: ({
        row: {
          data: {ip_version},
        },
      }) => {
        if (ip_version === 6) {
          return intl('Protocol.IPv6');
        }

        if (ip_version === 4) {
          return intl('Protocol.IPv4');
        }

        return null;
      },
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: ({row}) => row.data.updated_at || row.data.created_at || null,
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
    description: {
      header: intl('Common.Description'),
      value: 'description',
    },
  },

  /* Grid's breakpoints configuration */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(200px, auto)'},
      {columns: ['type'], size: 'minmax(150px, auto)'},
      {columns: ['dataCenter'], size: 'minmax(150px, auto)'},
      {columns: ['ipVersion'], size: 'max-content'},
      {columns: ['updatedAt'], size: 'minmax(100px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(100px, auto)'},
      {columns: ['description'], size: 'minmax(150px, 300px)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'type', 'dataCenter'], size: 'minmax(100px, auto)'},
            {columns: ['ipVersion'], size: 'max-content'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
            {columns: ['description'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type', 'dataCenter'], size: 'minmax(100px, auto)'},
          {columns: ['ipVersion'], size: 'max-content'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          {columns: ['description'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['type'], size: 'minmax(85px, auto)'},
            {columns: ['dataCenter'], size: 'minmax(80px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(125px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(170px, auto)'},
            {columns: ['ipVersion'], size: 'minmax(40px, auto)'},
            {columns: ['description'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type', 'dataCenter'], size: 'minmax(140px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(130px, auto)'},
          {columns: ['ipVersion', 'description'], size: 'minmax(110px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['type'], size: 'minmax(100px, auto)'},
            {columns: ['dataCenter'], size: 'minmax(80px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(170px, auto)'},
            {columns: ['ipVersion'], size: 'minmax(40px, auto)'},
            {columns: ['description'], size: 'minmax(50px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type', 'dataCenter'], size: 'minmax(140px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          {columns: ['ipVersion', 'description'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
