/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import {createContext, useState, useMemo, useCallback} from 'react';
import produce from 'immer';

export const HeaderContext = createContext(null);

export default function HeaderProvider({children}) {
  const [context, setContext] = useState([]);

  const handleSetContext = useCallback(
    ({action, ...props}) =>
      setContext(
        produce(draft => {
          if (action === 'HEADER_PUSH') {
            // Append new header values into the stack
            draft.push(props);
          } else if (action === 'HEADER_POP') {
            // Remove header values from the stack
            draft.splice(
              draft.findIndex(item => item.id === props.id),
              1,
            );
          }
        }, []),
      ),
    [],
  );

  const contextValue = useMemo(() => ({context, setContext: handleSetContext}), [context, handleSetContext]);

  return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
}
