/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getId} from 'utils/href';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {endpointEnforcementListGridSettings} from './EndpointEnforcementListConfig';
import {getExplorerRows} from 'containers/IlluminationMap/List/MapListState';
import {providerConsumerOrder} from 'containers/PolicySettings/PolicySettingsState';
import {getLabelTypes} from 'containers/IlluminationMap/Graph/MapGraphState';
import {getRouteParams} from 'containers/App/AppState';
import {getGridSettings} from 'antman/containers/TemplatesWizard/RuleImpact/RuleImpactConfig';
import {isUserReadOnlyAll} from 'containers/User/UserState';

export default {
  unenforcedEndpoints(state = [], action) {
    switch (action.type) {
      case 'SET_UNENFORCED_ENDPOINTS':
        return action.data;
      default:
        return state;
    }
  },
  unenforcedEndpointGroup(state = {}, action) {
    switch (action.type) {
      case 'SET_UNENFORCED_ENDPOINT_GROUP':
        return action.data;
      default:
        return state;
    }
  },
};

const getUnenforcedEndpoints = state => state.endpoints.unenforcedEndpoints;
const getUnenforcedEndpointGroup = state => state.endpoints.unenforcedEndpointGroup;

const getUnenforcedEndpointRows = createSelector(
  [getUnenforcedEndpoints, getUnenforcedEndpointGroup],
  (endpoints, group) =>
    endpoints
      .filter(endpoint => endpoint.labels[0]?.href === group.labels[0].href)
      .map(endpoint => {
        const endpointName = endpoint.hostname || endpoint.name;

        return {
          key: endpoint.href,
          selectable: true,
          data: {
            hostname: endpointName,
            enforcementMode: endpoint.enforcement_mode,
            id: getId(endpoint.href),
            ...endpoint,
          },
        };
      }),
);

export const getEndpointGridSettings = createSelector(
  [isUserReadOnlyAll, endpointEnforcementListGridSettings],
  (userIsReadOnlyAll, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnlyAll;

    return {...gridSettings, columns};
  },
);

export const getEndpointListGrid = state =>
  getGridSelector(state, {
    settings: getEndpointGridSettings,
    rows: getUnenforcedEndpointRows,
  });

const getTrafficGridSettings = createSelector(
  [providerConsumerOrder, getLabelTypes, getRouteParams],
  (order, labelTypes, {policy}) => {
    return getGridSettings(order, labelTypes, policy || 'reported');
  },
);

export const getEndpointEnforcementTrafficGrid = state =>
  getGridSelector(state, {
    settings: getTrafficGridSettings,
    rows: getExplorerRows,
  });
