/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {isSuperclusterMember, isUserReadOnlyAll} from 'containers/User/UserState';
import {hrefUtils} from 'utils';
import {gridSettings} from './FlowCollectionFiltersListConfig';
import {ProtocolMap} from 'containers/Service/ServiceUtils';
import {isAPIAvailable} from 'api/apiUtils';
import {networkMap, portIP, sourceMap} from '../FlowCollectionFiltersUtils';
import intl from 'intl';
import {getTransmissionName} from 'containers/Events/Detail/EventsDetailsUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'FLOWCOLLECTIONFILTERS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'FLOWCOLLECTIONFILTERS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getFlowCollectionFiltersList = state => state.flowCollectionFilters.list;
export const getFlowCollectionFiltersCount = state => state.flowCollectionFilters.count;

const getFlowCollectionFilterRows = createSelector([getFlowCollectionFiltersList], flowcollection =>
  flowcollection.map(item => ({
    key: item.href,
    id: hrefUtils.getId(item.href),
    removable: isAPIAvailable('traffic_collector_setting.delete'),
    selectable: isAPIAvailable('traffic_collector_setting.delete'),
    // Fill each Report with user object
    data: {
      action: item.action === 'drop' ? intl('FlowCollectionFilters.Drop') : intl('FlowCollectionFilters.Aggregate'),
      transmission: item.transmission ? getTransmissionName(item.transmission) : '',
      protocol: ProtocolMap()[Number(item?.target?.proto)],
      destinationip: item?.target[portIP.destIP] === '0.0.0.0/0' ? 'Any' : item.target[portIP.destIP],
      destinationport: item?.target[portIP.destPort] === -1 ? 'Any' : item.target[portIP.destPort],
      sourceip: item?.target[portIP.srcIP] === '0.0.0.0/0' ? 'Any' : item.target[portIP.srcIP],
      sourceport: item?.target[portIP.srcPort] === -1 ? 'Any' : item.target[portIP.srcPort],
      network: item && networkMap[item.network],
      source: item && sourceMap[item.data_source],
    },
  })),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, isSuperclusterMember, gridSettings],
  (userIsReadOnlyAll, isSuperclusterMember, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnlyAll || isSuperclusterMember;

    return {...gridSettings, columns};
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getFlowCollectionFilterRows,
  });

export const getFlowCollectionFiltersListPage = createSelector(
  [getGrid, getFlowCollectionFiltersCount],
  (grid, count) => ({grid, count}),
);
