/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Link} from 'components';
import {withForwardRef, forwardRefSymbol} from 'react-forwardref-utils';
import {isUserOwner} from 'containers/User/UserState';
import {useContext, useEffect} from 'react';
import {call} from 'redux-saga/effects';
import {AppContext} from 'containers/App/AppUtils';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {useSelector} from 'utils/redux';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';

UserName.propTypes = {
  user: PropTypes.shape({
    // id could be
    // - a number, e.g. local user or external user
    // - a UUID string, e.g. service account
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    full_name: PropTypes.string,
    href: PropTypes.string,
    username: PropTypes.string,
    type: PropTypes.oneOf(['local', 'external', 'serviceAccount']),
  }),
  display: PropTypes.oneOf(['full_name', 'username']),
  linkProps: PropTypes.object,
};

function UserName({user = fillUserInfo(), display = 'username', [forwardRefSymbol]: ref, linkProps}) {
  const isOwner = useSelector(isUserOwner);
  const {fetcher} = useContext(AppContext);

  useEffect(() => {
    // this means the user isn't in the all-user list (the user existed but got deleted)
    // in that case, we fetch that user's data explicitly and it gets appended into the all-user list
    if (user.id === -1 && user.href) {
      fetcher.fork(function* () {
        yield call(fetchSelectiveUsers, [user]);
      });
    }
  }, [user, fetcher]);

  const shouldShowLink = isOwner && !(user.id === -1 || user.id === 0);

  if (!shouldShowLink) {
    return user[display];
  }

  let route;

  if (user.type === 'serviceAccount') {
    route = 'serviceAccounts.item';
  } else if (user.type === 'external') {
    route = 'users.external.detail';
  } else {
    // default navigate to local
    route = 'users.local.detail';
  }

  return (
    <Link {...linkProps} to={`rbac.${route}`} params={{id: user.id}} ref={ref}>
      {user[display]}
    </Link>
  );
}

UserName.dateAtTimeBy = function (at, by, display) {
  return intl.utils.format.dateAtTimeBy(at, by && <UserName user={by} display={display} />, null, {jsx: true});
};

export default withForwardRef({hoistSources: true})(UserName);
