/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {explorerTimeOptions, DEFAULT_OPTION_INDEX} from '../MapUtils';
import type {Query, Filters} from './MapFilterTypes';
import {createSelector} from 'reselect';

export const mapQueryNamePrefix = 'MAP_QUERY_';
export const mapFocusedQueryNamePrefix = 'FOCUSED_';
export const mapLandingQueryNamePrefix = 'LANDING_';
export const mapLandingQueryTimePrefix = 'Time: ';
export const defaultTime = explorerTimeOptions[DEFAULT_OPTION_INDEX];

export const EmptyFilters: Filters = {
  providerInclude: new Map(),
  providerExclude: new Map(),
  consumerInclude: new Map(),
  consumerExclude: new Map(),
  consumerOrProviderInclude: new Map(),
  consumerOrProviderExclude: new Map(),
  servicesInclude: new Map(),
  servicesExclude: new Map(),
  policyDecisions: new Map(),
  time: {label: defaultTime.label, value: 'day'},
};

export const EmptyQuery: Query = {
  queryId: undefined,
  queryName: undefined,
  date: undefined,
  filters: EmptyFilters,
};

export const transmissionOptions = [
  {label: intl('Map.Traffic.Broadcast'), value: 'broadcast'}, // value: value in lowercase string expected to be passed to  BE
  {label: intl('Map.Traffic.Multicast'), value: 'multicast'},
  {label: intl('Map.Traffic.Unicast'), value: 'unicast'},
];

export const reportPolicyOptions = createSelector([], () => [
  {label: intl('Common.Blocked'), value: 'blocked'},
  {label: intl('Common.BlockedByBoundary'), value: 'blocked_by_boundary'},
  {label: intl('Common.PotentiallyBlocked'), value: 'potentially_blocked'},
  {label: intl('Common.PotentiallyBlockedByBoundary'), value: 'potentially_blocked_by_boundary'},
  {label: intl('Common.Unknown'), value: 'unknown'},
  {label: intl('Common.Allowed'), value: 'allowed'},
]);
