/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import cx from 'classnames';
import {useRef, useLayoutEffect, useMemo, useState, useEffect} from 'react';
import {useDeepCompareMemo} from 'utils/react';
import {AttributeList} from 'components';
import ContainerResource from './ContainerResource';
import ListResource from './ListResource';
import {CATEGORYPANEL_ID, OPTIONPANEL_ID, getQueryAndKeyword} from 'containers/Selector/SelectorUtils';
import attributeListStyles from 'components/AttributeList/AttributeList.css';

const breakpoints = [{props: {className: attributeListStyles.sizeXS}}];
export default function Resources(props) {
  const {
    theme,
    category,
    category: {infoPanel: categoryInfoPanel, infoPanelPosition = 'bottom'},
    stickyCategoryPosition = 'bottom',
    resources,
    searchPromises = {},
    infoPanel: info,
    initialLoadParams,
    shouldRenderInfoPanel,
    query: queryProp,
    showAll,
    onShowAllClick,
    ...restProps
  } = props;

  const resourcesRef = useRef(null);

  const [showInfoPanel, setShowInfoPanel] = useState(shouldRenderInfoPanel);

  const [stickyResources, activeResources] = useMemo(() => _.partition(resources, ({sticky}) => sticky), [resources]);

  const template = useDeepCompareMemo(category.template);
  const pathArr = useDeepCompareMemo(props.pathArr);

  useLayoutEffect(() => {
    if (pathArr.includes(CATEGORYPANEL_ID)) {
      return;
    }

    // listens to template change
    // fallback to Selector default small/wide screen template if template string changes to empty string
    // e.g. on label create option click, the template is set to empty string so that form can occupy all available space
    resourcesRef.current.style.setProperty(
      '--resources-template-wide-screen',
      template || 'auto / repeat(auto-fit, minmax(180px, 1fr))',
    );
    resourcesRef.current.style.setProperty(
      '--resources-template-small-screen',
      template || 'auto / minmax(180px, 1fr)',
    );
  }, [pathArr, template]);

  useEffect(() => {
    setShowInfoPanel(shouldRenderInfoPanel);
  }, [shouldRenderInfoPanel]);

  const infoPanel = showInfoPanel
    ? (typeof categoryInfoPanel === 'function' ? categoryInfoPanel(restProps) : categoryInfoPanel) ?? info
    : null;

  const getResources = (resources, sticky) => {
    const isInOptionPanel = !pathArr.includes(CATEGORYPANEL_ID);

    if (showAll && !sticky && isInOptionPanel) {
      return (
        <div data-tid="comp-selector-resources-show-all" className={theme.showAll} onClick={onShowAllClick}>
          {intl('ObjectSelector.ShowAll')}
        </div>
      );
    }

    return resources.map(resource => {
      const {id, type, name, hidden, showTitle, queryKeywordsRegex} = resource;

      const Component = type === 'container' ? ContainerResource : ListResource;
      const {query = '', keyword} = getQueryAndKeyword(queryProp, queryKeywordsRegex);
      const searchParams = searchPromises[id];

      if (hidden({query, keyword})) {
        // if a resource is hidden:
        // - immediately reject search promises
        // - don't render anything

        searchParams?.onSearchReject();

        return null;
      }

      const value = (
        <Component
          key={id}
          {...restProps}
          query={query}
          keyword={keyword}
          theme={theme}
          id={id}
          isGridArea={Boolean(template)}
          pathArr={[...pathArr, OPTIONPANEL_ID]}
          resource={resource}
          initialLoadParams={initialLoadParams?.[id]}
          searchParams={searchParams}
        />
      );

      return sticky ? {...(showTitle && {key: name}), value} : value;
    });
  };

  return (
    <>
      {stickyResources.length > 0 && stickyCategoryPosition === 'top' && (
        <>
          <div className={theme.stickyResources}>
            <AttributeList theme={theme} breakpoints={breakpoints}>
              {getResources(stickyResources, true)}
            </AttributeList>
          </div>
          <div className={cx(theme.divider, theme.shadowDown)} data-tid="comp-sectiondivider" />
        </>
      )}
      {infoPanel &&
        infoPanelPosition === 'top' &&
        (typeof infoPanel === 'string' ? <span className={theme.categoryInfo}>{infoPanel}</span> : infoPanel)}
      <div ref={resourcesRef} className={cx(theme.resources)}>
        {getResources(activeResources)}
      </div>
      {infoPanel &&
        infoPanelPosition === 'bottom' &&
        (typeof infoPanel === 'string' ? <span className={theme.categoryInfo}>{infoPanel}</span> : infoPanel)}
      {stickyResources.length > 0 && stickyCategoryPosition === 'bottom' && (
        <>
          <div className={cx(theme.divider, theme.shadowUp)} data-tid="comp-sectiondivider" />
          <div className={theme.stickyResources}>
            <AttributeList theme={theme} breakpoints={breakpoints}>
              {getResources(stickyResources, true)}
            </AttributeList>
          </div>
        </>
      )}
    </>
  );
}
