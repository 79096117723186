/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {object, mixed} from 'yup';
import EnforcementBoundariesVisibilityForm from './EnforcementBoundariesVisibilityForm';
import {Form} from 'components';
import {createSelector} from 'reselect';

// Enforcement Mode Selector Options
export const coreOptions = createSelector([], () => [
  {label: intl('Common.Idle'), value: 'idle', subLabel: intl('Common.IdleControl')},
  {label: intl('Common.VisibilityOnly'), value: 'visibility_only', subLabel: intl('Workloads.VisibilityOnlyReports')},
  {
    label: intl('Workloads.Selective'),
    value: 'selective',
    subLabel: intl('Workloads.EnforcementBoundariesSelectedInbound'),
  },
  {label: intl('Workloads.Full'), value: 'full', subLabel: intl('Workloads.FullEnforcementInbound')},
]);

export const edgeOptions = createSelector([], () => [
  {label: intl('Common.Idle'), value: 'idle', subLabel: intl('Common.IdleControlEdge')},
  {
    label: intl('Common.VisibilityOnly'),
    value: 'visibility_only',
    subLabel: intl('Workloads.VisibilityOnlyReportsEdge'),
  },
  {label: intl('Common.Enforced'), value: 'full', subLabel: intl('Workloads.EnforceBoundariesEdgeDes')},
]);

// Enforcement and Visibility Yup Schema
export const getEnforcementBoundariesVisibilitySchema = {
  enforcementMode: object().nullable(),
  visibility_level: mixed().when('enforcementMode', {
    is: val => Boolean(val?.value) && val?.value !== 'idle',
    then: mixed().required(),
  }),
};

/**
 * Get the enforcement and visibility level data structure for setting up form format in AttributeList
 *
 * @param {Object} form formik's form
 * @returns {Array<Object>}  An array of objects in AttributeList's children formatting
 */
export const getEnforcementBoundariesVisibility = ({
  form,
  edgeEnabled = false,
  crowdstrikeEnabled = false,
  options = {},
}) => {
  const {values} = form;

  const enforcementModeOptions = edgeEnabled ? edgeOptions() : coreOptions();

  const ele = [
    {
      tid: 'enforcement_mode',
      key: <Form.Label name="enforcementMode" title={intl('Common.Enforcement')} />,
      value: (
        <Form.Selector
          name="enforcementMode"
          disabled={options.disabled}
          excludeOptions={options.excludeOptions}
          options={enforcementModeOptions}
        />
      ),
    },
  ];

  if (!crowdstrikeEnabled) {
    const keyTitle =
      values.enforcementMode?.value === 'idle' ? (
        intl('Common.Visibility')
      ) : (
        <Form.Label name="visibility_level" title={intl('Common.Visibility')} />
      );

    ele.push({
      tid: 'visibility_level',
      key: keyTitle,
      value: (
        <EnforcementBoundariesVisibilityForm
          name="visibility_level"
          enforcementMode={form.values?.enforcementMode?.value}
        />
      ),
    });
  }

  return ele;
};

/**
 * Get the selective enforcement initial values: enforcement mode and visibility_level.
 * Enforcement Mode by default is 'visibility_only'
 *
 * @param {Object} data - Data consist of selective enforcement data
 * @returns {Array<Object>}  An array of objects in AttributeList formatting
 */
export const getEnforcementBoundariesInitialValues = (data = {}, edgeEnabled = false) => {
  const enforcementModeOptions = edgeEnabled ? edgeOptions() : coreOptions();

  const values = {
    visibility_level: data.visibility_level ?? 'flow_summary',
    enforcementMode: Form.Utils.findSelectedOption(enforcementModeOptions, data.enforcement_mode ?? 'visibility_only'),
  };

  return values;
};
