/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {
  areVulnerabilitiesEnabled,
  getRouteCurrentParams,
  getRouteName,
  isCrowdstrike,
  isEdge,
  isRansomwareReadinessEnabled,
} from 'containers/App/AppState';
import {getClusters} from 'containers/Health/HealthState';
import {getWorkloadsCount} from '../List/WorkloadListState';
import {isExplorerEnabled, isUserWithReducedScope} from 'containers/User/UserState';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getWorkloadInstance = state => state.workload.instance;
export const getWorkload = createSelector([getRouteName, getWorkloadInstance], (routeName, workload) =>
  routeName === 'app.workloads.create' ? {} : workload,
);

export const getVirtualServiceWorkloadInstance = state => state.workload.vslist;

export const getWorkloadItemPage = createSelector(
  [
    getWorkloadInstance,
    getRouteCurrentParams,
    getClusters,
    getWorkloadsCount,
    getVirtualServiceWorkloadInstance,
    isUserWithReducedScope,
    isExplorerEnabled,
    areVulnerabilitiesEnabled,
    isEdge,
    isCrowdstrike,
    isRansomwareReadinessEnabled,
  ],
  (
    workload,
    currentRouteParams,
    allClusters,
    workloadCount,
    virtualservices,
    isUserWithReducedScope,
    isExplorerEnabled,
    areVulnerabilitiesEnabled,
    edgeEnabled,
    crowdstrikeEnabled,
    isRansomwareReadinessEnabled,
  ) => ({
    workload,
    currentRouteParams,
    allClusters,
    workloadCount,
    virtualservices,
    isUserWithReducedScope,
    isExplorerEnabled,
    areVulnerabilitiesEnabled,
    edgeEnabled,
    crowdstrikeEnabled,
    isRansomwareReadinessEnabled,
  }),
);
