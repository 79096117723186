/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
/* eslint-disable react/jsx-no-bind */
import {edge} from 'api/apiUtils';
import {MenuDelimiter, MenuItem, Pill} from 'components/index';
import intl from 'intl';
import {partial, isEmpty} from 'lodash';
import {copyToClipboard} from 'utils/dom';
import {isIgnoredFilterCategory} from '../ContextMenu/ContextMenuUtils';

const updateFilters = (filterType, filterCategory, labels, component) => {
  component.props.updateFilters(filterType, filterCategory, labels);
};

function removeIgnoredFilterCategoryOptions(menuItems, filterCategory, component) {
  const {stickyAppGroupFields} = component.props;

  return Object.fromEntries(
    Object.entries(menuItems).filter(
      ([filterType]) => !isIgnoredFilterCategory(filterType, filterCategory, stickyAppGroupFields),
    ),
  );
}

function mapMenuGroupsToMenuItems(menuGroups, filterCategory, copyText, defaultMenuItems, component) {
  return [
    ...defaultMenuItems.filter(item => item.props.key !== 'copy'),
    ...menuGroups
      .map(menuGroup => removeIgnoredFilterCategoryOptions(menuGroup, filterCategory, component))
      .filter(menuGroup => !isEmpty(menuGroup))
      .flatMap(menuGroup => Object.values(menuGroup).concat(<MenuDelimiter />)),
    <MenuItem key="copy" tid="copy" text={intl('Common.Copy')} icon="copy" onClick={() => copyToClipboard(copyText)} />,
  ];
}

export function getProviderAndConsumerMenuItems({filterCategory, labels, copyText, defaultMenuItems, component}) {
  if (!component) {
    return defaultMenuItems;
  }

  const {orView} = component.props;

  const orViewMenuGroups = [getConsumerOrProviderMenuGroup(filterCategory, labels, defaultMenuItems, component)];

  const andViewMenuGroups = [
    getProviderMenuGroup(filterCategory, labels, component),
    getConsumerMenuGroup(filterCategory, labels, component),
  ];

  const menuGroups = orView ? orViewMenuGroups : andViewMenuGroups;

  return mapMenuGroupsToMenuItems(menuGroups, filterCategory, copyText, defaultMenuItems, component);
}

function getProviderMenuGroup(filterCategory, labels, component) {
  return {
    providerInclude: (
      <MenuItem
        key="provider-is"
        tid="provider-is"
        text={intl('Explorer.ProviderIs')}
        icon="filter"
        onClick={partial(updateFilters, 'providerInclude', filterCategory, labels, component)}
      />
    ),
    providerExclude: (
      <MenuItem
        key="provider-is-not"
        tid="provider-is-not"
        text={intl('Explorer.ProviderIsNot')}
        icon="filter"
        onClick={partial(updateFilters, 'providerExclude', filterCategory, labels, component)}
      />
    ),
  };
}

function getConsumerMenuGroup(filterCategory, labels, component) {
  return {
    consumerInclude: (
      <MenuItem
        key="consumer-is"
        tid="consumer-is"
        text={intl('Explorer.ConsumerIs')}
        icon="filter"
        onClick={partial(updateFilters, 'consumerInclude', filterCategory, labels, component)}
      />
    ),
    consumerExclude: (
      <MenuItem
        key="consumer-is-not"
        tid="consumer-is-not"
        text={intl('Explorer.ConsumerIsNot')}
        icon="filter"
        onClick={partial(updateFilters, 'consumerExclude', filterCategory, labels, component)}
      />
    ),
  };
}

function getConsumerOrProviderMenuGroup(filterCategory, labels, defaultMenuItems, component) {
  return {
    consumerOrProviderInclude: (
      <MenuItem
        key="consumer-or-provider-is"
        tid="consumer-or-provider-is"
        text={intl('Explorer.ConsumerOrProviderIs')}
        icon="filter"
        onClick={partial(updateFilters, 'consumerOrProviderInclude', filterCategory, labels, component)}
      />
    ),
    consumerOrProviderExclude: (
      <MenuItem
        key="consumer-or-provider-is-not"
        tid="consumer-or-provider-is-not"
        text={intl('Explorer.ConsumerOrProviderIsNot')}
        icon="filter"
        onClick={partial(updateFilters, 'consumerOrProviderExclude', filterCategory, labels, component)}
      />
    ),
  };
}

export function getServiceMenuItems({filterCategory, labels, copyText, defaultMenuItems, component}) {
  if (!component) {
    return defaultMenuItems;
  }

  const menuGroups = [
    {
      servicesInclude: (
        <MenuItem
          key="service-is"
          tid="service-is"
          text={intl('Common.ServiceIs')}
          icon="filter"
          onClick={partial(updateFilters, 'servicesInclude', filterCategory, labels, component)}
        />
      ),
      servicesExclude: (
        <MenuItem
          key="service-is-not"
          tid="service-is-not"
          text={intl('Common.ServiceIsNot')}
          icon="filter"
          onClick={partial(updateFilters, 'servicesExclude', filterCategory, labels, component)}
        />
      ),
    },
  ];

  return mapMenuGroupsToMenuItems(menuGroups, filterCategory, copyText, defaultMenuItems, component);
}

export const getIPListPill = (value, component) => {
  return (
    <Pill.IPList
      key={value}
      value={value}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'ip_list',
          labels: [value],
          copyText: value.name,
          defaultMenuItems,
          component,
        });
      }}
    />
  );
};

export const getWorkloadPill = (endpoint, component) => {
  const endpointDetails = endpoint.details;

  return (
    <Pill.Workload
      id={endpointDetails.href}
      key={endpointDetails.href}
      icon={endpointDetails.subType === 'unmanaged' ? 'unmanaged' : 'workload'}
      value={endpointDetails}
      contextualMenuFullIp={endpoint.fullIp}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'workload',
          labels: [endpointDetails],
          copyText: endpointDetails.name,
          defaultMenuItems,
          component,
        });
      }}
    />
  );
};

export const getContainerWorkloadPill = (endpoint, component) => {
  const endpointDetails = endpoint.details;

  return (
    <Pill.ContainerWorkload
      id={endpointDetails.href}
      key={endpointDetails.href}
      value={endpointDetails}
      icon="container-workload"
      contextualMenuFullIp={endpoint.fullIp}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'workload',
          labels: [endpointDetails],
          copyText: endpointDetails.name,
          defaultMenuItems,
          component,
        });
      }}
    >
      {endpointDetails.name}
    </Pill.ContainerWorkload>
  );
};

export const getServicePill = (key, value, component) => {
  return (
    <Pill.Service
      key={key}
      value={value}
      contextualType={intl('Common.Service')}
      contextualMenu={defaultMenuItems => {
        return getServiceMenuItems({
          filterCategory: 'policy_services',
          labels: [value],
          copyText: value.name,
          defaultMenuItems,
          component,
        });
      }}
    />
  );
};

export const getIpAddressOrIpCountPill = (key, value, component) => {
  return (
    <Pill
      key={key}
      contextualType={intl('Common.IPAddress')}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'ip_or_cidr',
          labels: [value],
          copyText: value,
          defaultMenuItems,
          component,
        });
      }}
    >
      {value}
    </Pill>
  );
};

export const getFQDNPill = (key, value, component) => {
  if (!value) {
    return;
  }

  return (
    <Pill
      key={value}
      contextualType={intl('PCE.FQDN')}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'fqdn',
          labels: [value],
          copyText: value,
          defaultMenuItems,
          component,
        });
      }}
    >
      {value}
    </Pill>
  );
};

export const getLabelsPill = (key, label, clickableRef, component) => {
  return (
    <Pill.Label
      key={key}
      href={label.href}
      group={label.href.includes('label_groups')}
      type={edge ? null : key}
      ref={clickableRef}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'labelsAndLabelGroups',
          labels: [label],
          copyText: label.value || label.name,
          defaultMenuItems,
          component,
        });
      }}
    >
      {label.value || label.name}
    </Pill.Label>
  );
};

export const getVirtualServicePill = (endpoint, component) => {
  return (
    <Pill.VirtualService
      key={endpoint.details.href}
      id={endpoint.details.href}
      value={endpoint.details}
      contextualMenuFullIp={endpoint.fullIp}
      contextualMenu={defaultMenuItems => {
        return getProviderAndConsumerMenuItems({
          filterCategory: 'labelsAndLabelGroups',
          labels: endpoint.details.labels,
          copyText: endpoint.details.name,
          defaultMenuItems,
          component,
        });
      }}
    />
  );
};

export const getTransmissionPill = (value, component) => {
  return (
    <Pill
      key={value.transmission}
      id={value.transmission}
      value={value.transmission}
      contextualType={intl('Common.TransmissionMode')}
      contextualMenu={defaultMenuItems => {
        const transmission = value.transmission || '';

        return getProviderAndConsumerMenuItems({
          filterCategory: 'transmission',
          labels: [{label: transmission, value: transmission.toLowerCase()}],
          copyText: value.transmission,
          defaultMenuItems,
          component,
        });
      }}
    >
      {value.transmission}
    </Pill>
  );
};
