/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import ServerEnforcementListReducers from './ServerEnforcement/List/ServerEnforcementListState';
import ServerEnforcementViewState from 'antman/containers/Servers/ServerEnforcement/View/ServerEnforcementViewState';
import {extractReportData} from 'containers/Workload/Item/Report/WorkloadCompatibilityReportState';
import {getRouteName} from 'containers/App/AppState';

export default {
  servers: combineReducers({
    ...ServerEnforcementListReducers,
    ...ServerEnforcementViewState,
    list(state = [], action) {
      switch (action.type) {
        case 'SET_SERVERS':
          return action.data.list;
        default:
          return state;
      }
    },
    count(state = {}, action) {
      switch (action.type) {
        case 'SET_SERVERS':
          return action.data.count;
        default:
          return state;
      }
    },
    startTime(state = null, action) {
      switch (action.type) {
        case 'SERVER_WIZARD_START_TIME':
          return action.data;
        default:
          return state;
      }
    },
    showPreviouslyPairedServers(state = false, action) {
      switch (action.type) {
        case 'SET_SHOW_PREVIOUSLY_PAIRED_SERVERS':
          return action.data;
        default:
          return state;
      }
    },
    compatibilityReport(state = {}, action) {
      switch (action.type) {
        case 'SERVER_GET_REPORT':
          return action.data;
        default:
          return state;
      }
    },
    toggledServers(state = [], action) {
      switch (action.type) {
        case 'SET_TOGGLED_SERVERS':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

export const getServersCount = state => state.servers.count?.matched;
export const getServersList = state => state.servers.list;
export const getServerWizardStartTime = state => state.servers.startTime;
export const getShowPreviouslyPairedServers = state => state.servers.showPreviouslyPairedServers;
export const getCompatibilityReport = state => state.servers.compatibilityReport;
export const getToggledServers = state => state.servers.toggledServers;

const getSortedServers = createSelector(getServersList, servers =>
  [...servers].sort((a, b) => new Date(b.agent.status.managed_since) - new Date(a.agent.status.managed_since)),
);

export const getFilteredServers = createSelector(
  [getSortedServers, getServerWizardStartTime],
  (serversList, serverWizardStartTime) => {
    return serversList.filter(item => item.agent.status.managed_since >= serverWizardStartTime);
  },
);

export const getServerRows = createSelector(
  [getSortedServers, getFilteredServers, getShowPreviouslyPairedServers, getToggledServers, getRouteName],
  (serverList, filteredServerList, showPreviouslyPairedServers, toggledServers, routeName) => {
    const isLabeling = routeName === 'app.servers.labeling';

    return (showPreviouslyPairedServers ? serverList : filteredServerList).reduce((servers, server, index) => {
      const {hostname, name, href, server_roles, ven, enforcement_mode} = server;

      // Display the VEN status in the pairing page
      const {status} = server.agent;
      const serverName = hostname || name;
      const isToggled = toggledServers.includes(href);

      // hide untoggled servers on labeling page
      if (!isLabeling || (isLabeling && isToggled)) {
        servers.push({
          key: server.href,
          data: {
            itemNumber: index + 1,
            hostname: serverName,
            status,
            os: server.os_id,
            serverRoles: server_roles.map(({short_name}) => short_name),
            href,
            compatibilityReport: ven.compatibility_report,
            isToggled,
            enforcementMode: enforcement_mode,
          },
        });
      }

      return servers;
    }, []);
  },
);

const getWorkloadCompatibilityReport = createSelector([getCompatibilityReport], compatibilityReport => {
  const reportData = _.isEmpty(compatibilityReport) ? [] : extractReportData(compatibilityReport.results.qualify_tests);

  return reportData.map((item, index) => ({
    key: index,
    ...item,
  }));
});

export const getCompatibilityReportRows = createSelector(
  [getWorkloadCompatibilityReport],
  compatibilityReportData => compatibilityReportData,
);
