/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {getSupportProducts, isUserMSPOwner} from 'containers/User/UserState';
import {gridSettings} from './MyManagedTenantsListConfig';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getProductsMap} from '../Item/Edit/MyManagedTenantsEditState';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'MSPTENANTS_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'MSPTENANTS_GET_LIST':
        return {matched: action.data.length, total: action.data.length};
      default:
        return state;
    }
  },

  summary(state = {}, action) {
    switch (action.type) {
      case 'MSPTENANTS_GET_SUMMARY':
        return {
          numActiveTenants: action.data.numActiveTenants,
          numActiveEdgeVENs: action.data.numActiveEdgeVENs,
          numActiveCoreVENs: action.data.numActiveCoreVENs,
        };
      default:
        return state;
    }
  },
};

export const getMSPTenants = state => state.mspTenants.list;
export const getMSPTenantsCount = state => state.mspTenants.count;

const getMSPTenantsRows = createSelector([getMSPTenants, isUserMSPOwner], (mspTenants, userIsMSPOwner) =>
  mspTenants.map(item => ({
    key: item.href,
    selectable: userIsMSPOwner,
    removable: userIsMSPOwner,
    data: {
      ...item,
    },
  })),
);

export const getMSPTenantSummary = state => state.mspTenants.summary;

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getMSPTenantsRows,
  });

export const getMyManagedTenantsList = createSelector(
  [getGrid, getMSPTenantsCount, getProductsMap, getSupportProducts, getMSPTenantSummary, isUserMSPOwner],
  (grid, count, productsMap, supportProducts, {numActiveEdgeVENs, numActiveCoreVENs}, userIsMSPOwner) => {
    const isAddTenantsEnabled = userIsMSPOwner && Array.isArray(supportProducts) && supportProducts.length > 0;
    const isRemoveTenantsEnabled = userIsMSPOwner;
    const summaryCards = [
      {
        id: 'activeTenants',
        header: intl('MSP.ActiveTenants'),
        count: count?.total,
      },
      {
        id: 'activeEdgeVENs',
        header: intl('MSP.ActiveEdgeVENs'),
        count: numActiveEdgeVENs,
      },
      {
        id: 'activeCoreVENs',
        header: intl('MSP.ActiveCoreVENs'),
        count: numActiveCoreVENs,
      },
    ];

    return {
      grid,
      count,
      summaryCards,
      productsMap,
      supportProducts,
      isAddTenantsEnabled,
      isRemoveTenantsEnabled,
    };
  },
);
