/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {edge} from 'api/apiUtils';
import {createSelector} from 'reselect';
import {populateFacetCategory, provisionStatusCategory} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getIsNewUI, getIsCSFrame} from 'containers/App/AppState';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';

const facet = args =>
  populateFacetCategory({
    resourceType: 'ip_lists',
    params: {pversion: 'draft'},
    ...args,
  });

export const categories = createSelector([getIsCSFrame], isCSFrame => {
  return [
    facet({id: 'name', name: intl('Common.Name')}),
    facet({id: 'ip_address', name: intl('Common.IPAddress')}),
    facet({id: 'description', name: intl('Common.Description')}),
    ...(edge || isCSFrame ? [] : [facet({id: 'fqdn', name: intl('PCE.FQDN')})]),
    provisionStatusCategory,
  ];
});

/**
[{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/

export const gridSettings = createSelector([getIsNewUI, getIsCSFrame], (isNewUI, isCSFrame) => ({
  id: 'iplistlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: getProvisionStatusColumn(isNewUI),
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => row.data.name,
    },
    ipRange: {
      header: intl('PolicyGenerator.Addresses'),
      value: ({
        row: {
          data: {ip_ranges: ranges, fqdns},
        },
      }) => {
        if ((!ranges || !ranges.length || !ranges[0]) && (!fqdns || !fqdns.length || !fqdns[0])) {
          return '';
        }

        const firstItem = fqdns && fqdns.length && fqdns[0] ? fqdns[0] : ranges[0];
        let result = firstItem.fqdn || firstItem.from_ip;

        if (firstItem.exclusion) {
          result = `!${result}`;
        }

        if (firstItem.to_ip) {
          result = `${result} - ${firstItem.to_ip}`;
        }

        const total = (ranges ? ranges.length : 0) + (fqdns ? fqdns.length : 0);

        if (total > 1) {
          result = `${result} +${total - 1} ${intl('Common.More')}`;
        }

        return result;
      },
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      disabled: isCSFrame,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['ipRange'], size: 'minmax(120px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(120px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(120px, auto)'},
      {columns: ['desc'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['ipRange'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(100px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(100px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['ipRange'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(100px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(100px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['ipRange'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['ipRange'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'ipRange'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'ipRange'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'ipRange', 'status'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'ipRange', 'status'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'ipRange', 'status'], size: 'minmax(140px, auto)'},
            {columns: ['updatedAt', 'updatedBy', 'desc'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'ipRange', 'status'], size: 'minmax(140px, auto)'},
          {columns: ['updatedAt', 'updatedBy', 'desc'], size: 'minmax(140px, auto)'},
        ];
      },
    },
  ],
}));
