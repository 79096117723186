/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {useMemo} from 'react';
import cx from 'classnames';
import {FieldArray} from 'formik';
import * as PropTypes from 'prop-types';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {areArraysEqualWhenSorted} from '@thor/utils';
import {whenPropTypes, useWhen} from '../FormUtils';
import FormCheckbox from './FormCheckbox';
import styles from './Checkbox.css';
import stylesUtils from 'utils.css';

FormCheckboxGroupWrap.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string.isRequired,

  // Children can be either a function, that will get current name/values/form as arguments
  // and that is called on render to return any further renderable content that contains FormCheckbox,
  // or renderable content can be provided directly
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // By default state of group and values are automatically handled by FormCheckbox itself (uncontrolled).
  // If you want to intercept change event and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,

  // By default checkboxes are wrapped into a div with .gapLarge class.
  // Set noWrap to true to return checkboxes as is, without any wrapper or styling
  noWrap: PropTypes.bool,
  // By default wrapper has .gapLarge class, set horizontal to true to also add .gapHorizontalWrap class
  horizontal: PropTypes.bool,
  // Or you can specify other gap classes or any other classes by simply passing className prop
  // Like <Form.CheckboxGroup className={`${styles.gapSmall} ${styles.gapAlbatross}`}> for smaller gaps in albatross line
  className: PropTypes.string,

  // Might depend on other fields
  ...whenPropTypes,
};

function FormCheckboxGroup(props) {
  // Control field reset when dependencies are not true
  const {
    form,
    field,
    field: {name, values},
    children,
    ...restProps
  } = useWhen(props.form, props.field, props);
  const onChange = props.onChange;
  let content = typeof children === 'function' ? children(name, values, form) : children;

  restProps.noWrap ??= false;

  if (!restProps.noWrap) {
    const {
      form,
      field,
      when,
      onChange,
      noWrap,
      horizontal = false,
      theme,
      ...groupElementProps
    } = mixThemeWithProps(styles, restProps);

    groupElementProps.className ||= cx(stylesUtils.gapLarge, {[stylesUtils.gapHorizontalWrap]: horizontal});

    content = <div {...groupElementProps}>{content}</div>;
  }

  const context = useMemo(
    () => ({
      name,
      values,
      error: form.touched[name] && form.errors[name] !== undefined,
      onChange: (evt, checking, pressedKeys, checkboxProps) => {
        const {value} = checkboxProps;

        if ((checking && values.includes(value)) || (!checking && !values.includes(value))) {
          return;
        }

        if (onChange) {
          // If group is controlled, just call onChange prop and let parent decide what to do
          return onChange(evt, name, value, checking, pressedKeys);
        }

        const initialValues = form.initialValues[name];
        const newValues = checking ? [...values, value] : values.filter(val => val !== value);

        if (areArraysEqualWhenSorted(newValues, initialValues)) {
          // If list of selected checkboxes is equal to default one,
          // then revert to default one to keep object reference, so formik can unmark it as changed
          form.setFieldValue(name, initialValues);
        } else if (checking) {
          // Call FieldArray helper methods (instead of manual setFieldValue), which will also mark checkboxes as touched
          field.push(value);
        } else {
          field.remove(values.indexOf(value));
        }
      },
    }),
    [name, values, field, form, onChange],
  );

  return <FormCheckbox.Context.Provider value={context}>{content}</FormCheckbox.Context.Provider>;
}

// Temporary FieldArray wrapper until we get hooks in Formik 2.0
export default function FormCheckboxGroupWrap(props) {
  return (
    <FieldArray name={props.name}>
      {options => {
        const {form, ...field} = options;

        field.values = form.values[field.name];

        return <FormCheckboxGroup {...props} form={form} field={field} />;
      }}
    </FieldArray>
  );
}
