/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import PubSub from 'pubsub';
import {Resizable, type ResizableProps} from 're-resizable';
import FocusLock from 'react-focus-lock';
import {useTransformRef} from 'use-callback-ref';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {forwardRef, PureComponent} from 'react';
import type {MouseEventHandler, RefObject, MouseEvent, ComponentPropsWithoutRef, Ref, CSSProperties} from 'react';
import {ModalContext, type ModalContextValue} from './ModalUtils';
import ModalGateway from './ModalGateway';
import Header from './ModalHeader';
import Content from './ModalContent';
import Footer from './ModalFooter';
import ModalStickyShadow from './ModalStickyShadow';
import {Gateway} from 'components';
import Confirmation from './Confirmation/Confirmation';
import Alert from './Alert/Alert';
import PageInvoker from './PageInvoker/PageInvoker';
import {tidUtils} from 'utils';
import styles from './Modal.css';
import Support from './ErrorHandler/ErrorHandler';
import type {BodyScrollOptions} from 'body-scroll-lock';
import type {MutuallyExclusive, ReactStrictNode} from 'utils/types';

export type ModalProps = MutuallyExclusive<{
  // small: 400px, maximum is 96vw
  small?: boolean;
  // medium: 500px, maximum is 96vw
  medium?: boolean;
  // large: 640px, maximum is 96vw
  large?: boolean;
  // full: 92vw and 96vw if windows is smaller than 800px
  full?: boolean;
  // stretch: Stretch from 360px to full size
  stretch?: boolean;
}> &
  ThemeProps & {
    // Modal size fixes width Default is medium

    /**
     * Alternatively you can give it a custom initial width
     * instant: Whether modal should be emerged instantly (true) or using animation (false - default)
     * notResizable: Whether modal should not be resizable by user
     * dontStretchChildren: Don't apply `flex: 1 1 auto` to children
     * dontRestrainChildren: Don't apply `overflow: hidden` to children
     */
    tid?: string;
    instant?: boolean;
    notResizable?: boolean;
    dontStretchChildren?: boolean;
    dontRestrainChildren?: boolean;

    /**
     * Don't change width after initial show if Modal is set to 'stretch'.
     * Useful when Modal should initially adjust, but subsequent content changes should not change width to avoid width jumps,
     * For example, SessionExpiration has a countdown and width should not change when the number decrements from 10 to 9
     */
    fixStretchedWidth?: boolean;
    width?: number | string;

    /**
     * min/maxWidth can be either a number(640), string('640px'/'80%') or a size('small'/'medium'/'large')
     */
    minWidth?: number | string; // 400 by default
    minHeight?: number | string; // 200 by default
    maxWidth?: number | string;
    maxHeight?: number | string;

    /**
     * Whether first tabbable element inside modal should be automatically focused on open (false - default).
     * If you have several tabbable elements inside modal (links, buttons, etc), but want to automatically focus on not first,
     * just assign tabIndex="1" (first positive index) property to that element
     */
    // interface ModalContentProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title'>, ThemeProps {
    autoFocus?: boolean;
    focusLockProps?: BodyScrollOptions;

    /**
     * idleOnEsc: Do nothing (true) or close modal (false - default) on esc key press
     * idleOnBackdropClick: Do nothing (true) or close modal (false - default) on backdrop click/touch
     * onClose: Callback that will be called on Esc or CloseIcon/Backdrop click (if enabled by above properties)
     */
    idleOnEsc?: boolean;
    idleOnBackdropClick?: boolean;
    onClose?: MouseEventHandler;

    /**
     * Alternative to handling onClose you can specify a reference of an action element, like Button or Link,
     * that should be artificially clicked on Esc or CloseIcon/Backdrop click.
     * For instance when Button in Footer is a link that changes route
     */
    closeRef?: HTMLElement & RefObject<HTMLElement>;

    children?: ReactStrictNode;
    message?: string;
    name?: string;
    onSave?: () => void;
    style?: CSSProperties;
  };

type ModalPropsIn = ModalProps & Required<Pick<ModalProps, keyof typeof Modal.defaultProps>>;

type ModalState = {
  context: ModalContextValue;
};

/* In some cases, we may need to position elements in between multiple Modals mounts
 * For e.g. In case of Selector (lets say S) if we render EditLabels button in Selector option panel
 * then clicking on this button should mount a Modal (lets say M), this modal further mounts a Selector for scope selection
 * So, here the z-order is S1 -> M1 -> S2 -> M2.
 * zIndex variable is incremented by 10 in Modal constructor and is passed as context to Selector, which positions itself at zIndex + 1
 * */
let zIndex = 10_000;
let closeEventTimeoutId: number | null;
const sizes = {
  small: '400px',
  medium: '500px',
  large: '640px',
  full: '92%',
  stretch: 'auto',
};

// A way to make react-focus-lock work with re-resizable without nesting one into each and creating extra divs
// https://github.com/theKashey/react-focus-lock/issues/85
const resizableWithRef = forwardRef((props: ResizableProps, ref: Ref<HTMLElement | null | undefined>) => (
  <Resizable {...props} ref={useTransformRef<Resizable, HTMLElement | null | undefined>(ref, i => i?.resizable)} />
));

export default class Modal extends PureComponent<ModalPropsIn, ModalState> {
  static Gateway = ModalGateway;
  static Confirmation = Confirmation;
  static Content = Content;
  static Header = Header;
  static Footer = Footer;
  static Alert = Alert;
  static Support = Support;
  static StickyShadow = ModalStickyShadow;
  static PageInvoker = PageInvoker;

  width?: string | null;
  $modal?: HTMLElement;
  tid?: string;
  onClose?: MouseEventHandler;

  static defaultProps = {
    instant: false,
    autoFocus: false,
    notResizable: false,

    minWidth: 400,
    minHeight: 200,

    idleOnEsc: false,
    idleOnBackdropClick: false,
  };

  constructor(props: ModalPropsIn) {
    super(props);

    zIndex += 10;

    this.handleClose = this.handleClose.bind(this);
    this.saveModalRef = this.saveModalRef.bind(this);
    this.getModalRef = this.getModalRef.bind(this);

    this.state = {context: {onClose: this.handleClose, getModalRef: this.getModalRef, zIndex}};
    this.width = null;
  }

  static getDerivedStateFromProps(nextProps: ModalPropsIn, prevState: ModalState) {
    const {context} = prevState;

    if (context.tid !== nextProps.tid) {
      return {context: {...context, tid: nextProps.tid}};
    }

    return null;
  }

  componentDidMount() {
    if (closeEventTimeoutId) {
      clearTimeout(closeEventTimeoutId);
      closeEventTimeoutId = null;
    } else {
      // Publish open event for QA
      PubSub.publish('MODAL.OPEN');
    }
  }

  componentWillUnmount() {
    closeEventTimeoutId = window.setTimeout(() => {
      closeEventTimeoutId = null;
      // Publish close event for QA
      PubSub.publish('MODAL.CLOSE');
    }, 100);
  }

  getModalRef(): HTMLElement | undefined {
    return this.$modal;
  }

  saveModalRef(element: HTMLElement): void {
    this.$modal = element;

    if (!element && this.width) {
      this.width = null;
    }
  }

  handleModalClick(evt: MouseEvent): void {
    // Do not propagate click on modal itself to make click on .animator work only outside of modal
    evt.stopPropagation();
  }

  handleClose(evt: MouseEvent): void {
    const {closeRef, onClose} = this.props;

    if (closeRef) {
      try {
        (closeRef.current || closeRef).click();
      } catch (error) {
        console.warn('Modal onclose button click emulation', error);
      }
    } else if (onClose) {
      onClose(evt);
    }
  }

  fixStretchedWidth(): void {
    // This call is delayed from the ModalGateway until show animation is done,
    // because getBoundingClientRect takes css transforms into account,
    // but we need to use it because unlike offsetWidth it returns precise width with fractions
    if (this.props.stretch && this.props.fixStretchedWidth && this.$modal) {
      this.width = `${this.$modal.getBoundingClientRect().width}px`;
    } else {
      this.width &&= null;
    }
  }

  render() {
    const {
      children,
      tid,
      small,
      large,
      full,
      stretch,
      autoFocus,
      instant,
      notResizable,
      dontStretchChildren,
      dontRestrainChildren,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      idleOnEsc,
      idleOnBackdropClick,
    } = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    let width;

    if (this.width) {
      width = this.width;
    } else if (small) {
      width = sizes.small;
    } else if (large) {
      width = sizes.large;
    } else if (full) {
      width = sizes.full;
    } else if (stretch) {
      width = sizes.stretch;
    } else {
      width = this.props.width /*custom*/ ?? sizes.medium;
    }

    const container = notResizable ? 'div' : resizableWithRef;
    const props: ComponentPropsWithoutRef<'div'> & ResizableProps = {
      'onClick': this.handleModalClick,
      'data-tid': tidUtils.getTid('comp-dialog', tid),
    };
    const className = cx(theme.modal, {
      [theme.stretchChildren]: !dontStretchChildren,
      [theme.noOverflowChildren]: !dontRestrainChildren,
    });

    if (notResizable) {
      props.style = {
        ...this.props.style,
        width,
        minWidth: sizes[minWidth as keyof typeof sizes] ?? minWidth,
        minHeight,
        maxWidth: sizes[maxWidth as keyof typeof sizes] ?? maxWidth,
        maxHeight,
      };
    } else {
      props.minWidth = sizes[minWidth as keyof typeof sizes] ?? minWidth;
      props.minHeight = minHeight;
      props.maxWidth = sizes[maxWidth as keyof typeof sizes] ?? maxWidth;
      props.maxHeight = maxHeight;

      /**
       * @type [defaultSize] is an optional property on @type [ResizableProps]
       * @type [ResizableProps] has property @type [Size], which requires width and height.
       */
      props.defaultSize = {width, height: 'auto'};
      props.handleWrapperClass = theme.fixedHeight;
    }

    const focusLockProps = {
      ...this.props.focusLockProps,
      ref: this.saveModalRef,
      className,
      autoFocus,
      as: container,
      lockProps: props,
    };

    return (
      <Gateway
        into="modal"
        theme={theme}
        instant={instant}
        instance={this}
        idleOnEsc={idleOnEsc}
        idleOnBackdropClick={idleOnBackdropClick}
        onClose={this.handleClose}
        zIndex={this.state.context.zIndex}
      >
        <FocusLock {...focusLockProps}>
          <ModalContext.Provider value={this.state.context}>{children}</ModalContext.Provider>
        </FocusLock>
      </Gateway>
    );
  }
}
