/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Children, Component} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {AppContext, type AppContextValue} from 'containers/App/AppUtils';
import {
  Badge,
  CounterBadge,
  Link,
  Icon,
  Tooltip,
  type TooltipProps,
  type LinkLikeProp,
  type IconName,
  type IconProps,
  type CounterBadgeColors,
} from 'components';
import {tidUtils} from 'utils';
import MenuItemCopy from './Presets/MenuItemCopy';
import styles from './Menu.css';
import type {
  ReactElement,
  KeyboardEvent,
  MouseEvent,
  FocusEvent,
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
} from 'react';
import type {ReactStrictNode} from 'utils/types';
import type {WithElement} from 'utils/react';
import type {MouseEventLike} from 'utils/dom';

// Pick classnames for current component from styles object
// don't use _.pick because _.pick doesn't work with identity-obj-proxy used in testing mock for css modules
const themable = {
  item: styles.item,
  itemSelectable: styles.itemSelectable,
  itemWithDropdown: styles.itemWithDropdown,
  drillIcon: styles.drillIcon,
  itemContent: styles.itemContent,
  itemContentWithCounter: styles.itemContentWithCounter,
  icon: styles.icon,
  text: styles.text,
  extender: styles.extender,
  badge: styles.badge,
  counter: styles.counter,
  disabled: styles.disabled,
  activeDropdown: styles.activeDropdown,
  activeItem: styles.activeItem,
  activeItemContent: styles.activeItemContent,
};

const defaultTid = 'comp-menu-item';

interface BaseProps {
  children?: JSX.Element | JSX.Element[];

  // Item content
  text?: ReactStrictNode;

  icon?: IconName | ReactElement | boolean;
  iconProps?: IconProps;

  // Optional parameters, if item is for navigation
  link?: LinkLikeProp;
  badge?: 'new' | 'preview';

  counter?: number; // Show CounterBadge with this number
  counterColor?: CounterBadgeColors; // Color of CounterBadge

  tid?: string;

  onRef?(item: MenuItem, hasElement: boolean): void; // Pass item instance upwards
  onClick?(event?: MouseEventLike, item?: MenuItem): void; // To notify upper subscriber
  onFocus?(event?: FocusEvent, item?: MenuItem): void; // To notify upper subscriber
  /**
   * This is used by MenuDropdown
   * @param event FocusEvent
   * @param item The menu item itself
   */
  onOriginFocus?(event?: FocusEvent, item?: MenuItem): void;
  onMouse?(event?: MouseEvent, item?: MenuItem): void; // To notify upper subscriber
  onSelect?(event?: MouseEventLike, item?: MenuItem): false | void; // User's handler for select. Return false to cancel

  disabled?: boolean; // disable toogle
  notSelectable?: boolean; // Make item not selectable. Is true by default if no onClick/link specified
  noCloseOnClick?: boolean; // Do not close menu on click

  data?: unknown;

  // Flag to highlight active tab
  // isActive can be set the following ways:
  //  1) Controlled e.g. passed in as a prop.
  //  2) If the MenuItem is the active route.
  //  3) If the MenuItem has a child which is the active route.
  isActive?: string[] | boolean | string | ((router: AppContextValue['router']) => boolean);

  /** set the item to be focused when the menu open */
  initiallyFocused?: boolean;

  wrapper?(content: JSX.Element): JSX.Element;

  // tooltip props
  tooltip?: ReactStrictNode;
  tooltipProps?: TooltipProps;

  updateParentPosture?: () => void;
}

export interface MenuItemProps extends Omit<ComponentPropsWithoutRef<'li'>, keyof BaseProps>, ThemeProps, BaseProps {}

type MenuItemState = Readonly<{
  isActive: MenuItemProps['isActive'];
  isFocusedParent: boolean;
  router: AppContextValue['router'];
}>;

export default class MenuItem extends Component<MenuItemProps, MenuItemState> {
  static contextType = AppContext;
  static Copy = MenuItemCopy;

  declare static context: AppContextValue;

  contentElement?: HTMLElement | WithElement | null;
  itemElement: HTMLElement | null;

  offsetTop = 0;

  constructor(props: MenuItemProps, context: AppContextValue) {
    super(props, context);

    this.state = {
      isActive: props.isActive,
      // isFocusedParent is used to keep the parent dropdown MenuItem highlighted when in focus
      isFocusedParent: false,
      router: context.router,
    };

    this.itemElement = null;

    this.saveMenuItemLIRef = this.saveMenuItemLIRef.bind(this);
    this.saveContentRef = this.saveContentRef.bind(this);

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.setParentFocus = this.setParentFocus.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<MenuItemProps>, prevState: MenuItemState) {
    const isActive = checkIsActive(nextProps, prevState.router);

    if (isActive !== prevState.isActive) {
      return {isActive};
    }

    return null;
  }

  shouldComponentUpdate(nextProps: MenuItemProps, nextState: MenuItemState) {
    return (
      nextState !== this.state ||
      Object.entries(nextProps).some(([key, value]) => {
        // MenuItem doesn't render children, so we don't care about children changes
        if (key === 'children') {
          return false;
        }

        return value !== this.props[key as keyof MenuItemProps];
      })
    );
  }

  setParentFocus(focusedParent: boolean): void {
    this.setState({isFocusedParent: focusedParent});
  }

  private saveMenuItemLIRef(element: HTMLElement | null) {
    this.itemElement = element;

    if (this.props.children) {
      this.offsetTop = element ? element.offsetTop : 0;
    }

    if (this.props.onRef) {
      this.props.onRef(this, Boolean(element));
    }
  }

  private saveContentRef(element: HTMLElement | WithElement | null) {
    this.contentElement = element;
  }

  private handleKeyDown(evt: KeyboardEvent) {
    // Prevent browser scrolling on space button down event
    if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  private handleKeyUp(evt: KeyboardEvent) {
    // Emulate click on enter/space button up event
    if (evt.key === ' ' || evt.key === 'Enter') {
      this.click();
    }
  }

  private handleMouseMove(evt: MouseEvent) {
    if (this.props.onMouse) {
      this.props.onMouse(evt, this);
    }
  }

  private handleFocus(evt: FocusEvent) {
    if (this.props.onFocus) {
      this.props.onFocus(evt, this);
    }
  }

  private handleClick(evt: MouseEventLike) {
    // stopPropagation is needed to prevent second trigger of this handler,
    // because we have it on both item and content (to treat dropdown arrow)
    evt.stopPropagation();

    if (this.props.notSelectable) {
      return;
    }

    // If user specified onSelect handler, call it.
    const selectResult = this.props.onSelect ? this.props.onSelect(evt, this) : undefined;

    // If onSelect returns false or it's element with sub dropdown - do nothing
    if (selectResult !== false && !this.props.children) {
      this.props.onClick?.(evt, this);
    }

    return selectResult; // Returning false to Link handler prevents click execution
  }

  focus(): void {
    if (document.activeElement !== this.itemElement) {
      this.itemElement?.focus();
    }
  }

  click(): void {
    const element = this.contentElement
      ? 'element' in this.contentElement
        ? this.contentElement.element
        : (this.contentElement as HTMLElement)
      : this.contentElement;

    if (element?.click) {
      element.click();
    }
  }

  render() {
    const {
      // Exclude passing dispatch, isActive to <li> element
      children,
      link,
      icon,
      iconProps,
      text,
      tid,
      data,
      isActive,
      badge,
      counter,
      counterColor,
      theme,
      disabled,
      noCloseOnClick,
      notSelectable,
      onRef,
      onFocus,
      // unused but it's destructure out of rest to prevent it from passing down to native elements
      onOriginFocus,
      // unused but it's destructure out of rest to prevent it from passing down to native elements
      initiallyFocused,
      onMouse,
      onClick,
      onSelect,
      wrapper,
      tooltip,
      tooltipProps,
      updateParentPosture,
      ...rest
    } = mixThemeWithProps(themable, this.props);

    const itemProps: ComponentPropsWithRef<'li'> = rest;

    // Flag to highlight menu item
    const {isActive: isActiveMenuItem, isFocusedParent} = this.state;

    itemProps.role = 'menuitem';
    itemProps.ref = this.saveMenuItemLIRef;
    itemProps.className = cx(theme.item, {
      [theme.itemSelectable]: !notSelectable,
      [theme.disabled]: disabled,
      [theme.activeItem]: isActiveMenuItem,
      [theme.activeDropdown]: isFocusedParent,
    });

    if (tid) {
      itemProps['data-tid'] = tidUtils.getTid(defaultTid, tid);
    }

    if (disabled) {
      itemProps['aria-disabled'] = true;
    }

    itemProps.onKeyUp = this.handleKeyUp;
    itemProps.onKeyDown = this.handleKeyDown;

    itemProps.onFocus = this.handleFocus;
    itemProps.onClick = this.handleClick;
    itemProps.onMouseMove = this.handleMouseMove;

    const contentProps = {
      // Make item content not tabbable (if it's a link <a> in browser has tabindex 0 by default),
      // to prevent a11y-focus-scope from iterating over this node
      tabIndex: -1,
      ref: this.saveContentRef,
      onClick: this.handleClick,
      disabled,
    };

    const contentClass = cx(theme.itemContent, {
      [theme.itemContentWithCounter]: counter,
      [theme.activeItemContent]: isActiveMenuItem,
    });

    const contentChildren = (
      <>
        {icon && (
          <div className={theme.icon}>
            {typeof icon === 'string' ? (
              <Icon name={icon} position="before" {...iconProps} />
            ) : typeof icon === 'boolean' ? null : (
              icon
            )}
          </div>
        )}
        <div className={theme.text}>{text}</div>
        {badge && <Badge type={badge} theme={theme} />}
        {counter && (
          <>
            <div className={theme.extender} />
            <CounterBadge
              count={counter}
              color={counterColor}
              instantAppearance
              theme={{counter: theme.counter}}
              disabled
              size="large"
            />
          </>
        )}
      </>
    );

    let content;

    if (link) {
      // If item is a Link, assign classes string to .link theme and link object properties to route properties
      Object.assign(contentProps, typeof link === 'string' ? {to: link} : link, {
        theme: Link.getLinkTheme(contentClass),
      });
      content = <Link {...contentProps}>{contentChildren}</Link>;
    } else {
      content = (
        <div {...contentProps} className={contentClass}>
          {contentChildren}
        </div>
      );
    }

    // Add arrow if item has dropdown
    if (children) {
      itemProps.className += ` ${theme.itemWithDropdown}`;
    }

    const liElem = (
      <li {...itemProps}>
        {content}
        {children && (
          <div className={theme.drillIcon}>
            <Icon name="next" />
          </div>
        )}
      </li>
    );

    const wrapped = wrapper?.(liElem) ?? liElem;

    return tooltip ? (
      <Tooltip content={tooltip} {...tooltipProps}>
        {() => wrapped}
      </Tooltip>
    ) : (
      wrapped
    );
  }
}

function checkIsActive(props: MenuItemProps, router: AppContextValue['router']): boolean {
  const {
    children,
    isActive,
    link, // Link is either a string or an object {to: string, params: object}
  } = props;

  if (typeof isActive === 'boolean') {
    return isActive;
  }

  if (typeof isActive === 'function') {
    return isActive(router);
  }

  const route = router.getState();

  if (!route) {
    return false;
  }

  const currentRouteName = route.name;

  if (Array.isArray(isActive)) {
    return isActive.some(routeName => currentRouteName.startsWith(`app.${routeName}`));
  }

  if (children) {
    // Highlights parent menu item
    return checkIfSomeChildActive(children, router);
  }

  let to;

  if (typeof isActive === 'string') {
    to = isActive;
  } else if (typeof link === 'string') {
    to = link;
  } else if (typeof link === 'object') {
    to = link.to;
  }

  if (to) {
    return currentRouteName === `app.${to}` || currentRouteName.startsWith(`app.${to}.`);
  }

  return false;
}

function checkIfSomeChildActive(children: MenuItemProps['children'], router: AppContextValue['router']): boolean {
  return Children.toArray(children).some(child => {
    const newChild = child as JSX.Element;

    return (
      (newChild.props.children && checkIfSomeChildActive(newChild.props.children, router)) ||
      checkIsActive(newChild.props, router)
    );
  });
}
