/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {classSplitter} from 'utils/react';
import {isMotionReduced} from 'utils/dom';
import {Icon} from 'components';
import styles from './TypedMessages.css';
import stylesUtils from 'utils.css';
import type {ReactStrictNode} from 'utils/types';

const iconByType = {
  info: 'info',
  success: 'inuse',
  warning: 'warning',
  error: 'error',
} as const;

const variants = {
  hide: () => ({height: 0, opacity: 0, transition: {duration: isMotionReduced() ? 0 : 0.15}}),
  show: () => ({height: 'auto', opacity: 1, transition: {duration: isMotionReduced() ? 0 : 0.2}}),
};

export type TypedMessage = {
  // Every new child will be animated on add, specify key so 'framer-motion' could determine which one needs to be animated
  key?: string;
  // Colored optional icon
  icon?: 'error' | 'info' | 'success' | 'warning';
  // To offset child message padding to parent icon
  alignWithIcon?: boolean;
  // Any context/text next to the optional icon, or without the icon
  content?: ReactStrictNode;
  // Color of the content and icon
  color?: 'error' | 'gray' | 'info' | 'success' | 'warning';
  // Shorthand for a specific fontSize (like 'var(--12px)'), but default is inherited from the parent
  fontSize?: string;
  // Custom tid
  tid?: string;
};

export interface TypedMessagesProps extends ThemeProps {
  // Gap class to specify distance between children. Default is 'gap' which will inherit specific gap from the closest parent
  // You can specify any gap class to override, like 'gapSmall'
  gap?: string;
  // Children is an array of objects, or null/false/'' to skip rendering
  children: (TypedMessage | null)[];
}

export default function TypedMessages(props: TypedMessagesProps): JSX.Element {
  const {gap = 'gap', children} = props;
  const theme = composeThemeFromProps(styles, props);

  return (
    <div className={classSplitter(stylesUtils, gap)}>
      <AnimatePresence initial={false}>
        {children.reduce((result: JSX.Element[], item, index) => {
          if (item) {
            const {key, icon, content, color, fontSize, alignWithIcon = false, tid} = item;

            result.push(
              <motion.div
                key={key || index}
                className={theme.section}
                style={{fontSize}}
                variants={variants}
                initial="hide"
                animate="show"
                exit="hide"
              >
                {icon ? (
                  <div data-tid={tid} className={cx(theme.icon, color ? theme[color] : theme[icon])}>
                    <Icon name={iconByType[icon]} />
                  </div>
                ) : null}
                <div
                  data-tid={tid}
                  className={cx(theme.message, {
                    ...(color && {[theme[color]]: color}),
                    [theme.offsetIconPadding]: alignWithIcon,
                  })}
                >
                  {content}
                </div>
              </motion.div>,
            );
          }

          return result;
        }, [])}
      </AnimatePresence>
    </div>
  );
}
