/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {AppContext} from 'containers/App/AppUtils';
import {AnimatePresence, motion} from 'framer-motion';
import _ from 'lodash';
import {useContext, useLayoutEffect, useState} from 'react';
import {isMotionReduced} from 'utils/dom';
import {getNativeElement} from 'utils/react';

export interface CutoutGatewayProps {
  elem: HTMLElement | null;

  /**
   * The offset of the cutout edges. Can be negative or positive.
   *
   * **Must be** a css value with unit (e.g. `1px`, `1em`) or variable
   **/
  offset?: string;
  onUnmountReady(): void;
}

export default function CutoutGateway({elem, offset = '0px', onUnmountReady}: CutoutGatewayProps): JSX.Element | null {
  const context = useContext(AppContext);
  const [position, setPosition] = useState<{x: number; y: number} | null>(null);
  const [dimension, setDimension] = useState<{width: number; height: number} | null>(null);

  // TODO: scroll lock document.body
  useLayoutEffect(() => {
    const originBodyPosition = document.body.style.position;

    document.body.style.position = 'relative';

    const id = context.upsert({insensitive: true});

    return () => {
      document.body.style.position = originBodyPosition;
      context.remove(id);
    };
  }, [context]);

  useLayoutEffect(() => {
    const target = getNativeElement(elem);

    if (target && target instanceof HTMLElement) {
      const bodyBB = document.body.getBoundingClientRect();

      let raf: number | undefined;

      const observeDimensionAndLocation = () => {
        const bb = target.getBoundingClientRect();

        const newDimension = {
          width: bb.width,
          height: bb.height,
        };

        if (!_.isEqual(dimension, newDimension)) {
          setDimension(newDimension);
        }

        const newPosition = {
          x: bb.x - bodyBB.x,
          y: bb.y - bodyBB.y,
        };

        if (!_.isEqual(newPosition, position)) {
          setPosition(newPosition);
        }

        raf = requestAnimationFrame(observeDimensionAndLocation);
      };

      observeDimensionAndLocation();

      return () => {
        if (raf !== undefined) {
          cancelAnimationFrame(raf);
        }
      };
    }

    if (dimension !== null) {
      setDimension(null);
    }

    if (position !== null) {
      setPosition(null);
    }
  }, [dimension, position, setDimension, setPosition, elem, offset]);

  return (
    <AnimatePresence initial={false} onExitComplete={onUnmountReady}>
      {!onUnmountReady && position && dimension && (
        <motion.svg
          key="overlay"
          width="100vw"
          height="100%"
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{type: 'tween', duration: isMotionReduced() ? 0 : 0.2}}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 'var(--shadow-above-all-z)',
            pointerEvents: 'none',
          }}
        >
          <mask id="cutout">
            <rect x="0" y="0" width="100%" height="100%" fill="rgb(255 255 255)" />
            <rect
              style={{
                transform: `translate(calc(${position.x}px - ${offset}), calc(${position.y}px - ${offset}))`,
                width: `calc(${dimension.width}px + ${offset} * 2)`,
                height: `calc(${dimension.height}px + ${offset} * 2)`,
              }}
              fill="rgb(0 0 0)"
            />
          </mask>
          <rect x="0" y="0" width="100%" height="100%" fill="rgb(0 0 0 / 0.5)" mask="url(#cutout)" />
        </motion.svg>
      )}
    </AnimatePresence>
  );
}
