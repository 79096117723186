/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Component, Fragment, createElement} from 'react';
import {Link, Notifications} from 'components';
import ProvisionButtons from 'containers/Provisioning/Provision/ProvisionButtons';
import {intersperseArray} from '@thor/utils';
import styles from './Provisioning.css';
import {AppContext} from 'containers/App/AppUtils';

export default class ProvisioningNotification extends Component {
  static contextType = AppContext;
  static propTypes = {
    disabled: PropTypes.bool,
    params: PropTypes.object,
    pversion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDone: PropTypes.func,
    onNavigate: PropTypes.func,
    objectsToProvision: PropTypes.object.isRequired,
    objectsToRevert: PropTypes.object, // Useful when provision and revert objects are different
    draft: PropTypes.object,
    active: PropTypes.object,
    hideButtons: PropTypes.bool,
    hideRevertButton: PropTypes.bool,
    pversionObjIsDeleted: PropTypes.bool,
    titleActive: PropTypes.string,
    titleDraft: PropTypes.string,
    titleUnsaved: PropTypes.string,
    policyUpdated: PropTypes.bool,

    unsavedWarningData: PropTypes.object,
    unsavedWarningTitle: PropTypes.string,
    unsavedWarningMessage: PropTypes.string,
    revertWarningTitle: PropTypes.string,
  };

  static defaultProps = {
    pversion: 'active',
    hideButtons: false,
    hideRevertButton: false,
    pversionObjIsDeleted: false,
    titleActive: intl('Policy.YouViewingActiveVersion'),
    titleDraft: intl('Policy.YouViewingDraftVersion'),
    titleUnsaved: intl('Policy.RulesetHasUnsavedChangesPending'),
    policyUpdated: false,
    unsavedWarningTitle: intl('Provision.ConfirmChanges'),
    unsavedWarningMessage: intl('Provision.ConfirmChangesWillBeSaved'),
    revertWarningTitle: intl('Provision.Revert.DiscardRulesetChanges'),
  };

  handleActiveClick = (evt, ...args) =>
    this.props.onNavigate ? this.props.onNavigate(evt, this.props.params.active, ...args) : null;

  handleDraftClick = (evt, ...args) =>
    this.props.onNavigate ? this.props.onNavigate(evt, this.props.params.draft, ...args) : null;

  render() {
    const {
      disabled,
      pversion,
      params,
      onDone,
      objectsToProvision,
      objectsToRevert,
      hideRevertButton,
      titleActive,
      titleDraft,
      titleUnsaved,
      active,
      draft,
      pversionObjIsDeleted,
      hideButtons,
      revertProps,
      policyUpdated,
      unsavedWarningData,
      unsavedWarningTitle,
      unsavedWarningMessage,
      revertWarningTitle,
    } = this.props;
    const {isCSFrame} = this.context;
    const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
    const isOldVersion = !_.isNaN(Number(pversion));

    const notification = {};
    const messages = [];

    if (pversion === 'draft') {
      if (policyUpdated) {
        messages.push(intl('Policy.PolicyUpdatesExist'));
      }

      notification.title = isCSFrame ? titleUnsaved : titleDraft;

      if (draft?.update_type === 'create') {
        notification.title += ` (${intl('Provision.PendingAddition')})`;
      } else if (draft?.update_type === 'delete') {
        notification.title += ` (${intl('Provision.PendingDeletion')})`;
      }
    } else if (isOldVersion) {
      let titleOldPversion = intl('Policy.OldVersionDesc', {version: pversion});
      // Note: A draft and active view will exist when a restore happens.
      // When pversionObjIsDeleted exist and !active && !draft - a restore did not happen
      //      pversionObjIsDeleted exist and active && draft - a restore occurred

      if (pversionObjIsDeleted && !active && !draft) {
        titleOldPversion = `${titleOldPversion} (${intl('Common.Deleted')})`;
      }

      notification.title = titleOldPversion;
    } else {
      notification.title = titleActive; //As default pversion is Active
    }

    if (pversion === 'active' || isOldVersion) {
      notification.type = 'instruction';

      if (active && isOldVersion) {
        messages.push(
          <Link params={params.active} onClick={this.handleActiveClick}>
            {intl('Policy.ViewActiveVersion')}
          </Link>,
        );
      }

      if (draft) {
        messages.push(
          <Link params={params.draft} onClick={this.handleDraftClick}>
            {intl('Policy.ViewDraftVersion')}
          </Link>,
        );
      }
    } else {
      notification.type = 'warning';

      if (!hideButtons) {
        notification.button = (
          <ProvisionButtons
            provisionProps={{color: 'primary', ...(typeof disabled === 'boolean' && {disabled})}}
            revertProps={revertProps}
            onDone={onDone}
            theme={buttonsTheme}
            objectsToProvision={objectsToProvision}
            objectsToRevert={objectsToRevert}
            hideRevertButton={hideRevertButton}
            unsavedWarningData={unsavedWarningData}
            unsavedWarningTitle={unsavedWarningTitle}
            unsavedWarningMessage={unsavedWarningMessage}
            revertWarningTitle={revertWarningTitle}
          />
        );
      }

      if (active) {
        messages.push(
          <Link params={params.active} onClick={this.handleActiveClick}>
            {intl('Policy.ViewActiveVersion')}
          </Link>,
        );
      }
    }

    notification.message = createElement(Fragment, {}, ...intersperseArray(messages, ' '));

    return <Notifications sidebar>{[notification]}</Notifications>;
  }
}
