/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {useSelector, useDispatch} from 'utils/redux';
import {useLayoutEffect, useCallback, useContext, isValidElement} from 'react';
import {StickyShadow, Button, Breadcrumbs, Badge, Switch} from 'components';
import {ProvisionMenu, UserMenu} from 'containers';
import HeaderProvider, {HeaderContext} from './HeaderProvider';
import {domUtils} from 'utils';

import styles from './Header.css';
import {getFeatureFlagsEvaluator, getVersionMismatch} from 'containers/App/AppState';
import {isUserAdmin, isUserOwner, isUserReadOnly, isUserWithReducedScope} from 'containers/User/UserState';
import {getHeaderHealthData} from 'containers/Header/HeaderState';
import {AppContext} from 'containers/App/AppUtils';

const stickyHeaderTheme = {sticky: styles.header, shadowDepth4: styles.shadowDepth4};

export default function Header() {
  const dispatch = useDispatch();
  const {context} = useContext(HeaderContext) ?? {};
  const {isNewUI, toggleNewUI} = useContext(AppContext);
  const {title, subtitle, label, noUnderline, headlineContentArr = []} = Object.assign({}, ...context);
  const userIsReadOnly = useSelector(isUserReadOnly);
  const userIsAdmin = useSelector(isUserAdmin);
  const userIsOwner = useSelector(isUserOwner);
  const userIsWithReducedScope = useSelector(isUserWithReducedScope);
  const healthData = useSelector(getHeaderHealthData);
  const versionMismatch = useSelector(getVersionMismatch);
  const featureFlagsEvaluator = useSelector(getFeatureFlagsEvaluator);

  const {healthEnabled, clusterCount, clusterStatus} = healthData;

  const healthIsEnabled = healthEnabled && (userIsOwner || userIsAdmin || userIsReadOnly);

  const showHealthCount = clusterCount || (clusterStatus && clusterStatus !== 'Normal');

  const handleClick = useCallback(
    (type, evt) => {
      domUtils.preventEvent(evt);
      dispatch({type, data: 'click'});
    },
    [dispatch],
  );

  // Set document title in Big-Endian order
  useLayoutEffect(() => {
    document.title = _.compact([label, subtitle, title, intl('Illumio.Name')]).join(' - ');
  }, [title, subtitle, label]);

  const displayedTitle = subtitle ?? title;

  return (
    <StickyShadow alwaysAnimate type="header" depth={4} theme={stickyHeaderTheme}>
      <div className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div className={styles.headline}>
            <Breadcrumbs />
            <div className={styles.headlineContent}>
              {displayedTitle ? (
                <div className={styles.title} data-tid="comp-navbar-label">
                  {displayedTitle}
                </div>
              ) : null}
              {label ? (
                <Badge size="large" theme={styles} themePrefix="mode-">
                  {`${intl('Common.Mode')}: ${label.replaceAll(/[()]/g, '')}`}
                </Badge>
              ) : null}
              {headlineContentArr.map((item, index) => (
                <div key={isValidElement(item) && item.key ? item.key : index} data-tid="elem-headline-item">
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.buttons}>
            {!__MSP__ && healthIsEnabled && versionMismatch === 0 && (
              <Button.Link
                size="large"
                tid="comp-navbar-health"
                color="standard"
                link="health.list"
                icon="health"
                counter={showHealthCount ? clusterCount || 1 : 0}
                counterColor={clusterStatus === 'Error' ? 'red' : 'orange'}
                theme={styles}
              />
            )}
            {!__MSP__ && !userIsWithReducedScope && versionMismatch === 0 && <ProvisionMenu />}
            <Button
              size="large"
              color="standard"
              tid="help-menu"
              icon="help"
              onClick={_.partial(handleClick, 'TOGGLE_HELP_MENU_MODE')}
              theme={styles}
            />
            <UserMenu />
            {(!__ANTMAN__ || featureFlagsEvaluator('EYE-102-Xpress-Lightning-UI-Toggle')) && (
              <Switch
                tid="newUI"
                checked={isNewUI}
                onChange={toggleNewUI}
                innerLabelOn={intl('Common.NewUI')}
                innerLabelOff={intl('Common.NewUI')}
                tooltip={isNewUI ? intl('Common.NewUITooltipOff') : intl('Common.NewUITooltipOn')}
              />
            )}
          </div>
        </div>
        {!noUnderline && <hr className={styles.horizontalLine} />}
      </div>
    </StickyShadow>
  );
}

Header.Provider = HeaderProvider;
