/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createShallowEqualSelector} from 'utils/react';
import {ipv4ToInt} from 'utils/ip';
import {
  formatFlowsAndBytes,
  formatArrow,
  getEndpointLabels,
  getEnforcement,
  getIpAddressOrIpCount,
  getManagedEndpointPill,
  getIpListsOrAggregatedLists,
  formatPortProtocol,
  formatProcess,
  formatWindowsService,
  formatUserName,
  getFQDN,
  formatPolicyDecision,
  formatConnectionCount,
  getUserServices,
  sortCheck,
} from 'containers/IlluminationMap/List/MapListUtils';
import {isManagedEndpoint} from 'containers/IlluminationMap/MapTypes';
import {getPort} from 'containers/Service/ServiceUtils';

/**
 [{
 header: string | Function,

 key: string | Function,
 value: string | Function,
 format: Function,
 sort: Function, // Getter for sorting value
 sortFunction: Function, // Custom sort function
 sortable: [true]boolean,
 isDate: boolean | string,
 }];
 */
export const getGridSettings = createShallowEqualSelector(
  options => options,
  ({providerConsumerOrder = 'consumerFirst', labelTypes, policyVersion = 'reported', connection = 'aggregated'}) => {
    return {
      id: 'maplist',
      sort: '-lastDetected',
      // API returns data already sorted by last detected https://jira.illum.io/browse/EYE-93678
      sortedNaturallyBy: '-lastDetected',
      capacities: [25, 50, 100, 250, 500],
      capacity: 50,
      maxPage: Number.MAX_SAFE_INTEGER,
      showColumns: true,
      showCapacity: true,
      showPagination: true,
      showManager: true,
      columns: {
        policy: {
          header: policyVersion === 'reported' ? intl('Common.ReportedPolicy') : intl('Common.DraftPolicy'),
          columns: {
            policyDecision: {
              header: intl('Explorer.Policy'),
              sort: ({row}) => row.data.policy[policyVersion]?.decision,
              format: ({row}) => formatPolicyDecision(row.data, policyVersion, connection),
            },
            direction: {
              header: intl('Explorer.Reporter'),
              disabled: connection === 'aggregated',
              sort: ({row}) => row.data.direction,
            },
          },
          templates: ['policyDecision', 'direction'],
        },
        source: {
          header: intl('Common.Consumer'),
          columns: {
            sourceName: {
              header: intl('Common.Name'),
              disabled: connection === 'aggregated',
              value: 'source',
              format: ({value}) => getManagedEndpointPill(value),
              sort: ({value}) => (isManagedEndpoint(value) ? value.details.name : null),
            },
            sourceIpOrCount: {
              header: intl('Common.IPAddress'),
              value: 'source',
              format: ({value}) => getIpAddressOrIpCount(value, 'source', connection),
              sort: ({value}) => (connection === 'aggregated' ? value.ips?.size : ipv4ToInt(value.ip)),
              sortFunction: sortCheck,
            },
            sourceNetworkProfile: {
              header: [intl('Edge.NetworkProfile')],
              value: ({row}) => row.data.network.name,
              format: ({value}) => value && `[${value}]`,
            },
            sourceFqdn: {
              header: intl('Common.ConsumerFqdn'),
              value: ({row}) => getFQDN(row.data.source), // TODO!!!!!!  dnsAddresses[rowA.dst_ip]
              sort: ({value = '\uFFFF'}) => value.toLocaleLowerCase().split('.').reverse().join(''),
              sortFunction: sortCheck,
            },
            sourceMode: {
              header: intl('Common.Enforcement'),
              value: 'source',
              format: ({value}) => getEnforcement(value, connection),
            },
            sourceItemsList: {
              header: intl('Common.Items'),
              value: 'source',
              format: ({row, value}) => getIpListsOrAggregatedLists(value, row.data, 'source', connection),
            },
          },
          templates: [
            ...(connection === 'aggregated' ? [] : ['sourceName']),
            'sourceIpOrCount',
            'sourceNetworkProfile',
            'sourceFqdn',
            'sourceMode',
            'sourceItemsList',
          ],
        },
        sourceLabels: {
          header: 'Consumer Labels',
          horizontal: true,
          ...getEndpointLabels('source', labelTypes),
        },
        sourceService: {
          header: `${intl('Port.ConsumerProcess')} [${intl('Users.User')}]`,
          columns: {
            sourceProcess: {
              header: intl('Common.Process'),
              format: ({row}) => formatProcess(row.data.service.outbound),
            },
            sourceWindowsService: {
              header: intl('Services.WindowsService'),
              format: ({row}) => formatWindowsService(row.data.service.outbound),
            },
            sourceUser: {
              header: intl('Users.User'),
              format: ({row}) => formatUserName(row.data.service.outbound),
            },
          },
          templates: ['sourceProcess', 'sourceWindowsService', 'sourceUser'],
        },
        consumerToProviderArrow: {
          header: formatArrow(null, policyVersion, providerConsumerOrder),
          manager: false,
          sortable: false,
          style: 'consumerToProviderArrow',
          format: ({row}) => formatArrow(row.data, policyVersion, providerConsumerOrder),
        },
        target: {
          header: intl('Common.Provider'),
          columns: {
            targetName: {
              header: intl('Common.Name'),
              disabled: connection === 'aggregated',
              value: 'target',
              format: ({value}) => getManagedEndpointPill(value),
              sort: ({value}) => (isManagedEndpoint(value) ? value.details.name : null),
            },
            targetIpOrCount: {
              header: intl('Common.IPAddress'),
              value: 'target',
              format: ({value}) => getIpAddressOrIpCount(value, 'target', connection),
              sort: ({value}) => (connection === 'aggregated' ? value.ips?.size : ipv4ToInt(value.ip)),
              sortFunction: sortCheck,
            },
            targetNetworkProfile: {
              header: intl('Edge.NetworkProfile'),
              value: ({row}) => row.data.network.name,
              format: ({value}) => value && `[${value}]`,
            },
            targetFqdn: {
              header: intl('Common.ProviderFqdn'),
              value: ({row}) => getFQDN(row.data.target),
              sort: ({value = '\uFFFF'}) => value.toLocaleLowerCase().split('.').reverse().join(''),
              sortFunction: sortCheck,
            },
            targetTransmission: {
              header: intl('Common.TransmissionMode'),
              value: 'target',
              format: ({value}) => (value.transmission === 'Unicast' ? null : <div>{value.transmission}</div>),
            },
            targetMode: {
              header: intl('Common.Enforcement'),
              value: 'target',
              sort: ({value}) => getEnforcement(value),
              format: ({value}) => getEnforcement(value),
            },
            targetItemsList: {
              header: intl('Common.Items'),
              value: 'target',
              format: ({row, value}) => getIpListsOrAggregatedLists(value, row.data, 'target', connection),
            },
          },
          templates: [
            ...(connection === 'aggregated' ? [] : ['targetName']),
            'targetIpOrCount',
            'targetNetworkProfile',
            'targetFqdn',
            'targetTransmission',
            'targetMode',
            'targetItemsList',
          ],
        },
        targetLabels: {
          header: 'Provider Labels',
          horizontal: true,
          ...getEndpointLabels('target', labelTypes),
        },
        targetService: {
          header: `${intl('Port.ProviderPortProcessService')} [${intl('Users.User')}]`,
          columns: {
            portProtocol: {
              header: intl('Port.Protocol'),
              value: 'service',
              format: ({value}) => formatPortProtocol(value),
              sort: ({value}) => getPort(value),
            },
            targetProcess: {
              header: intl('Common.Process'),
              format: ({row}) => formatProcess(row.data.service.inbound),
            },
            targetWindowsService: {
              header: intl('Services.WindowsService'),
              format: ({row}) => formatWindowsService(row.data.service.inbound),
            },
            targetUser: {
              header: intl('Users.User'),
              format: ({row}) => formatUserName(row.data.service.inbound),
            },
            targetServices: {
              header: intl('Common.Services'),
              format: ({row}) => getUserServices(row.data.service?.services),
            },
          },
          templates: ['portProtocol', 'targetProcess', 'targetWindowsService', 'targetUser', 'targetServices'],
        },
        flowsConnectionsAndBytes: {
          header: `${intl('Common.Flows')}/${intl('Common.Bytes')}`,
          columns: {
            connections: {
              header: intl('Common.Connections'),
              disabled: connection !== 'aggregated',
              value: ({row}) => parseInt(row.data.connectionCount, 10),
              format: ({value}) => formatConnectionCount(value),
            },
            flowsAndBytes: {
              header: intl('Common.Flows'),
              value: ({row}) => parseInt(row.data.flows, 10),
              format: ({row}) => formatFlowsAndBytes(row.data),
            },
          },
          templates: ['connections', 'flowsAndBytes'],
        },
        firstDetected: {
          isDate: 'L_HH_mm_ss',
          header: intl('Explorer.FirstDetected'),
          value: 'firstDetected',
        },
        lastDetected: {
          isDate: 'L_HH_mm_ss',
          header: intl('BlockedTraffic.List.LastDetected'),
          value: 'lastDetected',
        },
      },
      templates: [
        providerConsumerOrder === 'consumerFirst'
          ? [
              {columns: ['policy'], size: 'minmax(100px, 150px)'},
              {columns: ['source'], size: 'minmax(125px, 300px)'},
              {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
              {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
              {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
              {columns: ['target'], size: 'minmax(125px, 300px)'},
              {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
              {columns: ['targetService'], size: 'minmax(100px, 180px)'},
              {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
              {columns: ['firstDetected'], size: 'minmax(110px, 140px)'},
              {columns: ['lastDetected'], size: 'minmax(110px, 140px)'},
            ]
          : [
              {columns: ['policy'], size: 'minmax(100px, 150px)'},
              {columns: ['target'], size: 'minmax(125px, 300px)'},
              {columns: ['targetLabels'], size: 'minmax(180px, auto)'},
              {columns: ['targetService'], size: 'minmax(100px, 180px)'},
              {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
              {columns: ['source'], size: 'minmax(125px, 300px)'},
              {columns: ['sourceLabels'], size: 'minmax(180px, auto)'},
              {columns: ['sourceService'], size: 'minmax(100px, 180px)'},
              {columns: ['flowsConnectionsAndBytes'], size: 'minmax(120px, 140px)'},
              {columns: ['firstDetected'], size: 'minmax(110px, 140px)'},
              {columns: ['lastDetected'], size: 'minmax(110px, 140px)'},
            ],
        {
          maxWidth: 1600,
          template() {
            return providerConsumerOrder === 'consumerFirst'
              ? [
                  {columns: ['policy'], size: 'minmax(auto, 125px)'},
                  {columns: ['source', 'sourceService'], size: 'minmax(auto, 400px)'},
                  {columns: ['sourceLabels'], size: 'minmax(175px, auto)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['target', 'targetService'], size: 'minmax(auto, 400px)'},
                  {columns: ['targetLabels'], size: 'minmax(175px, auto)'},
                  {columns: ['flowsConnectionsAndBytes'], size: 'minmax(110px, 125px)'},
                  {columns: ['firstDetected'], size: 'minmax(110px, 125px)'},
                  {columns: ['lastDetected'], size: 'minmax(110px, 125px)'},
                ]
              : [
                  {columns: ['policy'], size: 'minmax(100px, 125px)'},
                  {columns: ['target', 'targetService'], size: 'minmax(auto, 400px)'},
                  {columns: ['targetLabels'], size: 'minmax(175px, auto)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['source', 'sourceService'], size: 'minmax(auto, 400px)'},
                  {columns: ['sourceLabels'], size: 'minmax(175px, auto)'},
                  {columns: ['flowsConnectionsAndBytes'], size: 'minmax(110px, 125px)'},
                  {columns: ['firstDetected'], size: 'minmax(110px, 125px)'},
                  {columns: ['lastDetected'], size: 'minmax(110px, 125px)'},
                ];
          },
        },
        {
          maxWidth: 1300,
          template() {
            return providerConsumerOrder === 'consumerFirst'
              ? [
                  {columns: ['policy'], size: 'minmax(100px, 130px)'},
                  {columns: ['source', 'sourceService'], size: 'minmax(auto, 400px)'},
                  {columns: ['sourceLabels'], size: 'minmax(125px, auto)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['target', 'targetService'], size: 'minmax(auto, 400px)'},
                  {columns: ['targetLabels'], size: 'minmax(125px, auto)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(140px, 150px)',
                  },
                ]
              : [
                  {columns: ['policy'], size: 'minmax(100px, 130px)'},
                  {columns: ['target', 'targetService'], size: 'minmax(auto, 400px)'},
                  {columns: ['targetLabels'], size: 'minmax(125px, auto)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['source', 'sourceService'], size: 'minmax(auto, 400px)'},
                  {columns: ['sourceLabels'], size: 'minmax(125px, auto)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(140px, 150px)',
                  },
                ];
          },
        },
        {
          maxWidth: 960,
          template() {
            return providerConsumerOrder === 'consumerFirst'
              ? [
                  {columns: ['policy'], size: 'minmax(100px, 100px)'},
                  {columns: ['source', 'sourceService'], size: 'minmax(auto,250px)'},
                  {columns: ['sourceLabels'], size: 'minmax(120px, auto)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['target', 'targetService'], size: 'minmax(auto, 250px)'},
                  {columns: ['targetLabels'], size: 'minmax(120px, auto)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(140px, 150px)',
                  },
                ]
              : [
                  {columns: ['policy'], size: 'minmax(100px, 100px)'},
                  {columns: ['target', 'targetService'], size: 'minmax(auto, 250px)'},
                  {columns: ['targetLabels'], size: 'minmax(120px, auto)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['source', 'sourceService'], size: 'minmax(auto, 250px)'},
                  {columns: ['sourceLabels'], size: 'minmax(120px, auto)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(140px, 150px)',
                  },
                ];
          },
        },
        {
          maxWidth: 800,
          template() {
            return providerConsumerOrder === 'consumerFirst'
              ? [
                  {columns: ['policy'], size: 'minmax(90px, 100px)'},
                  {columns: ['source', 'sourceLabels', 'sourceService'], size: 'minmax(auto, 275px)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['target', 'targetLabels', 'targetService'], size: 'minmax(auto, 275px)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(140px, 150px)',
                  },
                ]
              : [
                  {columns: ['policy'], size: 'minmax(90px, 100px)'},
                  {columns: ['target', 'targetLabels', 'targetService'], size: 'minmax(auto, 275px)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['source', 'sourceLabels', 'sourceService'], size: 'minmax(auto, 275px)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(140px, 150px)',
                  },
                ];
          },
        },
        {
          maxWidth: 640,
          template() {
            return providerConsumerOrder === 'consumerFirst'
              ? [
                  {columns: ['policy'], size: 'minmax(90px, 100px)'},
                  {columns: ['source', 'sourceLabels', 'sourceService'], size: 'minmax(auto, 200px)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['target', 'targetLabels', 'targetService'], size: 'minmax(auto, 200px)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(120px, 125px)',
                  },
                ]
              : [
                  {columns: ['policy'], size: 'minmax(90px, 100px)'},
                  {columns: ['target', 'targetLabels', 'targetService'], size: 'minmax(auto, 200px)'},
                  {columns: ['consumerToProviderArrow'], size: 'minmax(auto, 50px)'},
                  {columns: ['source', 'sourceLabels', 'sourceService'], size: 'minmax(auto, 200px)'},
                  {
                    columns: ['flowsConnectionsAndBytes', 'firstDetected', 'lastDetected'],
                    size: 'minmax(120px, 125px)',
                  },
                ];
          },
        },
      ],
    };
  },
);
