/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {Component, createRef} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import type {ComponentPropsWithRef, ComponentPropsWithoutRef, MutableRefObject, ChangeEvent, MouseEvent} from 'react';
import styles from './Switch.css';
import {tidUtils} from 'utils';
import {randomString} from '@thor/utils';
import type {ReactStrictNode} from 'utils/types';
import {Tooltip} from 'components';
import type {TooltipProps} from 'components/Tooltip/Tooltip';

type SwitchProps = ComponentPropsWithoutRef<'div'> &
  ThemeProps & {
    tid?: string;
    name?: string;

    // Whether switch should have error stroke color
    error?: boolean;
    // Whether switch should be disabled, also makes it insensitive
    disabled?: boolean;

    /** By default, switch input is _uncontrolled_, but passing "checked" makes it controlled by parent */
    checked?: boolean;

    /** Callback that is called on upon change, required in case of controlled behavior (when 'checked' prop is specified by parent) */
    onChange?: (evt: ChangeEvent<HTMLInputElement>, checked: boolean) => void;

    // Callback that is called after changed checked state has been rendered. Useful in case of _uncontrolled_ behavior to notify parent
    // Gets object with pressed keys as last argument
    onAfterChange?: (checked: boolean) => void;
    // We can lso set the initial state for _uncontrolled_ checkboxes
    initiallyChecked?: boolean;

    // Custom props for a hidden <input/> checkbox, for instance for specifying custom data-tid
    inputProps?: ComponentPropsWithRef<'input'>;
    // position of label text before the switch or after the switch
    labelBefore?: string;
    labelAfter?: string;
    // Optional labels inside the switch for on/off states
    innerLabelOn?: React.ReactNode;
    innerLabelOff?: React.ReactNode;
    // support for two sizes Small and Large and Large is the default
    size?: 'large' | 'small';

    tooltip?: ReactStrictNode;
    tooltipProps?: TooltipProps;
  };

type SwitchState = Readonly<{
  checked: boolean;
  controlled: boolean;
}>;

export default class Switch extends Component<SwitchProps, SwitchState> {
  id: string;
  elementRef: MutableRefObject<HTMLLabelElement | null>;

  constructor(props: SwitchProps) {
    super(props);

    // Generate unique id to tie input and text labels
    this.id = randomString(5, true);

    this.state = {checked: this.props.initiallyChecked ?? false, controlled: typeof this.props.checked === 'boolean'};
    this.elementRef = createRef();

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleLabelClick = this.handleLabelClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<SwitchProps>, prevState: SwitchState) {
    const controlled = typeof nextProps.checked === 'boolean';

    if (controlled && nextProps.checked !== prevState.checked) {
      return {controlled, checked: nextProps.checked};
    }

    if (controlled !== prevState.controlled) {
      return {controlled, checked: Boolean(prevState.checked)};
    }

    return null;
  }

  componentDidUpdate(_prevProps: Readonly<SwitchProps>, prevState: SwitchState) {
    const {onAfterChange} = this.props;

    if (prevState.checked !== this.state.checked && typeof onAfterChange === 'function') {
      onAfterChange(this.state.checked);
    }
  }

  handleCheckboxChange(evt: ChangeEvent<HTMLInputElement>): void {
    const {onChange} = this.props;
    const {controlled} = this.state;

    // Switch always controls 'checked' state on the <input>, since we pass 'checked' to inputProps.
    // Here we take the updated (intentional) 'checked' state from the dom input itself,
    // but it will flip back by React before Paint, unless we rerender again with the new state later
    const checking = evt.target.checked;

    if (controlled) {
      // Let the parent decided if it wants to accept and render or not (then React will flip it back before Paint)
      onChange?.(evt, checking);
    } else {
      // If the parent doesn't control Switch, rerender with the new state right away
      this.setState({checked: checking});
    }
  }

  handleLabelClick(evt: MouseEvent): void {
    evt.stopPropagation(); // To prevent parent click event

    // Remove text selection, that browser adds from the previous focused element.
    // Might happen if user clicks to check/uncheck very quickly or in case of shift+click
    if (typeof window.getSelection === 'function') {
      window.getSelection()?.removeAllRanges();
    }
  }

  render() {
    const {
      name,
      disabled = false,
      // Custom input properties
      inputProps: {...inputProps} = {},
      size = 'large',
      innerLabelOn,
      innerLabelOff,
      labelBefore,
      labelAfter,
      tid = name,
      tooltip,
      tooltipProps,
    } = this.props;

    const theme = composeThemeFromProps(styles, this.props);
    const {checked} = this.state;

    inputProps.id ||= this.id;
    inputProps.name ||= name;
    inputProps['data-tid'] ||= tidUtils.getTid('switch-input', tid);

    inputProps.type = 'checkbox';
    inputProps.checked = checked;
    inputProps.disabled = disabled;
    inputProps.className = theme.input;
    inputProps.onChange = this.handleCheckboxChange;

    if (disabled) {
      inputProps.tabIndex = -1;
    }

    return (
      <>
        {tooltip ? <Tooltip content={tooltip} reference={this.elementRef} {...tooltipProps} /> : null}

        {/* A label element wrapping the switch and the surrounding text, making all of it clickable */}
        <label
          htmlFor={inputProps.id}
          ref={this.elementRef}
          onClick={this.handleLabelClick}
          data-tid={tidUtils.getTid('switch', tid)}
          className={cx(theme.label, theme[size], {
            // #FirefoxHAS - Remove the following when Firefox starts supporting :has()
            [theme.disabled]: disabled,
            [theme[checked ? 'on' : 'off']]: true,
          })}
        >
          {/* Optional label before the switch */}
          {labelBefore ? <div className={theme.text}>{labelBefore}</div> : null}

          {/* The switch itself */}
          <div className={theme.switch}>
            {/* Hidden checkbox */}
            <input {...inputProps} />
            {/* The circle handle */}
            <div className={theme.handle} />
            {/* The optional text before/after the handle inside the Switch */}
            {innerLabelOn || innerLabelOff ? (
              <div className={theme.textInner}>
                {innerLabelOn ? <div className={theme.textInnerOn}>{innerLabelOn}</div> : null}
                {innerLabelOff ? <div className={theme.textInnerOff}>{innerLabelOff}</div> : null}
              </div>
            ) : null}
          </div>

          {/* Optional label after the switch */}
          {labelAfter ? <div className={theme.text}>{labelAfter}</div> : null}
        </label>
      </>
    );
  }
}
