/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {ExpandableLabelList} from 'components';
import type {ExpandableLabelListProps} from 'components/ExpandableLabelList/ExpandableLabelList';

const shortDefaults = {
  initialCount: 8,
  showInModalFrom: 25,
};

// Policy Object view pages with overwritten default props
export default function ExpandableLabelListShort<T>(
  props: Omit<ExpandableLabelListProps<T>, 'initialCount' | 'showInModalFrom'>,
): JSX.Element {
  return <ExpandableLabelList {...props} {...shortDefaults} />;
}
