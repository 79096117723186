/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {AppContext} from 'containers/App/AppUtils';
import Selectors from '../Selectors';
import {PureComponent} from 'react';
import {connect} from 'utils/redux';
import {createNewObject} from 'containers/Selectors/SelectorSaga';
import {getAllMatches, isLoadingMatches} from 'containers/Selectors/SelectorState';

@connect((state, props) => ({
  loading: isLoadingMatches(state),
  matches: getAllMatches(state, props.objects),
}))
export default class SingleGroupMultiItemSelect extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    initialItems: PropTypes.array,
    disabled: PropTypes.bool,
    facets: PropTypes.object,
    statics: PropTypes.object,
    categories: PropTypes.array.isRequired,
    hiddenCategories: PropTypes.array,
    objects: PropTypes.array.isRequired,
    allowCreateTypes: PropTypes.array,
    addValue: PropTypes.func,
    placeholder: PropTypes.string,
    footer: PropTypes.any,
    onSelectionChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onSelectionChange: _.noop,
  };

  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getDropdownValues(matches) {
    if (!matches) {
      return {};
    }

    return _.reduce(
      matches,
      (prev, object, key) => {
        const matches = object.matches
          ? object.matches.map(match => {
              if (typeof match === 'string') {
                return {categoryKey: key, value: match};
              }

              return {categoryKey: key, ...match};
            })
          : [];

        prev[key] = {
          matches,
          count: object.num_matches,
        };

        return prev;
      },
      {},
    );
  }

  handleInputChange(input, object) {
    this.fetchData(input, object);
  }

  async handleCreate(input, object) {
    const {type, key} = object;
    const data = {key, value: input};
    const query = {query: '', key};

    const created = await this.context.store.runSaga(createNewObject, type, data, query).toPromise();

    this.props.onSelectionChange([{key: created.key, value: created.value, href: created.href}]);
  }

  fetchData(input, object) {
    const {type, key, ...params} = object;
    const query = {query: input, key};

    this.props.dispatch({type: `${type.toUpperCase()}_MATCHES_REQUEST`, object: type, query, params});
  }

  render() {
    const {
      initialItems,
      hiddenCategories,
      placeholder,
      loading,
      disabled,
      footer,
      statics,
      facets,
      objects,
      allowCreateTypes,
    } = this.props;
    const matches = this.getDropdownValues(this.props.matches);
    const categories = [...this.props.categories];

    if (hiddenCategories && hiddenCategories.length) {
      categories.push({key: 'show_all_categories', value: intl('ObjectSelector.ShowAllCategories')});
    }

    const props = {
      initialItems,
      placeholder,
      activeCategoryKey: categories[0].categoryKey,
      categories,
      clearAll: true,
      disabled,
      hiddenCategories,
      facets,
      statics,
      matches,
      objects,
      footer,
      loading,
      allowCreateTypes,
      onCreate: this.handleCreate,
      showContainerTitle: true,
      allowMultipleItems: true,
      onInputChange: this.handleInputChange,
      onSelectionChange: this.props.onSelectionChange,
    };

    return <Selectors {...props} />;
  }
}
