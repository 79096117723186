/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import type {AnyAction} from 'redux';
import type {RootState} from 'utils/redux';
import type {HrefId} from 'utils/href';

declare module 'utils/redux' {
  interface RootState {
    // TODO: should augment user state
    readonly xpressDashboard: {
      readonly serversCount: number;
      readonly protectedServersCount: number;
      readonly unlabeledServersCount: number;
      readonly unprotectedServersCount: number;
      readonly endpointsCount: number;
      readonly protectedEndpointsCount: number;
      readonly unlabeledEndpointsCount: number;
      readonly unprotectedEndpointsCount: number;
      readonly queryId: HrefId | null;
      readonly inProgressQueryId?: HrefId;
      readonly trafficUpdatedAt: string;
    };
  }
}

export default {
  xpressDashboard(state = {}, action: AnyAction): RootState['xpressDashboard'] {
    switch (action.type) {
      case 'SET_XPRESS_DASHBOARD_DATA':
        return {...state, ...action.data};
      default:
        return state as RootState['xpressDashboard'];
    }
  },
};

const getXpressDashboard = (state: RootState) => state.xpressDashboard;

export const getXpressDashboardState = createSelector(getXpressDashboard, dashboardState => dashboardState);
