/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {DefaultDraftBlockRenderMap, getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import {
  getConvertedPastedTextToList,
  setPastedText,
  getSelectedRangeMaxAllowedPastedText,
  getSingleRowMaxAllowedPastedText,
} from '../../EditorTypes/IPListEditor/IPListEditorUtils';
import IPDecorator from './Decorators/IPDecorator';
import styles from './IP.css';
import IPItem from './IPItem';
import ErrorCounter from './ErrorCounter/ErrorCounter';
import {Map} from 'immutable';
import {ipUtils} from 'utils';
import _ from 'lodash';

export default config => {
  // store is a value used to pass into DecoratedErrorCounter or
  // other future components.
  //
  // Note: This is the standard declaration used for creating plugins for draft-js-plugins
  // e.g. https://github.com/draft-js-plugins/draft-js-plugins/blob/master/draft-js-emoji-plugin/src/index.js
  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
  };

  // Component div for the EditorState component for any matching 'unstyled' block
  const MyBlockerRenderMap = props => (
    <div className={styles.main} data-tid="comp-ip-editor">
      {props.children}
    </div>
  );

  const theme = config?.theme;

  // Whether we need to show blue dot on the changed rows
  const showModified = config?.showModified;

  // A component used to show the error counts
  const DecoratedErrorCounter = props => <ErrorCounter {...props} theme={theme} store={store} />;

  const blockRenderMap = Map({
    unstyled: {
      element: 'div', // When block type is 'unstyled' then use a 'div'
      wrapper: <MyBlockerRenderMap />, // Parent container for all type: unstyled
    },
  });

  // Initialize decorators for <EditorState/>
  const decorators = [IPDecorator(config)];

  // Use this to merge new render types
  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  return {
    DecoratedErrorCounter,
    initialize: ({getEditorState, setEditorState}) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },
    keyBindingFn: (evt, {getEditorState}) => {
      const {hasCommandModifier} = KeyBindingUtil;

      // 'Ctr-r' : redo
      if (evt.keyCode === 82 && hasCommandModifier(evt)) {
        // Allow DraftJS to intercept 'Ctrl-r'
        // Don't return 'handled' in handleKeyCommand to allow DraftJS to process
        return 'redo';
      }

      if (evt.key === 'ArrowUp') {
        const editorState = getEditorState();
        const currentContent = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const firstBlock = currentContent.getFirstBlock();
        const firstBlockKey = firstBlock.getKey();
        const focusKey = selectionState.getFocusKey();

        // When on the first block don't move the up arrow cursor
        if (start === 0 && end === 0 && firstBlockKey === focusKey) {
          // Prevent the left arrow from continuing
          return 'arrowup';
        }
      }

      if (evt.key === 'ArrowLeft') {
        const editorState = getEditorState();
        const selectionState = editorState.getSelection();
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();

        if (start === 0 && end === 0) {
          // Prevent the left arrow from continuing
          return 'arrowleft';
        }
      }

      if (evt.key === 'Enter') {
        const editorState = getEditorState();
        const currentContent = editorState.getCurrentContent();
        const blockMap = currentContent.getBlockMap();

        const maxAllowedIP = config?.maxAllowedIP;

        // When maxAllowedIP doesn't exist then there isn't a max ip
        if (Number(maxAllowedIP) && blockMap.size >= maxAllowedIP) {
          // Prevent Enter command when max IP has reached
          return 'preventEnter';
        }
      }

      // Key binding fallback for default DraftJS command
      return getDefaultKeyBinding(evt);
    },
    decorators,
    blockRenderMap: extendedBlockRenderMap,
    blockRendererFn: (contentBlock, {setEditorState, getEditorState, setReadOnly}) => {
      const type = contentBlock.getType();

      // 'unstyled' is default block type
      if (type === 'unstyled') {
        return {
          component: IPItem,
          props: {
            onChange: setEditorState,
            getEditorState,
            setReadOnly,
            showModified,
          },
        };
      }
    },
    handleKeyCommand: command => {
      /* Note:
       * Return 'not-handled' to use DraftJS default behavior
       * Return 'handled' to not use DraftJS default behavior
       *
       * There is an issue created for this method: 'handleKeyCommand'
       * doesn't honor 'not-handled'
       *
       * https://github.com/facebook/draft-js/issues/1296
       */

      if (command === 'preventEnter') {
        // Return 'handled' to prevent DraftJS's default 'Enter' command
        return 'handled';
      }

      if (command === 'arrowleft' || command === 'arrowup') {
        // Return 'handled' to not use DraftJS's 'ArrowLeft' and 'ArrowUp' default behavior
        return 'handled';
      }

      // Return 'not-handled' to use DraftJS'S default key behavior
      return 'not-handled';
    },
    handlePastedText: (text, html, editorState, {setEditorState}) => {
      const selectionState = editorState.getSelection();
      const selectionAnchorKey = selectionState.getAnchorKey();
      const selectionFocusKey = selectionState.getFocusKey();
      const {maxAllowedIP, disableValidation, ...props} = config;
      const parseAddressChecks = _.pick(props, Object.keys(ipUtils.parseAddressChecks));

      let textList = getConvertedPastedTextToList(text, undefined, disableValidation);
      let currentEditorState = editorState;

      if (maxAllowedIP) {
        if (selectionAnchorKey === selectionFocusKey) {
          const {newEditorState, max} = getSingleRowMaxAllowedPastedText({editorState, maxAllowedIP});

          currentEditorState = newEditorState;
          textList = textList.splice(0, max);
        } else {
          textList = textList.splice(0, getSelectedRangeMaxAllowedPastedText({editorState, maxAllowedIP}));
        }
      }

      const newEditorState = setPastedText(currentEditorState, textList, parseAddressChecks, disableValidation);

      setEditorState(newEditorState);

      // Return 'handled' to not use DraftJS default behavior
      return 'handled';
    },
  };
};
