/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {PureComponent} from 'react';
import {HeaderMenu} from 'containers';
import {connect} from 'utils/redux';
import {Icon, CounterBadge, MenuItem, Button} from 'components';
import {getPendingCount} from 'containers/Provisioning/Pending/List/PendingListState';
import styles from './ProvisionMenu.css';
import ProvisionProgressMenu from './ProvisionProgressMenu';
import {AppContext} from 'containers/App/AppUtils';

const menuTheme = _.pick(styles, ['menu', 'trigger']);

@connect(state => ({count: getPendingCount(state)}))
export default class ProvisionMenu extends PureComponent {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    const {count} = this.props;

    return (
      <>
        <MenuItem link="pending" text={intl('Provision.DraftChangeCount', {count})} data-tid="comp-navbar-provision" />
        <MenuItem
          link="versions.list"
          text={intl('Provision.Versions', {multiple: true})}
          data-tid="comp-navbar-versions"
        />
        <MenuItem link="provisioning" text={<ProvisionProgressMenu />} data-tid="comp-navbar-provision-bar" />
      </>
    );
  }

  render() {
    const {count} = this.props;

    return this.context.isNewUI ? (
      <Button.Menu
        theme={styles}
        themePrefix="provision-"
        menuNoDropdownIcon
        size="large"
        color="standard"
        data-tid="comp-navbar-provision"
        menuAlign="left"
        menu={this.renderContent}
        icon="provision"
        counter={count}
        counterColor="orange"
      />
    ) : (
      <div className={styles.provision} data-tid="comp-navbar-provision">
        <HeaderMenu theme={menuTheme} data-tid="provision" iconBefore={<Icon name="provision" />}>
          {this.renderContent}
        </HeaderMenu>
        <CounterBadge color="orange" count={count} theme={styles} size="large" />
      </div>
    );
  }
}
