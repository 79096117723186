/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {routesMerger, routesTree as commonRoutes} from '../router/routes';

export default _.mergeWith(
  commonRoutes,
  {
    name: 'app',
    forwardTo: 'landing',
    children: [
      {path: '/', forwardTo: 'landing'},
      {name: 'landing', path: '/landing', container: 'Landing', load: () => import(/* webpackChunkName: 'Landing' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Landing/Landing')},
      {
        name: 'coreservicesettings',
        path: '/coreservicesettings',
        container: 'CoreServicesSettings', load: () => import(/* webpackChunkName: 'CoreServicesSettings' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/CoreServices/Settings/CoreServicesSettings'),
      },
      {
        name: 'coreservices',
        path: '/coreservices',
        container: 'CoreServicesMain', load: () => import(/* webpackChunkName: 'CoreServicesMain' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/CoreServices/CoreServicesMain'),
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:coreserviceslist', container: 'CoreServicesList', load: () => import(/* webpackChunkName: 'CoreServicesList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/CoreServices/List/CoreServicesList')},
          {
            name: 'item',
            path:
              '/:id/:tab' +
              '?:coreServicesItem-recommended' +
              '?:coreServicesItem-accepted' +
              '?:coreServicesItem-rejected',
            container: 'CoreServicesItem', load: () => import(/* webpackChunkName: 'CoreServicesItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/CoreServices/Item/CoreServicesItem'),
            defaultParams: {tab: 'recommended'},
          },
        ],
      },
      {
        name: 'workloads',
        path: '/workloads',
        forwardTo: '>list',
        container: 'WorkloadManagement', load: () => import(/* webpackChunkName: 'WorkloadManagement' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/WorkloadManagement/WorkloadManagement'),
        children: [
          {
            name: 'list',
            group: 'Workloads',
            path: '/?:id?:workloadlist?:type',
            container: 'WorkloadList', load: () => import(/* webpackChunkName: 'WorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/List/WorkloadList'),
          },
          {name: 'create', path: '/create', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/Edit/WorkloadEdit')},
          {
            name: 'item',
            path: '/:id',
            forwardTo: '>view',
            container: 'WorkloadItem', load: () => import(/* webpackChunkName: 'WorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/WorkloadItem'),
            children: [
              {name: 'view', path: '/', container: 'WorkloadSummary', load: () => import(/* webpackChunkName: 'WorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/Summary/WorkloadSummary')},
              {name: 'edit', path: '/edit', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/Edit/WorkloadEdit')},
              {name: 'virtualServices', path: '/virtualServices?:workloadvs', container: 'WorkloadVirtualServices', load: () => import(/* webpackChunkName: 'WorkloadVirtualServices' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/VirtualServices/WorkloadVirtualServices')},
              {name: 'services', path: '/services?:workloadprocesses', container: 'WorkloadProcesses', load: () => import(/* webpackChunkName: 'WorkloadProcesses' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/Processes/WorkloadProcesses')},
              {name: 'blockedTraffic', path: '/blockedtraffic', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/JumpToOld')},
              {name: 'rules', path: '/rules?:inbound?:outbound?:forward?:customip?:admin', container: 'WorkloadRules', load: () => import(/* webpackChunkName: 'WorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/Rules/WorkloadRules')},
              {name: 'report', path: '/report?:compatibilityReport', container: 'WorkloadCompatibilityReport', load: () => import(/* webpackChunkName: 'WorkloadCompatibilityReport' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/Report/WorkloadCompatibilityReport')},
              {name: 'vulnerabilities', path: '/vulnerabilities?:workloadvulnerability', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/JumpToOld')},
              {
                name: 'boundaries',
                path: '/boundaries?:inboundenforcement?:outboundenforcement',
                container: 'WorkloadEnforcementBoundaries', load: () => import(/* webpackChunkName: 'WorkloadEnforcementBoundaries' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Workload/Item/EnforcementBoundaries/WorkloadEnforcementBoundaries'),
              },
            ],
          },
          {
            name: 'containers',
            path: '/containers',
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:scope?:cclusterworkloadlist', container: 'ContainerWorkloadList', load: () => import(/* webpackChunkName: 'ContainerWorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/ContainerWorkload/List/ContainerWorkloadList')},
              {
                name: 'item',
                path: '/:id',
                container: 'ContainerWorkloadItem', load: () => import(/* webpackChunkName: 'ContainerWorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/ContainerWorkload/Item/ContainerWorkloadItem'),
                forwardTo: '>view',
                children: [
                  {name: 'view', path: '/', container: 'ContainerWorkloadSummary', load: () => import(/* webpackChunkName: 'ContainerWorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/ContainerWorkload/Item/Summary/ContainerWorkloadSummary')},
                  {name: 'containers', path: '/containers', container: 'ContainerWorkloadContainers', load: () => import(/* webpackChunkName: 'ContainerWorkloadContainers' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/ContainerWorkload/Item/Containers/ContainerWorkloadContainers')},
                  {name: 'rules', path: '/rules?:inbound?:outbound?:customip', container: 'ContainerWorkloadRules', load: () => import(/* webpackChunkName: 'ContainerWorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/ContainerWorkload/Item/Rules/ContainerWorkloadRules')},
                ],
              },
            ],
          },
          {
            name: 'vens',
            path: '/vens',
            container: 'Ven', load: () => import(/* webpackChunkName: 'Ven' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Ven/Ven'),
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:venslist', container: 'VenList', load: () => import(/* webpackChunkName: 'VenList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Ven/List/VenList'), group: 'Workloads'},
              {
                name: 'item',
                path: '/:id',
                forwardTo: '>view',
                container: 'VenItem', load: () => import(/* webpackChunkName: 'VenItem' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Ven/Item/VenItem'),
                children: [
                  {name: 'view', path: '/?:supportreportlist', container: 'VenSummary', load: () => import(/* webpackChunkName: 'VenSummary' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Ven/Item/Summary/VenSummary')},
                  {name: 'edit', path: '/edit', container: 'VenEdit', load: () => import(/* webpackChunkName: 'VenEdit' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Ven/Item/Edit/VenEdit')},
                  {
                    name: 'containerWorkloads',
                    path: '/containerworkloads?:cclusterworkloadlist',
                    container: 'VenContainerWorkloads', load: () => import(/* webpackChunkName: 'VenContainerWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/Ven/Item/ContainerWorkloads/VenContainerWorkloads'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'xpressdashboard',
        path: '/xpressdashboard',
        container: 'XpressDashboard', load: () => import(/* webpackChunkName: 'XpressDashboard' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Dashboard/XpressDashboard'),
      },
      {
        name: 'rulesets',
        children: [
          {
            name: 'item',
            path: '/:id/:pversion?:tab?:maplist',
          },
        ],
      },
      {
        name: 'ruleSearch',
        path: '/rulesearch/:pversion',
        defaultParams: {pversion: 'draft'},
        container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/containers/JumpToOld'),
        group: 'Troubleshoot',
      },
      // new CoreX POC routes
      {name: 'protect', path: '/protect', container: 'Protect', load: () => import(/* webpackChunkName: 'Protect' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Protect/Protect')},
      {
        name: 'endpoints',
        path: '/endpoints',
        forwardTo: '>pairing',
        children: [
          {
            name: 'pairing',
            path: '/pairing',
            container: 'EndpointPairing', load: () => import(/* webpackChunkName: 'EndpointPairing' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Endpoints/EndpointPairing/EndpointPairing'),
          },
          {
            name: 'traffic',
            path: '/traffic?endpointTrafficList',
            container: 'EndpointTraffic', load: () => import(/* webpackChunkName: 'EndpointTraffic' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Endpoints/EndpointTraffic/EndpointTraffic'),
          },
          {
            name: 'enforcement',
            path: '/enforcement',
            forwardTo: '>list',
            children: [
              {
                name: 'list',
                path: '?:tab?:maplist?:endpointEnforcementList',
                container: 'EndpointEnforcementList', load: () => import(/* webpackChunkName: 'EndpointEnforcementList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Endpoints/EndpointEnforcement/List/EndpointEnforcementList'),
                defaultParams: {tab: 'user'},
              },
            ],
          },
        ],
      },
      {
        name: 'servers',
        path: '/servers?:onboarding',
        container: 'Servers', load: () => import(/* webpackChunkName: 'Servers' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Servers/Servers'),
        forwardTo: '>pairing',
        children: [
          {
            name: 'pairing',
            path: '/pairing',
            container: 'ServerPairing', load: () => import(/* webpackChunkName: 'ServerPairing' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Servers/ServerPairing/ServerPairing'),
          },
          {
            name: 'labeling',
            path: '/labeling',
            container: 'ServerLabeling', load: () => import(/* webpackChunkName: 'ServerLabeling' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Servers/ServerLabeling/ServerLabeling'),
          },
          {
            name: 'enforcement',
            path: '/enforcement',
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/', container: 'ServerEnforcementList', load: () => import(/* webpackChunkName: 'ServerEnforcementList' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Servers/ServerEnforcement/List/ServerEnforcementList')},
              {
                name: 'item',
                path: '/:id',
                forwardTo: '>view',
                children: [
                  {
                    name: 'view',
                    path: '?:maplist',
                    container: 'ServerView', load: () => import(/* webpackChunkName: 'ServerView' */ '/home/jenkins/BUILD_AREA/workspace/thor-pipeline_trains_corex_dev/app/antman/containers/Servers/ServerEnforcement/View/ServerEnforcementView'),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  routesMerger,
);
