/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {isEdge, getIsCSFrame} from 'containers/App/AppState';
import {
  getAllUsersMap,
  isUserReadOnly,
  isUserReadOnlyAll,
  doesUserHaveGlobalObjectPermissions,
} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './IPListListConfig';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'IP_LISTS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'IP_LISTS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getIPLists = state => state.iplist?.list;
export const getIPListsCount = state => state.iplist.count;

const getIPListsRows = createSelector(
  [getIPLists, getAllUsersMap, isUserReadOnly, doesUserHaveGlobalObjectPermissions],
  (iplist, usersMap, userIsReadOnly, userHasGlobalObjectPermissions) =>
    iplist.map(item => ({
      key: item.href,
      // IP List is in draft mode if it has pending status
      draft: Boolean(item.update_type),
      // Make IPList created by system not selectable, since it cannot be removed or provisioned
      selectable: userHasGlobalObjectPermissions && item.created_by?.href !== '/users/0',
      // IPList is removable only if it's active or with pending changes expect pending deletion
      removable: !userIsReadOnly && (!item.update_type || item.update_type !== 'delete'),
      // Fill each IPList with user object
      data: {
        ...item,
        created_by: fillUserInfo(usersMap, item.created_by),
        updated_by: fillUserInfo(usersMap, item.updated_by),
      },
    })),
);

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getGridSettings = createSelector([isUserReadOnlyAll, gridSettings], (userIsReadOnlyAll, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = userIsReadOnlyAll;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getIPListsRows,
    filterMap: getFilterMap,
  });

export const getIPListsPage = createStructuredSelector({
  categories,
  grid: getGrid,
  count: getIPListsCount,
  userIsReadOnly: isUserReadOnly,
  edgeEnabled: isEdge,
  isCSFrame: getIsCSFrame,
});
