/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useContext, type ComponentPropsWithoutRef} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {ModalContext} from './ModalUtils';
import {Icon} from 'components';
import styles from './ModalHeader.css';
import type {ReactStrictNode} from 'utils/types';

export interface ModalHeaderProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title'>, ThemeProps {
  // Any valid react element can be a title
  title?: ReactStrictNode;
  // Whether hide (true) or show close icon on header (true - default)
  noCloseIcon?: boolean;
}

export default function ModalHeader(props: ModalHeaderProps): JSX.Element {
  const {title, theme, noCloseIcon, ...elementProps} = mixThemeWithProps(styles, props);
  const {onClose} = useContext(ModalContext);

  elementProps.className = theme.header;
  elementProps['data-tid'] = 'comp-dialog-header';

  return (
    <div {...elementProps}>
      {title ? (
        <div className={theme.title} data-tid="comp-dialog-title">
          <span className={theme.titleText} title={typeof title === 'string' ? title : undefined}>
            {title}
          </span>
        </div>
      ) : null}
      {!noCloseIcon && typeof onClose === 'function' && (
        <div className={theme.close} onClick={onClose} data-tid="comp-dialog-close" title={intl('Common.Close')}>
          <Icon name="close" />
        </div>
      )}
    </div>
  );
}
