/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import PropTypes from 'prop-types';
import {Component, createRef} from 'react';
import {object, string} from 'yup';
import {connect} from 'utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {Badge, Button, Modal, ToolGroup, ToolBar, TypedMessages, AttributeList, Form, InfoCard, Link} from 'components';
import {getLabelGroupEdit} from './LabelGroupEditState';
import LabelGroupState from '../LabelGroupState';
import {updateLabelGroup, fetchVersions, createLabelGroup, fetchLabelGroupEdit} from '../Item/LabelGroupItemSaga';
import {fetchLabelGroupsMatches} from '../List/LabelGroupListSaga';
import {HeaderProps} from 'containers';
import {reactUtils, hrefUtils} from 'utils';
import {setExistingName, isExcludeName, getDuplicateObject} from 'containers/FormComponents/duplicateValidation';
import {getLabelSelectorOptions} from 'containers/Label/LabelSettings/LabelSettingState';
import {formatDataReference, isEntityEdited} from 'utils/dataValidation';

const getInitialValues = (labelGroup = {}, labelGroupTypes = []) => ({
  name: labelGroup.detail?.draft?.name ?? '',
  description: labelGroup.detail?.draft?.description ?? '',
  type: Form.Utils.findSelectedOption(labelGroupTypes, labelGroup.detail?.draft?.key),
});
// when container is controlled we will pass the data via containerProps, as opposed to connecting to the store
const makeMapState = (state, props) =>
  props.controlled ? {labelSelectorOptions: getLabelSelectorOptions(state)} : getLabelGroupEdit(state);

@connect(makeMapState, null, null, {forwardRef: true})
export default class LabelGroupEdit extends Component {
  static prefetch = fetchLabelGroupEdit;
  static contextType = AppContext;
  static reducers = LabelGroupState;

  static propTypes = {
    buttonAlign: PropTypes.oneOf(['top', 'bottom']),
    onDone: PropTypes.func,
    onCancel: PropTypes.func,
    excludeNames: PropTypes.array, // checking for exclude names in isExcludeName fn
    excludeNameMessage: PropTypes.string, // error message in case user hits one of the excluded names
  };

  constructor(props) {
    super(props);

    // Use to determine if a label group name already exist setting this doesn't require re-render
    this.existingNameError = false;

    this.labelGroupTypes = props.labelSelectorOptions.filter(type => !props.excludeKeys?.includes(type.value));
    this.initialValues = getInitialValues(props.labelGroup, this.labelGroupTypes);

    this.schemas = object({
      name: string()
        .max(225)
        .test(
          'is-duplicate',
          () => {
            if (this.duplicateObject) {
              return intl(
                'Common.NameExist',
                {
                  value: (
                    <Link to="labelGroups.item" params={{id: hrefUtils.getId(this.duplicateObject.href)}}>
                      {this.duplicateObject.name}
                    </Link>
                  ),
                },
                {jsx: true},
              );
            }

            return this.props.excludeNameMessage ?? intl('Common.NameIsNotAllowed');
          },
          () =>
            // During an onBlur (navigating away), need to preserve the error message when there is a name clash
            !this.existingNameError,
        )
        .required(Form.emptyMessage),
      type: object().nullable().required(Form.emptyMessage),
      description: string(),
    });

    this.state = {
      saving: false,
      error: null,
      isEdit: props.routeName === 'app.labelGroups.item.summary.edit',
      initialValues: this.initialValues,
    };

    this.infoCardIconRef = createRef();

    this.renderForm = this.renderForm.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.validateAndUpdateName = _.debounce(this.validateAndUpdateName.bind(this), 500);
    this.duplicateObject = null;
  }

  componentDidMount() {
    const {labelGroup, controlled} = this.props;

    if (controlled && !_.isEmpty(labelGroup)) {
      // We need to call formik setValue to set field values with prop data so that form dirty is set true
      // Passing it as initial value in <Form/> will not set dirty flag
      this.formik.setValues(getInitialValues(labelGroup, this.labelGroupTypes));

      this.validateAndUpdateName();
    }
  }

  handleCancel(evt) {
    const {
      props: {onCancel, routeParams},
      state: {isEdit},
      context: {navigate},
    } = this;

    if (typeof onCancel === 'function') {
      return onCancel();
    }

    navigate({
      evt,
      ...(isEdit ? {to: 'labelGroups.item.summary', params: routeParams} : {to: 'labelGroups.list'}),
    });
  }

  async handleSave(evt) {
    const {
      props: {labelGroup, onDone, shouldDispatch},
      state: {isEdit},
      formik: {values, setSubmitting},
      context: {fetcher, navigate},
    } = this;

    try {
      let id;
      const data = {
        name: values.name.trim(),
        description: values.description.trim(),
      };

      if (!isEdit) {
        data.key = values.type.value;
      }

      await reactUtils.setStateAsync({saving: true}, this);

      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      if (isEdit) {
        id = this.props.routeParams.id;

        if (labelGroup.detail.draft.external_data_set) {
          data.external_data_set = labelGroup.detail.draft.external_data_set;
        }

        if (labelGroup.detail.draft.external_data_reference) {
          data.external_data_reference = formatDataReference(labelGroup.detail.draft.external_data_reference);
        }

        await fetcher.spawn(updateLabelGroup, {params: {label_group_id: id, pversion: 'draft'}, data});
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createLabelGroup, {params: {pversion: 'draft'}, data});

        id = hrefUtils.getId(href);
      }

      const {
        detail: {draft},
      } = await fetcher.fork(fetchVersions, {
        params: {id, pversion: 'draft'},
        dispatch: shouldDispatch,
      });

      // Wait for progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));

      if (typeof onDone === 'function') {
        onDone(evt, draft);
      } else {
        // Navigate to a view page
        navigate({
          to: isEdit ? 'labelGroups.item.summary' : 'labelGroups.item.members',
          params: {id, pversion: 'draft'},
        });
      }
    } catch (error) {
      this.setState({error, saving: false});
      // Call formik method to set isSubmitting to false
      setSubmitting(false);
    }
  }

  // Handle the input field
  async handleNameChange(evt) {
    const {setFieldValue, validateForm} = this.formik;
    const value = evt.target.value;

    // Update the Form.Input name value since self component is controlling
    setFieldValue('name', value);

    // Reset the existingNameError here to prevent seeing the deleted character delay
    if (this.existingNameError) {
      this.existingNameError = false;
      // Call to validateForm formik's schema
      validateForm();
    }

    // Don't need to call debounce when value is empty
    if (value.trim()) {
      // Note: Invoke debounce here to delay after calling setFieldValue for formik's values to update properly
      this.validateAndUpdateName();
    }
  }

  handleAlertClose() {
    this.setState({error: null});
  }

  // Validating name if it's already existing
  async validateAndUpdateName() {
    const {
      context: {fetcher},
      props: {excludeNames},
      formik: {values},
    } = this;

    this.duplicateObject = null;

    if (isExcludeName(excludeNames, this.formik.values.name, this.state.initialValues.name)) {
      this.existingNameError = true;
      setExistingName(this.formik);

      return;
    }

    // Note: Important to trim value to pass to facet API
    const value = values.name.trim();

    // Don't need to make request when the original label name match current in addition make only
    // request when there is a value.
    if (value && this.formik.initialValues.name !== value) {
      if (this.fetchMatches) {
        // Cancel task if it is still running
        fetcher.cancel(this.fetchMatches);
      }

      this.fetchMatches = fetcher.fork(fetchLabelGroupsMatches, {
        query: {facet: 'name', query: value, max_results: 1},
        params: {pversion: 'draft'},
      });

      try {
        const {data} = await this.fetchMatches;

        // returns duplicated object of Input request with a link (href) to render in errorMessage of Input
        this.duplicateObject = getDuplicateObject(data?.matches, this.formik.values.name);

        if (this.duplicateObject) {
          this.existingNameError = true;
          setExistingName(this.formik);

          return;
        }
      } catch (error) {
        await reactUtils.setStateAsync({facet: {error}}, this);
      }
    }
  }

  renderAlert() {
    const {error, isEdit} = this.state;
    const title = isEdit ? intl('LabelGroups.UpdateError') : intl('LabelGroups.CreateError');

    return (
      <Modal.Alert stretch title={title} onClose={this.handleAlertClose} buttonProps={{text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: error.errors}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {saving, onSaveDone, error, isEdit} = this.state;
    const {isValid} = options;
    const {labelGroup, buttonAlign = 'top', typeIsDisabled, saveButtonProps, cancelButtonProps} = this.props;

    this.formik = options;

    const buttons = (
      <ToolBar>
        <ToolGroup>
          <Button
            icon="save"
            text={intl('Common.Save')}
            tid="save"
            disabled={isValid === false}
            onClick={this.handleSave}
            onProgressDone={onSaveDone}
            progressCompleteWithCheckmark
            progress={saving}
            progressError={error !== null}
            {...saveButtonProps}
          />
          <Button
            icon="cancel"
            text={intl('Common.Cancel')}
            color="standard"
            tid="cancel"
            onClick={this.handleCancel}
            {...cancelButtonProps}
          />
        </ToolGroup>
      </ToolBar>
    );

    return (
      <>
        {buttonAlign === 'top' && buttons}
        <AttributeList>
          {[
            {
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              tid: 'name',
              value: (
                <Form.Input
                  name="name"
                  tid="name"
                  onChange={this.handleNameChange}
                  placeholder={intl('LabelGroups.Create.Placeholder.LabelGroupName')}
                />
              ),
            },
            {
              key: <Form.Label name="type" title={intl('Common.Type')} />,
              tid: 'type',
              value: (
                <>
                  <Form.Selector
                    name="type"
                    tid="type"
                    disabled={isEdit || typeIsDisabled}
                    options={this.labelGroupTypes}
                    placeholder={intl('LabelGroups.Create.Placeholder.LabelGroupType')}
                  />
                  <InfoCard trigger={this.infoCardIconRef}>
                    {() => [
                      {title: intl('LabelGroups.Types')},
                      {header: intl('Common.Role'), message: intl('Common.RoleDescription')},
                      {header: intl('Common.Application'), message: intl('Common.ApplicationDescription')},
                      {header: intl('Common.Environment'), message: intl('Common.EnvironmentDescription')},
                      {header: intl('Common.Location'), message: intl('Common.LocationDescription')},
                    ]}
                  </InfoCard>
                </>
              ),
              icon: <InfoCard.Icon ref={this.infoCardIconRef} />,
              valueGap: 'gapLarge',
            },
            {
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              tid: 'description',
              value: (
                <Form.Textarea
                  name="description"
                  tid="description"
                  placeholder={intl('LabelGroups.Create.Placeholder.LabelGroupDescription')}
                />
              ),
            },
            isEdit &&
              labelGroup.detail.draft &&
              labelGroup.detail.draft.external_data_set && {
                tid: 'external_data_set',
                key: intl('Common.ExternalSet'),
                value: labelGroup.detail.draft.external_data_set,
              },
            isEdit &&
              labelGroup.detail.draft &&
              labelGroup.detail.draft.external_data_reference && {
                tid: 'external_data_reference',
                key: intl('Common.ExternalReference'),
                valueGap: 'gapMedium gapHorizontal gapAlignStart',
                value: isEntityEdited(labelGroup.detail.draft.external_data_reference) ? (
                  <>
                    <Badge type="updated" style={{lineHeight: 'var(--21px)'}}>
                      {intl('Common.Edited')}
                    </Badge>
                    <span>{labelGroup.detail.draft.external_data_reference}</span>
                  </>
                ) : (
                  labelGroup.detail.draft.external_data_reference
                ),
              },
            buttonAlign === 'bottom' ? {value: buttons} : null,
          ]}
        </AttributeList>
      </>
    );
  }

  render() {
    const {
      state: {error, isEdit},
      props: {routeParams, controlled, formProps},
    } = this;

    return (
      <>
        {!controlled && (
          <HeaderProps
            title={intl('Labels.Groups')}
            label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
            up={isEdit ? {to: 'labelGroups.item.summary', params: routeParams} : 'labelGroups.list'}
          />
        )}
        <Form
          enableReinitialize
          schemas={this.schemas}
          initialValues={controlled ? getInitialValues() : this.initialValues}
          {...formProps}
        >
          {this.renderForm}
        </Form>
        {error && this.renderAlert()}
      </>
    );
  }
}
