/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {getRouteParams, getRouteName, getRoute} from 'containers/App/AppState';
import {getUserAllGridSeenColumns} from 'containers/User/UserState';
import {createDeepEqualSelector} from 'utils/react';
import * as GridUtils from './GridUtils';

// Method to generate uniqueId from grid id and route name
// For instance, grid-iplistlist-app_iplists
const routeNameDotsRegex = /\./g;
export const getGridUniqueId = (id, routeName) => `${id}_${routeName.replaceAll(routeNameDotsRegex, '-')}`;

// Method to generate uniqueId for cache key of 're-reselect'
export const getGridUniqueKey = (state, settings) =>
  getGridUniqueId(typeof settings === 'function' ? settings(state).id : settings.id, getRouteName(state));

const emptyArray = [];
const emptyObject = {};

const emptyGridParams = {passed: emptyObject, valid: emptyObject, isEmpty: true};
export const getGridUrlParams = createCachedSelector(
  [
    (state, {route}) => (route === undefined ? getRoute(state) : route),
    (state, {settings}) => (typeof settings === 'function' ? settings(state) : settings),
  ],
  (route, grid) => {
    const validUrlKeys = ['sort', 'filter', 'capacity', 'columns', 'page'];
    const gridParamsUrl = route.params[grid.id];

    if (!gridParamsUrl) {
      return emptyGridParams;
    }

    try {
      const passed = JSON.parse(gridParamsUrl);

      let valid = Object.entries(passed).reduce((result, [key, value]) => {
        if (validUrlKeys.includes(key) && value) {
          result[key] = value;
        }

        return result;
      }, {});

      // If all passed keys are valid, just assign passed to valid so consumer can use '===' to check if url is valid
      if (Object.keys(passed).every(key => valid.hasOwnProperty(key))) {
        valid = passed;
      }

      return {passed, valid, isEmpty: Object.keys(valid).length === 0};
    } catch {
      return {...emptyGridParams, isInvalid: true};
    }
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getValidSortValues = createCachedSelector(
  (state, {settings}) => (typeof settings === 'function' ? settings(state) : settings),
  settings =>
    Object.entries(settings.columns).reduce((result, [id, column]) => {
      if (id === 'checkboxes' || column.sortable === false || column.disabled) {
        return result;
      }

      // Allow sortable column to be sorted
      // A column with sub columns still can have its own value/format/sort functions to combine things in any way.
      // So allow parent column to be sorted as well
      result.add(id);

      if (__DEV__ && column.templates?.length && !column.columns) {
        console.error(
          `Did you forget to pass 'columns' prop along with the 'templates' prop for the '${id}' column of the '${settings.id}' Grid?`,
        );
      }

      // Allow sortable sub columns to be sorted too, combine a sort id as 'parentId-childId'
      column.templates?.forEach(subColumnId => {
        if (__DEV__ && !column.columns[subColumnId]) {
          console.error(
            `Did you forget to pass a '${subColumnId}' column in the '${id}' parent column of the '${settings.id}' Grid?`,
          );
        }

        if (column.columns[subColumnId].sortable === false || column.columns[subColumnId].disabled) {
          return;
        }

        result.add(`${id}-${subColumnId}`);
      });

      return result;
    }, new Set()),
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getUrlSortParam = createCachedSelector(
  [getGridUrlParams, getValidSortValues],
  (gridParams, validSortValues) => {
    const urlValue = gridParams && !gridParams.isInvalid && gridParams.valid.sort;

    if (GridUtils.isSortValueValid(validSortValues, urlValue)) {
      return urlValue;
    }
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getUrlPageParam = createCachedSelector(
  [(state, {settings}) => (typeof settings === 'function' ? settings(state) : settings), getGridUrlParams],
  (settings, gridParams) => {
    const pageNumber = gridParams && !gridParams.isInvalid && gridParams.valid.page;

    if (!pageNumber) {
      return;
    }

    const value = Number(pageNumber);

    if (!isNaN(value) && value > 0 && value <= settings.maxPage) {
      return value;
    }
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getUrlCapacityParam = createCachedSelector(
  [(state, {settings}) => (typeof settings === 'function' ? settings(state) : settings), getGridUrlParams],
  (settings, gridParams) => {
    const urlValue = gridParams && !gridParams.isInvalid && gridParams.valid.capacity;

    if (!urlValue) {
      return;
    }

    const value = Number(urlValue);

    if (GridUtils.isCapacityValueValid(settings.capacities, value)) {
      return value;
    }
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getGridDefaultColsSelector = createCachedSelector(
  [(state, {settings}) => (typeof settings === 'function' ? settings(state) : settings).columns],
  columns => GridUtils.prepareColumns(columns),
)({keySelector: (state, {settings}) => getGridUniqueKey(state, settings), selectorCreator: createDeepEqualSelector});

const emptyColumns = {passed: emptyArray, valid: emptyArray, isEmpty: true};

export const getUrlColumnsParam = createCachedSelector(
  [
    getGridDefaultColsSelector,
    (state, props) => {
      const gridParams = getGridUrlParams(state, props);

      // Extracting only columns url params, to make getUrlColumnsParam insensitive to other params like page
      return gridParams && !gridParams.isInvalid && gridParams.valid.columns;
    },
  ],
  (defaultColumns, passedColumns) => {
    if (!passedColumns) {
      return {...emptyColumns, columns: defaultColumns};
    }

    const result = GridUtils.getValidColumns(defaultColumns, passedColumns);

    result.columns = new Map(
      [...defaultColumns.entries()].map(([id, column]) => [
        id,
        {...column, hidden: !result.valid.includes(id) && column.id !== 'checkboxes'},
      ]),
    );

    return result;
  },
)({keySelector: (state, {settings}) => getGridUniqueKey(state, settings), selectorCreator: createDeepEqualSelector});

const getUserSeenAndNewColumnIds = createCachedSelector(
  [
    getRouteName,
    getUserAllGridSeenColumns,
    (state, {settings}) => (typeof settings === 'function' ? settings(state) : settings),
  ],
  (routeName, allGridSeenCols, settings) => {
    const allColumnIds = GridUtils.allColumnIds(settings.columns);
    const seenColumnIds = allGridSeenCols[getGridUniqueId(settings.id, routeName)] || emptyArray;
    const newColumnIds = seenColumnIds.length > 0 ? allColumnIds.filter(id => !seenColumnIds.includes(id)) : emptyArray;

    return {newColumnIds, seenColumnIds, allColumnIds};
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getGridColumnIds = createCachedSelector(
  [getUrlColumnsParam, getUserSeenAndNewColumnIds],
  (urlColumnsObj, seenAndNewColumnIds) => {
    const columnIds = GridUtils.getColumnIdsByCategory(urlColumnsObj.columns);

    return {...seenAndNewColumnIds, ...GridUtils.sortGridColumnIds(columnIds, urlColumnsObj.columns)};
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

const emptyFilter = {passed: emptyObject, valid: emptyObject, isEmpty: true};
export const getUrlFilterParam = createCachedSelector(
  [getGridUrlParams, (state, {filterMap}) => (typeof filterMap === 'function' ? filterMap(state) : filterMap)],
  (gridParams, filterMap) => {
    const urlValue = gridParams && !gridParams.isInvalid && gridParams.valid.filter;

    if (!urlValue) {
      return emptyFilter;
    }

    const validKeysSet = new Set(Object.keys(filterMap));
    const passed = urlValue;

    if (!Object.keys(passed).length) {
      return emptyFilter;
    }

    let valid = Object.entries(passed).reduce((result, [key, values]) => {
      if (validKeysSet.has(key) && Array.isArray(values) && values.length) {
        result[key] = values;
      }

      return result;
    }, {});

    // If all passed keys are valid, just assign passed to valid so consumer can use '===' to check if url is valid
    if (Object.keys(passed).every(key => valid.hasOwnProperty(key))) {
      valid = passed;
    }

    return {passed, valid, isEmpty: Object.keys(valid).length === 0};
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

const emptyScope = {passed: emptyObject, valid: emptyObject, isEmpty: true};
export const getUrlScopeValue = createSelector(
  state => getRouteParams(state).scope,
  urlValue => {
    if (!urlValue) {
      return emptyScope;
    }

    try {
      const passed = JSON.parse(urlValue);

      if (!Object.keys(passed).length) {
        return emptyScope;
      }

      // TODO: add validation to compute 'valid'
      const valid = passed;

      return {passed, valid, isEmpty: Object.keys(valid).length === 0};
    } catch {
      return {...emptyScope, isInvalid: true};
    }
  },
);

const getSettings = (state, settings) => {
  if (__DEV__ && typeof settings !== 'function') {
    throw new Error('Grid settings should be a selector');
  }

  return settings(state);
};

export const getGridConfigSelector = createCachedSelector(
  [getRouteName, (state, {settings}) => getSettings(state, settings)],
  (routeName, settings) => ({...settings, uniqueId: getGridUniqueId(settings.id, routeName)}),
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getGridSelector = createCachedSelector(
  [
    getGridConfigSelector,
    (state, {rows}) => (typeof rows === 'function' ? rows(state) : rows),
    (state, {settings}) => getGridUrlParams(state, {settings}),
    (state, {settings}) => getUrlSortParam(state, {settings}),
    (state, {settings}) => getUrlPageParam(state, {settings}),
    (state, {settings, filterMap}) => getUrlFilterParam(state, {settings, filterMap}),
    (state, {settings}) => getUrlCapacityParam(state, {settings}),
    (state, {settings}) => getUrlColumnsParam(state, {settings}),
    (state, {settings}) => getGridColumnIds(state, {settings}),
  ],
  (settings, rows, params, sort, page, filter, capacity, columnsObj, columnIds) => {
    const result = GridUtils.getGridData({
      settings,
      rows,
      columns: columnsObj.columns,
      sort,
      page,
      filter: filter.valid,
      capacity: capacity || settings.capacity,
      params: params.valid,
      columnIds,
    });

    return result;
  },
)((state, {settings}) => getGridUniqueKey(state, settings));

export const getGridSortedRows = createCachedSelector(
  [
    getGridConfigSelector,
    (state, {rows}) => (typeof rows === 'function' ? rows(state) : rows),
    (state, {settings}) => getGridUrlParams(state, {settings}),
    (state, {settings}) => getUrlSortParam(state, {settings}),
    (state, {settings}) => getUrlPageParam(state, {settings}),
    (state, {settings, filterMap}) => getUrlFilterParam(state, {settings, filterMap}),
    (state, {settings}) => getUrlCapacityParam(state, {settings}),
    (state, {settings}) => getUrlColumnsParam(state, {settings}),
    (state, {settings}) => getGridColumnIds(state, {settings}),
  ],
  (settings, rows, params, sort, page, filter, capacity, columnsObj, columnIds) => {
    const sortObject = GridUtils.getSortObject({sort, gridId: settings.id});
    const sortedNaturallyBy = settings.sortedNaturallyBy;
    const resultColumns = GridUtils.getColumns({sort, sortObject, columns: columnsObj.columns, gridId: settings.id});
    const result = GridUtils.getSortedRows({
      settings,
      rows,
      columns: resultColumns,
      sort: sort || settings.sort,
      sortObject,
      sortedNaturallyBy,
      page,
      filter: filter.valid,
      capacity: capacity || settings.capacity,
      params: params.valid,
      columnIds,
      gridId: settings.id,
    });

    return result;
  },
)((state, {settings}) => getGridUniqueKey(state, settings));
