/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import {createSelector, createStructuredSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {serverLabelingListGridSettings, rulePreviewGridSettings} from './ServerLabelingConfig';
import {getServerRows} from '../ServersState';
import {getRuleTemplates} from 'antman/containers/TemplatesWizard/TemplatesWizardState';
import {getHelpUrl, getHelpVersion} from 'containers/App/AppState';

export default {
  customServerPolicies(state = {}, action) {
    switch (action.type) {
      case 'SET_SERVER_POLICY':
        const {href, selectedProtectionSchema} = action.data;

        if (href) {
          return {...state, [href]: selectedProtectionSchema};
        }

        return action.data;
      default:
        return state;
    }
  },

  rulePreview(state = {}, action) {
    switch (action.type) {
      case 'GET_SERVER_RULES_PREVIEW':
        return action.data;
      default:
        return state;
    }
  },

  protectionSchemas(state = [], action) {
    switch (action.type) {
      case 'SET_PROTECTION_SCHEMAS':
        return action.data;
      default:
        return state;
    }
  },
};

export const getCustomServerPolicies = state => state.customServerPolicies;
export const getProtectionSchemas = state => state.protectionSchemas;
const getOverrideDenyRules = state => state.rulePreview.override_deny_rules ?? [];
const getAllowRules = state => state.rulePreview.sec_rules ?? [];
const getDenyRules = state => state.rulePreview.deny_rules ?? [];

const getProtectionSchemasMap = createSelector(getProtectionSchemas, protectionSchemas =>
  protectionSchemas.reduce((map, schema) => {
    // some templates don't have rule_set_external_data_reference
    const key = schema.rule_set_external_data_reference ?? schema.external_data_reference;

    return map.set(key, schema);
  }, new Map()),
);

export const getServerRowsWithPolicySelections = createSelector(
  [getServerRows, getCustomServerPolicies, getRuleTemplates, getProtectionSchemasMap],
  (serverRows, customPolicies, ruleTemplates, protectionSchemasMap) => {
    return serverRows.map(serverRow => {
      return produce(serverRow, draft => {
        const {serverRoles, href} = serverRow.data;
        // only recommend a template if the template exists; default to 'None' even if server role is set
        const allTemplates = serverRoles.filter(serverRole => Boolean(protectionSchemasMap.get(serverRole)));

        const serverRoleTemplates = allTemplates.length ? allTemplates : [];
        const selectedProtectionSchema =
          customPolicies[href] ?? (serverRoleTemplates.length ? serverRoleTemplates : 'None');
        const protectionSchemaDetails = protectionSchemasMap.get(selectedProtectionSchema);
        const selectedProtectionSchemaArray = Array.isArray(selectedProtectionSchema)
          ? selectedProtectionSchema
          : [selectedProtectionSchema];

        const serverRulesets = selectedProtectionSchemaArray.map(external_data_reference => {
          return ruleTemplates.find(template => template.external_data_reference === external_data_reference);
        });

        // anything after hyphen is unique identifier
        draft.data.recommendedPolicyName = protectionSchemaDetails?.protection_schema ?? allTemplates;
        draft.data.serverRoleTemplates = serverRoleTemplates;
        draft.data.selectedProtectionSchema = selectedProtectionSchemaArray;
        draft.data.serverPolicy = serverRulesets.reduce((serverPolices, ruleset) => {
          if (ruleset) {
            serverPolices.push({
              rules: ruleset,
              count:
                ruleset?.deny_rules && ruleset?.sec_rules ? ruleset.deny_rules.length + ruleset.sec_rules.length : 0,
            });
          }

          return serverPolices;
        }, []);
        draft.data.labelsMap =
          protectionSchemaDetails?.label_groups?.reduce((map, label) => {
            const labelWithHref = label.href ? label : {...label, href: ''};

            return map.set(label.key, labelWithHref);
          }, new Map()) || new Map();
      });
    });
  },
);

export const getServerLabelingListGrid = state =>
  getGridSelector(state, {
    settings: serverLabelingListGridSettings,
    rows: getServerRowsWithPolicySelections,
  });

const getGrid = (settings, rows) => {
  return state => getGridSelector(state, {settings, rows});
};

// Adds row key and row type to rule
const addKeysToRules = (rules, type) =>
  rules?.map(
    (rule, index) =>
      produce(rule, draft => {
        draft.type = type;
        draft.key = `${type}${index}`;
      }) ?? [],
  );

const getOverrideDenyRulesRows = createSelector(getOverrideDenyRules, rules => addKeysToRules(rules, 'overrideDeny'));
const getAllowRulesRows = createSelector(getAllowRules, rules => addKeysToRules(rules, 'allow'));
const getDenyRulesRows = createSelector(getDenyRules, rules => addKeysToRules(rules, 'deny'));

const getRulePreviewGrids = createStructuredSelector({
  overrideDenyRulesGrid: getGrid(rulePreviewGridSettings, getOverrideDenyRulesRows),
  allowRulesGrid: getGrid(rulePreviewGridSettings, getAllowRulesRows),
  denyRulesGrid: getGrid(rulePreviewGridSettings, getDenyRulesRows),
});

export const getServerLabelingListPage = createSelector(
  [
    getServerLabelingListGrid,
    getServerRowsWithPolicySelections,
    getRulePreviewGrids,
    getHelpUrl,
    getHelpVersion,
    getProtectionSchemas,
    getProtectionSchemasMap,
    getRuleTemplates,
  ],
  (
    grid,
    serversList,
    {overrideDenyRulesGrid, allowRulesGrid, denyRulesGrid},
    helpUrl,
    helpVersion,
    protectionSchemas,
    protectionSchemasMap,
    templates,
  ) => ({
    grid,
    serversList,
    overrideDenyRulesGrid,
    allowRulesGrid,
    denyRulesGrid,
    protectionSchemas,
    protectionSchemasMap,
    helpLinks: {
      labelHref: `${helpUrl}/${helpVersion}/Guides/security-policy/security-policy-objects/labels-and-label-groups.htm`,
      policyHref: `${helpUrl}/${helpVersion}/Guides/security-policy/overview/illumio-policy-model.htm`,
    },
    templates,
  }),
);
