/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useCallback, useMemo} from 'react';
import {Selector} from 'containers';
import {
  getEndpointCategories,
  advancedResourcesIds,
  isResourceSelected,
  useAdvancedOptionInfoPanel,
} from './Configs/RulesetRulesSelectorConfig';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {useSelector} from 'utils/redux';
import {getIsCSFrame, getIsCSIpList} from 'containers/App/AppState';

const combinedResourceIds = ['labels_include', 'ip_list', 'workload'];
export default function EndpointSelector({
  values,
  type,
  onSelectionChange,
  showUserGroups,
  showContainerHost,
  showVirtualServers,
  warnings,
  isDenyRule,
  isGlobalConsumer,
  ...selectorProps
}) {
  const labelTypesNameObj = useSelector(getDisplayNames);
  const queryKeywordsRegex = useSelector(getTypeInitialRegExp);
  const isCSFrame = useSelector(getIsCSFrame);
  const isCSIpList = useSelector(getIsCSIpList);

  const categories = useCallback(
    advancedEnabled =>
      getEndpointCategories({
        advanced: advancedEnabled,
        showUserGroups,
        showContainerHost,
        showVirtualServers,
        warnings,
        isDenyRule,
        labelTypesNameObj,
        queryKeywordsRegex,
        isCSFrame,
        isGlobalConsumer,
        type,
        isCSIpList,
      }),
    [
      showUserGroups,
      showContainerHost,
      warnings,
      showVirtualServers,
      isDenyRule,
      labelTypesNameObj,
      queryKeywordsRegex,
      isCSFrame,
      isGlobalConsumer,
      type,
      isCSIpList,
    ],
  );
  const handleSelectionChange = useCallback(
    values => {
      onSelectionChange(values);
    },
    [onSelectionChange],
  );

  const combinedCategoryProps = useMemo(
    () => ({
      maxColumns: 3,
      infoPanel: useAdvancedOptionInfoPanel,
      resources: {
        combined: {
          enableHistory: true,
          historyKey: 'endpoint',
        },
      },
    }),
    [],
  );

  const advancedEnabled = useMemo(() => advancedResourcesIds.some(id => isResourceSelected(id, values)), [values]);

  return (
    <Selector.MultiMode
      tid={`${type}-selector`}
      advancedEnabled={advancedEnabled || isCSFrame}
      values={values}
      activeCategoryId="includeLabels"
      hideClearAll
      maxColumns={3}
      searchBarMaxHeight={250}
      combinedCategoryProps={combinedCategoryProps}
      placeholder={
        isCSFrame
          ? type === 'consumers'
            ? intl('Antman.SelectOrTypeConsumers')
            : intl('Antman.SelectOrTypeProviders')
          : type === 'consumers'
          ? intl('Common.SelectConsumers')
          : intl('Common.SelectProviders')
      }
      categories={categories}
      combinedResourceIds={combinedResourceIds}
      onSelectionChange={handleSelectionChange}
      {...selectorProps}
    />
  );
}
