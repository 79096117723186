/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import Selectors from '../Selectors';
import {PureComponent} from 'react';
import {connect} from 'utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {getAllMatches, isLoadingMatches} from 'containers/Selectors/SelectorState';
import {createNewObject} from '../SelectorSaga';
import {parseAddressLine} from 'utils/ip';

@connect((state, props) => ({
  loading: isLoadingMatches(state),
  matches: getAllMatches(state, props.objects),
}))
export default class MultiGroupMultiItemSelect extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    initialItems: PropTypes.array,
    disabled: PropTypes.bool,
    facets: PropTypes.object,
    statics: PropTypes.object,

    // cateogories hidden on initial render, will render 'Show All Categories' option
    hiddenCategories: PropTypes.array,
    categories: PropTypes.array.isRequired,
    removeCheckbox: PropTypes.bool,
    hideSelectedOption: PropTypes.bool,
    hideCategory: PropTypes.bool,

    objects: PropTypes.array.isRequired, // list of API objects we display
    creatable: PropTypes.array, // list of object keys that allow creation
    addValue: PropTypes.func,
    placeholder: PropTypes.string,
    footer: PropTypes.any, // custom dropdown footer sent by parent component

    dontAllowCreate: PropTypes.func,
    onSelectionChange: PropTypes.func,
    filterMatches: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    hideCategory: false,
    onSelectionChange: _.noop,
    hideSelectedOption: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(prevState.initialItems, nextProps.initialItems)) {
      return {initialItems: nextProps.initialItems, items: nextProps.initialItems};
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      items: props.initialItems || [],
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getStaticValues() {
    if (this.props.statics) {
      return Object.entries(this.props.statics).reduce((result, [key, value]) => {
        result[key] = value.map(value => ({
          categoryKey: key,
          value: typeof value === 'object' ? value.value : value,
          desc: value.desc,
        }));

        return result;
      }, {});
    }

    return;
  }

  getDropdownValues(matches) {
    if (!matches) {
      return {};
    }

    return _.reduce(
      matches,
      (prev, object, key) => {
        const matches = object.matches
          ? object.matches.map(match => {
              if (typeof match === 'string') {
                return {categoryKey: key, value: match};
              }

              return {categoryKey: key, ...match};
            })
          : [];
        const category = this.props.categories.find(category => category.categoryKey === key);

        if (object.num_matches === 0) {
          category.disabled = true;
        } else {
          category.disabled = false;
        }

        prev[key] = {
          matches,
          count: object.num_matches,
        };

        return prev;
      },
      {},
    );
  }

  handleInputChange(input, object) {
    if (object) {
      this.fetchData(input, object);
    }
  }

  async handleCreate(input, object, items) {
    const {type, key, pversion} = object;
    const query = {query: '', key};
    let data = {key, name: input};

    if (type === 'ip_lists') {
      const {from_ip: fromIP, to_ip: toIP, fqdn} = parseAddressLine(input, null, null, null, null, false, false);

      data = {
        ...data,
        description: '',
        ...(fromIP && {ip_ranges: [{from_ip: fromIP, ...(toIP && {to_ip: toIP})}]}), // [{from_ip: "10.0.0.0", to_ip: "10.255.255.255"}]
        ...(fqdn && {fqdns: [{fqdn}]}), // fqdns: [{fqdn: "*.illumio.com"}],
      };
    } else {
      data = {key, value: input};
    }

    try {
      const created = await this.context.store.runSaga(createNewObject, type, data, query, {pversion}).toPromise();

      if (type === 'ip_lists') {
        let newItems = [
          ...items,
          {
            categoryKey: 'ip_lists',
            categoryName: intl('Common.IPAddressOptions'),
            href: created.href,
            name: created.name,
          },
        ];

        const category = this.props.categories.find(category => category.categoryKey === 'ip_lists');

        if (category.filterItems) {
          newItems = category.filterItems(items, newItems);
        }

        this.props.onSelectionChange(newItems);
        this.setState({items: newItems});
      } else {
        this.props.onSelectionChange([
          ...items,
          {key: key || type, value: created.value || created.name, href: created.href},
        ]);
      }
    } catch (error) {
      console.error(`Could not create: ${type}`, error);
    }
  }

  fetchData(input, object) {
    if (object) {
      const {type, key, ...params} = object;
      const query = {query: input, key};

      if (type) {
        this.props.dispatch({type: `${type.toUpperCase()}_MATCHES_REQUEST`, object: type, query, params});
      }
    }
  }

  render() {
    const {items} = this.state;
    const {
      hiddenCategories,
      placeholder,
      disabled,
      allowCreateTypes,
      dontAllowCreate,
      hideSelectedOption,
      displayAllCategories,
      loading,
      footer,
      statics,
      facets,
      objects,
      removeCheckbox,
      filterMatches,
      hideCategory,
      onFocus,
      errorMessage,
    } = this.props;

    const matches = this.getDropdownValues(this.props.matches);
    const categories = [...this.props.categories];

    if (filterMatches) {
      Object.keys(matches).forEach(category => {
        if (matches[category].count) {
          matches[category].matches = matches[category].matches.filter(filterMatches);
          matches[category].count = matches[category].matches.count;
        }
      });
    }

    if (hiddenCategories && hiddenCategories.length) {
      categories.push({categoryKey: 'show_all_categories', value: intl('ObjectSelector.ShowAllCategories')});
    }

    // if no checkbox, make it true;
    const props = {
      initialItems: items,
      placeholder,
      activeCategoryKey: categories[0].categoryKey,
      disabled,
      hiddenCategories,
      categories,
      clearAll: true,
      checkbox: !removeCheckbox,
      facets,
      statics: this.getStaticValues(statics),
      matches,
      objects,
      allowCreateTypes,
      dontAllowCreate,
      onCreate: this.handleCreate,
      footer,
      loading,
      hideSelectedOption,
      allowMultipleItems: true,
      displayAllCategories,
      showContainerTitle: true,
      onInputChange: this.handleInputChange,
      onSelectionChange: this.props.onSelectionChange,
      onFocus,
      hideCategory,
      errorMessage,
    };

    return <Selectors {...props} />;
  }
}
