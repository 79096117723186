/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector, createStructuredSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './LabelSettingsConfig';
import {getOrgSettings, isEdge} from 'containers/App/AppState';
import {getId} from 'utils/href';
import {convertToHex, hexToRgb} from 'utils/color';
import {getCSSVariables} from 'utils/dom';
import {moveItemInArray} from 'intl/utils';

const REALMap = {
  role: {
    initial: 'R',
    icon: 'role',
    background_color: '#ce93d8',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(206, 147, 216)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Roles'),
  },
  env: {
    initial: 'E',
    icon: 'env',
    background_color: '#26a69a',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(38, 166, 154)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Environments'),
  },
  app: {
    initial: 'A',
    icon: 'app',
    background_color: '#42a5f5',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(66, 165, 245)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Applications'),
  },
  loc: {
    initial: 'L',
    icon: 'loc',
    background_color: '#857AD6',
    foreground_color: '#ffffff',
    background_color_rgb: 'rgb(133, 122, 214)',
    foreground_color_rgb: 'rgb(255, 255, 255)',
    display_name_plural: intl('Common.Locations'),
  },
};

const defaultCustomInfo = {initial: '', icon: '', background_color: 'var(--gray-500)', foreground_color: '#ffffff'};
const allCssVariables = getCSSVariables();

/** @type {import('illumio').LabelSettings[]} */
const REALDefaultList = [
  {
    key: 'role',
    display_name: intl('Common.Role'),
    display_info: REALMap.role,
  },
  {
    key: 'env',
    display_name: intl('Common.Environment'),
    display_info: REALMap.env,
  },
  {
    key: 'app',
    display_name: intl('Common.Application'),
    display_info: REALMap.app,
  },
  {
    key: 'loc',
    display_name: intl('Common.Location'),
    display_info: REALMap.loc,
  },
];

const fixedNameByType = {
  allWorkloads: intl('Workloads.All'),
  ipList: intl('Common.IPRange'),
  virtualService: intl('Common.VirtualService'),
  userGroup: intl('Common.UserGroup'),
  virtualServer: intl('Common.VirtualServer'),
  scope: intl('Common.Scope'),
};

export default {
  labelsettings(state = REALDefaultList, action) {
    switch (action.type) {
      case 'LABEL_SETTINGS_REORDER':
        const {from, to} = action.data;

        return moveItemInArray(state, from, to);
      case 'LABEL_SETTINGS_GET_LIST':
        // Fix bad default colors
        return action.data.list.map(label => {
          if (
            (label.display_info?.background_color === '#5d08' || label.display_info?.background_color === '#1a45') &&
            REALMap[label.key]
          ) {
            return {
              ...label,
              display_info: {
                ...label.display_info,
                background_color: REALMap[label.key].background_color,
              },
            };
          }

          return label;
        });
      default:
        return state;
    }
  },
  labelsettingscount(state = {}, action) {
    switch (action.type) {
      case 'LABEL_SETTINGS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

/**
 *
 * @param {import('react-redux').DefaultRootState} state
 * @returns
 */

export const getLabelTypeList = state => state.labelsettings;
export const getLabelSettingsCount = state => state.labelsettingscount;
export const getLabelStyle = createSelector(getOrgSettings, org => (__ANTMAN__ ? 'initial' : org.label_display));

export const getLabelSetting = createSelector([getLabelTypeList, isEdge], (labelSettings, edgeEnabled) => {
  if (labelSettings.length) {
    return labelSettings.map(item => {
      const info = item.display_info ?? {};
      const defaultInfo = REALMap[item.key] ?? defaultCustomInfo;

      /** @type {import('illumio').LabelSettings} */
      const caculateLabelSetting = {
        ...item,
        ...(edgeEnabled && item.key === 'role' && {display_name: intl('Common.Group')}),
        display_info: {
          initial: info.initial ?? defaultInfo.initial,
          icon: info.icon ?? defaultInfo.icon,
          background_color: convertToHex(allCssVariables, info.background_color ?? defaultInfo.background_color),
          display_name_plural: info.display_name_plural ?? defaultInfo.display_name_plural ?? item.display_name ?? '',
          foreground_color: info.foreground_color ?? defaultInfo.foreground_color,
          background_color_rgb: hexToRgb(convertToHex(info.background_color ?? defaultInfo.background_color)),
          foreground_color_rgb: hexToRgb(info.foreground_color ?? defaultInfo.foreground_color),
          sort_ordinal: info.sort_ordinal,
        },
      };

      return caculateLabelSetting;
    });
  }

  return REALDefaultList;
});
export const getLabelSettingsMap = createSelector(
  [getLabelSetting],
  labelSettings => new Map(labelSettings.map(setting => [setting.key, setting])),
);

export const getLabelTypes = createSelector([getLabelTypeList], typeList => typeList.map(label => label.key));
export const getLabelTypesIndexMap = createSelector(
  [getLabelTypes],
  labelTypes => new Map(labelTypes.map((type, index) => [type, index])),
);

export const getLabelSelectorOptions = createSelector([getLabelTypeList], typeList =>
  typeList.map(label => ({value: label.key, label: label.display_name})),
);

export const getLabelSelectorOptionsMap = createSelector([getLabelTypeList], typeList => {
  const typeMap = new Map();

  typeList.forEach(label => {
    typeMap.set(label.key, label.display_name);
  });

  return typeMap;
});

export const getDisplayNames = createSelector([getLabelTypeList, isEdge], (typeList, edgeEnabled) => {
  const typeListObj = typeList.reduce(
    (result, labelTypeObj) => ({...result, [labelTypeObj.key]: labelTypeObj.display_name}),
    {},
  );

  return {...typeListObj, role: edgeEnabled ? intl('Common.Group') : typeListObj.role};
});

export const getDisplayNamesWithFixedTypes = createSelector([getDisplayNames], displayNames => ({
  ...displayNames,
  ...fixedNameByType,
}));

export const getTypeInitialRegExp = createSelector(
  [getLabelSetting],
  typeListSettings =>
    new RegExp(
      `^\\s*(|${typeListSettings.map(setting => _.escapeRegExp(setting.display_info.initial)).join('|')}):`,
      'i',
    ),
);

const getLabelSettingsRows = createSelector([getLabelSetting], labelSettingsList => {
  return labelSettingsList.map(item => {
    const labelSelectable = item.caps?.includes('delete') && !item.usage.labels && !item.usage.label_groups;

    return {
      key: item.href,
      id: getId(item.href),
      selectable: true,
      disableCheckbox: !labelSelectable,
      data: item,
      ...(!labelSelectable && {checkboxTooltip: 'The label type is in used by Labels or Label Groups'}),
    };
  });
});

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getLabelSettingsRows,
  });

export const getLabelSettingsPage = createStructuredSelector({
  grid: getGrid,
  count: getLabelSettingsCount,
  labelStyle: getLabelStyle,
});
