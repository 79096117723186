/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {
  GraphSelection,
  GraphSelectionType,
  GraphComboOrEndpoint,
  GraphCombos,
  GraphLink,
  Items,
  ComboId,
} from '../Graph/MapGraphTypes';
import type {LinkData, EndpointDataType, EndpointType} from '../MapTypes';
import {getComboIds} from '../Graph/Utils/MapGraphStyleUtils';
import type {MutableRefObject} from 'react';
import {isManagedEndpoint} from '../MapTypes';
import {removeSourceTargetFromString} from 'containers/IlluminationMap/MapUtils';

export function getSelectionType(selectionObject: GraphSelection = {}): GraphSelectionType {
  const selectedTypes: GraphSelectionType[] = Object.keys(selectionObject).reduce(
    (result: GraphSelectionType[], type: string) => {
      const selection = {...selectionObject};

      delete selection.clickedId;

      if (selection[type as keyof GraphSelection]?.length) {
        result.push(type as GraphSelectionType);
      }

      return result;
    },
    [],
  );

  // selection is empty; return undefined;
  if (selectedTypes.length === 0) {
    return undefined;
  }

  // selection contains one type; return selection type;
  if (selectedTypes.length === 1) {
    return selectedTypes[0];
  }

  // multiple types were selected; return 'multiple';
  return 'multiple';
}

export const getBidirectionalId = (linkId: string): string => (linkId || '').replace(/_bidirectional$/, '');

export const getLinkEndpointDetails = (
  id: ComboId | string,
  items: Items,
  combos: GraphCombos,
): GraphComboOrEndpoint | undefined => {
  const unmanagedDetails = items.unmanagedEndpoints[id];
  const endpointDetails = items.managedEndpoints[id];
  const comboDetails = combos[id as ComboId];

  if (unmanagedDetails) {
    return unmanagedDetails;
  }

  if (endpointDetails) {
    return endpointDetails;
  }

  if (comboDetails) {
    return comboDetails;
  }
};

export const calculatedSelectedLink = (
  selectedLinkId: string,
  selectedComboLinkId: string,
  selectedComboLinkId2: string,
  links: Record<string, GraphLink>,
  comboLinks: Record<string, GraphLink>,
  openComboLinks: Record<string, GraphLink>,
): GraphLink[] => {
  // If it's an individual link
  if (links[selectedLinkId]) {
    return [links[selectedLinkId]];
  }

  // If it's a closed combo link
  if (comboLinks[selectedLinkId]) {
    return [comboLinks[selectedLinkId]];
  }

  if (comboLinks[selectedComboLinkId]) {
    return [comboLinks[selectedComboLinkId]];
  }

  // Open combo links are bi-directional, so show the traffic in both directions
  return [selectedComboLinkId, selectedComboLinkId2].reduce((result: GraphLink[], id): GraphLink[] => {
    if (openComboLinks[id]) {
      result.push(openComboLinks[id]);
    }

    return result;
  }, []);
};

export const calculateSelectedLinkFromSelection = (
  filters: GraphSelection,
  links: Record<string, GraphLink>,
  comboLinks: Record<string, GraphLink>,
  openComboLinks: Record<string, GraphLink>,
): GraphLink[] => {
  const selectedLinkId = (filters.link?.length && filters.link[0]) || '';

  const selectedComboLinkId = getBidirectionalId((filters.comboLink?.length && filters.comboLink[0]) || '');
  const selectedComboLinkId2 = selectedComboLinkId.split(';').reverse().join(';');

  return calculatedSelectedLink(
    selectedLinkId,
    selectedComboLinkId,
    selectedComboLinkId2,
    links,
    comboLinks,
    openComboLinks,
  );
};

export const calculateSelectedLinkFromHover = (
  id: string,
  links: Record<string, GraphLink>,
  comboLinkIds: MutableRefObject<Record<string, string>>,
  comboLinks: Record<string, GraphLink>,
  openComboLinks: Record<string, GraphLink>,
): GraphLink[] => {
  const selectedLinkId = id;
  const selectedComboLinkId = (comboLinkIds && comboLinkIds.current[id]) || '';
  const selectedComboLinkId2 = (selectedComboLinkId && selectedComboLinkId.split(';').reverse().join(';')) || '';

  return calculatedSelectedLink(
    selectedLinkId,
    selectedComboLinkId,
    selectedComboLinkId2,
    links,
    comboLinks,
    openComboLinks,
  );
};

export const comboMatching = (
  link: LinkData,
  endpoint: EndpointType,
  ids: string[],
  openAppGroups: string[] | null,
): boolean => {
  const linkEndpoint = link[endpoint];

  if (isManagedEndpoint(linkEndpoint)) {
    const details = linkEndpoint.details;
    const endpointIds = (details?.labels || []).map(label => label.id);
    const endpointKeys = details && Object.keys(details.labelObject);

    if (openAppGroups) {
      return !openAppGroups.includes(`_appGroup_${details.appGroupId}`);
    }

    if (ids.includes('deleted')) {
      return details.subType === 'deleted';
    }

    if (details.subType === 'deleted') {
      return ids.includes('deleted');
    }

    // If id is not a number, it represents a missing label key.
    // If the link endpoint is also missing that key match the link.
    return ids.every(id => endpointIds.includes(id) || (details && isNaN(Number(id)) && !endpointKeys.includes(id)));
  }

  return false;
};

export const managedMatching = (link: LinkData, endpoint: EndpointType, node: EndpointDataType): boolean => {
  const linkEndpoint = link[endpoint];

  return isManagedEndpoint(linkEndpoint) && linkEndpoint.details.href === node.replace(`_${endpoint}`, '');
};

export const unmanagedMatching = (link: LinkData, endpoint: EndpointType, node: EndpointDataType): boolean =>
  link[endpoint].type === node;

export const calculateFilteredLinks = (
  links: LinkData[],
  filter: GraphSelection,
  focusedData: {focusedComboId: string; openComboId: string},
): LinkData[] => {
  const endpointTypes: EndpointType[] = ['source', 'target'];
  const openAppGroups = Object.values(focusedData).map(data => data && removeSourceTargetFromString(data));

  return links.filter(link => {
    if (filter.combo && filter.combo.length) {
      return filter.combo.some(node => {
        let ids = getComboIds(
          node.includes('superAppGroups') ? node : (node || '').replace('_source', '').replace('_target', ''),
        );

        if (Array.isArray(ids)) {
          ids = ids.map(id => id || 'deleted');
        }

        return ['source', 'target'].some(endpoint =>
          comboMatching(link, endpoint as EndpointType, ids as string[], null),
        );
      });
    }

    if (filter.managed && filter.managed.length) {
      return filter.managed.some(node =>
        ['source', 'target'].some(endpoint =>
          managedMatching(link, endpoint as EndpointType, node as EndpointDataType),
        ),
      );
    }

    if (filter.unmanaged && filter.unmanaged.length) {
      return filter.unmanaged.some(node =>
        ['source', 'target'].some(endpoint =>
          unmanagedMatching(link, endpoint as EndpointType, node as EndpointDataType),
        ),
      );
    }

    if (filter.link && filter.link.length) {
      return filter.link.some(selectedLink => {
        const [source, target] = selectedLink.split(';');
        const linkEndpoint = {source, target};

        return endpointTypes.every(
          endpoint =>
            unmanagedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType) ||
            managedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType),
        );
      });
    }

    if (filter.comboLink && filter.comboLink.length) {
      return filter.comboLink.some(linkId => {
        if (!linkId) {
          return false;
        }

        const selectedLink = getBidirectionalId(linkId);
        const bidirectional = selectedLink !== linkId;

        const [source, target] = selectedLink.split(';');
        const linkEndpoint = {source, target};
        const reverseLinkEndpoint = {source: target, target: source};

        let unidirectionalAppGroupLink;

        if (focusedData.openComboId && linkId.includes('_appGroup_')) {
          unidirectionalAppGroupLink =
            (focusedData.openComboId.includes('_source') && focusedData.openComboId === linkEndpoint.source) ||
            (focusedData.openComboId.includes('_target') && focusedData.openComboId === linkEndpoint.target)
              ? 'forward'
              : 'reverse';
        }

        let sourceComboIds = getComboIds((source || '').replace('_source', ''));

        if (Array.isArray(sourceComboIds)) {
          sourceComboIds = sourceComboIds.map(id => id || 'deleted');
        }

        let targetComboIds = getComboIds((target || '').replace('_target', ''));

        if (Array.isArray(targetComboIds)) {
          targetComboIds = targetComboIds.map(id => id || 'deleted');
        }

        const linkEndpointCombos = {source: sourceComboIds, target: targetComboIds};
        const linkReverseEndpointCombos = {source: targetComboIds, target: sourceComboIds};

        const linkMatches =
          (!unidirectionalAppGroupLink || unidirectionalAppGroupLink === 'forward') &&
          endpointTypes.every(endpoint =>
            Array.isArray(linkEndpointCombos[endpoint] as string[]) ||
            linkEndpointCombos[endpoint] === '_superAppGroups'
              ? comboMatching(
                  link,
                  endpoint,
                  (linkEndpointCombos[endpoint] || ['deleted']) as string[],
                  linkEndpoint[endpoint].includes('_superAppGroups_') ? openAppGroups : null,
                )
              : unmanagedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType) ||
                managedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType),
          );

        let reverseLinkMatches = false;

        if (bidirectional && (!unidirectionalAppGroupLink || unidirectionalAppGroupLink === 'reverse')) {
          reverseLinkMatches = endpointTypes.every(endpoint =>
            Array.isArray(linkReverseEndpointCombos[endpoint] as string[])
              ? comboMatching(link, endpoint, linkReverseEndpointCombos[endpoint] as string[], null)
              : unmanagedMatching(link, endpoint, reverseLinkEndpoint[endpoint] as EndpointDataType) ||
                managedMatching(link, endpoint, reverseLinkEndpoint[endpoint] as EndpointDataType),
          );
        }

        return linkMatches || reverseLinkMatches;
      });
    }

    return true;
  });
};
