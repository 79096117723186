/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {createContext, createElement, type ElementType, type Ref, PureComponent, type CSSProperties} from 'react';
import {withForwardRef, forwardRefSymbol, type ForwardRefProps} from 'react-forwardref-utils';
import styles from './StickyShadow.css';
import type {ReactStrictNode} from 'utils/types';

export type StickyContextValue = {
  checkIn: (child: StickyChild) => void;
  offset: string;
  checkOut: (child: StickyChild) => void;
};

type WrappedComponentProps = {
  className?: string;
  theme?: Record<string, string>;
  themeNoCache?: boolean;
  style?: CSSProperties;
};

export const StickyContext = createContext<StickyContextValue>(null!);

type StickyContainerProps = ForwardRefProps<HTMLDivElement> &
  typeof StickyContainer.defaultProps & {
    // Sticky container can mimic any html element ('div', 'h2' etc) or custom component (constructor like Link, Label etc)
    // Custom components are usually functions. but can be objects if they wrapped in forwardRef
    type?: ElementType<WrappedComponentProps>;
    // Offset positive of which will applied to the sticky element's 'top' and nagative will be applied to the shadow helper
    offset?: string;
    // If you want shadow to start showing earlier than sticky element stucks, for instance `10px` earlier
    shadowPreOffset?: string;
    children?: ReactStrictNode;
    className?: string;
    ref?: Ref<HTMLElement>;
  };

type StickyChild = {setRatio: (isIntersecting: boolean, ratio: number) => void};

@withForwardRef()
export default class StickyContainer extends PureComponent<StickyContainerProps> {
  static defaultProps = {
    type: 'div',
    offset: '0px',
    shadowPreOffset: '0px',
  };

  stickyChildren?: Set<StickyChild>;

  stickyContext: {
    checkIn: (child: StickyChild) => void;
    offset: string;
    checkOut: (child: StickyChild) => void;
  };

  helper?: JSX.Element;
  helperDom?: Element;
  observer?: IntersectionObserver;

  constructor(props: StickyContainerProps) {
    super(props);

    this.stickyChildren = new Set<StickyChild>();

    this.saveHelperRef = this.saveHelperRef.bind(this);
    this.registerChildSticky = this.registerChildSticky.bind(this);
    this.deregisterChildSticky = this.deregisterChildSticky.bind(this);

    // If offset is not zero, start showing shadow ten pixels in advance
    // to finish its animation by the time element have actually stuck, to bring more smoothness
    const helperOffset =
      props.offset === '0px' ? props.offset : `calc(-1 * ${props.offset} - ${props.shadowPreOffset})`;

    this.helper = <div style={{top: helperOffset}} className={styles.helper} ref={this.saveHelperRef} />;

    this.stickyContext = {
      offset: this.props.offset,
      checkIn: this.registerChildSticky,
      checkOut: this.deregisterChildSticky,
    };
  }

  componentDidMount() {
    if (this.helperDom) {
      this.observer = new IntersectionObserver(([entry]) => {
        this.stickyChildren?.forEach(stickyChild =>
          stickyChild?.setRatio(entry.isIntersecting, entry.intersectionRatio),
        );
      });

      this.observer.observe(this.helperDom);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private saveHelperRef(element: HTMLDivElement) {
    if (!element && this.observer && this.helperDom) {
      this.observer.unobserve(this.helperDom);
    }

    this.helperDom = element;
  }

  private registerChildSticky(child: StickyChild) {
    this.stickyChildren?.add(child);
  }

  private deregisterChildSticky(child: StickyChild) {
    this.stickyChildren?.delete(child);
  }

  render() {
    const {children, offset, shadowPreOffset, type, [forwardRefSymbol]: forwardRef, ...props} = this.props;

    props.ref = forwardRef;
    props.className = props.className ? `${props.className} ${styles.container}` : styles.container;

    return (
      <StickyContext.Provider value={this.stickyContext}>
        {createElement(type, props, this.helper, children)}
      </StickyContext.Provider>
    );
  }
}
