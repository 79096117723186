/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {dataValidationUtils} from 'utils';
import intl from 'intl';
import stylesUtils from 'utils.css';

export const validateIpList = data => {
  // This will move the current newIp onto the list to be saved
  const update = dataValidationUtils.ipListsValidateValues(data);

  return update;
};

// https://stackoverflow.com/questions/30329991/ipv6-as-a-comparable-javascript-string
export const ipv6Value = ip => {
  // take care of leading and trailing ::
  const ipString = ip.replaceAll(/^:|:$/g, '');

  const ipv6 = ipString.split(':');

  for (let i = 0; i < ipv6.length; i++) {
    let hex = ipv6[i];

    if (hex === '') {
      // normalize grouped zeros ::
      hex = [];

      for (let j = ipv6.length; j <= 8; j++) {
        hex.push('0000');
      }

      ipv6[i] = hex.join(':');
    } else {
      // normalize leading zeros
      ipv6[i] = `0000${hex}`.substr(-4);
    }
  }

  return ipv6.join(':');
};

export const ipSortValue = ip => {
  let ipArray = ip.split('.');
  let exp = 8;
  let radix = 10;

  if (ip.includes(':')) {
    ipArray = ipv6Value(ip).split(':');

    exp = 16;
    radix = 16;
  }

  const length = ipArray.length;

  const value = ipArray.reduce((result, value, index) => {
    result += parseInt(value, radix) * Math.pow(2, exp * (length - index));

    return result;
  }, 0);

  return value;
};

// Determine if this is a system backend generated user
export const isSystemUser = (globalObject, item) => globalObject && item?.created_by?.href === '/users/0';

// Get FQDN instructions
export const getFQDNInstructions = () => [
  {
    header: intl('IPLists.Mixin.FQDN'),
    message: intl('IPLists.Mixin.InstructionsExample.IllumioFQDN'),
  },
  {
    header: intl('IPLists.Mixin.WildcardMatches'),
    message: intl('IPLists.Mixin.InstructionsExample.Wildcard'),
  },
];

// CIDR and Ipv4 instructions
export const getCidrIpv4 = () => [
  {
    header: intl('IPLists.Mixin.SingleIP'),
    message: (
      <>
        {intl('Protocol.IPv4')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.Single')}
      </>
    ),
  },
  {
    header: intl('IPLists.Mixin.CIDRBlock'),
    message: (
      <>
        {intl('IPLists.Mixin.SlashTip')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.CIDR')}
      </>
    ),
  },
];

// CIDR and Ipv6 instructions
export const getCidrIpv6 = () => [
  {
    header: intl('IPLists.Mixin.SingleIP'),
    message: (
      <>
        {intl('Protocol.IPv6')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.SingleV6')}
      </>
    ),
  },
  {
    header: intl('IPLists.Mixin.CIDRBlock'),
    message: (
      <>
        {intl('IPLists.Mixin.SlashTip')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.CIDRV6')}
      </>
    ),
  },
];

// Get IP address instructions
export const getIPInstructions = () => [
  {
    header: intl('IPLists.Mixin.SingleIP'),
    message: (
      <>
        {intl('IPLists.Mixin.IPVersions')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.Single')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.SingleV6')}
      </>
    ),
  },
  {
    header: intl('IPLists.Mixin.CIDRBlock'),
    message: (
      <>
        {intl('IPLists.Mixin.SlashTip')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.CIDR')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.CIDRV6')}
      </>
    ),
  },
  {
    header: intl('IPLists.Mixin.Ranges'),
    message: (
      <>
        {intl('IPLists.Mixin.HyphenTip')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.Range')}
      </>
    ),
  },
  {
    header: intl('IPLists.Mixin.Comments'),
    message: (
      <>
        {intl('IPLists.Mixin.HashTip')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.Comment')}
      </>
    ),
  },
  {
    header: intl('IPLists.Mixin.Excludes'),
    message: (
      <>
        {intl('IPLists.Mixin.ExclusionTip', {className: stylesUtils.bold}, {html: true})}
        <br />
        {intl('IPLists.Mixin.ExclusionInclusionText')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.Exclusion')}
        <br />
        {intl('IPLists.Mixin.InstructionsExample.ExclusionV6')}
      </>
    ),
  },
];
