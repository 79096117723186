/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {forwardRefSymbol, forwardRefFactory, type ForwardRefProps} from 'react-forwardref-utils';
import {Children, createContext, createRef, PureComponent} from 'react';
import type {
  MutableRefObject,
  TransitionEvent,
  KeyboardEvent,
  MouseEvent,
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
} from 'react';
import {Icon, CounterBadge, Link, Menu, type MenuProps, Tooltip} from 'components';
import {domUtils, tidUtils, typesUtils} from 'utils';
import styles from './Button.css';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {LinkProps, LinkLikeProp, LinkClass} from 'components/Link/Link';
import type {IconName} from 'components/Icon/IconName';
import type {CounterBadgeProps} from 'components/CounterBadge/CounterBadge';
import type {ReactStrictNode} from 'utils/types';

export type ButtonAs = 'button' | 'link' | 'menu';

export type ButtonType = 'button' | 'reset' | 'submit';

// TODO: maybe this could be a general type
export type ButtonSize = 'large' | 'medium' | 'small';

// TODO: maybe this could be a general type
export type ButtonColor = 'primary' | 'secondary' | 'standard';

type MenuAlign = 'left' | 'right';

// List of props specific to Button component, all extra props will be passed down to rendered element (div, Link, Menu) as is.
// So if you want to pass handlers (like onClick) or dom attributes, just do it.
// For example, <Button autoFocus icon="add" onFocus={this.handleFocus}> to render button that is focused on first render automatically
interface ButtonOwnProps extends ThemeProps {
  children?: ReactStrictNode;

  /**
   * The html type of the button
   */
  type?: ButtonType;

  noStyle?: boolean;

  /** Do not fill with color and background color */
  noFill?: boolean;

  color?: ButtonColor;

  /** Do not activate initial / outro animation */
  noAnimateInAndOut?: boolean;

  /** Allow to hide text on small screens */
  textIsHideable?: boolean;

  /** Don't show content by default, only when button is hovered/focused/active/progress */
  showContentOnAction?: boolean;

  insensitive?: boolean; // Makes button not interactable (not clickable, not tabbable)

  progressError?: boolean; // Will finish progress state with error style

  // Prevent progress from having final animation that fills it up to 100% (with color change)
  // when 'progress' changes from 'true' to 'false'
  progressDontComplete?: boolean;
  // Show checkmark on progress completion
  progressCompleteWithCheckmark?: boolean;

  // Callback to notify parent when progress is started
  onProgressStart?: (component: BaseButton) => void;
  // Callback when progress reaches 100% after 'progress' changes from 'true' to 'false' if progressDontComplete is 'false'
  onProgress100?: (component: BaseButton) => void;
  // Callback before 100% progress starts to fade out
  onProgressBeforeFade?: (component: BaseButton) => void;
  // Callback when progress finishes all its animations if progressDontComplete is 'false'
  onProgressDone?: (component: BaseButton) => void;

  // Callback to notify parent (for instance modal) about unmounting button
  onBeforeUnmount?: (component: BaseButton) => void;

  disabled?: boolean; // Makes button insensitive and apply disabled styles
  progress?: boolean; // Makes button insensitive and show progress bar

  icon?: IconName;
  iconAfterText?: boolean;
  text?: string;
  tid?: string;
  tabIndex?: number;

  isGroupFirst?: boolean;
  isGroupLast?: boolean;

  counter?: CounterBadgeProps['count']; // Show CounterBadge with this number
  counterColor?: CounterBadgeProps['color']; // Type of CounterBadge

  size?: ButtonSize;

  // tooltip props
  tooltip?: typesUtils.ReactStrictNode;
  tooltipProps?: TooltipProps;
}

interface BaseButtonState {
  progress: {
    active?: boolean;
    state?: 'finishing' | 'stopped' | null;
    width?: string | null;
  };
  progressing?: boolean;
  isInsensitive?: boolean;
}

// props that should be delegated to the child component
type BaseButtonProps =
  | (ButtonLinkProps & {as: 'link'})
  | (ButtonMenuProps & {as: 'menu'})
  | (ButtonProps & {as: 'button'});

export type ButtonContextType = Pick<
  BaseButtonProps,
  'onBeforeUnmount' | 'onProgress100' | 'onProgressBeforeFade' | 'onProgressDone' | 'onProgressStart'
>;

export class BaseButton extends PureComponent<BaseButtonProps, BaseButtonState> {
  static Context = createContext<ButtonContextType>(null!);

  buttonRef: MutableRefObject<HTMLElement | null>;
  link?: LinkClass;
  menu?: Menu;
  button?: HTMLElement | null;

  progressDoneTimeout?: number;
  progressFinalizeRAF?: number;
  progressElement?: HTMLElement | null;
  finishingProgress?: Record<string, boolean>;

  constructor(props: BaseButtonProps) {
    super(props);

    this.state = {progress: {}};

    this.buttonRef = createRef(); // Separate ref object to pass down to Tooltip

    this.saveRef = this.saveRef.bind(this);
    this.saveLinkRef = this.saveLinkRef.bind(this);
    this.saveMenuRef = this.saveMenuRef.bind(this);
    this.saveProgressRef = this.saveProgressRef.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleDisabledClick = this.handleDisabledClick.bind(this);
    this.handleProgressAnimationEnd = this.handleProgressAnimationEnd.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<BaseButtonProps>, prevState: BaseButtonState) {
    const {progress: progressing} = nextProps;
    let progress;
    let isInsensitive = nextProps.insensitive || nextProps.disabled;

    if (progressing === prevState.progressing) {
      progress = prevState.progress;
    } else {
      // Every time progress prop changes, reset progress state params
      progress = {
        active: progressing || (prevState.progressing && !nextProps.progressDontComplete),
        state: null, // 'stopped', 'finishing',
        width: null,
      };
    }

    // Button should not be sensitive until progress is done
    if (!isInsensitive && progress.active) {
      isInsensitive = true;
    }

    if (progress !== prevState.progress || isInsensitive !== prevState.isInsensitive) {
      return {progress, progressing, isInsensitive};
    }

    return null;
  }

  componentDidUpdate(_prevProps: BaseButtonProps, prevState: BaseButtonState) {
    if (!this.state.progressing) {
      if (this.state.progress.active) {
        this.switchProgressState();
      } else if (prevState.progress.active && this.props.onProgressDone) {
        clearTimeout(this.progressDoneTimeout);
        this.progressDoneTimeout = window.setTimeout(() => {
          this.props.onProgressDone!(this);
        });
      }
    } else if (!prevState.progressing && this.props.onProgressStart) {
      this.props.onProgressStart(this);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.progressDoneTimeout);
    cancelAnimationFrame(this.progressFinalizeRAF!);

    if (this.props.onBeforeUnmount) {
      this.props.onBeforeUnmount(this);
    }
  }

  private saveLinkRef(link: LinkClass) {
    this.link = link; // Save reference of the Link instance
    this.saveRef(link?.element ?? null); // Pass dom element further into saveRef
  }

  private saveMenuRef(menu: Menu) {
    this.menu = menu; // Save reference of the Menu instance
    this.saveRef(menu?.triggerRef.current ?? null); // Pass dom element further into saveRef
  }

  private saveRef(element: HTMLElement | null) {
    this.button = element;
    this.buttonRef.current = element;
  }

  private saveProgressRef(element: HTMLElement | null) {
    this.progressElement = element;
  }

  private handleProgressAnimationEnd(evt: TransitionEvent) {
    if (evt.target === this.progressElement && this.finishingProgress) {
      this.finishingProgress[evt.propertyName] = true;

      if (evt.propertyName === 'width' && this.props.onProgress100) {
        this.props.onProgress100(this);
      } else if (evt.propertyName === 'color' && this.props.onProgressBeforeFade) {
        this.props.onProgressBeforeFade(this);
      }

      // Wait for the whole animation to finish
      if (Object.values(this.finishingProgress).every(isTrue => isTrue)) {
        this.setState({progress: {}});
      }
    }
  }

  private handleKeyDown(evt: KeyboardEvent<HTMLAnchorElement> & KeyboardEvent<HTMLButtonElement>) {
    if (!('link' in this.props) && evt.key === 'Enter') {
      evt.preventDefault();
    }

    if ('onKeyDown' in this.props && this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  private handleKeyUp(evt: KeyboardEvent<HTMLAnchorElement> & KeyboardEvent<HTMLButtonElement>) {
    if (!('link' in this.props) && evt.key === 'Enter') {
      // By default button is clicked on Space, emulate click on Enter also
      // By default link is clicked on Enter, it will emulate click on Space also inside LInk component
      this.click();
    }

    if ('onKeyUp' in this.props && this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }
  }

  private handleDisabledClick(evt: MouseEvent) {
    domUtils.preventEvent(evt);
    setTimeout(() => this.button?.blur());

    // Prevent link navigation by returning false
    return false;
  }

  private switchProgressState() {
    if (this.state.progress.state === null) {
      this.setState(state => {
        // Calculate current progress fill percentage, round up to four decimal places
        const fixPercentage =
          Math.ceil(
            (1e6 * this.progressElement!.getBoundingClientRect().width) / this.button!.getBoundingClientRect().width,
          ) / 1e4;

        // Fix reached width and remove animation at once within current frame
        return {progress: {...state.progress, width: `${fixPercentage}%`, state: 'stopped'}};
      });
    } else if (this.state.progress.state !== 'finishing') {
      // Schedule a task to start a final animation on the next frame.
      // We can't use one requestAnimationFrame because if there is enough time left in the current frame,
      // browser will call its callback immediately,
      // but we want to set the new state after current frame ends (after browser's layout and paint) to start final animation.
      // For this we need to specify two requestAnimationFrames, second one inside first one will be definitely deferred to the next frame.
      // https://youtu.be/cCOL7MC4Pl0
      cancelAnimationFrame(this.progressFinalizeRAF!);
      this.progressFinalizeRAF = requestAnimationFrame(() => {
        this.progressFinalizeRAF = requestAnimationFrame(() => {
          this.setState(state => {
            const width = '100%';

            if (!this.props.progressDontComplete) {
              // Track transitionEnd of each individual transition property, to know when the whole transition is complete.
              // Because in certain cases (like Chrome on Windows inside VirtualBox)
              // transitionEnd might be fired not in the same order in which property transitions are completed, so we can't rely on order.
              this.finishingProgress = {
                'width': false,
                'background-color': false,
                'color': false,
                'opacity': false,
              };
            }

            return {progress: {...state.progress, width, state: 'finishing'}};
          });
        });
      });
    }
  }

  // Method to call from parent component, for instance, from modal
  click(): void {
    this.button?.click();
  }

  render() {
    const {
      type = 'button',
      size = 'medium',
      color = 'primary',
      icon,
      text,
      tid,
      tabIndex = 0,
      insensitive,
      disabled,
      progress: progressing,
      progressError,
      progressDontComplete,
      progressCompleteWithCheckmark,
      onProgressStart,
      onProgress100,
      onProgressDone,
      onProgressBeforeFade,
      onBeforeUnmount,
      noStyle,
      noFill,
      noAnimateInAndOut,
      textIsHideable,
      showContentOnAction,
      counter,
      counterColor,
      onClick,
      isGroupFirst,
      isGroupLast,
      children,
      theme,
      tooltip,
      tooltipProps,
      iconAfterText,
      // All extra props go to rendered element as is
      ...rest
    } = mixThemeWithProps(styles, this.props);

    const elementProps = rest as
      | (ComponentPropsWithoutRef<'button'> &
          Omit<ButtonProps, keyof ComponentPropsWithoutRef<'button'>> & {as: 'button'})
      | (MenuProps & Omit<ButtonMenuProps, keyof MenuProps> & {as: 'menu'})
      | (Omit<ButtonLinkProps, keyof LinkProps> & Partial<LinkProps> & {as: 'link'});
    const {progress, isInsensitive} = this.state;

    let classes = cx(theme.button, {
      [theme.isGroupFirst]: isGroupFirst,
      [theme.isGroupLast]: isGroupLast,
      [theme.showContentOnAction]: showContentOnAction && !progress.active,
      [theme.animateInAndOut]: !noAnimateInAndOut,
    });

    if (!noStyle) {
      classes += ` ${theme.styled}`;

      if (size === 'large') {
        classes += ` ${theme.large}`;
      } else if (size === 'small') {
        classes += ` ${theme.small}`;
      } else {
        classes += ` ${theme.medium}`;
      }

      if (color === 'secondary') {
        classes += ` ${theme.secondary}`;
      } else if (color === 'standard') {
        classes += ` ${theme.standard}`;
      } else {
        classes += ` ${theme.primary}`;
      }

      if (!noFill) {
        classes += ` ${theme.fill}`;
      }
    }

    if (disabled) {
      classes += ` ${theme.disabled}`;
      elementProps['aria-disabled'] = true;
    } else if (isInsensitive) {
      classes += ` ${theme.insensitive}`;
    }

    if (elementProps.as === 'menu') {
      elementProps.tid = tid;
      elementProps.insensitive = isInsensitive;
    } else {
      elementProps.tabIndex = isInsensitive ? -1 : tabIndex; // If button is not clickable, make it nontabable (but focusable with .focus())
      elementProps['data-tid'] = tidUtils.getTid('comp-button', tid);
    }

    // If it's pure sensitive button, attach handlers to control focus style and enter/space keys
    if (elementProps.as !== 'menu' && elementProps.as !== 'link' && !isInsensitive) {
      elementProps.onKeyUp = this.handleKeyUp;
      elementProps.onKeyDown = this.handleKeyDown;
    }

    // If button is insensitive, prevent default action on keyDown, which prevent click handler on space as well
    if (isInsensitive && elementProps.as !== 'menu') {
      elementProps.onKeyDown = domUtils.preventEvent;
    }

    elementProps.onClick = isInsensitive ? this.handleDisabledClick : onClick;

    if (elementProps.as === 'button') {
      elementProps.type = type; // Assign passed type only if it's native button
    }

    const elementChildren: typesUtils.ReactStrictNode[] = [];

    if (children) {
      elementChildren.push(
        <div
          key="content"
          className={cx({[theme.animateContent]: progress.state === 'finishing' && progressCompleteWithCheckmark})}
        >
          {children}
        </div>,
      );
    } else {
      const iconAndTextElements = [];

      if (icon) {
        let iconClass = theme.icon;

        if (progress.state === 'finishing') {
          if (progressCompleteWithCheckmark) {
            iconClass += ` ${theme.animateContent}`;
          } else if (color === 'standard') {
            iconClass += ` ${theme.animateBlackToWhiteAndBack} ${theme.animateBlackToWhiteAndBackTo100Only}`;
          }
        }

        iconAndTextElements.push(
          <div key="icon" className={iconClass}>
            <Icon name={icon} theme={theme} themePrefix="icon-" />
          </div>,
        );
      }

      if (text) {
        let textClass = cx(theme.text, {
          [theme.textIsHideable]: textIsHideable && icon,
        });

        if (progress.state === 'finishing') {
          if (color === 'standard') {
            if (!progressCompleteWithCheckmark || (icon && text)) {
              textClass += ` ${theme.animateBlackToWhiteAndBack}`;

              if (!progressCompleteWithCheckmark) {
                textClass += ` ${theme.animateBlackToWhiteAndBackTo100Only}`;
              }
            }
          }

          if (progressCompleteWithCheckmark && !icon) {
            textClass += ` ${theme.animateContent}`;
          }
        }

        iconAndTextElements.push(
          <span key="text" className={textClass} data-tid="button-text">
            {text}
          </span>,
        );
      }

      if (iconAndTextElements.length) {
        elementChildren.push(...(iconAfterText ? iconAndTextElements.reverse() : iconAndTextElements));
      }
    }

    elementProps['aria-live'] = 'polite';

    if (progress.active) {
      elementProps['aria-busy'] = true;

      const progressProps: ComponentPropsWithRef<'div'> = {
        key: 'progress',
        ref: this.saveProgressRef,
        className: cx(theme.progress, {
          [theme.progressPause]: !progressing,
          [theme.progressStop]: progress.state !== null,
          [theme.progressTo100]: progress.state === 'finishing',
          [theme.progressTo100Error]: progress.state === 'finishing' && progressError,
          [theme.progressTo100Only]: progress.state === 'finishing' && !progressCompleteWithCheckmark,
        }),
      };

      if (progress.width) {
        progressProps.style = {width: progress.width};
      }

      if (progress.state === 'finishing' && !progressDontComplete) {
        progressProps.onTransitionEnd = this.handleProgressAnimationEnd;
      }

      // Need to wrap progress into another div with border-radius and `overflow: hidden`
      // to workaround the Firefox rendering bug EYE-64587
      elementChildren.unshift(
        <div key="progress-wrapper" className={theme.progressWrapper}>
          <div {...progressProps} />
        </div>,
      );

      if (progress.state === 'finishing' && progressCompleteWithCheckmark) {
        elementChildren.push(
          <div
            key="progressIcon"
            className={
              children ||
              (elementProps.as === 'menu' && !icon && text) ||
              (elementProps.as !== 'menu' && (!icon || !text))
                ? theme.progressIcon
                : theme.progressIconLeft
            }
          >
            <Icon name={progressError ? 'error' : 'check'} theme={theme} themePrefix="progressIcon-" />
          </div>,
        );
      }
    } else {
      elementProps['aria-busy'] = false;
    }

    const counterBadgeSizeMap = new Map<string, 'large' | 'small'>([
      ['small', 'small'],
      ['medium', 'large'],
      ['large', 'large'],
    ]);

    if (counter) {
      elementChildren.push(
        <CounterBadge
          instantIncrement
          key="counter"
          disabled={disabled}
          theme={{counter: theme.counter}}
          count={counter}
          color={counterColor}
          size={counterBadgeSizeMap.get(size)}
        />,
      );
    }

    let button: JSX.Element;

    if (elementProps.as === 'menu') {
      const {menu, menuProps, menuAlign = 'right', menuNoDropdownIcon, as, ...props} = elementProps;

      if (__DEV__ && menuProps && 'ref' in menuProps) {
        console.error(
          "Pass ref to Button directly and take 'menu' prop from its instance as opposed to passing ref to menuProps",
        );
      }

      Object.assign(
        props,
        {label: elementChildren.length ? elementChildren : null, align: menuAlign === 'right' ? 'end' : 'start'},
        menuProps,
      );

      // If button contains context menu, render menu instead and apply button styles to menu's trigger
      if (!menuNoDropdownIcon) {
        props.icon = (
          <Icon
            name="down"
            theme={theme}
            themePrefix={
              progress.state === 'finishing' && progressCompleteWithCheckmark && !icon && !text && !children
                ? 'menuIconAnimate-'
                : 'menuIcon-'
            }
            position={text || icon ? 'after' : undefined}
          />
        );
      }

      let menuClass;

      if (size === 'large') {
        menuClass = theme['menu-menuLarge'];
      } else if (size === 'small') {
        menuClass = theme['menu-menuSmall'];
      } else {
        menuClass = theme['menu-menuMedium'];
      }

      props.theme = {
        ...theme,
        // We need to apply size class to root menu div as well to make dropdown vary depends on size
        'menu-menu': cx(theme['menu-menu'], menuClass),
        // Apply button styles to menu trigger
        'menu-trigger': cx(classes, theme['menu-trigger']),
        'menu-triggerLabel': cx(theme['menu-triggerLabel'], {
          // Add left padding to dropdown icon if there is a meaningful content before it
          [theme['menu-realLabel']]: children || text || icon,
        }),
        // Change alignment of dropdown and position of arrow
        'menu-dropdown': theme['menu-dropdown'],
        'menu-dropdownWithArrow': theme['menu-dropdownWithArrow'],
      };
      props.themePrefix = 'menu-';
      props.themeNoCache = true; // We generate theme object on each render here, no need to cache it in map
      props.openedTriggerTheme = theme.active; // Button should be in active state style while context menu is open

      button = (
        <Menu {...props} ref={this.saveMenuRef}>
          {Array.isArray(menu) ? Children.map(menu, i => i) : menu}
        </Menu>
      );
    } else {
      // set aria-label of button
      if (typeof text === 'string') {
        elementProps['aria-label'] = text;
      } else if (typeof tooltip === 'string') {
        elementProps['aria-label'] = tooltip;
      } else if (icon) {
        elementProps['aria-label'] = Icon.getTitle(icon);
      }

      // Wrap children into one more 'overflow: hidden' element, keeping button itself 'position: relative'.
      // To make icon and text not breakout of button on transition when progress is done,
      // but keeping the ability to breakout other absolute elements like counter badge.
      const inner = (
        <div className={`${theme.noOverflowContainer} ${theme.withHorizontalPadding} ${theme.withVerticalPadding}`}>
          {elementChildren}
        </div>
      );

      if (elementProps.as === 'link') {
        const {as, linkProps, link, ...props} = elementProps;

        // If button is a Link, assign classes string to .link theme and link object properties to route properties
        props.theme = Link.getLinkTheme(classes);
        Object.assign(props, linkProps, typeof link === 'string' ? {to: link} : link);

        button = (
          <Link {...props} ref={this.saveLinkRef}>
            {inner}
          </Link>
        );
      } else {
        const {as, ...props} = elementProps;

        props.className = classes;

        button = (
          // eslint-disable-next-line react/button-has-type
          <button {...props} ref={this.saveRef}>
            {inner}
          </button>
        );
      }
    }

    return (
      <>
        {button}
        {tooltip ? <Tooltip content={tooltip} reference={this.buttonRef} {...tooltipProps} /> : null}
      </>
    );
  }
}

const ButtonConsumer = forwardRefFactory(
  ({[forwardRefSymbol]: ref, ...props}: BaseButtonProps & ForwardRefProps<BaseButton>): JSX.Element => {
    return (
      <BaseButton.Context.Consumer>
        {contextProps => {
          let resultProps: BaseButtonProps;

          if (contextProps === null) {
            resultProps = props;
          } else {
            // Context has precedence over own props to be able to override progress state from ModalGateway
            resultProps = {...props, ...contextProps};

            if (contextProps.onProgressStart && props.onProgressStart) {
              resultProps.onProgressStart = (...args) => {
                props.onProgressStart!(...args);
                contextProps.onProgressStart!(...args);
              };
            }

            if (contextProps.onProgress100 && props.onProgress100) {
              resultProps.onProgress100 = (...args) => {
                props.onProgress100!(...args);
                contextProps.onProgress100!(...args);
              };
            }

            if (contextProps.onProgressBeforeFade && props.onProgressBeforeFade) {
              resultProps.onProgressBeforeFade = (...args) => {
                props.onProgressBeforeFade!(...args);
                contextProps.onProgressBeforeFade!(...args);
              };
            }

            if (contextProps.onProgressDone && props.onProgressDone) {
              resultProps.onProgressDone = (...args) => {
                props.onProgressDone!(...args);
                contextProps.onProgressDone!(...args);
              };
            }
          }

          return <BaseButton {...resultProps} ref={ref} />;
        }}
      </BaseButton.Context.Consumer>
    );
  },
  {hoistSource: BaseButton},
);

export interface ButtonMenuProps extends ButtonOwnProps, Omit<MenuProps, 'children' | 'icon'> {
  // If button should have a dropdown, assign menu items to it
  menu?: MenuProps['children'];
  menuNoDropdownIcon?: boolean; // Don't add the dropdown icon
  menuProps?: MenuProps; // Extra props to pass to menu
  menuAlign?: MenuAlign;
}

const ButtonMenu = forwardRefFactory(
  ({[forwardRefSymbol]: ref, ...props}: ButtonMenuProps & ForwardRefProps<BaseButton>) => (
    <ButtonConsumer ref={ref} as="menu" {...props} />
  ),
  {hoistSource: ButtonConsumer},
);

interface ButtonLinkProps
  extends ButtonOwnProps,
    Omit<Partial<LinkProps>, 'color' | 'tooltip' | 'tooltipProps' | 'type'> {
  linkProps?: Partial<LinkProps>;
  // Link parameters, if button is for navigation
  link?: LinkLikeProp;
}

const ButtonLink = forwardRefFactory(
  ({[forwardRefSymbol]: ref, ...props}: ButtonLinkProps & ForwardRefProps<BaseButton>) => (
    <ButtonConsumer ref={ref} as="link" {...props} />
  ),
  {
    hoistSource: ButtonConsumer,
  },
);

export interface ButtonProps extends ButtonOwnProps, Omit<ComponentPropsWithoutRef<'button'>, 'children' | 'color'> {}

// eslint-disable-next-line no-underscore-dangle
const _Button = forwardRefFactory(
  ({[forwardRefSymbol]: ref, ...props}: ButtonProps & ForwardRefProps<BaseButton>) => (
    <ButtonConsumer ref={ref} as="button" {...props} />
  ),
  {hoistSource: ButtonConsumer},
);

const Button = _Button as typeof _Button & {Link: typeof ButtonLink; Menu: typeof ButtonMenu};

Button.Link = ButtonLink;
Button.Menu = ButtonMenu;

export default Button;
