/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {DISCOVERED_GROUP} from 'edge/containers/Group/GroupUtils';
import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {isSuperclusterMember, getOrgId} from 'containers/User/UserState';
import {getLocalClusterBasic} from 'containers/Health/HealthState';
import {getGroupProfile, getGroup, getGroupItem} from 'edge/containers/Group/GroupViewState';
import {
  areVulnerabilitiesEnabled,
  getRouteParams,
  getRouteCurrentParams,
  isEdge,
  isCrowdstrike,
  isEnhancedDataCollectionEnabled,
  getRouteName,
  getIsNewUI,
} from 'containers/App/AppState';
import {getPolicyStateContent, getWorkloadHealthStatus} from '../WorkloadUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories, resourceType} from './WorkloadListConfig';
import {getPairingProfiles} from 'containers/PairingProfile/List/PairingProfileListState';
import {getPairingProfileInstance, getPairingKey} from 'containers/PairingProfile/Item/PairingProfileItemState';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  // Edge / CS related
  discoveredWorkloads(state = [], action) {
    switch (action.type) {
      case 'DISCOVERED_WORKLOADS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  // Edge / CS related
  discoveredWorkloadsCount(state = {}, action) {
    switch (action.type) {
      case 'DISCOVERED_WORKLOADS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  // Edge / CS related
  externalDataSetCount(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_EXTERNAL_DATA_SET_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  ignoreScope(state = false, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.ignoreScope;
      default:
        return state;
    }
  },

  map(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST': {
        const result = {...state};

        for (const workload of action.data.list) {
          result[workload.href] = workload;
        }

        return result;
      }
      case 'WORKLOADS_GET_INSTANCE':
        if (action.data && !_.isEqual(action.data, state[action.data.href])) {
          return {...state, [action.data.href]: action.data};
        }

        return state;
      default:
        return state;
    }
  },
};

export const getWorkloads = state => state.workload.list;
export const getWorkloadsHrefMap = state => state.workload.map;
export const getWorkloadsCount = state => state.workload.count;
export const getWorkloadsIgnoreScope = state => state.workload.ignoreScope;
const getOSFamilies = state => state.workload.osFamilies;

// Edge / CS related
export const getDiscoveredWorkloads = state => state.workload.discoveredWorkloads;
export const getDiscoveredWorkloadsCount = state => state.workload.discoveredWorkloadsCount;
export const getWorkloadsExternalDataSetCount = state => state.workload.externalDataSetCount;

// Edge / CS related
export const isShowingDiscoveredWorkloads = createSelector(
  [getRouteCurrentParams],
  routeParams => routeParams.group === DISCOVERED_GROUP,
);
// Edge / CS related
export const getDiscoveredWorkloadsMap = createSelector(getDiscoveredWorkloads, discoveredWorkloads =>
  discoveredWorkloads.reduce((map, item) => {
    map.set(item.href, item);

    return map;
  }, new Map()),
);

const getWorkloadsRows = createSelector(
  [
    getWorkloads,
    getDiscoveredWorkloadsMap,
    isSuperclusterMember,
    getLocalClusterBasic,
    isCrowdstrike,
    isEnhancedDataCollectionEnabled,
  ],
  (
    workload,
    discoveredWorkloadsMap,
    userIsSuperclusterMember,
    localCluster = {},
    crowdstrikeEnabled,
    hasEnhancedDataCollection,
  ) =>
    workload.map(item => {
      const ven = item.agent;
      const online = item.online;
      const venConfig = ven && ven.config;
      const venStatus = ven && ven.status;
      const creator = _.get(item, 'created_by.href');
      let pairingType = 'none';

      const {enforcement_mode = '', visibility_level = '', log_traffic = ''} = item;

      if (creator && venStatus) {
        if (creator.includes('agents')) {
          pairingType = 'pairingKey';
        } else if (creator.includes('users')) {
          pairingType = 'kerberosOrPKI';
        }
      }

      const healthStatus = getWorkloadHealthStatus({ven, localCluster, userIsSuperclusterMember, online});
      // Used to hide fields other than Name and Policy State
      const isDiscoveredWorkload = discoveredWorkloadsMap?.has(item.href);

      return {
        isDiscoveredWorkload,
        pairingType,
        healthStatus,
        key: item.href,
        unmanaged: !venStatus,
        // item.caps.length === 0 "read only"
        selectable: Array.isArray(item.caps) && item.caps.length > 0,
        staticPolicy: venConfig && venConfig.security_policy_update_mode === 'static',
        policyState: getPolicyStateContent(enforcement_mode, visibility_level, log_traffic).key,
        enforcement: enforcement_mode,
        visibility: visibility_level,
        hasEnhancedDataCollection,
        data: {
          ...item,
          enforcement_mode,
          visibility_level,
          log_traffic,
          labelsMap: getMappedLabelsForGrid(item.labels),
        },
      };
    }),
);

export const getCategories = createSelector(
  [getRouteCurrentParams, categories, getDiscoveredWorkloads, getWorkloadsCount, getWorkloadsIgnoreScope],
  (routeParams, categoriesArr, discoveredWorkloads, count, ignoreScope) => {
    if (routeParams.group === DISCOVERED_GROUP) {
      // Edge / CS related
      // discovered workloads can only be filtered by name
      return [{id: 'name', name: intl('Common.Name'), resources: {name: {statics: discoveredWorkloads}}}];
    }

    let categories = categoriesArr;

    if (!ignoreScope) {
      // PCE category exists in supercluster environment if ignoreScope is false
      const pceCategoryIndex = categories.findIndex(({name}) => name === intl('Common.PCE'));

      if (pceCategoryIndex !== -1) {
        // set hidden false to show this category in filters
        categories[pceCategoryIndex] = {...categories[pceCategoryIndex], hidden: false};
      }
    }

    if (!count.staticMode) {
      // If none of the workloads have static policy applied then
      // remove security settings static policy related filters
      categories = categories.reduce((result, category) => {
        if (category.id === 'security_policy_received_at' || category.id === 'security_policy_update_mode') {
          return result;
        }

        if (category.id === 'policy_health') {
          const statics = category.resources.policy_health.statics;

          result.push({
            ...category,
            resources: {
              policy_health: {statics: statics.filter(policyHealth => policyHealth.value !== intl('Common.Staged'))},
            },
          });

          return result;
        }

        result.push(category);

        return result;
      }, []);
    }

    return categories;
  },
);

export const getFilterMap = createSelector([getCategories], categories => getAllResourcesObject(categories));

export const getGridSettings = createCachedSelector(
  [
    areVulnerabilitiesEnabled,
    isShowingDiscoveredWorkloads,
    isEdge,
    isCrowdstrike,
    gridSettings,
    (_, props) => props?.gridId,
    (_, props) => props?.hideGroupColumn,
  ],
  (
    vulnerabilitiesAreEnabled,
    showingDiscoveredWorkloads,
    edgeEnabled,
    crowdstrikeEnabled,
    gridSettings,
    gridId,
    hideGroupColumn,
  ) => {
    const columns = {...gridSettings.columns};

    // Fields to disable in CrowdStrike + Discovered Workloads and hide in Antman
    columns.vulnerability.disabled = !vulnerabilitiesAreEnabled || edgeEnabled || crowdstrikeEnabled;
    columns.status.disabled = showingDiscoveredWorkloads;
    columns.sync.disabled = crowdstrikeEnabled;
    columns.hostname.disabled = crowdstrikeEnabled;
    columns.last.disabled = showingDiscoveredWorkloads;
    columns.paired.disabled = crowdstrikeEnabled;
    columns.labels.disabled = hideGroupColumn || showingDiscoveredWorkloads;
    columns.visibility.disabled = crowdstrikeEnabled;

    const sort = vulnerabilitiesAreEnabled && !crowdstrikeEnabled ? '-vulnerability' : gridSettings.sort;

    const newGridSettings = {...gridSettings, columns, sort};

    // Hide columns customization in Discovered Workloads since we only show one column ('Name')
    newGridSettings.showColumns = !showingDiscoveredWorkloads;

    if (gridId) {
      // grid config key in kvPairs consists of gridId and routeName.
      // In case of groupDashboard there are two workload lists and both uses the same ID: 1. Discovered workloads 2. Group workloads
      // Because of this grid config of one grid overwrites another one.
      // So, we need to pass a different gridId in this case to have unique kvPairs entries
      newGridSettings.id = gridId;
    }

    return newGridSettings;
  },
)((state, props) => props?.gridId ?? gridSettings(state).id);

export const getGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getGridSettings(state, props),
    rows: getWorkloadsRows,
    filterMap: getFilterMap,
  });

export const getPairingProfilePairAccessList = createSelector(getPairingProfiles, pairingProfileList =>
  pairingProfileList.filter(
    pairingProfile =>
      // Don't allow pairing to profile that has been disabled
      Array.isArray(pairingProfile.caps) &&
      pairingProfile.caps.includes('generate_pairing_key') &&
      pairingProfile.enabled,
  ),
);

export const getPairingProfilePairs = createSelector(
  [getPairingProfileInstance, getOSFamilies, getPairingKey, isEdge, isCrowdstrike],
  (pairingProfile, workloadOsFamilies, activationCode, edgeEnabled, crowdstrikeEnabled) => ({
    // Activation code used for Pairing Profile
    activationCode,
    // Specific pairing profile instance
    pairingProfile,
    // Workload OS Families
    workloadOsFamilies,
    edgeEnabled,
    crowdstrikeEnabled,
  }),
);

export const getPageType = createSelector([getRouteParams, getRouteName], (params, routeName) => {
  const isVenListPage = __ANTMAN__ && routeName === 'app.workloads.vens.list';
  const isServersPage = __ANTMAN__ && params?.type === 'server';
  const isEndpointsPage = __ANTMAN__ && params?.type === 'endpoint';

  return {
    isVenListPage,
    isServersPage,
    isEndpointsPage,
    isWorkloadsPage: !__ANTMAN__ || (!isServersPage && !isEndpointsPage && !isVenListPage),
  };
});

export const getWorkloadsPage = createSelector(
  [
    getGrid,
    getWorkloadsCount,
    getCategories,
    isEdge,
    getOrgId,
    getDiscoveredWorkloadsCount,
    isShowingDiscoveredWorkloads,
    isCrowdstrike,
    getGroupItem,
    getGroup,
    getGroupProfile,
    getPairingProfilePairs,
    getIsNewUI,
    getPageType,
  ],
  (
    grid,
    count,
    categories,
    edgeEnabled,
    orgId,
    discoveredWorkloadsCount,
    showingDiscoveredWorkloads,
    crowdstrikeEnabled,
    groupItem,
    group,
    groupProfile,
    pairingProfilePairs,
    isNewUI,
    {isVenListPage, isServersPage, isEndpointsPage, isWorkloadsPage},
  ) => {
    const rowsMap = new Map();
    // Edge property
    let includeMatches;
    let pageTitle;

    if (edgeEnabled) {
      for (const row of grid.rows) {
        rowsMap.set(row.key, row);
      }
    }

    if (__ANTMAN__) {
      pageTitle = isServersPage
        ? intl('Common.Servers')
        : isEndpointsPage
        ? intl('Edge.Endpoints')
        : intl('Workloads.All');
    } else {
      pageTitle = intl('Common.Workloads');
    }

    return {
      grid,
      rowsMap,
      count,
      resourceType,
      edgeEnabled,
      orgId,
      discoveredWorkloadsCount,
      showingDiscoveredWorkloads,
      crowdstrikeEnabled,
      includeMatches,
      groupProfile,
      showActionButtons: !groupItem?.isOldVersion,
      pageTitle,
      categories,
      isVenListPage,
      isWorkloadsPage,
      isServersPage,
      isEndpointsPage,
      isNewUI,
      // new ui doesnt have quick pair
      ...(__ANTMAN__ && !isNewUI && pairingProfilePairs),
    };
  },
);
