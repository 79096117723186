/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Button, Link, Spinner} from 'components';
import {UserName} from 'containers';
import {hrefUtils} from 'utils';
import {apiPrefix} from 'api/api';
import * as GridUtils from 'components/Grid/GridUtils';
import styles from './SupportReportsList.css';
import {createSelector} from 'reselect';

const spinnerTheme = {spinner: styles['icon-spinner']};

function formatVen(row, value) {
  if (row && row.data && !row.data.ven) {
    return (
      <div>
        {value}
        <span className={styles.deletedSupportReports}>{`(${intl('Common.Deleted')})`}</span>
      </div>
    );
  }

  if (row?.data?.ven?.status === 'stopped') {
    return (
      <div>
        {value}
        <span className={styles.deleted}>{` (${intl('Common.Stopped')})`}</span>
      </div>
    );
  }

  if (
    row?.data?.ven &&
    row.data.ven.status !== 'active' &&
    row.data.ven.status !== 'suspend' &&
    row.data.ven.status !== 'stopped'
  ) {
    return (
      <div>
        {value}
        <span className={styles.deleted}>{` (${intl('VEN.Unpaired')})`}</span>
      </div>
    );
  }

  return (
    <Link to="workloads.vens.item" params={{id: hrefUtils.getId(row.data.ven.href)}}>
      {value}
    </Link>
  );
}

function formatDownload(row, value) {
  if (!row.data.received_at) {
    if (
      row?.data?.ven &&
      (row.data.ven.deleted || (row.data.ven.status !== 'active' && row.data.ven.status !== 'suspend'))
    ) {
      return <span className={styles.deleted}>{intl('SupportReports.SupportReports')}</span>;
    }

    if (new Date(row.data.requested_at).getTime() + intl.utils.DAY > Date.now()) {
      return (
        <div>
          <Spinner color="dark" theme={spinnerTheme} />
          <span className={styles.downloadText}>{intl('SupportReports.Generating')}</span>
        </div>
      );
    }

    return intl('SupportReports.Failed');
  }

  if (!value) {
    return;
  }

  return (
    <Button.Link
      color="standard"
      size="small"
      text={intl('Common.Download')}
      link={value.href ? {href: `${apiPrefix}${value.href}`} : value}
    />
  );
}

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'supportreportlist',
  sort: '-createdOn',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    venName: {
      header: intl('Common.VEN'),
      value: ({row}) => row.data.name,
      format: ({row, value}) => formatVen(row, value),
    },
    createdOn: {
      header: intl('Common.GeneratedAt'),
      isDate: 'L_HH_mm_ss',
      value: 'requested_at',
    },
    generatedBy: GridUtils.clickableColumn({
      header: intl('Common.GeneratedBy'),
      value: ({row}) => row.data.created_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.created_by} ref={clickableRef} />,
    }),
    downloadUrl: {
      header: intl('Common.Download'),
      value: 'download_url',
      format: ({row, value}) => formatDownload(row, value),
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    id: ?string, // Optional breakpoint id, goes to cellFormat function
    data: ?object, // Object with any data, goes to cellFormat function
    maxWidth: number, // Maximum width for breakpoint, minimum will be calculated automatically, goes to cellFormat function
    template: string | Function, // Columns configuration
  }];
   */
  templates: [
    [
      {columns: ['venName'], size: 'minmax(100px, auto)'},
      {columns: ['createdOn'], size: 'minmax(100px, auto)'},
      {columns: ['generatedBy'], size: 'minmax(120px, auto)'},
      {columns: ['downloadUrl'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['venName'], size: 'minmax(100px, auto)'},
            {columns: ['generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
            {columns: ['downloadUrl'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['venName'], size: 'minmax(100px, auto)'},
          {columns: ['generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
          {columns: ['downloadUrl'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['venName', 'generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
            {columns: ['downloadUrl'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['venName', 'generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
          {columns: ['downloadUrl'], size: 'minmax(120px, auto)'},
        ];
      },
    },
  ],
}));
