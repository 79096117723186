/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {GraphLink, ComboMapping, GraphServices, GraphService, GraphCombo, ComboId} from '../MapGraphTypes';
import {type IPEndpoint, isUnmanagedEndpoint, type LinkData} from '../../MapTypes';
import {getAggregatedPolicy, getAggregatedReportedPolicy, getAggregatedDraftPolicy} from '../../MapPolicyUtils';
import {getStrippedComboId, isStrippedChildCombo} from './MapGraphComboUtils';
import {maxDate, minDate} from 'containers/IlluminationMap/ToolBar/TimeMachine/MapTimeMachineUtils';

export const getAggregatedServices = (aggregatedServices: GraphServices, services: GraphServices): GraphServices => {
  return Object.keys(services).reduce((result, serviceKey) => {
    const aggregatedService: GraphService = aggregatedServices[serviceKey];
    const service = services[serviceKey];

    if (aggregatedService) {
      aggregatedService.policy = getAggregatedPolicy(aggregatedService.policy, service.policy);

      aggregatedService.subLinkCount = (aggregatedService.subLinkCount || 0) + (service.subLinkCount || 0);
      aggregatedService.flows = (aggregatedService.flows || 0) + (service.flows || 0);
      aggregatedService.ipLists = [...aggregatedService.ipLists, ...service.ipLists];
    } else {
      aggregatedServices[serviceKey] = {...service};
    }

    return result;
  }, aggregatedServices);
};

export const getLink = (currentLink: GraphLink, link: LinkData, hrefs: {source: string; target: string}): GraphLink => {
  const firstDetected = new Date(link.firstDetected);
  const lastDetected = new Date(link.lastDetected);
  let resultLink: GraphLink;

  if (currentLink) {
    resultLink = {...currentLink};
    resultLink.policy = {
      reported: getAggregatedReportedPolicy(resultLink.policy, link.policy),
      draft: getAggregatedDraftPolicy(resultLink.policy, link.policy),
    };
    resultLink.data.numConnections = (resultLink.data.numConnections ?? 0) + (link.numConnections ?? 0);
    resultLink.data.firstDetected = minDate(resultLink.data.firstDetected ?? firstDetected, firstDetected);
    resultLink.data.lastDetected = maxDate(resultLink.data.lastDetected ?? lastDetected, lastDetected);
  } else {
    resultLink = {
      type: 'link',
      services: {} as GraphServices,
      policy: link.policy,
      subLinkCount: 0,
      data: {
        id1: hrefs.source,
        id2: hrefs.target,
        firstDetected,
        lastDetected,
        sequenceId: link.sequenceId,
        numConnections: link.numConnections,
      },
    };
  }

  let ipLists: IPEndpoint[] = [];

  if (isUnmanagedEndpoint(link.source)) {
    ipLists = link.source.details.ipLists || [];
  }

  if (isUnmanagedEndpoint(link.target)) {
    ipLists = link.target.details.ipLists || [];
  }

  const service: GraphServices = {
    [link.serviceKey]: {
      ...link.service,
      policy: link.policy,
      flows: link.flows,
      subLinkCount: 1,
      ipLists,
    },
  };

  resultLink.services = getAggregatedServices(resultLink.services, service);

  return resultLink;
};

export const calculateComboLinks = (
  links: Record<string, GraphLink>,
  combos: ComboMapping,
  closedCombos: Record<string, GraphCombo>,
  state: 'closed' | 'open',
): Record<string, GraphLink> => {
  return Object.keys(links).reduce((comboLinks: Record<string, GraphLink>, linkKey): Record<string, GraphLink> => {
    const link = links[linkKey];
    const sourceCombos = combos.endpoints[link.data.id1] && [...combos.endpoints[link.data.id1]];
    const targetCombos = combos.endpoints[link.data.id2] && [...combos.endpoints[link.data.id2]];
    let sources: string[];
    let targets: string[];

    if (state === 'closed') {
      // Find the highest level closed combo
      sources = [(sourceCombos && sourceCombos.reverse().find(combo => closedCombos[combo])) || link.data.id1];
      targets = [(targetCombos && targetCombos.reverse().find(combo => closedCombos[combo])) || link.data.id2];
    } else {
      sources = sourceCombos || [link.data.id1];
      targets = targetCombos || [link.data.id2];
    }

    // Store these for performance
    const strippedComboIds: Record<ComboId, string> = {};

    (sources || []).forEach(initialSource => {
      (targets || []).forEach(initialTarget => {
        let source = initialSource as ComboId;
        let target = initialTarget as ComboId;

        strippedComboIds[source] ||= getStrippedComboId(source);

        strippedComboIds[target] ||= getStrippedComboId(target);

        if (isStrippedChildCombo(strippedComboIds[target], strippedComboIds[source], target, source, combos)) {
          source =
            (sourceCombos && sourceCombos.reverse().find(combo => closedCombos[combo])) || (link.data.id1 as ComboId);
        }

        if (isStrippedChildCombo(strippedComboIds[source], strippedComboIds[target], source, target, combos)) {
          target =
            (targetCombos && targetCombos.reverse().find(combo => closedCombos[combo])) || (link.data.id2 as ComboId);
        }

        const comboLinkKey = [source, target].join(';');

        // This is the first link in the combo link or all it's parents are open so just use this link
        if (
          !comboLinks[comboLinkKey] ||
          (source === link.data.id1 && sourceCombos && target === link.data.id2 && targetCombos)
        ) {
          comboLinks[comboLinkKey] = {
            ...link,
            services: {},
            data: {
              id1: source,
              id2: target,
              firstDetected: link.data.firstDetected,
              lastDetected: link.data.lastDetected,
              sequenceId: link.data.sequenceId,
              numConnections: 0,
            },
            subLinkCount: 0,
          };
        }

        comboLinks[comboLinkKey].data.firstDetected = minDate(
          comboLinks[comboLinkKey].data.firstDetected,
          link.data.firstDetected,
        );
        comboLinks[comboLinkKey].data.lastDetected = maxDate(
          comboLinks[comboLinkKey].data.lastDetected,
          link.data.lastDetected,
        );
        comboLinks[comboLinkKey].data.numConnections =
          comboLinks[comboLinkKey].data.numConnections + link.data.numConnections;

        if (source && target) {
          comboLinks[comboLinkKey].subLinkCount = (comboLinks[comboLinkKey].subLinkCount || 0) + 1;
          comboLinks[comboLinkKey].services = getAggregatedServices(comboLinks[comboLinkKey].services, link.services);
          comboLinks[comboLinkKey].policy = getAggregatedPolicy(comboLinks[comboLinkKey].policy, link.policy);
        }
      });
    });

    return comboLinks;
  }, {});
};
