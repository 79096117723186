/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {isAsyncExplorerEnabled, isIlluminationClassicEnabled} from 'containers/App/AppState';
import {HeaderProps} from 'containers';
import {object, string, number, boolean} from 'yup';
import {getUserByHref} from '../UserUtils';
import {fetchProfile, updateUserProfile} from './MyProfileSaga';
import * as UserSelectors from '../UserState';
import {getUserSettings} from '../Settings/SettingsState';
import {updateUserSettings} from '../Settings/SettingsSaga';
import {getLabelSettingsCount} from 'containers/Label/LabelSettings/LabelSettingState';
import {AttributeList, Button, ToolBar, ToolGroup, Form} from 'components';
import {timeZones, hrefUtils} from 'utils';
import {getClusters} from 'containers/Health/HealthState';
import styles from './MyProfile.css';

export const MIN_NUMBER_CONNS = 1;
export const MAX_NUMBER_CONNS_DB = 200_000;
export const MAX_NUMBER_CONNS_EXPLORER = 10_000;

@connect(state => {
  const users = UserSelectors.getAllUsers(state);
  const iAm = UserSelectors.getUser(state);
  const settings = getUserSettings(state);
  const clusters = getClusters(state);
  const asyncExplorerEnabled = isAsyncExplorerEnabled(state);

  return {
    iAm,
    settings,
    myUserObject: getUserByHref(users, iAm.href),
    isSuperClusterReadOnlyMode: UserSelectors.getIsSuperClusterReadOnlyMode(state),
    clusters,
    asyncExplorerEnabled,
    isScopedUser: UserSelectors.isUserScoped(state),
    myOrganizationName: UserSelectors.getMemberOrgName(state),
    myOrganizationId: UserSelectors.getMemberOrgId(state),
    managingOrganizationName: UserSelectors.getOrgName(state),
    managingOrganizationId: UserSelectors.getOrgId(state),
    labelSettingsCount: getLabelSettingsCount(state),
    illuminationClassicEnabled: isIlluminationClassicEnabled(state),
  };
})
export default class MyProfile extends Component {
  static prefetch = fetchProfile;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    // time zones
    this.timeZonesData = timeZones.getTimezones();

    // initial values
    this.initialValues = {
      username: props.myUserObject.username || '',
      myOrganizationName: props.myOrganizationName ?? '',
      myOrganizationId: props.myOrganizationId ?? '',
      managingOrganizationName: props.managingOrganizationName ?? '',
      managingOrganizationId: props.managingOrganizationId ?? '',
      full_name: props.iAm.full_name || '',
      time_zone: Form.Utils.findSelectedOption(this.timeZonesData, props.iAm.time_zone),
      colorBlind: props.settings.colorBlind || '',
      xpressFeatures: props.settings.xpressFeatures || '',
      explorerMaxResults: props.settings?.explorerMaxResults?.toString() || '',
      explorerMaxDownloadResults: props.settings?.explorerMaxDownloadResults?.toString() || '',
      defaultPolicyVersion: props.settings.defaultPolicyVersion || '',
      defaultIllumination: props.settings.defaultIllumination
        ? props.settings.defaultIllumination === 'enabled'
        : props.illuminationClassicEnabled,
      defaultAppMap:
        props.settings.defaultAppMap || (props.labelSettingsCount.total > 4 ? 'illuminationPlus' : 'appMap'),
    };

    // Personal values
    this.personalValues = ['full_name', 'time_zone'];

    this.renderForm = this.renderForm.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnPasswordChange = this.handleOnPasswordChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);

    this.minMaxExplorerLabel = intl('PasswordPolicy.ValidHint.RangeXtoY', {
      rangeStart: MIN_NUMBER_CONNS,
      rangeEnd: MAX_NUMBER_CONNS_EXPLORER,
    });
    this.minMaxDownloadLabel = intl('PasswordPolicy.ValidHint.RangeXtoY', {
      rangeStart: MIN_NUMBER_CONNS,
      rangeEnd: MAX_NUMBER_CONNS_DB,
    });

    // yup validation schemas
    this.schemas = object({
      username: string().required(),
      full_name: string().required(intl('Users.NameRequired')),
      myOrganizationName: string().required(),
      myOrganizationId: string().required(),
      managingOrganizationName: string().required(),
      managingOrganizationId: number().required(),
      time_zone: object().nullable().required(),
      colorBlind: Form.Radio.schema.oneOf(['deficient', 'normal']).required(),
      xpressFeatures: Form.Radio.schema.oneOf(['essential', 'advanced']).required(),
      defaultPolicyVersion: Form.Radio.schema.oneOf(['reported', 'draft']).required(),
      defaultAppMap: Form.Radio.schema.oneOf(['appMap', 'illuminationPlus']).required(),
      defaultIllumination: boolean().required(),
      explorerMaxResults: number()
        .typeError(intl('PasswordPolicy.Errors.Number'))
        .integer(intl('PasswordPolicy.Errors.Integer'))
        .required(intl('PasswordPolicy.Errors.Required'))
        .min(MIN_NUMBER_CONNS, this.minMaxExplorerLabel)
        .max(MAX_NUMBER_CONNS_EXPLORER, this.minMaxExplorerLabel)
        .test('containsNonNumber', intl('PasswordPolicy.Errors.Number'), value => /^\d+$/.test(value)),
      explorerMaxDownloadResults: number()
        .typeError(intl('PasswordPolicy.Errors.Number'))
        .integer(intl('PasswordPolicy.Errors.Integer'))
        .required(intl('PasswordPolicy.Errors.Required'))
        .min(MIN_NUMBER_CONNS, this.minMaxDownloadLabel)
        .max(MAX_NUMBER_CONNS_DB, this.minMaxDownloadLabel)
        .test('containsNonNumber', intl('PasswordPolicy.Errors.Number'), value => /^\d+$/.test(value)),
    });

    this.state = {
      cancel: false,
    };
  }

  // A callback to handle submission when no errors exist
  async handleOnSubmit(values) {
    const {
      colorBlind,
      explorerMaxResults,
      explorerMaxDownloadResults,
      defaultPolicyVersion,
      defaultAppMap,
      defaultIllumination,
      xpressFeatures,
    } = values;
    const newSettings = {
      colorBlind,
      explorerMaxResults: parseInt(explorerMaxResults, 10),
      explorerMaxDownloadResults: parseInt(explorerMaxDownloadResults, 10),
      defaultPolicyVersion,
      defaultAppMap,
      defaultIllumination: defaultIllumination ? 'enabled' : 'disabled',
      xpressFeatures,
    };

    const updateInfo = this.personalValues.reduce((element, current) => {
      if (current === 'undefined') {
        return element;
      }

      let item = values[current];

      if (current === 'time_zone') {
        // Parse the value from time_zone object
        item = values[current].value;
      }

      // Compare the original with formik's values
      if (this.props.iAm[current] !== item) {
        element[current] = item;
      }

      return element;
    }, {});

    // Need to re-intialize initialValues back to same formatting type
    this.initialValues = {
      ...this.initialValues,
      ...updateInfo,
      time_zone: values.time_zone,
      colorBlind,
      explorerMaxResults,
      explorerMaxDownloadResults,
      defaultPolicyVersion,
      defaultAppMap,
      defaultIllumination,
      xpressFeatures,
    };

    await Promise.all([
      !_.isEqual(newSettings, this.props.settings) &&
        this.context.fetcher.spawn(updateUserSettings, {settings: newSettings}),
      !_.isEmpty(updateInfo) && this.context.fetcher.spawn(updateUserProfile, updateInfo),
    ]);
  }

  handleOnPasswordChange() {
    // TODO: Possible need to create a route for user to change password
    const passwordUrl = `${this.props.iAm.login_url}${hrefUtils.getChangePassword()}`;

    return passwordUrl;
  }

  handleInputBlur(options, inputName, evt) {
    const {minLimit, maxLimit, rangeErrorMessage} = options;
    const {errors, handleBlur, setFieldValue} = this.formik;

    // Run formik's built in handleBlur so that Yup schema validation / formik's touched will work correctly
    if (handleBlur) {
      handleBlur(evt);
    }

    const currError = errors[inputName];

    if (!currError || currError === rangeErrorMessage) {
      const stringValue = evt.target.value.replaceAll(/\D/g, '');
      const value = parseInt(stringValue, 10);

      if (value < minLimit) {
        setFieldValue(inputName, minLimit.toString());
      } else if (value > maxLimit) {
        setFieldValue(inputName, maxLimit.toString());
      } else {
        setFieldValue(inputName, value.toString());
      }
    }
  }

  parseRouteName(routeName) {
    // match everything after "app."
    const regex = /^app\.(.*)$/;

    // routeName: app.components.list
    // e.g. name = components.list
    const name = routeName.replace(regex, '$1');

    return name;
  }

  renderForm(options, numOfClusters, showReturnFromDB, isSuperClusterReadOnlyMode, iAm, isScopedUser, notMSSP) {
    // type: external - SAML PINGONE login
    // type: local - doesn't use SAML PINGONE login
    const isLocalUser = iAm && iAm.type === 'local';

    // type: external will not see change password only type: local can see this option
    const passwordChangeInfo = isLocalUser
      ? [
          {title: intl('Common.PasswordChange')},
          {value: intl('Users.PassClickToChange'), tid: 'click-to-change'},
          {
            tid: 'button',
            value: (
              <Button.Link
                text={intl('Common.PasswordChange')}
                color="secondary"
                tid="change-password"
                disabled={isSuperClusterReadOnlyMode}
                link={{href: this.handleOnPasswordChange()}}
              />
            ),
          },
        ]
      : [];

    // All options from formik will reset when handleOnSubit() called when initialValues is reinitialized
    const {
      // check for errors
      // e.g. isValid: true [no errors AND dirty === true], isValid: false [errors OR dirty === false]
      // dirty: true - the original data was modified
      // dirty: false - the original wasn't modified
      // isValid: false - there is an error with the data or data wasn't modified
      // isValid: true - no errors and original data modified
      isValid,
      isSubmitting,
    } = options;

    this.formik = options;

    const attributesHead = [
      {divider: true},
      {title: intl('Users.Personal')},
      {
        key: <Form.Label name="username" title={intl('Common.UserEmail')} />,
        tid: 'username',
        value: <Form.Input tid="username" icon="email" disabled name="username" />,
      },
      {
        key: <Form.Label name="full_name" title={intl('Common.Name')} />,
        tid: 'full-name',
        value: (
          <Form.Input
            disabled={isSuperClusterReadOnlyMode || !isLocalUser}
            tid="full-name"
            icon="id"
            name="full_name"
          />
        ),
      },
      {
        key: <Form.Label name="time_zone" title={intl('Common.Timezone')} />,
        tid: 'time-zone',
        value: (
          <Form.Selector
            name="time_zone"
            icon="time"
            theme={{dropdown: styles.dropdown}}
            disabled={isSuperClusterReadOnlyMode}
            options={this.timeZonesData}
          />
        ),
      },
      {divider: true},
      {title: intl('Users.MyOrganization')},
      {
        key: <Form.Label name="myOrganizationName" title={intl('Users.MyOrganization')} />,
        tid: 'my-organization-name',
        value: <Form.Input tid="my-organization-name" disabled name="myOrganizationName" />,
      },
      {
        key: <Form.Label name="myOrganizationId" title={intl('Users.MyOrganizationId')} />,
        tid: 'my-organization-id',
        value: <Form.Input tid="my-organization-id" disabled name="myOrganizationId" />,
      },
      {divider: true},
      ...(notMSSP
        ? []
        : [
            {title: intl('Users.ManagingOrganization')},
            {
              key: <Form.Label name="managingOrganizationName" title={intl('Users.ManagingOrganization')} />,
              tid: 'managing-organization-name',
              value: <Form.Input tid="managing-organization-name" disabled name="managingOrganizationName" />,
            },
            {
              key: <Form.Label name="managingOrganizationId" title={intl('Users.ManagingOrganizationId')} />,
              tid: 'managing-organization-id',
              value: <Form.Input tid="managing-organization-id" disabled name="managingOrganizationId" />,
            },
            {divider: true},
          ]),
      {title: intl('Users.Accessibility')},
      {
        key: <Form.Label name="colorBlind" title={intl('Users.ColorMode')} />,
        tid: 'color-mode',
        value: (
          <Form.RadioGroup name="colorBlind">
            <Form.Radio
              tid="normal"
              value="normal"
              disabled={isSuperClusterReadOnlyMode}
              label={intl('Users.ColorNormal.Title')}
              subLabel={intl('Users.ColorNormal.Message')}
            />
            <Form.Radio
              tid="deficient"
              value="deficient"
              disabled={isSuperClusterReadOnlyMode}
              label={intl('Users.ColorDeficient.Title')}
              subLabel={intl('Users.ColorDeficient.Message')}
            />
          </Form.RadioGroup>
        ),
      },
      ...(__ANTMAN__
        ? [
            {divider: true},
            {title: intl('Antman.Common.XpressPreferences')},
            {
              key: <Form.Label name="xpressFeatures" title={intl('Antman.Common.FeatureMode')} />,
              tid: 'xpress-features',
              value: (
                <Form.RadioGroup name="xpressFeatures">
                  <Form.Radio
                    tid="essential"
                    value="essential"
                    disabled={isSuperClusterReadOnlyMode}
                    label={intl('Antman.Common.EssentialMode.Title')}
                    subLabel={intl('Antman.Common.EssentialMode.Message')}
                  />
                  <Form.Radio
                    tid="advanced"
                    value="advanced"
                    disabled={isSuperClusterReadOnlyMode}
                    label={intl('Antman.Common.AdvancedMode.Title')}
                    subLabel={intl('Antman.Common.AdvancedMode.Message')}
                  />
                </Form.RadioGroup>
              ),
            },
          ]
        : []),
      ...(__MSP__
        ? []
        : [
            {divider: true},
            {title: intl('Common.Visibility')},
            {
              key: <Form.Label name="defaultPolicyVersion" title={intl('Users.DefaultPolicyVersion')} />,
              tid: 'default-policy-version',
              value: (
                <Form.RadioGroup name="defaultPolicyVersion">
                  <Form.Radio
                    tid="reported"
                    value="reported"
                    disabled={isSuperClusterReadOnlyMode}
                    label={intl('Map.ReportedView')}
                    subLabel={intl('Map.PolicyVersionPanel.ReportedViewDetails')}
                  />
                  <Form.Radio
                    tid="draft"
                    value="draft"
                    disabled={isSuperClusterReadOnlyMode}
                    label={intl('Map.DraftView')}
                    subLabel={intl('Map.PolicyVersionPanel.DraftViewDetails')}
                  />
                </Form.RadioGroup>
              ),
            },
            ...(__ANTMAN__ || this.context.isNewUI
              ? []
              : [
                  !isScopedUser && {
                    key: <Form.Label name="defaultIllumination" title={intl('Common.IlluminationMap')} />,
                    tid: 'default-illumination',
                    value: (
                      <Form.Checkbox
                        name="defaultIllumination"
                        label={intl('IlluminationMap.ContinueUsingIlluminationClassic')}
                        subLabel={intl('IlluminationMap.IlluminationPlusDescription')}
                      />
                    ),
                  },
                ]),
            ...(this.context.isNewUI
              ? []
              : [
                  {
                    key: <Form.Label name="defaultAppMap" title={intl('Common.AppGroupMap')} />,
                    tid: 'default-policy-version',
                    value: (
                      <Form.RadioGroup name="defaultAppMap">
                        <Form.Radio
                          tid="illumination-plus"
                          value="illuminationPlus"
                          disabled={isSuperClusterReadOnlyMode}
                          label={intl('IlluminationMap.AppGroupMapIlluminationPlus')}
                          subLabel={intl('Common.IlluminationPlusDetails')}
                        />
                        <Form.Radio
                          tid="app-map"
                          value="appMap"
                          disabled={isSuperClusterReadOnlyMode}
                          label={intl('IlluminationMap.AppGroupMapClassic')}
                          subLabel={intl('Common.AppGroupMapDetails')}
                        />
                      </Form.RadioGroup>
                    ),
                  },
                ]),
            {
              key: (
                <Form.Label
                  name="explorerMaxResults"
                  title={
                    this.context.isNewUI
                      ? intl('Explorer.DisplayedInTraffic')
                      : intl('Explorer.DisplayedInIlluminationPlus')
                  }
                />
              ),
              tid: 'explorer-max-results',
              value: (
                <>
                  <Form.Input
                    hideErrorMessage
                    tid="explorer-max-results"
                    disabled={isSuperClusterReadOnlyMode}
                    onBlur={_.partial(
                      this.handleInputBlur,
                      {
                        minLimit: MIN_NUMBER_CONNS,
                        maxLimit: MAX_NUMBER_CONNS_EXPLORER,
                        rangeErrorMessage: this.minMaxExplorerLabel,
                      },
                      'explorerMaxResults',
                    )}
                    name="explorerMaxResults"
                  />
                  <div className={styles.validHint} data-tid="explorer-max-results-hint">
                    {this.minMaxExplorerLabel}
                  </div>
                </>
              ),
            },
          ]),
    ];

    const attributesReturnFromDB = [
      {
        key: <Form.Label name="explorerMaxDownloadResults" title={intl('Explorer.ReturnedFromDB', {numOfClusters})} />,
        tid: 'explorer-max-download-results',
        value: (
          <>
            <Form.Input
              hideErrorMessage
              tid="explorer-max-download-results"
              disabled={isSuperClusterReadOnlyMode}
              onBlur={_.partial(
                this.handleInputBlur,
                {
                  minLimit: MIN_NUMBER_CONNS,
                  maxLimit: MAX_NUMBER_CONNS_DB,
                  rangeErrorMessage: this.minMaxDownloadLabel,
                },
                'explorerMaxDownloadResults',
              )}
              name="explorerMaxDownloadResults"
            />
            <div className={styles.validHint} data-tid="explorer-max-results-hint">
              {this.minMaxDownloadLabel}
            </div>
          </>
        ),
      },
    ];

    const attributesTail = [{divider: true}, ...passwordChangeInfo];

    const attributes = [...attributesHead, ...(showReturnFromDB ? attributesReturnFromDB : []), ...attributesTail];

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <Button
              icon="save"
              type="submit"
              text={intl('Common.Save')}
              tid="save"
              progress={isSubmitting}
              progressCompleteWithCheckmark
              disabled={isValid === false || isSuperClusterReadOnlyMode}
            />
            <Button
              icon="cancel"
              text={intl('Common.Cancel')}
              color="standard"
              tid="cancel"
              onClick={_.partial(this.context.navigateBackOrTo, 'map')}
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList>{attributes}</AttributeList>
      </>
    );
  }

  render() {
    const {
      asyncExplorerEnabled,
      clusters,
      isSuperClusterReadOnlyMode,
      iAm,
      isScopedUser,
      managingOrganizationId,
      myOrganizationId,
    } = this.props;
    const numOfClusters = clusters?.length ?? 1;
    const notMSSP = Number(managingOrganizationId) === Number(myOrganizationId);

    return (
      <>
        <HeaderProps title={intl('Users.MyProfile')} />
        <Form
          enableReinitialize
          schemas={this.schemas}
          initialValues={this.initialValues}
          onSubmit={this.handleOnSubmit}
        >
          {options =>
            this.renderForm(
              options,
              numOfClusters,
              asyncExplorerEnabled,
              isSuperClusterReadOnlyMode,
              iAm,
              isScopedUser,
              notMSSP,
            )
          }
        </Form>
      </>
    );
  }
}
