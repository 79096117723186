/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import type {AnyAction} from 'redux';
import type {Rule} from 'illumio';
import {createSelector, createStructuredSelector} from 'reselect';
import type {RootState} from 'utils/redux';
import {rulesGridSettings, type RuleTypes} from './RuleInspectorConfig';
import {populateRule, sortRulesByCreatedAt} from 'containers/Ruleset/Item/RulesetItemUtils';
import {getIsCSFrame} from 'containers/App/AppState';

export interface OverlappingRules {
  sec_rules: Rule[];
  deny_rules: Rule[];
  override_deny_rules: Rule[];
}

declare module 'utils/redux' {
  // TODO: should augment user state
  interface RootState {
    readonly overlappingRules: OverlappingRules;
  }
}

export default {
  overlappingRules(
    state: OverlappingRules = {sec_rules: [], deny_rules: [], override_deny_rules: []},
    action: AnyAction,
  ): OverlappingRules {
    switch (action.type) {
      case 'GET_OVERLAPPING_RULES':
        return action.data;
      default:
        return state;
    }
  },
};

export const getOverlappingRules = (state: RootState): OverlappingRules => state.overlappingRules;

const populateRuleWithType = (type: RuleTypes) => (rule: Rule, index: number) =>
  populateRule({
    rule,
    ruleNumber: index,
    scopes: null,
    pversion: '',
    type,
    actionButtonsDisabled: true,
  });

const getRulesRows = createSelector([getOverlappingRules], overlapping => {
  return {
    allow: sortRulesByCreatedAt(overlapping.sec_rules).map(populateRuleWithType('allow')),
    deny: sortRulesByCreatedAt(overlapping.deny_rules).map(populateRuleWithType('deny')),
    override: sortRulesByCreatedAt(overlapping.override_deny_rules).map(populateRuleWithType('override')),
  };
});

// @ts-ignore
const getRulesGridSettings = createSelector([rulesGridSettings, getIsCSFrame], (gridSettings, isCSFrame: boolean) => {
  return ['allow', 'deny', 'override'].reduce(
    (result, type) => {
      return {
        ...result,
        [type]: produce(gridSettings, gridSettingsDraft => {
          gridSettingsDraft.id = `${gridSettingsDraft.id}-${type}`;
          gridSettingsDraft.columns.ruleSet.disabled = isCSFrame;
        }),
      };
    },
    {allow: {}, deny: {}, override: {}},
  );
});

export const getGridProps = createStructuredSelector({
  rulesRows: getRulesRows,
  gridSettings: getRulesGridSettings,
});
