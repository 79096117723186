/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import cx from 'classnames';
import React, {PureComponent, createRef, isValidElement, createElement} from 'react';
import type {
  MutableRefObject,
  KeyboardEvent,
  KeyboardEventHandler,
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ReactNode,
} from 'react';
import {mixThemeWithProps, type Theme, type ThemeProps} from '@css-modules-theme/react';
import {tidUtils} from 'utils';
import {Icon, Link, ContextualMenu, MenuDelimiter, MenuItem, MenuInfo, Tooltip} from 'components';
import {AppContext} from 'containers/App/AppUtils';
import type {LinkProps, LinkLikeProp, TooltipProps, LinkClass} from 'components';
import PillSymbol from './Symbol/PillSymbol';
import PillDiff from './PillDiff';
import Endpoint from './Endpoint/Endpoint';
import Group from './Group/Group';
import GroupsDiff from './Group/GroupsDiff';
import IPListDiff from './IPList/IPListDiff';
import IPList from './IPList/IPList';
import LabelDiff from './Label/LabelDiff';
import Label from './Label/Label';
import Service from './Service/Service';
import ServiceDiff from './Service/ServiceDiff';
import VirtualServer from './VirtualServer/VirtualServer';
import VirtualService from './VirtualService/VirtualService';
import Workload from './Workload/Workload';
import styles from './Pill.css';
import menuStyles from 'components/Menu/Menu.css';
import type {WithElement} from 'utils/react';
import type {MouseEventLike, MouseEventLikeHandler} from 'utils/dom';
import type {ReactStrictNode} from 'utils/types';
import type {UpdateTypeWithTooltip} from './PillUtils';
import type {IconName} from 'components/Icon/IconName';
import type {MenuInfoCallbackArgs} from 'components/Menu/MenuInfo';
import ContainerWorkload from 'components/Pill/ContainerWorkload/ContainerWorkload';

type PillPropsBase = {
  icon?: IconName;
  initial?: string;
  pillStyle?: string;
  hideIcon?: boolean;
  // Content of pill. Will also be used for title attribute if children is a text and title property is 'true'
  // Status will appear a colored dot in front of the label content
  status?: 'added' | 'allowed' | 'blocked' | 'modified' | 'potentiallyBlocked' | 'unknown';

  // Link parameters, if label is clickable to navigate
  link?: LinkLikeProp;

  // Useful to indicate policy object update type in top-right corner
  hideUpdateType?: boolean;

  children?: ReactStrictNode;
  // String of boolean which controls 'title' attribute
  title?: string; // Text that should be added to title attribute instead of children content

  // If pill displays a group (icon will have 'cloned' background)
  group?: boolean;
  // If pill should have pin icon
  pinned?: boolean;
  // Makes pill not interactable (not clickable, not tabbable)
  insensitive?: boolean;

  highlighted?: boolean;

  // Mutually exclusive statuses: created - green, deleted - red crossed out, disabled - insensitive and apply disabled styles
  created?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  exclusion?: boolean;
  exclusionContent?: ReactNode;
  noFill?: boolean;
  // ...mutuallyExclusiveTruePropsSpread('created', 'deleted', 'disabled', 'error'),

  onClick?: MouseEventLikeHandler;
  onClose?: MouseEventLikeHandler;

  onKeyUp?: KeyboardEventHandler;
  onKeyDown?: KeyboardEventHandler;

  // Additional tid that will be added to default one
  // For instance, if icon is 'role' and tid is 'added': 'comp-pill comp-pill-role comp-pill-added'
  tid?: string;

  // tooltip props
  tooltip?: TooltipProps['content'];
  tooltipProps?: TooltipProps;

  /** Custom contextual menu array or a render function to augment the default menu */
  contextualMenu?: ContextualMenuType;

  /** Shortcut string for a default Type attribute in default menu */
  contextualType?: string;

  /** Shortcut string for a copy action value in default menu */
  contextualCopyValue?: string;

  /** Do not add contextual menu to this pill */
  noContextualMenu?: boolean;
};

export type ContextualMenuItemType = typeof MenuDelimiter | typeof MenuInfo | typeof MenuItem | typeof MenuItem.Copy;
export type ContextualMenuOptionsType = {pillTheme: Theme; menuStyles: Record<string, string>};
export type ContextualMenuType = (
  items: ContextualMenuItem[],
  {pillTheme, menuStyles}: ContextualMenuOptionsType,
) => ContextualMenuItem[];

export type ContextualMenuItem = {
  type: ContextualMenuItemType;
  props: {
    key: string;
    children?:
      | JSX.Element
      | JSX.Element[]
      | ((args: MenuInfoCallbackArgs) => JSX.Element | JSX.Element[] | Promise<JSX.Element | JSX.Element[]>);
    content?: string;
    prefetch?: (signal: AbortSignal) => Promise<unknown>;
    contentType?: never;
  };
  attributes?: {key?: string; value?: string; props?: ComponentPropsWithRef<'div'>}[];
};

export type PillProps = Omit<ComponentPropsWithoutRef<'div'> & Partial<LinkProps>, keyof PillPropsBase> &
  PillPropsBase &
  ThemeProps &
  UpdateTypeWithTooltip;

export default class Pill extends PureComponent<PillProps> implements WithElement {
  static contextType = AppContext;

  static Icon = PillSymbol;
  static Diff = PillDiff;
  static Endpoint = Endpoint;
  static Group = Group;
  static GroupsDiff = GroupsDiff;
  static Label = Label;
  static LabelDiff = LabelDiff;
  static IPList = IPList;
  static IPListDiff = IPListDiff;
  static Service = Service;
  static ServiceDiff = ServiceDiff;
  static VirtualServer = VirtualServer;
  static VirtualService = VirtualService;
  static Workload = Workload;
  static ContainerWorkload = ContainerWorkload;
  // eslint-disable-next-line react/static-property-placement
  declare context: React.ContextType<typeof AppContext>;

  link?: LinkClass;
  element: HTMLElement | null = null;

  elementRef: MutableRefObject<HTMLElement | null>;

  constructor(props: PillProps) {
    super(props);

    this.elementRef = createRef(); // Separate ref object to pass down to Tooltip

    this.saveRef = this.saveRef.bind(this);
    this.saveLinkRef = this.saveLinkRef.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderContextualMenu = this.renderContextualMenu.bind(this);
  }

  getPillTitle(): string | undefined {
    const {icon, children, title} = this.props;

    const name = icon && Icon.getTitle(icon);
    let desc: string | undefined;

    if (typeof title === 'string') {
      desc = title;
    } else if (typeof children === 'string') {
      desc = children;
    }

    if (desc && name && name !== desc) {
      return `${name}: ${desc}`;
    }

    return name || desc;
  }

  private saveLinkRef(link: LinkClass) {
    this.link = link; // Save reference of the Link instance
    this.saveRef(link?.element ?? null); // Pass dom element further into saveRef
  }

  private saveRef(element: HTMLElement | null) {
    this.element = element;
    this.elementRef.current = element;
  }

  private handleKeyDown(evt: KeyboardEvent) {
    if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  private handleKeyUp(evt: KeyboardEvent) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }

    if (evt.key === ' ' || evt.key === 'Enter') {
      // Emulate click on Space and Enter
      this.props.onClick?.(evt);
    }
  }

  private handleClose(evt: MouseEventLike) {
    evt.stopPropagation(); // To stop click event on whole label

    this.props.onClose?.(evt);
  }

  private hasContextualMenu() {
    const {props} = this;

    return (
      !props.noContextualMenu && Boolean(props.contextualMenu || props.contextualType || props.contextualCopyValue)
    );
  }

  private renderContextualMenu() {
    const {contextualMenu, contextualType, contextualCopyValue, theme} = mixThemeWithProps(styles, this.props);
    let menuContent: ContextualMenuItem[];

    if (contextualMenu && Array.isArray(contextualMenu)) {
      menuContent = contextualMenu;
    } else {
      const defaultItems: ContextualMenuItem[] = [];

      if (contextualType) {
        defaultItems.push(
          {
            type: MenuInfo,
            props: {key: 'info'},
            attributes: [{key: intl('Common.Type'), value: contextualType}],
          },
          {type: MenuDelimiter, props: {key: 'delimiter'}},
        );
      }

      if (contextualCopyValue) {
        defaultItems.push({type: MenuItem.Copy, props: {key: 'copy', content: contextualCopyValue}});
      }

      menuContent =
        typeof contextualMenu === 'function'
          ? contextualMenu(defaultItems, {pillTheme: theme, menuStyles})
          : defaultItems;
    }

    return (
      <>
        {menuContent.reduce((result: ReactNode[], item) => {
          if (!item) {
            return result;
          }

          if (isValidElement(item)) {
            result.push(item);
          } else if (item.type === MenuInfo) {
            let children;

            if (item.attributes) {
              children = (
                <>
                  {item.attributes.map(({key, props, value}) => (
                    <div key={key} {...props}>
                      <strong>{`${key}:`}</strong> {value}
                    </div>
                  ))}
                </>
              );
            } else {
              children = item.props.children;
            }

            result.push(<MenuInfo {...item.props}>{children}</MenuInfo>);
          } else if (item.type === MenuDelimiter) {
            const {children, ...menuDelimiterProps} = item.props;

            result.push(<MenuDelimiter {...menuDelimiterProps} />);
          } else if (item.type === MenuItem.Copy) {
            const {children, content, ...copyProps} = item.props;

            result.push(<MenuItem.Copy content={item.props.content ?? ''} {...copyProps} />);
          } else if (item.type) {
            result.push(createElement(item.type as React.ComponentType, item.props));
          } else {
            // TODO review type
            result.push(item as ReactNode);
          }

          return result;
        }, [])}
      </>
    );
  }

  render() {
    const {
      icon,
      initial,
      pillStyle,
      hideIcon,
      status,
      updateType,
      updateTypeTooltip,
      // Note: updateType feature is disabled, set the default value of hideUpdateType to false to enable this feature
      hideUpdateType = true,
      title,
      link,
      children,
      group,
      pinned,
      insensitive,
      highlighted,
      created,
      deleted,
      disabled,
      error,
      warning,
      exclusion,
      exclusionContent,
      onClose,
      tooltip,
      tooltipProps,
      noFill,
      theme,
      tid,
      contextualMenu,
      contextualType,
      contextualCopyValue,
      noContextualMenu = false,
      ...rest
    } = mixThemeWithProps(styles, this.props);

    const {isCSFrame} = this.context;

    const isInsensitive = insensitive || disabled;

    const tids = tidUtils.getTid(group ? 'comp-pill-group' : 'comp-pill', [icon, tid]);

    const classes = cx(theme.pill, {
      [theme[status!]]: Boolean(status),
      [theme.created]: created,
      [theme.deleted]: deleted,
      [theme.disabled]: disabled,
      [theme.exclusion]: exclusion,
      [theme.error]: error,
      [theme.warning]: warning && !error,
      [theme.pinned]: pinned,
      [theme.fill]: !noFill,
      [theme.highlighted]: highlighted,
    });

    const sharedProps = {
      ...rest,
      'aria-label': typeof tooltip === 'string' ? tooltip : this.getPillTitle(),
      'data-tid': tids,
    };

    const isExclusionNewUI = this.context.isNewUI && exclusion && Boolean(exclusionContent);

    const exclusionElement = isExclusionNewUI ? (
      <div className={styles.exclusionArea}>
        {!noFill && !hideIcon && (icon || initial) && (
          <PillSymbol
            name={icon}
            initial={initial}
            pillStyle={pillStyle}
            group={group}
            theme={theme}
            themePrefix="pillSymbol-"
          />
        )}
        {exclusionContent}
      </div>
    ) : null;

    const child = (
      <div className={theme.content}>
        {!hideUpdateType && updateType && (
          <Icon name="online" tooltip={updateTypeTooltip} theme={theme} themePrefix={`${updateType}-`} />
        )}
        {status && <Icon name="online" theme={theme} themePrefix="status-" />}
        {!isExclusionNewUI && !noFill && !hideIcon && (icon || initial) && (
          <PillSymbol
            name={icon}
            initial={initial}
            pillStyle={pillStyle}
            group={group}
            theme={theme}
            themePrefix="pillSymbol-"
          />
        )}
        {children ? (
          <span className={theme.text} data-tid="elem-text">
            {children}
          </span>
        ) : null}

        {onClose ? (
          <div onClick={this.handleClose} className={theme.close} role="button">
            <Icon name="close" theme={theme} themePrefix="close-" />
            {pinned && <Icon name="pin" theme={theme} themePrefix="pinned-" />}
          </div>
        ) : null}
      </div>
    );

    let pill;

    if (link && !isInsensitive && !isCSFrame) {
      // If Pill is a Link, assign classes string to .link theme and link object properties to route properties.
      // It will become focusable and activatable by space/enter automatically
      const props = {
        ...sharedProps,
        ref: this.saveLinkRef,
        theme: Link.getLinkTheme(`${classes} ${theme.interactive} ${theme.clickable}`),
        to: typeof link === 'string' ? link : undefined,
      };

      if (typeof link !== 'string') {
        Object.assign(props, link);
      }

      pill = (
        <Link {...props}>
          {exclusionElement}
          {child}
        </Link>
      );
    } else {
      const clickable = !isInsensitive && typeof rest.onClick === 'function';
      const interactive = clickable || (!isInsensitive && this.hasContextualMenu());

      // If Pill is not a link but has onClick handler, make it focusable (tabIndex) and control keys manually
      pill = (
        <div
          {...sharedProps}
          ref={this.saveRef}
          className={cx(classes, {[theme.interactive]: interactive, [theme.clickable]: clickable})}
          {...(clickable
            ? {
                role: 'button',
              }
            : undefined)}
          {...(interactive
            ? {
                tabIndex: rest.tabIndex || 0,
                onKeyUp: this.handleKeyUp,
                onKeyDown: this.handleKeyDown,
              }
            : undefined)}
        >
          {exclusionElement}
          {child}
        </div>
      );
    }

    return (
      <>
        {pill}
        {tooltip ? <Tooltip content={tooltip} reference={this.elementRef} {...tooltipProps} /> : null}
        {this.hasContextualMenu() ? (
          <ContextualMenu reference={this.elementRef} theme={theme}>
            {this.renderContextualMenu}
          </ContextualMenu>
        ) : null}
      </>
    );
  }
}
