/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {hrefUtils} from 'utils';
import {sortAndStringifyArray} from '@thor/utils';
import {getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {getRouteName, getRoutePreviousName, getRouteCurrentParams, getRouteParams} from 'containers/App/AppState';
import {isUserReadOnly} from 'containers/User/UserState';
import {getServiceInstance} from 'containers/Service/Item/ServiceItemState';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVERS_GET_DETAIL':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVirtualServerDetail = state => state.virtualservers?.detail;

export const getVirtualServerVersions = createSelector(
  [getVirtualServerDetail, getRouteParams],
  (virtualServerDetail, {pversion}) => getPolicyVersions(virtualServerDetail, pversion),
);

export const getVirtualServerItem = createSelector(
  [
    getVirtualServerVersions,
    isUserReadOnly,
    getRoutePreviousName,
    getRouteName,
    getRouteCurrentParams,
    getServiceInstance,
  ],
  (
    {versions, isOldVersion, pversionObjIsDeleted},
    userIsReadOnly,
    previousRouteName,
    routeName,
    currentRouteParams,
    service,
  ) => {
    const {active, draft} = versions;
    const isDraft = currentRouteParams.pversion === 'draft';
    const isEdit = routeName.endsWith('edit');

    const summaryIsModified =
      isDraft &&
      Boolean(active) &&
      ['name', 'description', 'mode', 'labels', 'service.href'].some(path => {
        let activeValue = _.get(draft, path);
        let draftValue = _.get(active, path);

        if (path === 'service.href' && activeValue && draftValue) {
          activeValue = hrefUtils.getId(activeValue);
          draftValue = hrefUtils.getId(draftValue);
        } else if (path === 'labels' && Array.isArray(activeValue) && Array.isArray(draftValue)) {
          activeValue = sortAndStringifyArray(activeValue, 'href');
          draftValue = sortAndStringifyArray(draftValue, 'href');
        }

        return !_.isEqual(activeValue, draftValue);
      });
    const membersAreModified =
      isDraft &&
      Boolean(active) &&
      sortAndStringifyArray(draft.providers, 'label.href') !== sortAndStringifyArray(active.providers, 'label.href');

    return {
      versions,
      isOldVersion,
      isEdit,
      pversionObjIsDeleted,
      service,
      userIsReadOnly,
      previousRouteName,
      routeName,
      currentRouteParams,
      summaryIsModified,
      membersAreModified,
    };
  },
);
