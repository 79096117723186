/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';
import {getIsNewUI} from 'containers/App/AppState';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';

export const staticValues = () => ({
  provision: {
    [intl('Provision.PendingAddition')]: 'create',
    [intl('Provision.PendingDeletion')]: 'delete',
    [intl('Provision.PendingModification')]: 'update',
  },
});

export const gridSettings = createSelector([getIsNewUI], isNewUI => ({
  id: 'securegatewaylist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: getProvisionStatusColumn(isNewUI),
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => row.data.name,
    },
    ipAddress: {
      header: intl('SecureGateway.GatewayIPAddress'),
      value: ({row}) => row.data.ip_address,
    },
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breakpoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['ipAddress'], size: 'minmax(120px, auto)'},
      {columns: ['desc'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(120px, auto)'},
            {columns: ['ipAddress', 'desc'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(120px, auto)'},
          {columns: ['ipAddress', 'desc'], size: 'minmax(140px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status'], size: 'minmax(140px, auto)'},
            {columns: ['ipAddress', 'desc'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status'], size: 'minmax(140px, auto)'},
          {columns: ['ipAddress', 'desc'], size: 'minmax(140px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status', 'ipAddress', 'desc'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status', 'ipAddress', 'desc'], size: 'minmax(140px, auto)'},
        ];
      },
    },
  ],
}));
