/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {Button, type ButtonProps} from 'components';
import Modal, {type ModalProps} from '../Modal';
import type {ReactStrictNode} from 'utils/types';
import type {ModalHeaderProps} from '../ModalHeader';
import type {ModalContentProps} from '../ModalContent';

const defaultButtonProps = {
  tid: 'done',
  text: intl('Common.Done'),
};

// List of props specific to Alert modal, all extra props will be passed down to rendered Modal as is
type AlertProps = ModalContentProps &
  ModalHeaderProps &
  ModalProps & {
    // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
    buttonProps?: ButtonProps;
    // Shortcut to set progress state to the button if buttonProps is not specified
    isInProgress?: boolean;

    // Props for <Modal.Header>
    title?: ReactStrictNode;
    noCloseIcon?: boolean;

    // Props for <Modal.Content>
    insensitive?: boolean;
    notScrollable?: boolean;
    noPaddings?: boolean;
    gap?: string;
  };

export default class AlertModal extends PureComponent<AlertProps> {
  render() {
    const {
      children,
      buttonProps,
      isInProgress,
      // Destructure <Modal.Header> props
      title = intl('Common.Alert'),
      noCloseIcon,
      // Destructure <Modal.Content> props
      insensitive,
      notScrollable,
      noPaddings,
      gap,
      ...modalProps
    } = this.props;
    const headerProps = {title, noCloseIcon} as {title: string; noCloseIcon: boolean};
    const contentProps = {insensitive, notScrollable, noPaddings, gap};
    const button = {onClick: modalProps.onClose, progress: isInProgress, ...defaultButtonProps, ...buttonProps};

    return (
      <Modal {...modalProps}>
        <Modal.Header {...headerProps} />
        <Modal.Content {...contentProps}>{children}</Modal.Content>
        <Modal.Footer>
          <Button {...button} />
        </Modal.Footer>
      </Modal>
    );
  }
}
