/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import {useAnimationControls, motion} from 'framer-motion';
import {useEffect} from 'react';

const Animator = props => {
  const controls = useAnimationControls();
  const {children, inputDimensions} = props;

  useEffect(() => {
    (async () => {
      await controls.start({width: '100%'});
      controls.start({height: '100%'});
    })();
  }, [controls]);

  if (!inputDimensions) {
    return children;
  }

  if (inputDimensions) {
    const {width, height} = inputDimensions;

    return (
      <motion.div
        style={{
          width,
          height,
          borderRadius: 10,
        }}
        animate={controls}
        transition={{duration: 0.2, type: 'tween'}}
      >
        {children}
      </motion.div>
    );
  }
};

export default Animator;
