/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {getRouteParams, getIsNewUI} from 'containers/App/AppState';
import {Button, Pill, TypedMessages} from 'components';
import styles from './SecuritySettings.css';
import {createSelector} from 'reselect';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {populateLabelsCategories} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import tooltipStyles from 'components/Tooltip/Tooltip.css';
import {reshuffleLabels} from 'containers/Selector/Presets';
import {allScopeKey} from './SecuritySettingsUtils';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';
import {Selector} from 'containers';

const redundantResources = {
  labelsAndLabelGroups: ['allWorkloads'],
  allWorkloads: ['labelsAndLabelGroups'],
};

const resourceNames = {
  labelsAndLabelGroups: `'${intl('Rulesets.Rules.LabelAndLabelGroups')}'`,
  allWorkloads: `'${intl('Workloads.All')}'`,
};
const confirmationOnSelect = (evt, {value, values, resource}) => {
  const redundantSelections = redundantResources[resource.id]?.filter(id => values.has(id));

  if (redundantSelections?.length) {
    if (value.confirmed) {
      redundantSelections.forEach(id => values.delete(id));
    } else {
      return values;
    }
  }

  const resh = reshuffleLabels(evt, {value: _.omit(value, 'confirmed'), values, resource});

  return resh;
};
export const policyState = () => ({
  enforced: intl('Common.Enforced'),
  illuminated: intl('Common.Illuminated'),
  all: intl('Common.All'),
});

// eslint-disable-next-line no-underscore-dangle
const getParentTippyInstance = evt => evt.target.closest(`.${tooltipStyles.tooltip}`).parentElement._tippy;

const handleDoneTooltip = (evt, {onSelect, option, resource}) => {
  const confirmedValue = {...(typeof option === 'string' ? {value: option} : option), confirmed: true};

  const tippyInstance = getParentTippyInstance(evt);

  tippyInstance?.hide();
  onSelect(evt, {resourceId: resource.id, value: confirmedValue});
};

const handleCancelTooltip = evt => {
  // eslint-disable-next-line no-underscore-dangle
  const tippyInstance = evt.target.closest(`.${tooltipStyles.tooltip}`).parentElement._tippy;

  tippyInstance?.hide();
};
const getWarningContent = (message, {option, resource, onSelect}) => (
  <div>
    <TypedMessages>
      {[
        {
          icon: 'warning',
          content: message,
        },
      ]}
    </TypedMessages>
    <div className={styles.buttons}>
      <Button size="small" text="Yes" onClick={_.partial(handleDoneTooltip, _, {onSelect, resource, option})} />
      <Button color="standard" size="small" text="No" onClick={handleCancelTooltip} />
    </div>
  </div>
);

const tooltipProps = {
  trigger: 'click',
  visible: undefined,
  interactive: true,
  flipBehavior: ['right', 'left', 'top', 'bottom'],
  onShow: ({popper}) => {
    popper.focus();
    setTimeout(() => popper.querySelector('button')?.focus(), 500);
  },
  content: options => {
    const {option, values, resource} = options;

    const resourceId = option.resourceId ?? resource.id;
    const redundantSelections = redundantResources[resourceId];

    if (!redundantSelections?.length) {
      return null;
    }

    const replaceResources = [
      ...redundantSelections.reduce((result, id) => {
        if (values.has(id)) {
          result.add(id);
        }

        return result;
      }, new Set()),
    ];

    return replaceResources.length
      ? getWarningContent(
          intl('Rulesets.Rules.SelectingReplacesConfirmation', {
            resourceName: resourceNames[resourceId],
            selectedResourcesNames: intl.list(replaceResources.map(id => resourceNames[id])),
          }),
          options,
        )
      : null;
  },
};

export const editCategories = createSelector(
  [getRouteParams, getDisplayNames, getTypeInitialRegExp],
  ({section}, labelTypesNameObj, labelTypeInitialRegExp) => {
    return [
      Selector.categoryPresets.labelsAndLabelGroups({
        allowCreate: true,
        allowMultipleSelection: false,

        resourceType: '',
        ...(section === 'firewallcoexistence' ? {hasLabelGroups: false} : {}),
        hasNoLabels: false,
        labelTypesNameObj,
        labelTypeInitialRegExp,
        tooltipProps,
        onSelect: confirmationOnSelect,
      }),

      {
        id: 'stickyCategory',
        sticky: true,
        resources: {
          allWorkloads: {
            sticky: true,
            statics: [{value: intl('Workloads.All'), href: allScopeKey}],
            optionProps: {
              isPill: true,
              tooltipProps,
              allowMultipleSelection: true,
            },
            selectedProps: {hideResourceName: true},
            onSelect: confirmationOnSelect,
          },
        },
      },
    ];
  },
);

export const categories = createSelector(
  [getRouteParams, getDisplayNames, getTypeInitialRegExp],
  ({section, mode}, labelTypesNameObj, labelTypeInitialRegExp) => [
    ...populateLabelsCategories({
      resourceType: '',
      hasLabelGroups: section !== 'firewallcoexistence',
      hasNoLabels: true,
      labelTypesNameObj,
      labelTypeInitialRegExp,
    }),
    ...(mode === 'active'
      ? []
      : [
          {
            id: 'status',
            name: intl('Provision.Status'),
            resources: {
              status: {
                statics: [{value: intl('Provision.PendingAddition')}, {value: intl('Provision.PendingDeletion')}],
              },
            },
          },
        ]),
    ...(section === 'firewallcoexistence'
      ? [
          {
            id: 'state',
            name: intl('Policy.State'),
            resources: {
              state: {
                statics: [
                  {value: intl('Common.All')},
                  {value: intl('Common.Enforced')},
                  {value: intl('Common.Illuminated')},
                ],
              },
            },
          },
          {
            id: 'primaryFirewall',
            name: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'),
            resources: {
              primaryFirewall: {
                statics: [{value: intl('Common.Yes')}, {value: intl('Common.No')}],
              },
            },
          },
        ]
      : []),
  ],
);

const allSubColConfig = {
  noPadding: true,
  header: intl('Workloads.All'),
  value: ({row}) => row.data.labelsMap.size === 0 || Boolean(row.data.labelsMap.get(allScopeKey)),
  format: ({value}) =>
    value && (
      <Pill icon={undefined} link={{to: 'workloads.list'}}>
        {intl('Workloads.All')}
      </Pill>
    ),
};

export const gridSettings = createSelector(
  [state => getLabelsColumn(state, {hasAll: true, customAll: allSubColConfig}), getIsNewUI],
  (labelsColumn, isNewUI) => ({
    id: 'securitysettings',
    capacities: [25, 50, 100, 250, 500],
    capacity: 50,
    showCapacity: true,
    showPagination: true,
    maxPage: Number.MAX_SAFE_INTEGER,
    columns: {
      checkboxes: {},
      status: getProvisionStatusColumn(isNewUI, false),
      labels: {...labelsColumn, header: intl('Common.Scope')},
      state: {
        header: intl('Common.Enforcement'),
        value: 'state',
        format: ({value}) => (value ? policyState()[value] : intl('Common.All')),
      },
      primaryFirewall: {
        header: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'),
        value: 'primaryFirewall',
        format: ({value}) => (value ? intl('Common.Yes') : intl('Common.No')),
        sort: ({value}) => (value ? intl('Common.Yes') : intl('Common.No')),
      },
      action: {
        value: 'action',
        format: ({component, row}) =>
          row.selectable && (
            <Button
              color="standard"
              noFill
              icon="edit"
              tid="edit"
              disabled={!row.data.action}
              onClick={component && _.partial(component.handleModifySingleScope, row)}
            />
          ),
        sortable: false,
      },
    },
    templates: [
      [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['status'], size: 'min-content'},
        {columns: ['labels'], size: 'minmax(700px, auto)'},
        {columns: ['state'], size: 'minmax(100px, auto)'},
        {columns: ['primaryFirewall'], size: 'minmax(250px, auto)'},
        {columns: ['action'], size: 'max-content'},
      ],
      {
        maxWidth: 1152,
        template(columns) {
          if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['status'], size: 'minmax(130px, auto)'},
              {columns: ['labels'], size: 'minmax(150px, auto)'},
              {columns: ['action'], size: 'max-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['labels'], size: 'minmax(120px, auto)'},
            {columns: ['state'], size: 'minmax(100px, auto)'},
            {columns: ['primaryFirewall'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        },
      },
      {
        maxWidth: 960,
        template(columns) {
          if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['status'], size: 'minmax(130px, auto)'},
              {columns: ['labels'], size: 'minmax(200px, auto)'},
              {columns: ['action'], size: 'max-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['labels'], size: 'minmax(200px, auto)'},
            {columns: ['state', 'primaryFirewall'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        },
      },
      {
        maxWidth: 800,
        template(columns) {
          if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['status'], size: 'minmax(130px, auto)'},
              {columns: ['labels'], size: 'minmax(200px, auto)'},
              {columns: ['action'], size: 'max-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'minmax(130px, auto)'},
            {columns: ['labels'], size: 'minmax(250px, auto)'},
            {columns: ['state', 'primaryFirewall'], size: 'minmax(150px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        },
      },
      {
        maxWidth: 640,
        template(columns) {
          if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['status'], size: 'minmax(130px, auto)'},
              {columns: ['labels'], size: 'minmax(200px, auto)'},
              {columns: ['action'], size: 'max-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status', 'labels'], size: 'minmax(100px, auto)'},
            {columns: ['state', 'primaryFirewall'], size: 'minmax(100px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        },
      },
      {
        maxWidth: 450,
        template(columns) {
          if (columns.get('state').disabled && columns.get('primaryFirewall').disabled) {
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['status', 'labels'], size: 'minmax(100px, auto)'},
              {columns: ['action'], size: 'max-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status', 'labels', 'state', 'primaryFirewall'], size: 'minmax(100px, auto)'},
            {columns: ['action'], size: 'max-content'},
          ];
        },
      },
    ],
  }),
);
