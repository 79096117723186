/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useSelector} from 'utils/redux';
import {useEffect, useState, useCallback, useRef, useContext} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {AppContext} from 'containers/App/AppUtils';
import {getRouteName} from 'containers/App/AppState';
import PubSub from 'pubsub';
import {APIError, RequestError, TimeoutError} from 'errors';
import {isMotionReduced} from 'utils/dom';
import {AttributeList, Icon, Button, Modal} from 'components';
import styles from './NavigationAlert.css';

const handleReload = () => window.location.reload(true);
const initialState = {show: false, showDetails: false, type: 'warning', error: null, delay: 0};
const initialStateDelay = {show: false, showDetails: false, type: 'warning', error: null, delay: 0.4};
const variants = {
  initial: {y: '-100%', opacity: 0},
  enter: () => ({y: 0, opacity: 1, transition: {duration: isMotionReduced() ? 0 : 0.2}}),
  exit: delay => ({y: '-100%', opacity: 0, transition: {duration: isMotionReduced() ? 0 : 0.3, delay}}),
};

export default function NavigationAlert() {
  const routeName = useSelector(getRouteName);
  const [alertState, setAlertState] = useState(initialState);
  const alertStatePrevious = useRef(alertState);
  const showAlertTimeoutRef = useRef(null);
  const continueWaitingTimeoutRef = useRef(null);
  const continueWaitingDelayRef = useRef(null); // to take warning delay from prefetcher params
  const {
    store: {prefetcher},
    sendAnalyticsEvent,
  } = useContext(AppContext);
  const contentRendered = window.contentRendered;
  const contentRenderedWithError = window.contentRenderedWithError;
  const {initialFetchError} = prefetcher;

  const handleClose = useCallback(() => {
    if (initialFetchError) {
      // Don't close Alert on Detail modal close if it's a page load error, since there is no content on a page
      setAlertState({...alertState, showDetails: false});
    } else {
      // Close Alert on Detail modal close
      setAlertState(initialState);
    }
  }, [initialFetchError, alertState]);

  const handleDetailsClick = useCallback(() => {
    setAlertState({...alertState, showDetails: !alertState.showDetails});
  }, [alertState]);

  const handleContinue = useCallback(() => {
    const timesDismissed = (alertState.timesDismissed ?? 0) + 1;

    setAlertState({...initialState, timesDismissed});
    clearTimeout(continueWaitingTimeoutRef.current);

    // Wait for 'delay' after continue click to show warning alert again
    continueWaitingTimeoutRef.current = setTimeout(() => {
      setAlertState(({timestamp, timesDismissed = 0}) => ({show: true, type: 'warning', timestamp, timesDismissed}));
    }, continueWaitingDelayRef.current);

    sendAnalyticsEvent('navigation.warning.dismiss', {
      routeName,
      timesDismissed,
      timeElapsed: Date.now() - alertState.timestamp,
    });
  }, [alertState, routeName, sendAnalyticsEvent]);

  const handleStop = useCallback(() => {
    // Cancel fetch with batchEnd=true, cancelledByNavigationNotification=true params
    prefetcher.fetchCancel(true, true);
    // Clear timeout event is not published when transition is cancelled by this component, therefore
    // Initialize state and clear timer explicity
    clearTimeout(continueWaitingTimeoutRef.current);
    clearTimeout(showAlertTimeoutRef.current);
    setAlertState(initialState);

    sendAnalyticsEvent('navigation.warning.stop', {
      routeName,
      timeElapsed: Date.now() - alertState.timestamp,
    });
  }, [alertState, routeName, prefetcher, sendAnalyticsEvent]);

  useEffect(() => {
    const token = PubSub.subscribe(
      'NAVIGATION.ALERT',
      prefetcherParams => {
        // Reset timers on each pubsub subscription
        clearTimeout(continueWaitingTimeoutRef.current);
        clearTimeout(showAlertTimeoutRef.current);

        // Close the alert if fetchParams is null (alert closes by above resets), otherwise
        // set timer and/or display appropriate alerts
        if (prefetcherParams) {
          const {timestamp, warningTimeout, error} = prefetcherParams;

          if (error) {
            setAlertState({
              show: true,
              type: 'error',
              timestamp,
              error: prefetcherParams.error,
              token: Array.isArray(error.data) && error.data[0]?.token,
              noConnection: error instanceof RequestError && error.noConnection,
              timeout: error instanceof TimeoutError || (error instanceof APIError && error.timeout),
            });
          } else {
            const timeElapsed = Date.now() - timestamp; // Time elapsed since navigation started
            const warningDelay = Math.max(0, warningTimeout - timeElapsed);

            continueWaitingDelayRef.current = warningTimeout;
            showAlertTimeoutRef.current = setTimeout(() => {
              setAlertState(({timesDismissed = 0}) => ({show: true, type: 'warning', timestamp, timesDismissed}));
            }, warningDelay);
          }
        } else {
          setAlertState(initialStateDelay); // On success delay close to give a new page some time to render first
        }
      },
      {getLast: true},
    );

    return () => PubSub.unsubscribe(token);
  }, []);

  useEffect(() => {
    if (alertStatePrevious.current !== alertState) {
      if (alertState.show && !alertStatePrevious.current.show) {
        if (alertState.type === 'error') {
          sendAnalyticsEvent('navigation.error', {
            routeName,
            timeElapsed: Date.now() - alertState.timestamp,
            token: alertState.token,
            timeout: alertState.timeout,
            noConnection: alertState.noConnection,
          });
        } else if (alertState.type === 'warning') {
          sendAnalyticsEvent('navigation.warning', {
            routeName,
            timesShown: (alertState.timesDismissed ?? 0) + 1,
            timeElapsed: Date.now() - alertState.timestamp,
          });
        }
      }

      alertStatePrevious.current = alertState;
    }
  });

  // Set error message from error object, timeout is default error message
  let message;
  let requestId;
  let details;

  if (alertState.show) {
    if (alertState.type === 'warning') {
      message = intl(contentRendered ? 'NavigationAlert.Warning' : 'NavigationAlert.WarningInitial');
    } else if (alertState.type === 'error') {
      const {error, noConnection, timeout, token} = alertState;

      if (noConnection) {
        message = intl(
          contentRenderedWithError || !contentRendered
            ? 'NavigationAlert.NoConnectionPage'
            : 'NavigationAlert.NoConnection',
        );
      } else if (timeout) {
        message = intl('NavigationAlert.Timeout');

        if (error instanceof APIError) {
          details = error.message;
        }
      } else {
        message = intl(
          contentRenderedWithError || !contentRendered ? 'NavigationAlert.ErrorPage' : 'NavigationAlert.Error',
        );
        details = error.message;

        // If tokens exist, print them as well
        if (token) {
          details = (
            <>
              {details}
              <AttributeList keyColumnWidth="fit-content(75%)" noKeyPadding>
                {error.data.map(item => ({key: item?.token, value: item?.message}))}
              </AttributeList>
            </>
          );
        }
      }

      if (error instanceof APIError && error.response?.headers?.has('x-request-id')) {
        requestId = error.response.headers.get('x-request-id');
      }
    }
  }

  return (
    <>
      <AnimatePresence custom={alertState.delay}>
        {alertState.show && (
          <motion.div
            className={styles.navigationAlertContainer}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <div className={styles[alertState.type]}>
              <Icon name={alertState.type} theme={styles} themePrefix="status-" />

              <div className={styles.text}>{message}</div>

              <div className={styles.buttons}>
                {initialFetchError ? (
                  <Button color="standard" text={intl('Common.Reload')} onClick={handleReload} tid="reload" />
                ) : null}
                {details || requestId ? (
                  <Button color="standard" text={intl('Common.Details')} onClick={handleDetailsClick} tid="details" />
                ) : null}
                {contentRendered && alertState.type === 'warning' && (
                  <>
                    <Button noFill text={intl('Common.Stop')} onClick={handleStop} tid="stop" />
                    <Button text={intl('Common.ContinueWaiting')} onClick={handleContinue} tid="continue" />
                  </>
                )}
              </div>
              {contentRendered && alertState.type === 'error' && (
                <Icon name="close" onClick={handleClose} theme={styles} themePrefix="close-" />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {alertState.showDetails && (details || requestId) ? (
        <Modal.Support
          key="details"
          title={intl('NavigationAlert.Details')}
          onClose={handleClose}
          tid="navigation-details"
          details={details}
          requestId={requestId}
          message={message}
        />
      ) : null}
    </>
  );
}
