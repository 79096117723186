/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {flattenObject} from '@thor/utils';
import type {Config} from 'config';
import type {LangKeys} from './keys';

export type FlattenLang = Record<LangKeys, string>;
export type {LangKeys};

export default (config: Config): Record<string, FlattenLang> => {
  // Main language - first one in config. Usually English
  const mainLang = config.langs[0];
  // TODO: use dynamic import().
  // Currently webpack would bundle all language folder into the main chunk instead of split chunk
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const mainBundle = require(`./${mainLang}/en.js`).default;

  // // Dev only. Accept HMR updates of main language bundle and upstream them upper (to intl.js)
  if (module.hot) {
    module.hot.accept(`./${mainLang}/en.js`);
  }

  return _.transform(
    config.langs,
    (result: Record<string, FlattenLang>, lang) => {
      // TODO: use dynamic import();
      // Get language bundle
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      const bundle = require(`./${lang}/en.js`).default;

      // Keep main language as is, all others must fallback on main
      if (lang !== mainLang) {
        _.defaultsDeep(bundle, mainBundle);
      }

      // Flatten bundle
      result[lang] = flattenObject(bundle) as FlattenLang;
    },
    {},
  );
};
