/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

export const dragAndDropIndexMultiplier = 1_000_000;

/**
 * These are shortcuts to the label type setting.
 * Since settings are global and change rarely, we can put it there in closure,
 * and allow other modules use the subsequent utils to pull the settings without subscribing to Store.
 */

let labelTypesIndexMap = new Map();
let labelSettingsMap = new Map();
let labelDisplayStyle = 'icon';

export const getLabelTypesIndexMap = () => labelTypesIndexMap;
export const getLabelSettingsMap = () => labelSettingsMap;
export const getLabelDisplayStyle = () => labelDisplayStyle;

export const setLabelTypesIndexMap = (types = labelTypesIndexMap) => {
  if (types !== labelTypesIndexMap) {
    labelTypesIndexMap = types;
  }
};
export const setLabelSettingsMap = (settings = labelSettingsMap) => {
  if (settings !== labelSettingsMap) {
    labelSettingsMap = settings;
  }
};
export const setLabelDisplayStyle = (style = labelDisplayStyle) => {
  if (style !== labelDisplayStyle) {
    labelDisplayStyle = style;

    console.log(labelDisplayStyle);
  }
};

export const getLabelSettingsByType = type => {
  return labelSettingsMap.get(type);
};

export const getLabelTypeName = (type, plural = false) => {
  const labelTypeSettings = getLabelSettingsByType(type);

  return (
    (labelTypeSettings &&
      ((plural && labelTypeSettings.display_info?.display_name_plural) ||
        labelTypeSettings.display_name ||
        labelTypeSettings.key)) ||
    ''
  );
};

export const getLabelTypeInitial = (type, group = false) => {
  const labelTypeSettings = getLabelSettingsByType(type);
  let initial = labelTypeSettings?.display_info?.initial || getLabelTypeName(type).charAt(0);

  if (__ANTMAN__ && group) {
    initial = `${initial}G`;
  }

  return initial;
};

export const getLabelTypeDisplay = type => {
  const labelTypeSettings = getLabelSettingsByType(type);

  return labelTypeSettings?.display_info ?? {};
};

/**
 * Returns items containing labels and/or label groups sorted based on the order of label types
 *
 * Note: items coming from PillDiff will be 2d arrays sorted separately per type of diff to keep order:
 *     [[modified], [unchanged], [removed]]
 *    they also have no distinction between labels/label groups, so we only pass in labelTypeAccessor
 *
 * @param {array} items  an array of arrays or a flat array of labels and/or label groups
 * @param {string} labelTypeAccessor the path to the label type of the label
 *        e.g. for an item {label: {key: "app"}} it's "label.key"
 * @param {string} labelGroupTypeAccessor the path to the label type of the label group
 *        e.g. for an item {label_group: {key: "app"}} it's "label_group.key"
 *            - refer to RulesetListConfig
 * @returns flat array of items
 */
export const getSortedLabelsAndLabelGroups = (items, labelTypeAccessor = 'key', labelGroupTypeAccessor = 'key') => {
  if (!items) {
    return [];
  }

  const labelTypeAccessorArr = labelTypeAccessor.split('.');
  const labelGroupTypeAccessorArr = labelGroupTypeAccessor.split('.');

  // in the case of items with both labels and label groups, check if item is label first then label group
  // move non label/label group items to the end by assigning them a big index
  const sortFunc = (a, b) => {
    const aTypeIndex =
      labelTypesIndexMap.get(_.get(a, labelTypeAccessorArr) ?? _.get(a, labelGroupTypeAccessorArr)) ?? 1000;
    const bTypeIndex =
      labelTypesIndexMap.get(_.get(b, labelTypeAccessorArr) ?? _.get(b, labelGroupTypeAccessorArr)) ?? 1000;

    return aTypeIndex - bTypeIndex;
  };

  if (Array.isArray(items[0])) {
    return items.reduce((result, itemArr) => {
      result.push(...[...itemArr].sort(sortFunc));

      return result;
    }, []);
  }

  return [...items].sort(sortFunc);
};
