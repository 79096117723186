/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {isEdge} from 'containers/App/AppState';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {isVenOperationsEnabled} from './VenOperationsState';

export function* fetchVenOperations({force = false} = {}) {
  const VenOperationsEnabled = yield select(isVenOperationsEnabled);
  const edgeEnabled = yield select(isEdge);

  if (!VenOperationsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  const params = {
    cache: !force,
  };

  const {
    data: {ike_certificate_issuer_name_match, self_signed_certificate_rotation_interval_days},
  } = yield call(apiSaga, 'ven_settings.get', params);

  let interval;

  const ikeCertificate = ike_certificate_issuer_name_match?.[0].value ?? '';

  if (self_signed_certificate_rotation_interval_days) {
    interval = self_signed_certificate_rotation_interval_days[0]?.value ?? '';
  }

  yield put({type: 'IKE_CERTIFICATE_GET', data: {ikeCertificate, interval, edgeEnabled}});
}

export function* updateIKECertificate(ikeCertificate = null) {
  yield call(apiSaga, 'ven_settings.update', {
    data: {
      ike_certificate_issuer_name_match: [
        {
          // as per interface update for EYE-72490:
          // we will only support array of length 1, with scope = [] (i.e. all workloads), but
          // the schema is designed to be flexible to allow scoped settings in the future
          // i.e. choose a different certificate depending on datacenter, dev vs. prod, etc.
          scope: [],
          value: ikeCertificate,
        },
      ],
    },
  });

  apiCachedResponses.removeByMethodName('ven_settings.get');
}

export function* setRotationInterval(days) {
  yield call(apiSaga, 'ven_settings.update', {
    data: {
      self_signed_certificate_rotation_interval_days: [
        {
          scope: [],
          value: days,
        },
      ],
    },
  });

  apiCachedResponses.removeByMethodName('ven_settings.get');
}
