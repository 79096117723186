/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {AppContext} from 'containers/App/AppUtils';
import {PureComponent} from 'react';
import {GatewayContext, type ChildrenMap} from './GatewayController';
import type GatewayController from './GatewayController';
import type {ReactStrictNode} from 'utils/types';

/**
 * GatewayTarget is a component that renders children provided by the GatewayController.
 * It's a destination of a wormhole created by the Gateway.
 * It registers itself in the GatewayController after mounting and can render contents (children) from multiple Gateways
 * which target this GatewayTarget by a name in 'into' props.
 *
 * For example,
 * ####################################################################
 * Gateway into="T1"  ↘                                               #
 * Gateway into="T2" -> GatewayController -> GatewayTarget name="T1"  #
 * Gateway into="T1"  ↗                    ↘ GatewayTarget name="T2"  #
 * ####################################################################
 */

interface GatewayTargetProps {
  // Target unique name
  name: string;
  // Sticky element can mimic any html element ('div', 'h2' etc) or custom component (constructor like Link, Label etc)
  // Custom components are usually functions. but can be objects if they wrapped in forwardRef
  type?: React.JSXElementConstructor<unknown>;
  // Don't unmount children immediately, but save them in local state
  // and pass onUnmountReady callback down to them which will need to be called from children to unmount themselves
  waitBeforeUnmount?: boolean;
  children?: ReactStrictNode;
}
interface GatewayTargetState {
  childrenMap: ChildrenMap;
  childrenMapToRender: ChildrenMap;
  childrenIdWaitingForUnmount: Set<number>;
}

export default class GatewayTarget extends PureComponent<GatewayTargetProps, GatewayTargetState> {
  static contextType = GatewayContext;
  // eslint-disable-next-line react/static-property-placement
  declare context: GatewayController;

  constructor(props: GatewayTargetProps, context: GatewayController) {
    super(props, context);

    this.state = {childrenMap: new Map(), childrenMapToRender: new Map(), childrenIdWaitingForUnmount: new Set()};
  }

  componentDidMount() {
    this.context.checkInTarget(this.props.name, this);
  }

  componentWillUnmount() {
    this.context.checkOutTarget(this.props.name);
  }

  setChildren(childrenMap: ChildrenMap): void {
    // Create a new map from passed map to make it insensitive to map changes in GatewayController
    childrenMap = new Map(childrenMap);

    let {childrenMapToRender, childrenIdWaitingForUnmount} = this.state;

    if (this.props.waitBeforeUnmount) {
      childrenMapToRender = new Map(childrenMapToRender);

      for (const [id, item] of childrenMap) {
        childrenMapToRender.set(id, item);
      }

      for (const id of this.state.childrenMap.keys()) {
        if (!childrenMap.has(id)) {
          childrenIdWaitingForUnmount.add(id);
        }
      }
    } else {
      childrenMapToRender = childrenMap;
    }

    this.setState({childrenMap, childrenMapToRender, childrenIdWaitingForUnmount});
  }

  handleUnmountReady(childId: number): void {
    this.setState(state => {
      const childrenMapToRender = new Map(state.childrenMapToRender);
      const childrenIdWaitingForUnmount = new Set(state.childrenIdWaitingForUnmount);

      childrenMapToRender.delete(childId);
      childrenIdWaitingForUnmount.delete(childId);

      return {childrenMapToRender, childrenIdWaitingForUnmount};
    });
  }

  render() {
    const {
      props: {name, waitBeforeUnmount, type: ComponentType = 'div', ...componentPropsFromTarget},
      state: {childrenMapToRender, childrenIdWaitingForUnmount},
    } = this;

    if (!childrenMapToRender.size) {
      return null;
    }

    return Array.from(childrenMapToRender, ([id, {props, appContext}]) => {
      let {into, children, ...componentProps} = props;

      // Merge custom props from GatewayTarget and child component
      componentProps = {...componentPropsFromTarget, ...componentProps};

      // Don't pass children to the unmounting gateways,
      // but pass handler which will drop gateways when their unmounting is done
      if (childrenIdWaitingForUnmount.has(id)) {
        children = null;
        componentProps.onUnmountReady = this.handleUnmountReady.bind(this, id);
      }

      // Need a key in case of some child gateway gets removed, and all other should move
      return (
        <ComponentType key={id} {...componentProps}>
          {appContext && children ? <AppContext.Provider value={appContext}>{children}</AppContext.Provider> : children}
        </ComponentType>
      );
    });
  }
}
