/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {type ComponentPropsWithoutRef, type ForwardedRef, forwardRef} from 'react';
import cx from 'classnames';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {getTid} from 'utils/tid';
import {Icon} from 'components';
import styles from './Card.css';
import type {IconProps} from 'components/Icon/Icon';
import type {ReactStrictNode} from 'utils/types';
import type {MouseEventLikeHandler} from 'utils/dom';

export type CardProps = ComponentPropsWithoutRef<'div'> &
  ThemeProps & {
    children: ReactStrictNode;
    withBorder?: boolean;
    tid?: string;
    showCloseIcon?: boolean;
    onClose?: IconProps['onClick'];
    onCardClick?: MouseEventLikeHandler;
    customClass?: string;
    color?: 'primary' | 'secondary';
  };

export default forwardRef(function Card(props: CardProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
  const {
    children,
    theme,
    tid,
    customClass,
    color = 'primary',
    withBorder = false,
    showCloseIcon = false,
    onClose,
    onCardClick,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  // Default to primary style.
  let baseClass = theme.primary;

  if (color === 'secondary') {
    baseClass = theme.secondary;
  }

  elementProps['data-tid'] = getTid('comp-card', tid);
  elementProps.className = cx(
    baseClass,
    {
      [theme.withBorder]: withBorder,
      [theme.withCloseIcon]: showCloseIcon,
    },
    customClass,
  );

  return (
    <div ref={ref} {...elementProps} onClick={onCardClick}>
      {children}
      {showCloseIcon && <Icon name="close" onClick={onClose} theme={theme} themePrefix="close-" />}
    </div>
  );
});
