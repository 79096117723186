/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {getIsNewUI} from 'containers/App/AppState';
import {Link} from 'components';
import {hrefUtils} from 'utils';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import * as GridUtils from 'components/Grid/GridUtils';
import {getLoadBalancerInstance} from 'containers/LoadBalancer/Item/LoadBalancerItemState';
import {getFriendlyMode, getPort, isOnLoadBalancerPage} from '../VirtualServerUtils';
import styles from './VirtualServerList.css';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {
  populateFacetCategory,
  populateLabelsCategories,
  populateProtocolFacetCategory,
} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';

// This config is used context /virtualservers page and /loadbalancers/{id}/virtualservers tab.
// Virtual Servers are a subset of Discovered Virtual Servers. discovered_virtual_servers endpoint is used, not virtual_servers.
//
// VirtualServer Page:
// filters: slb, name, mode, vip, vip_port, vip_proto, labels
// Mode is a hard-coded set of (not enforced, enforced)
//
// LoadBalancer Page:
// SLB filters:  name, mode, vip, vip_port, vip_proto, labels (slb is only 1, set by page context).
// Note that there is no explicit SLB filter on the UI, since the entire tab is already filtered by the "parent" SLB.
// Facets on discovered_virtual_server for: name, vip, vip_port, vip_proto
// New autocomplete API for SLB name -> href is used.
export const categories = createSelector(
  [getDisplayNames, getTypeInitialRegExp, isOnLoadBalancerPage, getLoadBalancerInstance],
  (labelTypesNameObj, labelTypeInitialRegExp, isSLBPage, {href: slbHref}) => {
    const resourceType = 'discovered_virtual_servers';
    const query = isSLBPage ? {slb: slbHref} : {has_virtual_server: true}; // facets: do not suggest unmanaged VS except on SLB.servers tab

    return [
      populateFacetCategory({id: 'name', name: intl('Common.Name'), resourceType, query}),
      ...populateLabelsCategories({resourceType, hasNoLabels: true, labelTypesNameObj, labelTypeInitialRegExp}),
      populateFacetCategory({id: 'vip', name: intl('VirtualServers.VIP'), resourceType, query}),
      populateFacetCategory({
        id: 'vip_port',
        name: intl('VirtualServers.VIPPort'),
        resourceType,
        query,
        noPartial: true,
      }),
      populateProtocolFacetCategory({
        id: 'vip_proto',
        name: intl('VirtualServers.VIPProto'),
        resourceType,
        query,
        noPartial: true,
      }),
      ...(isSLBPage
        ? []
        : [
            {
              id: 'slbs',
              name: intl('VirtualServers.Detail.ServerLoadBalancer'),
              resources: {
                slbs: {
                  dataProvider: 'slbs.autocomplete',
                  apiArgs: {query},
                },
              },
            },
          ]),
      {
        id: 'virtual_server_mode',
        name: intl('Common.Enforcement'),
        resources: {
          virtual_server_mode: {
            statics: [
              {id: 'enforced', value: intl('VirtualServers.ModeManagedEnforced')},
              {id: 'unmanaged', value: intl('VirtualServers.ModeManagedNotEnforced')},
              {id: null, value: intl('Common.Unmanaged')},
            ],
          },
        },
      },
    ];
  },
);

/**
[{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/

export const gridSettings = createSelector([getLabelsColumn, getIsNewUI], (labelsColumn, isNewUI) => ({
  id: 'virtualserverlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500, 1000],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: getProvisionStatusColumn(isNewUI),
    name: {
      linky: ({row}) => row.data.managed !== intl('Common.Unmanaged'),
      header: intl('Common.Name'),
      value: ({row}) => row.data.name,
    },
    mode: {
      header: intl('Common.Mode'),
      value: 'dvsMode', // e.g. SNAT
      format: ({value}) => value?.toUpperCase(),
    },
    vipPort: {
      header: intl('Common.VIPPort'),
      value: 'vipPort',
      format: ({value}) => (value ? `${value.vip} ${getPort(value.port)} ${lookupProtocol(value.protocol)}` : null),
    },
    manageState: {
      header: intl('VirtualServers.Detail.ManagementState'),
      value: 'mode',
      format: ({row, value}) => (row.data.slb ? getFriendlyMode(value) : intl('VirtualServers.Unassociated')),
    },
    slb: GridUtils.clickableColumn({
      header: intl('VirtualServers.List.SLB'),
      value: 'slb',
      // Create link with custom ref and style that takes up all cell's vertical space
      format: ({value, clickableRef}) => {
        if (!value) {
          return null;
        }

        return (
          <Link
            to="loadBalancers.item"
            params={{id: hrefUtils.getId(value.href), tab: 'summary'}}
            ref={clickableRef}
            theme={styles}
            themePrefix="slb-"
            themeCompose="merge"
          >
            {value.name}
          </Link>
        );
      },
    }),
    labels: labelsColumn,
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['mode'], size: 'minmax(100px, auto)'},
      {columns: ['vipPort'], size: 'minmax(150px, auto)'},
      {columns: ['manageState'], size: 'minmax(150px, auto)'},
      {columns: ['slb'], size: 'minmax(130px, auto)'},
      {columns: ['labels'], size: 'minmax(300px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(120px, auto)'},
            {columns: ['mode'], size: 'minmax(90px, auto)'},
            {columns: ['vipPort'], size: 'minmax(150px, auto)'},
            {columns: ['manageState'], size: 'minmax(150px, auto)'},
            {columns: ['slb'], size: 'minmax(110px, auto)'},
            {columns: ['labels'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(120px, auto)'},
          {columns: ['mode'], size: 'minmax(90px, auto)'},
          {columns: ['vipPort'], size: 'minmax(150px, auto)'},
          {columns: ['manageState'], size: 'minmax(150px, auto)'},
          {columns: ['slb'], size: 'minmax(110px, auto)'},
          {columns: ['labels'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'mode'], size: 'minmax(150px, auto)'},
            {columns: ['vipPort', 'slb'], size: 'minmax(150px, auto)'},
            {columns: ['labels'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'mode'], size: 'minmax(150px, auto)'},
          {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(150px, auto)'},
          {columns: ['labels'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'mode', 'status'], size: 'minmax(100px, auto)'},
            {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
            {columns: ['labels'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'mode', 'status'], size: 'minmax(100px, auto)'},
          {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(140px, auto)'},
        ];
      },
    },
    {
      maxWidth: 660,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'mode', 'status'], size: 'minmax(150px, auto)'},
            {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
            {columns: ['labels'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'mode', 'status'], size: 'minmax(150px, auto)'},
          {columns: ['vipPort', 'slb', 'manageState'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(150px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'mode', 'vipPort', 'slb', 'manageState', 'status'], size: 'minmax(150px, auto)'},
            {columns: ['labels'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'mode', 'vipPort', 'slb', 'manageState', 'status'], size: 'minmax(150px, auto)'},
          {columns: ['labels'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
