/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import styles from './SplitView.css';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import type {TouchEvent, MouseEvent} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import cx from 'classnames';
import useResizeObserver from 'use-resize-observer';

/*

 SplitView

 When two SplitViewPane components are supplied, the first will be given a
 fixed width, and the second will fill up the rest of the available space.
 Between the two panes will be a divider which can be used to adjust the fixed
 width of the first pane.
 +------------------+-+-------------------+
 |                  |d|                   |
 |      first       |i|      second       |
 |  <SplitViewPane> |v|  <SplitViewPane>  |
 |                  |i|                   |
 |   (fixed width)  |d|   (fluid width)   |
 |                  |e|                   |
 |                  |r|                   |
 +------------------+-+-------------------+

 When one SplitViewPane is supplied, the content will be rendered into a single
 fluid width column.
 +----------------------------------------+
 |                                        |
 |               only one                 |
 |            <SplitViewPane>             |
 |                                        |
 |             (fluid width)              |
 |                                        |
 +----------------------------------------+
*/

const DEFAULT_LEFT_PANE_PERCENTAGE_WIDTH = 0.5;
const CURSOR_POSITION_OFFSET_PX = 1; // offset to help keep the resizer handle under the mouse

type SplitViewProps = ThemeProps & {
  children?: React.ReactElement<SplitViewPaneProps> | React.ReactElement<SplitViewPaneProps>[];
  initialLeftPaneWidthPercentage?: number;
  onResizeStarted?: () => void;
  onResizeCompleted?: (leftPaneWidthPercentage: number) => void;
  dividerDropShadowOrientation?: 'left' | 'right';
};

type SplitViewPaneProps = {
  children?: React.ReactNode;
  minWidth?: number;
};

type SplitViewPaneComponentProps = ThemeProps & {
  children?: React.ReactNode;
  width?: number;
  setWidth?: (width: number) => void;
  paneType: 'fixed' | 'fluid';
};

type SplitViewDividerProps = ThemeProps & {
  setWidth: (width: number) => void;
  onMouseDown: (event: React.MouseEvent) => void;
  onTouchEnd: (event: React.TouchEvent) => void;
  onTouchStart: (event: React.TouchEvent) => void;
  dropShadowOrientation: 'left' | 'right';
};

function SplitViewPaneComponent(props: SplitViewPaneComponentProps): React.ReactElement {
  const {theme, children, width, setWidth = () => {}, paneType} = mixThemeWithProps(styles, props);
  const ref = useRef<HTMLDivElement>(null);
  const isFixedPane = paneType === 'fixed';

  useEffect(() => {
    if (ref.current) {
      if (!isFixedPane) {
        ref.current.style.removeProperty('width');

        return;
      }

      if (!width) {
        setWidth(ref.current.clientWidth);

        return;
      }

      ref.current.style.width = `${width}px`;
    }
  }, [ref, width, setWidth, isFixedPane]);

  const paneClassNames = cx(theme.pane, {
    [theme.fixedPane]: isFixedPane,
    [theme.fluidPane]: !isFixedPane,
  });

  return (
    <div ref={ref} className={paneClassNames}>
      {children}
    </div>
  );
}

function SplitViewDivider(props: SplitViewDividerProps): JSX.Element {
  const {onMouseDown, onTouchStart, onTouchEnd, theme, setWidth, dropShadowOrientation} = mixThemeWithProps(
    styles,
    props,
  );
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref, setWidth]);

  const dividerClassNames = cx(theme.divider, {
    [theme.dropShadowOrientationRight]: dropShadowOrientation === 'right',
  });

  return (
    <div ref={ref} className={dividerClassNames}>
      <div
        className={theme.dividerHitBox}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      />
    </div>
  );
}

export function SplitViewPane(props: SplitViewPaneProps): React.ReactNode {
  return props.children;
}

export function SplitView(props: SplitViewProps): JSX.Element {
  const {
    theme,
    initialLeftPaneWidthPercentage = DEFAULT_LEFT_PANE_PERCENTAGE_WIDTH,
    onResizeStarted = () => {},
    onResizeCompleted = () => {},
    dividerDropShadowOrientation = 'left',
  } = mixThemeWithProps(styles, props);

  if (__DEV__) {
    const nChildren = React.Children.count(props.children || []);

    React.Children.forEach(props.children || [], child => {
      if (!React.isValidElement(child)) {
        return;
      }

      // typescript complains that child.type (string) will not have property 'name'
      // @ts-ignore
      const {name: childTypeName} = child.type;

      if (childTypeName !== SplitViewPane.name) {
        throw new TypeError(`SplitView requires children to be of type SplitViewPane, not ${childTypeName}.`);
      }
    });

    if (nChildren > 2) {
      throw new TypeError(`SplitView supports a maximum of 2 SplitViewPane children. ${nChildren} provided.`);
    }
  }

  const [leftPaneContent, rightPaneContent] = React.Children.toArray(props.children);
  const [leftPaneMinWidth = 0, rightPaneMinWidth = 0] = React.Children.map(
    props.children || [],
    child => child?.props?.minWidth ?? 0,
  );
  const splitEnabled = Boolean(leftPaneContent && rightPaneContent);
  const noPanes = Boolean(!leftPaneContent && !rightPaneContent);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRect, setContainerRect] = useState({
    x: 0,
    y: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [leftPaneWidth, setLeftPaneWidth] = useState(0);
  const [dividerWidth, setDividerWidth] = useState(0);
  const [dividerXPosition, setDividerXPosition] = useState(0);
  const [resizing, setResizing] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const {x: containerX, width: containerWidth} = containerRect;

  const startResizing = useCallback(
    (xPosition: number) => {
      setDividerXPosition(xPosition);
      setResizing(true);
      onResizeStarted();
    },
    [setDividerXPosition, setResizing, onResizeStarted],
  );

  const stopResizing = useCallback(() => {
    setResizing(false);
    onResizeCompleted(leftPaneWidth / containerWidth);
  }, [containerWidth, leftPaneWidth, onResizeCompleted, setResizing]);

  const updateLeftPaneWidth = useCallback(
    (newLeftPaneWidth: number) => {
      const newRightPaneWidth = containerWidth - newLeftPaneWidth;

      if (rightPaneMinWidth && newRightPaneWidth < rightPaneMinWidth) {
        setLeftPaneWidth(containerWidth - rightPaneMinWidth);

        return;
      }

      if (leftPaneMinWidth && newLeftPaneWidth < leftPaneMinWidth) {
        setLeftPaneWidth(leftPaneMinWidth);

        return;
      }

      if (newLeftPaneWidth > containerWidth) {
        setLeftPaneWidth(containerWidth);

        return;
      }

      setLeftPaneWidth(newLeftPaneWidth);
    },
    [containerWidth, leftPaneMinWidth, rightPaneMinWidth],
  );

  const onMove = useCallback(
    (xPosition: number) => {
      if (!resizing || !leftPaneWidth || !dividerXPosition) {
        return;
      }

      const newLeftPaneWidth = leftPaneWidth + xPosition - dividerXPosition - dividerWidth + CURSOR_POSITION_OFFSET_PX;

      setDividerXPosition(newLeftPaneWidth);
      updateLeftPaneWidth(newLeftPaneWidth);
    },
    [dividerXPosition, dividerWidth, leftPaneWidth, resizing, setDividerXPosition, updateLeftPaneWidth],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      startResizing(event.clientX - containerX);
    },
    [startResizing, containerX],
  );

  const handleTouchStart = useCallback(
    (event: React.TouchEvent) => {
      startResizing(event.touches[0].clientX - containerX);
    },
    [startResizing, containerX],
  );

  const handleMouseUp = useCallback(() => {
    stopResizing();
  }, [stopResizing]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onMove(event.clientX - containerX);
    },
    [onMove, containerX],
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      onMove(event.touches[0].clientX - containerX);
    },
    [onMove, containerX],
  );

  const updateContainerRect = useCallback(
    (leftPanePercentage?: number) => {
      const newLeftPanePercentage = leftPanePercentage ?? leftPaneWidth / containerWidth;
      let newContainerWidth = containerWidth;

      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();

        newContainerWidth = rect.width;

        setContainerRect(rect);
        updateLeftPaneWidth(newLeftPanePercentage * newContainerWidth);
      }
    },
    [containerRef, containerWidth, leftPaneWidth, updateLeftPaneWidth],
  );

  useEffect(() => {
    if (containerRef?.current) {
      updateContainerRect(initialLeftPaneWidthPercentage);
      setInitialized(true);
    }
  }, [initialized, setInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  useResizeObserver({
    ref: containerRef,
    onResize: () => updateContainerRect(),
  });

  const containerClassNames = cx(theme.container, {
    [theme.resizing]: resizing,
    [theme.splitEnabled]: splitEnabled,
  });

  return (
    <div
      ref={containerRef}
      className={containerClassNames}
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {!noPanes && (
        <>
          <SplitViewPaneComponent
            theme={theme}
            width={leftPaneWidth}
            setWidth={updateLeftPaneWidth}
            paneType={splitEnabled ? 'fixed' : 'fluid'}
          >
            {leftPaneContent}
          </SplitViewPaneComponent>
          {splitEnabled && (
            <>
              <SplitViewDivider
                theme={theme}
                setWidth={setDividerWidth}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleMouseUp}
                dropShadowOrientation={dividerDropShadowOrientation}
              />
              <SplitViewPaneComponent theme={theme} paneType="fluid">
                {rightPaneContent}
              </SplitViewPaneComponent>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SplitView;
