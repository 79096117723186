/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import Area from './GridAreaHead';
import styles from './Grid.css';

export default class GridRowHead extends PureComponent {
  static propTypes = {
    breakpoint: PropTypes.object.isRequired,
    secondary: PropTypes.bool,
    selectableAll: PropTypes.bool,
    selectedAll: PropTypes.bool,
    onSelectAll: PropTypes.func,
    onCellClick: PropTypes.func,
  };

  render() {
    const {
      grid,
      offset,
      breakpoint,
      component,
      theme,
      selectableAll,
      selectedAll,
      onSelectAll,
      onCellClick,
      secondary,
      handleStickyActivate,
      handleStickyDeactivate,
    } = this.props;

    return (
      <div className={theme.rowHead} data-tid="comp-grid-header-row">
        {/* To reflect existence of focuser in body rows for grid-template */}
        <div key="focuser" className={styles.focuser} />
        {breakpoint.columns.map((column, index) => (
          <Area
            key={column.cells?.map(cell => cell.id).join('-') ?? index}
            grid={grid}
            secondary={secondary}
            column={column}
            breakpoint={breakpoint.meta}
            component={component}
            theme={theme}
            offset={offset}
            selectableAll={selectableAll}
            selectedAll={selectedAll}
            onSelectAll={onSelectAll}
            onClick={onCellClick}
            handleStickyActivate={handleStickyActivate}
            handleStickyDeactivate={handleStickyDeactivate}
          />
        ))}
      </div>
    );
  }
}
