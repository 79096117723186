/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getId} from 'utils/href';
import {isUnmanagedEndpoint} from './MapTypes';
import {getItem, setItem} from 'utils/webStorage';

export const explorerPolicyViewOptions = [
  {label: intl('Map.DraftView'), value: 'draft'},
  {label: intl('Map.ReportedView'), value: 'reported'},
];

export const DEFAULT_OPTION_INDEX = 1;
export const explorerTimeOptions = [
  {label: intl('Explorer.LastHours', {count: 1}), value: 'hour', days: 0},
  // EYE-80272 use "Last 24 Hours" in place of "Last Day"
  {
    label: intl('Explorer.LastHours', {count: 24}),
    explorerValue: intl('Explorer.LastDays', {count: 1}),
    value: 'day',
    days: 1,
  },
  {label: intl('Explorer.LastWeeks', {count: 1}), value: 'week', days: 7},
  {label: intl('Explorer.LastMonths', {count: 1}), value: 'month', days: 31},
  {label: intl('DateTimeInput.Anytime'), value: 'anytime', days: 365},
  {label: intl('Common.Custom'), value: 'custom'},
];

export const otherTimeOptions = [{label: intl('Explorer.LastWeeks', {count: 2}), value: 'twoWeeks'}];

export const explorerDirectionOptions = [
  {label: intl('Common.Inbound'), value: 'inbound'},
  {label: intl('Common.Outbound'), value: 'outbound'},
];

export const explorerDataTypeOptions = [
  {label: intl('Explorer.TopPorts'), value: 'port'},
  {label: intl('Common.Workloads'), value: 'workloads'},
];

export const explorerDataTypeDefaultOption = 'port';

export const emptyFilters = () => ({
  consumerInclude: [],
  consumerExclude: [],
  consumerOrProviderInclude: [],
  providerInclude: [],
  providerExclude: [],
  consumerOrProviderExclude: [],
  portsInclude: [],
  portsExclude: [],

  /** @type {Record<string, string[]>} */
  action: {},
  time: intl('Explorer.LastMonths', {count: 1}),
});

export const decodeRouteParams = routeParams => {
  // TODO: unescape special characters after decoding
  return Object.entries(routeParams).reduce((result, [key, value]) => {
    let decoded = decodeURIComponent(value ?? '');

    if (key === 'grouping' && value) {
      const matches = decoded.matchAll(/([^\s,]+),?/g);

      decoded = Array.from(matches).reduce((decoded, match) => {
        const value = (match?.[1] ?? '').trim();

        if (value) {
          decoded.push(value);
        }

        return decoded;
      }, []);
    }

    if (key === 'offset' && value) {
      try {
        decoded = parseInt(value, 10);
      } catch {
        decoded = 0;
      }
    }

    if (value) {
      result[key] = decoded;
    }

    return result;
  }, {});
};

export const encodeRouteParams = routeParams => {
  // TODO: escape special characters before encoding
  return Object.entries(routeParams).reduce((result, [key, value]) => {
    let stringified = value ?? '';

    if (key === 'grouping' && Array.isArray(value)) {
      if (value.length) {
        stringified = value.join(',');
      } else {
        stringified = '[]';
      }
    }

    result[key] = encodeURIComponent(stringified);

    return result;
  }, {});
};

export const getLabelsFromAppGroupHref = (appGroupHref, appGroupLabels) => {
  return appGroupHref.split('x').map(id => appGroupLabels[id]);
};

export const getAppGroupComboId = (appGroupHref, appGroupLabels) => {
  const labels = getLabelsFromAppGroupHref(appGroupHref, appGroupLabels).filter(label => label);

  const labelIds = labels
    .map(label => `${label.label.key}:${getId(label.label.href)}`)
    .sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));

  return `_appGroup_${labelIds.join(',')}`;
};

export const getLabelsCrossProduct = labels => {
  const labelIds = labels
    .map(value => value.id)
    .sort()
    .join('x');

  return labelIds;
};

export const getRouteParamsForAppGroup = (connectedCombo, focusedComboId) => {
  const comboLabelCrossProduct = getLabelsCrossProduct(connectedCombo.labels);
  const connectedRouteParams = connectedCombo.id.includes('source')
    ? {consuming: comboLabelCrossProduct, providing: null}
    : {consuming: null, providing: comboLabelCrossProduct};
  const panelRouteParams = connectedCombo.id.includes('source')
    ? {comboLink: [`${connectedCombo.id};${focusedComboId}_bidirectional`]}
    : {comboLink: [`${focusedComboId};${connectedCombo.id}_bidirectional`]};

  return {connectedCombo: connectedRouteParams, panel: panelRouteParams};
};

export const removeSourceTargetFromString = text => {
  return text.includes('source') ? text.replace('_source', '') : text.replace('_target', '');
};

export const calculateGraphType = routeParams => {
  if (routeParams.display === 'mesh') {
    return 'directional';
  }

  if (routeParams.id) {
    return 'focused';
  }

  return 'bidirectional';
};

export const getFormattedTimeRange = range => ({
  start: intl.date(range.start, intl.formats.date.L_HH_mm),
  end: intl.date(range.end, intl.formats.date.L_HH_mm),
});

export const getCustomRangeLabel = range => {
  const {start: from, end: to} = getFormattedTimeRange(range);

  return `${intl('DateTimeInput.From')}: ${from} ${intl('DateTimeInput.To')}: ${to}`;
};

export const labelTruncationLength = 8;

export const getUnmanagedEndpointsAddresses = (links, dnsLookup = {}) => {
  const addresses = new Set();

  for (const link of links) {
    const {source, target} = link;

    if (isUnmanagedEndpoint(source) && !dnsLookup.hasOwnProperty(source.ip)) {
      addresses.add(source.ip);
    }

    if (isUnmanagedEndpoint(target) && !dnsLookup.hasOwnProperty(target.ip)) {
      addresses.add(target.ip);
    }
  }

  return [...addresses];
};

export const getDismissWarningNotification = () => {
  return getItem('illuminationplus-warning-notification') === 'false';
};

export const getDismissSuperclusterWarning = () => {
  return getItem('illuminationplus-supercluster-warning-notification') === 'false';
};

export const saveDismissWarningNotification = () => {
  setItem('illuminationplus-warning-notification', 'false');
};

export const saveDismissSuperclusterWarning = () => {
  setItem('illuminationplus-supercluster-warning-notification', 'false');
};
