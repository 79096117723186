/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import Selectors from '../Selectors';
import {PureComponent} from 'react';
import {connect} from 'utils/redux';
import {getOrgId} from '../../User/UserState';
import {getNoLabelHref} from '../SelectorUtils';
import {
  isLoadingFacets,
  isLoadingMatches,
  getFacetNameFromArray,
  getMatchesAndFacets,
} from 'containers/Selectors/SelectorState';

const labelKeys = new Set(['role', 'app', 'env', 'loc']);

@connect((state, props) => ({
  orgId: getOrgId(state),
  loading: isLoadingFacets(state) || isLoadingMatches(state),
  matchesAndFacetsMap: getMatchesAndFacets(state, props.objects),
  activeFacet: getFacetNameFromArray(state, props.objects),
}))
export default class ComboSelect extends PureComponent {
  static propTypes = {
    initialItems: PropTypes.array,
    activeCategoryKey: PropTypes.string,
    alwaysActive: PropTypes.bool,
    disabled: PropTypes.bool,
    facets: PropTypes.array,
    hideCategory: PropTypes.bool,
    statics: PropTypes.object,
    scrollable: PropTypes.bool,
    clearAll: PropTypes.bool,

    // categories hidden on initial render, will render 'Show All Categories' option
    hiddenCategories: PropTypes.array,
    categories: PropTypes.array.isRequired,
    includeMatches: PropTypes.array,

    objects: PropTypes.array.isRequired, // list of API objects we display
    creatable: PropTypes.array, // list of object keys that allow creation
    addValue: PropTypes.func,
    placeholder: PropTypes.string,
    footer: PropTypes.any, // custom dropdown footer sent by parent component
    selectedLabels: PropTypes.array, //Sending query param selected_labels to filter facet based on role, env, loc, app (RAEL)

    onSelectionChange: PropTypes.func,
    filterMatches: PropTypes.func, // A callback to filter rendered options

    // global settings
    displayAllCategories: PropTypes.bool, // display all categories in any condition

    // resource type to filter the labels by
    resourceType: PropTypes.string,
    filterLabelsByScope: PropTypes.bool, // default to true. if false, labels will not be filtered by users' existing scopes
    tid: PropTypes.string,
  };

  static defaultProps = {
    alwaysActive: false,
    scrollable: false,
    facets: [],
    disabled: false,
    hideCategory: false,
    displayAllCategories: false,
    clearAll: true,
    includeMatches: [],
    filterLabelsByScope: true,
  };

  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.getStaticValues = this.getStaticValues.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpenCategory = this.handleOpenCategory.bind(this);
  }

  getDropdownValues(matchesAndFacetsMap, facet) {
    if (!matchesAndFacetsMap) {
      return {};
    }

    const {orgId, facets, includeMatches, filterMatches} = this.props;
    const result = {};

    for (const [item, values] of matchesAndFacetsMap) {
      const {count, matches: list = []} = values;
      // matchesAndFacetsMap item will either contain facet or object, get category for object (autocomplete)
      const category = (item.object && this.props.categories.find(current => current.object === item.object)) || {};
      const key = item.facet === facet ? facet : category.categoryKey;

      let matches = list.reduce((result, match) => {
        //Case when API returns values but we want to force filter all values from dropdown
        if (includeMatches === null) {
          return result;
        }

        if (!includeMatches.length || includeMatches.some(item => !item.hasOwnProperty(key) || item[key] === match)) {
          if (typeof match === 'string' || typeof match === 'number') {
            result.push({categoryKey: key, value: String(match)});
          } else {
            result.push({categoryKey: key, ...match});
          }
        }

        return result;
      }, []);

      let additional = 0;

      // if category, such as Role Labels, has static values appended by user, such as 'No Role Labels', add them here
      if (category.statics) {
        const valuesToPrepend = category.statics.map(obj => ({
          categoryKey: key,
          href: !facets.includes(key) && !obj.href && getNoLabelHref(orgId, key),
          ...obj,
        }));

        matches = valuesToPrepend.concat(matches);
        additional = valuesToPrepend.length;
      }

      category.disabled = count === 0;

      result[key] = {
        matches,
        count: count + additional,
      };
    }

    this.props.facets.forEach(facet => {
      result[facet] ||= {matches: [], count: undefined};
    });

    if (typeof filterMatches === 'function') {
      Object.keys(result).forEach(category => {
        if (result[category].count) {
          result[category].matches = result[category].matches.filter(filterMatches);
          result[category].count = result[category].matches.count;
        }
      });
    }

    return result;
  }

  getStaticValues() {
    if (this.props.statics) {
      return Object.entries(this.props.statics).reduce((result, [key, value]) => {
        result[key] = value?.map(value => {
          // sometimes value contains more information, e.g. containerProps which is passed down to Option.js
          if (typeof value === 'object') {
            return {categoryKey: key, ...value};
          }

          return {categoryKey: key, value};
        });

        return result;
      }, {});
    }

    return;
  }

  handleInputChange(input, object, facet, items) {
    this.fetchData(input, object, facet, items);
  }

  handleOpenCategory(facet, items) {
    const {categories, facets} = this.props;
    const category = categories.find(({categoryKey}) => categoryKey === facet);
    const object = category ? category.object : null;

    if (facets.includes(facet) && object) {
      this.fetchData('', object, facet, items);
    }
  }

  // make the correct fetch request for resource objects or facet matches
  fetchData(input, object, facet, items) {
    const {categories, facets, resourceType, filterLabelsByScope} = this.props;

    if (!object || _.isEmpty(object)) {
      return;
    }

    const {type, key, queryArgs, ...params} = object;

    const selectedLabels =
      filterLabelsByScope && Array.isArray(items)
        ? _.orderBy(
            items.filter(({key, href}) => labelKeys.has(key) && href && !href.includes('exists')),
            'key',
          ).map(({href}) => ({
            ...(href.includes('label_group') ? {label_group: {href}} : {label: {href}}),
          }))
        : [];

    // if there's a facet passed back and it's correct, make a facets request for the correct resource
    if (facets.includes(facet) && categories.some(item => item.categoryKey === facet && item.object === object)) {
      const query = {query: input, facet, ...queryArgs};

      if (selectedLabels.length > 0) {
        query.selected_scope = selectedLabels;
      }

      if (type === 'labels' && resourceType) {
        query.resource_type = resourceType;
      }

      this.props.dispatch({type: `${type.toUpperCase()}_FACETS_REQUEST`, object: type, query, params});

      return;
    }

    // otherwise, make a matches request for a resource if it's correct
    const resourceMatch = categories.find(item => item.object === object);

    if (resourceMatch && !facets.includes(resourceMatch.categoryKey)) {
      const query = {query: input, key, ...queryArgs};

      if (selectedLabels.length > 0) {
        query.selected_scope = selectedLabels;
      }

      if (type === 'labels' && resourceType) {
        query.resource_type = resourceType;
      }

      this.props.dispatch({type: `${type.toUpperCase()}_MATCHES_REQUEST`, object: type, query, params});
    }
  }

  render() {
    const {
      initialItems,
      alwaysActive,
      hideCategory,
      hiddenCategories,
      placeholder,
      loading,
      footer,
      customPickers,
      activeCategoryKey,
      displayAllCategories,
      disabled,
      statics,
      activeFacet,
      facets,
      objects,
      partials,
      scrollable,
      clearAll,
      errorMessage,
      noIcon,
      tid,
    } = this.props;

    const matches = this.getDropdownValues(this.props.matchesAndFacetsMap, activeFacet);
    const categories = [...this.props.categories];

    if (hiddenCategories && hiddenCategories.length) {
      categories.push({categoryKey: 'show_all_categories', value: intl('ObjectSelector.ShowAllCategories')});
    }

    const props = {
      initialItems,
      placeholder,
      activeCategoryKey: activeCategoryKey || categories[0].categoryKey,
      disabled,
      hiddenCategories,
      displayAllCategories,
      categories,
      clearAll,
      statics: this.getStaticValues(statics),
      facets,
      matches,
      objects,
      footer,
      loading,
      partials,
      customPickers,
      allowMultipleItems: true,
      hideCategory,
      alwaysActive,
      scrollable,
      showContainerTitle: true,
      showFacetName: true,
      onInputChange: this.handleInputChange,
      onOpenCategory: this.handleOpenCategory,
      onSelectionChange: this.props.onSelectionChange,
      errorMessage,
      noIcon,
      tid,
    };

    return <Selectors {...props} />;
  }
}
