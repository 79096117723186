/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import _ from 'lodash';
import intl from 'intl';
import {Form} from 'components';
import Pill from 'components/Pill/Pill';
import {getDisplayNames, getLabelSelectorOptionsMap} from 'containers/Label/LabelSettings/LabelSettingState';
import {Selector} from 'containers';
import styles from './ContainerClusterContainerWorkloadProfileDetail.css';

const typeSelectorCategories = (typeMap, labelTypesNameObj, usedTypes) => [
  {
    id: 'types',
    name: intl('Common.Type'),
    maxColumns: 2,
    resources: {
      types: {
        statics: Object.entries(labelTypesNameObj).map(([id, value]) => ({value: id, text: value})),
        optionProps: {
          filterOption: type => {
            return !usedTypes.includes(type.value);
          },
          textPath: 'text',
          idPath: 'value',
        },
        selectedProps: {
          isPill: false,
          formatValue: ({value, onRemove, disabled}) => {
            return (
              <Pill onClose={disabled ? undefined : onRemove}>
                {typeMap.get(typeof value === 'object' ? value.value : value)}
              </Pill>
            );
          },
        },
      },
    },
  },
];

export const gridSettings = createSelector(
  [getLabelSelectorOptionsMap, getDisplayNames],
  (typeMap, labelTypesNameObj) => ({
    id: 'profilelabellist',
    getInitialRow: key => ({
      key,
      selectable: true,
      optionValue: 'noLabel',
      data: {key: undefined, multi: [], single: []},
    }),
    capacities: [25, 50, 100, 250, 500],
    capacity: 50,
    maxPage: Number.MAX_SAFE_INTEGER,
    showColumns: false,
    showCapacity: true,
    showPagination: true,
    columns: {
      checkboxes: {},
      type: {
        header: intl('ContainerClusters.LabelType'),
        value: ({row}) => row.data.key,
        format: ({row, value, component}) => {
          const values = new Map();

          if (value) {
            values.set('types', [value]);
          }

          const usedTypes = component.getUsedTypes();

          return (
            <div className={styles.gridCellContainer}>
              <Selector
                tid="type"
                categories={typeSelectorCategories(typeMap, labelTypesNameObj, usedTypes)}
                name={`labels[${row.idx}].data.key`}
                noActiveIndicator
                placeholder={intl('Labels.Create.Placeholder.LabelType')}
                footerProps={{noFilteringTips: true}}
                hideClearAll
                values={values}
                onSelectionChange={_.partial(component.handleTypeChange, row.idx)}
                theme={{selectorContainer: styles.flexGrow}}
              />
            </div>
          );
        },
      },
      optionValue: {
        header: intl('ContainerClusters.LabelAssignType'),
        sortable: false,
        value: ({row}) => row.data.optionValue,
        format: ({row, component}) => {
          return (
            <Form.RadioGroup name={`labels[${row.idx}].optionValue`} onChange={component.handleRadioChange}>
              <Form.Radio tid="multi" value="multi" label={intl('ContainerClusters.UseContainerAnnotations')} />
              <Form.Radio tid="single" value="single" label={intl('ContainerClusters.AssignLabel')} />
              <Form.Radio tid="noLabel" value="noLabel" label={intl('ContainerClusters.NoLabelAllowedHeader')} />
            </Form.RadioGroup>
          );
        },
      },
      labels: {
        header: intl('ContainerClusters.LabelsAllowedAssign'),
        sortable: false,
        format: ({row}) => {
          if (row.optionValue === 'single') {
            return (
              <Form.LabelSelector
                tid="single"
                disabled={row.optionValue !== 'single'}
                hideOptions={row.optionValue !== 'single'}
                name={`labels[${row.idx}].data.single`}
                placeholder={intl('Labels.Select', {multiple: false})}
                config={{
                  hasLabelGroups: false,
                  labelType: row.data.key,
                  allowMultipleSelection: false,
                  allowCreate: {
                    label: {
                      containerProps: {
                        typeIsDisabled: true,
                        getContainerProps: ({query, onDone, onCancel}) => ({
                          label: {
                            value: query,
                            key: typeof row.data.key,
                          },
                          onDone,
                          onCancel,
                        }),
                      },
                    },
                    labelGroup: false,
                  },
                }}
              />
            );
          }

          if (row.optionValue === 'multi') {
            return (
              <Form.LabelSelector
                disabled={row.optionValue !== 'multi'}
                hideOptions={row.optionValue !== 'multi'}
                tid="multi"
                name={`labels[${row.idx}].data.multi`}
                placeholder={intl('Labels.Select', {multiple: true})}
                type={row.data.key}
                config={{
                  hasLabelGroups: false,
                  labelType: row.data.key,
                  allowMultipleSelection: true,
                  allowCreate: {
                    label: {
                      containerProps: {
                        typeIsDisabled: true,
                        getContainerProps: ({query, onDone, onCancel}) => ({
                          label: {
                            value: query,
                            key: row.data.key,
                          },
                          onDone,
                          onCancel,
                        }),
                      },
                    },
                  },
                }}
              />
            );
          }
        },
      },
    },

    templates: [
      [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['type'], size: '250px'},
        {columns: ['optionValue'], size: '250px'},
        {columns: ['labels'], size: 'minmax(250px, auto)'},
      ],
      {
        maxWidth: 1024,
        template() {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type'], size: 'minmax(50px, auto)'},
            {columns: ['optionValue'], size: 'minmax(100px, auto)'},
            {columns: ['labels'], size: 'minmax(100px, auto)'},
          ];
        },
      },
      {
        maxWidth: 640,
        template() {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type'], size: 'minmax(50px, auto)'},
            {columns: ['optionValue'], size: 'minmax(100px, auto)'},
            {columns: ['labels'], size: 'minmax(100px, auto)'},
          ];
        },
      },
      {
        maxWidth: 480,
        template() {
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type'], size: 'minmax(100px, auto)'},
            {columns: ['optionValue'], size: 'minmax(100px, auto)'},
            {columns: ['labels'], size: 'minmax(100px, auto)'},
          ];
        },
      },
    ],
  }),
);
