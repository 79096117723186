/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError from './Application';
import type {RequestInfo} from './Request';
import type {ApplicationErrorOptions, ApplicationErrorJSONRepresent} from './Application';

interface TimeoutErrorJSONRepresent extends ApplicationErrorJSONRepresent {
  timeout?: TimeoutError['timeout'];
}

interface TimeoutErrorOptions extends ApplicationErrorOptions {
  timeout?: number;
  request?: RequestInfo;
}

/**
 * Timeout error
 * Params: timeout number(ms) or object with timeout number property and additional data (can override code, message)
 *
 * @example
 * throw new TimeoutError(5000)
 *
 * @example
 * throw new TimeoutError({timeout: 5000, message: 'Custom message', somedata: {}, somestring: ''})
 */
export default class TimeoutError extends ApplicationError {
  timeout?: number;

  constructor(data: TimeoutErrorOptions | number) {
    if (typeof data === 'number') {
      data = {timeout: data};
    }

    const {timeout, ...details} = data;

    _.defaults(details, {
      code: 'TIMEOUT',
      statusCode: 408,
      message:
        timeout === undefined
          ? 'Timeout'
          : `The request has timed out after ${
              timeout / 1000
            }s. Your connection might be unstable or the server might be busy to respond. Please check the status of your action later and repeat it if necessary.`,
    });

    super(details);
    this.name = 'TimeoutError';

    this.timeout = timeout;
  }

  toJSON(): TimeoutErrorJSONRepresent {
    const {timeout} = this;

    return Object.assign(super.toJSON(), {timeout});
  }
}
