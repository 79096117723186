/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {isNumberOrString, isValidNumber} from '@thor/utils';
import {isPortValidForProtocol, lookupProtocol} from 'containers/Service/ServiceUtils';
import {createSelector} from 'reselect';

/**
 * Port objects
 */
export interface PortObjects<T = number> {
  port: T;
  to_port: number;
  proto: number;
  process_name: string;
  service_name: string;

  protocol?: string | {[port: string]: string};
  protocolNum?: number;
}

/**
 * ICMP code and type. Make IcmpObjects a subtype of PortObjects
 */
export interface IcmpObjects<T = number> extends PortObjects<T> {
  icmp_code: number | string;
  icmp_type: number | string;
}

/**
 * Port Ranges. Make PortRanges a subtypes of PortObjects
 *
 * @example
 * e.g. protocol = {
 *        6: intl('Protocol.TCP'),
 *        17: intl('Protocol.UDP'),
 * }
 */
export interface PortRanges<T = number> extends PortObjects<T> {
  protocol: {[port: string]: string};
}

/** Nominal Types */
export type ValidIcmpTypeCode = IcmpObjects['icmp_type'] & {_brand: 'icmp_type'};
export type ValidPort = PortObjects<number | string>['port'] & {_brand: 'valid_port'};
export type PortRangesEqual = IcmpObjects & {_brand: 'port_ranges_equal'};
export type PortRangesOverlapping = PortRanges & {_brand: 'port_ranges_overlap'};

// 1 => 00001; 123 => 00123
export const stringifyPortForSort = (port: number): string => String(port).padStart(5, '0');

//  1- icmp
// 58 - icmpv6
export const getICMPProtocols = (): number[] => [1, 58];

export const stringifyPortObjectSort = (value: PortObjects): string =>
  [
    value.port >= 0 && stringifyPortForSort(value.port),
    value.to_port && `- ${stringifyPortForSort(value.to_port)}`,
    value.proto && value.proto !== -1 && lookupProtocol(value.proto),
    value.process_name,
    value.service_name,
  ]
    .filter(item => item)
    .join(' ');

// TODO: This function is terrible and hardly maintainable, even the name is wrong, we need to split it
export const stringifyPort = (value: IcmpObjects<number[] | number>): string => {
  if (Array.isArray(value.port) && value.port.length === 1 && value.port[0] === null && value.proto === null) {
    return intl('Common.AllServices');
  }

  const icmp =
    isNumberOrString(value.icmp_type) &&
    value.icmp_type + (isNumberOrString(value.icmp_code) ? `/${value.icmp_code}` : '');

  const portTo = value.to_port;
  const protoCode = value.proto ?? value.protocolNum;
  const protocol = (protoCode && protoCode !== -1 && lookupProtocol(protoCode)) ?? value.protocol;

  let port;

  if (Array.isArray(value.port)) {
    port = value.port.filter(Boolean).join(', ');
  } else if (isPortValidForProtocol(protocol, value.port) === false) {
    port = '';
  } else {
    port = String(value.port);
  }

  return [icmp, [port, portTo].filter(Boolean).join(' - '), protocol].filter(Boolean).join(' ');
};

export const stringifyPortObjectReadonly = (value: IcmpObjects<number[] | number>, port?: string): string =>
  [port ?? stringifyPort(value), value.process_name, value.service_name].filter(Boolean).join(' ');

/**
 *  Check for valid port
 *
 * @param port Port can be a string or number
 * @param min
 * @returns
 */
export const isValidPort = (port: PortObjects<number | string>['port'], min = 0): port is ValidPort => {
  if (_.isNil(port)) {
    return false;
  }

  return isValidNumber(port, min, 65_535);
};

export const isValidIcmpTypeCode = (type: IcmpObjects['icmp_type']): type is ValidIcmpTypeCode =>
  isValidNumber(type, 0, 255);

export const portProtocolRegex = {
  tcpPortProtocol: /\d+\s+(t|tc|tcp)$/i,
  udpPortProtocol: /\d+\s+(u|ud|udp)$/i,
  icmpPortProtocol: /\d+\s+(i|ic|icm|icmp)$/i,
  icmpv6PortProtocol: /\d+\s+(icmpv|icmpv6)$/i,
};

export const portProtocolMap = createSelector([], () => ({
  tcp: {
    text: intl('Protocol.TCP'),
    value: 6,
  },
  udp: {
    text: intl('Protocol.UDP'),
    value: 17,
  },
}));

export const arePortRangesOverlapping = (
  oldRange?: PortRanges,
  newRange?: PortRanges,
): oldRange is PortRangesOverlapping => {
  if (!oldRange || !newRange) {
    return false;
  }

  if (oldRange.protocol !== newRange.protocol) {
    return false;
  }

  const oldService = oldRange.service_name || null;
  const newService = newRange.service_name || null;

  if (oldService !== newService) {
    return false;
  }

  const oldProcess = oldRange.process_name || null;
  const newProcess = newRange.process_name || null;

  if (oldProcess !== newProcess) {
    return false;
  }

  if (
    !oldRange.protocol &&
    !oldService &&
    !oldProcess &&
    _.isNil(oldRange.port) &&
    _.isNil(oldRange.to_port) &&
    !oldRange.process_name &&
    !oldRange.service_name
  ) {
    return false;
  }

  if (oldRange.port === -1 || newRange.port === -1) {
    //-1 means all, so if the protocol, process, and service match its overlapping
    return true;
  }

  if (oldRange.port === undefined && newRange.port === undefined) {
    //if no port defined, then overlapping process name
    return true;
  }

  if (_.isNil(oldRange.to_port) && _.isNil(newRange.to_port)) {
    return oldRange.port === newRange.port;
  }

  if (_.isNil(oldRange.to_port)) {
    return oldRange.port >= newRange.port && oldRange.port <= newRange.to_port;
  }

  if (_.isNil(newRange.to_port)) {
    return newRange.port >= oldRange.port && newRange.port <= oldRange.to_port;
  }

  return oldRange.port <= newRange.to_port && newRange.port <= oldRange.to_port;
};

export const arePortRangesEqual = (oldRange?: IcmpObjects, newRange?: IcmpObjects): oldRange is PortRangesEqual => {
  if (!oldRange && !newRange) {
    return true;
  }

  if (!oldRange || !newRange) {
    return false;
  }

  const compactOldRange = {...oldRange};
  const compactNewRange = {...newRange};

  let kOld: keyof IcmpObjects;

  for (kOld in compactOldRange) {
    if (!compactOldRange[kOld]) {
      delete compactOldRange[kOld];
    }
  }

  let kNew: keyof IcmpObjects;

  for (kNew in compactNewRange) {
    if (!compactNewRange[kNew]) {
      delete compactNewRange[kNew];
    }
  }

  const keysToCompare = ['port', 'to_port', 'icmp_type', 'icmp_code', 'proto', 'service_name', 'process_name'] as const;

  let keyTo: (typeof keysToCompare)[number];

  for (keyTo of keysToCompare) {
    let oldValue = compactOldRange[keyTo];
    let newValue = compactNewRange[keyTo];

    if (keyTo === 'port') {
      // If no port value is present, default all ports, i.e. -1.
      oldValue ||= -1;
      newValue ||= -1;
    }

    if (oldValue !== newValue) {
      return false;
    }
  }

  return true;
};

export const portRegex = '(?::+(\\d+))$';

export const protos = createSelector([], () => ({
  6: intl('Protocol.TCP'),
  17: intl('Protocol.UDP'),
}));

export const portProtocol = (proto: PortObjects['proto']): string | undefined => {
  const protocol = new Map([
    [6, intl('Protocol.TCP')],
    [17, intl('Protocol.UDP')],
  ]);

  return protocol.get(proto);
};
