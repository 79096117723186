/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getPending, getGridSettings, getTotalCount, getFilterMap} from './PendingListState';
import apiSaga from 'api/apiSaga';
import {getProvisionCountsTotal, getProvisionMenuCounts} from 'containers/Provisioning/ProvisioningUtils';
import gridSaga from 'components/Grid/GridSaga';
import {isUserWithReducedScope} from 'containers/User/UserState';
import {RedirectError} from 'errors';
import {pickDefined} from '@thor/utils';

// Fetch list of all pending provisioning objects
export function* fetchPendingObjectList({force = false, priority = null} = {}) {
  if (!(yield select(isUserWithReducedScope))) {
    return yield call(apiSaga, 'sec_policy_pending.get', {
      priority,
      cache: !force,
      *afterFetch({data, count}) {
        if (force || data !== (yield select(getPending)) || count.total !== (yield select(getTotalCount))) {
          yield put({type: 'PROVISION_GET_PENDING', data, count: count.total});
        }

        return data;
      },
    });
  }
}

export function* fetchPendingCount({force = false, priority = null} = {}) {
  if (!(yield select(isUserWithReducedScope))) {
    return yield call(apiSaga, 'sec_policy_pending.get', {
      priority,
      cache: !force,
      query: {max_results: 0},
      *afterFetch({count}) {
        if (force || count.total !== (yield select(getTotalCount))) {
          yield put({type: 'PROVISION_GET_PENDING', count: count.total});
        }

        return count.total;
      },
    });
  }
}

export function* fetchPendingList(route, refetch = false) {
  const userIsWithReducedScope = yield select(isUserWithReducedScope);

  // Users with limited scoped read are not allowed to access this component.
  if (userIsWithReducedScope) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap,
    *onSaga({filterParams}) {
      const filter = filterParams.isEmpty ? undefined : filterParams.valid;
      let [data] = yield all([call(fetchPendingObjectList, {force: refetch}), call(fetchAllUsers, {force: refetch})]);

      if (filter?.type?.length > 0) {
        // currently we only support the type filter
        data = pickDefined(data, [filter.type[0].id]);
      }

      return getProvisionCountsTotal(getProvisionMenuCounts(data));
    },
  });
}
