/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import produce from 'immer';
import {PureComponent} from 'react';
import {MenuInfo, Pill, type PillProps} from 'components/index';
import {hrefUtils} from 'utils';
import {getUpdateTypeProps} from '../PillUtils';
import type {VirtualServer as VirtualServerData} from 'illumio';
import type {IP_IP} from 'utils/ip';

interface VirtualServerProps extends PillProps {
  // Virtual Server object
  value: VirtualServerData;
  contextualMenuFullIp?: IP_IP;

  // policy version
  pversion?: number | string;
}

export default class VirtualServer extends PureComponent<VirtualServerProps> {
  static propTypes = {};

  element: HTMLElement | null | undefined = null;

  constructor(props: VirtualServerProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  renderContextualMenu: PillProps['contextualMenu'] = (items, ...rest) => {
    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      if (this.props.contextualMenuFullIp && firstInfo?.attributes) {
        firstInfo.attributes.push({
          key: intl('Common.IPAddress'),
          value: this.props.contextualMenuFullIp,
        });
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, ...rest) : menu;
  };

  render() {
    const {value, pversion = 'draft', updateTypeTooltip, contextualMenuFullIp, ...pillProps} = this.props;

    Object.assign(pillProps, {
      ref: this.saveRef,
      icon: 'virtual-server',
      link: pillProps.onClick
        ? null
        : {to: 'virtualServers.item.summary', params: {id: hrefUtils.getId(value.href), pversion}},
      ...getUpdateTypeProps({
        object: 'virtualServer',
        updateType: value.update_type,
        updateTypeTooltip,
        deleted: pillProps.deleted,
        pversion,
      }),
    });

    if (!pillProps.noContextualMenu) {
      pillProps.contextualMenu = this.renderContextualMenu;
      pillProps.contextualType ??= intl('Common.VirtualServer');
      pillProps.contextualCopyValue ??= value.name;
    }

    return <Pill {...pillProps}>{value.name}</Pill>;
  }
}
