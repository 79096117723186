/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getRouteCurrentName} from 'containers/App/AppState';
import {getFriendlyName} from 'components/Pill/Workload/WorkloadUtils';

export const getFriendlyMode = mode => {
  switch (mode) {
    case 'unmanaged':
      return intl('VirtualServers.ModeManagedNotEnforced');
    case 'enforced':
      return intl('VirtualServers.ModeManagedEnforced');
    default:
      return intl('Common.Unmanaged');
  }
};

export const getFriendlyModeDesc = mode => {
  switch (mode) {
    case 'unmanaged':
      return intl('VirtualServers.Detail.NotEnforcedDesc');
    case 'enforced':
      return intl('VirtualServers.Detail.EnforcedDesc');
  }
};

export const getPort = port => {
  if (port === '-1') {
    return intl('Protocol.Any');
  }

  return port;
};

export const isOnLoadBalancerPage = createSelector(
  getRouteCurrentName,
  routeName => routeName === 'app.loadBalancers.item.servers',
);

export const fillInLabelObject = labelObject => {
  const newLabelObject = {...labelObject};

  if (_.isEmpty(newLabelObject)) {
    return {};
  }

  newLabelObject.role ||= {value: intl('Common.AllRoles'), key: 'role', categoryKey: 'all_roles'};

  newLabelObject.app ||= {value: intl('Common.AllApplications'), key: 'app', categoryKey: 'all_applications'};

  newLabelObject.env ||= {value: intl('Common.AllEnvironments'), key: 'env', categoryKey: 'all_environments'};

  newLabelObject.loc ||= {value: intl('Common.AllLocations'), key: 'loc', categoryKey: 'all_locations'};

  return newLabelObject;
};

export const generateRulesGridData = (gridData, service, online) => {
  const protoMap = new Map();
  let serviceValue;

  _.forEach(service.ports_and_protocols, ([port, proto = '', toPort] = []) => {
    if (parseInt(port, 10) !== -1) {
      let value = port;

      if (toPort) {
        value = `${value} - ${toPort}`;
      }

      if (protoMap.has(proto)) {
        protoMap.get(proto).push(value);
      } else {
        protoMap.set(proto, [value]);
      }
    }
  });

  if (protoMap.size) {
    serviceValue = Array.from(protoMap, ([proto, port]) => ({proto, port}));
  } else {
    serviceValue = [{name: intl('Common.AllServices')}];
  }

  _.forEach(service.rulesets, ruleset => {
    if (ruleset.peers && ruleset.peers.length) {
      _.forEach(ruleset.peers, peer => {
        switch (peer.type) {
          case 'all':
            gridData.push({
              key: `${ruleset.href}0.0.0.0/0`,
              service: serviceValue,
              ruleset: ruleset.name,
              href: ruleset.href,
              secure_connect: ruleset.secure_connect,
              entity: intl('Common.Anything'),
              ip: '0.0.0.0/0',
              online,
            });
            break;
          case 'workload':
            _.forEach(peer.ips, ip => {
              gridData.push({
                key: ruleset.href + ip,
                service: serviceValue,
                ruleset: ruleset.name,
                href: ruleset.href,
                secure_connect: ruleset.secure_connect,
                entity: getFriendlyName(peer),
                ip,
                online,
              });
            });
            break;
          case 'ip_list':
            _.forEach(peer.ip_ranges, ipRange => {
              gridData.push({
                key: `${ruleset.href}${ipRange.exclusion ? '!' : ''}${ipRange.from_ip}${
                  ipRange.to_ip ? ` - ${ipRange.to_ip}` : ''
                }`,
                service: serviceValue,
                ruleset: ruleset.name,
                href: ruleset.href,
                secure_connect: ruleset.secure_connect,
                entity: peer.name,
                ip: `${ipRange.exclusion ? '!' : ''}${ipRange.from_ip}${ipRange.to_ip ? ` - ${ipRange.to_ip}` : ''}`,
                online,
              });
            });
            break;
          case 'ams':
            gridData.push({
              key: `${ruleset.href}...`,
              service: serviceValue,
              ruleset: ruleset.name,
              href: ruleset.href,
              secure_connect: ruleset.secure_connect,
              entity: intl('Workloads.All'),
              ip: '...',
              online,
            });
            break;
        }
      });
    } else {
      gridData.push({
        key: `${ruleset.href}...`,
        service: serviceValue,
        ruleset: ruleset.name,
        href: ruleset.href,
        secure_connect: ruleset.secure_connect,
        entity: 'no matches',
        ip: '...',
        online,
      });
    }
  });
};
