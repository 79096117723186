/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import _ from 'lodash';
import {useCallback, useContext, useEffect, useMemo, useRef, useState, useLayoutEffect} from 'react';
import {motion} from 'framer-motion';
import {useDeepCompareMemo} from 'utils/react';
import {AppContext} from 'containers/App/AppUtils';
import {domUtils} from 'utils';
import {KEY_RETURN, KEY_TAB} from 'keycode-js';
import {Banner, Icon, Pill, Notifications} from 'components';
import {
  CATEGORYPANEL_ID,
  calculateConflicts,
  getOptionId,
  getOptionById,
  getOptionText,
  getSuggestionText,
  prepareSearchIndex,
  getSearchResult,
  sanitizeOption,
  resolveSelectIntoResource,
} from '../SelectorUtils';
import {useFilialPiety, getHighlightedText} from '../SelectorFormatUtils';
import {fetchResource} from 'containers/Selector/SelectorSaga';
import Option from 'containers/Selector/Option';
import InfoPanel from 'containers/Selector/InfoPanel';
import styleUtils from 'utils.css';

export default function ListResource(props) {
  const {
    query = '',
    keyword,
    id,
    saveRef,
    theme,
    isGridArea,
    insensitive,
    resource: {
      dataProvider,
      statics,
      name,
      showTitle,
      showSubtitle,
      formatTitle,
      infoPanel,
      historyKey,
      enableHistory,
      noEmptyBanner,
      emptyBannerContent,
      sticky,
      optionProps: {
        format,
        idPath,
        textPath,
        filterOption,
        showCheckbox,
        allowMultipleSelection,
        visibilityWhenSelected,
        isPill,
        pillProps,
        horizontal,
      } = {},
      template,
    },
    allResources,
    onMouseLeave,
    onOptionSelect,
    onOptionUnselect,
    history,
    initialLoadParams,
    onSetHighlighted,
    registerHandlers,
    onBack,
    setCategories,
    onReturnFocusToInput,
  } = props;

  const {fetcher} = useContext(AppContext);
  const fetchTaskRef = useRef();

  const noFilter = useDeepCompareMemo(props.resource.optionProps?.noFilter ?? sticky);

  const {saveChildRef, highlightedChild, setHighlightedChild, resetHighlightedChild, highlightedChildRef} =
    useFilialPiety();

  const [optionsObj, setOptionsObj] = useState({}); // {dataProviderOptions, staticsOptions, createOptions, partialOption}
  const [error, setError] = useState(null);

  const resource = useDeepCompareMemo(props.resource);
  const values = useDeepCompareMemo(props.values);
  const selectedValuesInResource = useDeepCompareMemo(values.get(id) ?? []);
  const pathArr = useDeepCompareMemo(props.pathArr);
  const searchParams = useDeepCompareMemo(props.searchParams ?? {});
  const {onInitialLoadDone, onInitialLoadReject} = useDeepCompareMemo(initialLoadParams ?? {});

  const resourceHistory = useMemo(
    () => (enableHistory ? history[historyKey ?? (typeof dataProvider === 'string' ? dataProvider : id)] ?? [] : []),
    [enableHistory, history, id, dataProvider, historyKey],
  );

  /* We do not need to execute fetch task in case of
    1) initial load of resources with only static values (Note: noFilter is passed for static options)
    2) resources that support recently used history and is more than 5, in this case we already have the resource history options available
 */
  const shouldShowRecentlyUsed =
    !query &&
    !keyword &&
    resourceHistory.filter(option => filterOption?.(option, values, resource) ?? true).length >= 5;
  const fetchIsNotNeeded = shouldShowRecentlyUsed || ((!query || noFilter) && !dataProvider && Array.isArray(statics));
  const optionsLoaded = fetchTaskRef.current === undefined ? fetchIsNotNeeded : fetchTaskRef.current === null;

  const allOptionsRef = useRef();

  const {staticsOptions, dataProviderOptions} = optionsObj;

  const filterableOptions = useMemo(
    () =>
      fetchIsNotNeeded
        ? (shouldShowRecentlyUsed ? resourceHistory : statics) ?? []
        : [
            ...((Array.isArray(staticsOptions) ? staticsOptions : staticsOptions?.matches) ?? []),
            ...((Array.isArray(dataProviderOptions) ? dataProviderOptions : dataProviderOptions?.matches) ?? []),
          ],
    [staticsOptions, dataProviderOptions, fetchIsNotNeeded, shouldShowRecentlyUsed, resourceHistory, statics],
  );

  const filteredOptions = useMemo(
    () =>
      filterableOptions.filter(option => {
        const selectIntoResource = resolveSelectIntoResource(allResources, resource, option);

        // the conflicts should be determined based on the "selectIntoResource"
        const conflicts = calculateConflicts(allResources, values, {
          value: sanitizeOption(option),
          resource: selectIntoResource,
        });

        return (
          (filterOption?.(option, values, resource) ?? true) &&
          !conflicts.some(({resolution, selected, incoming}) => {
            // the option is already selected
            const selfConflict = getOptionId(selected.value, idPath) === getOptionId(incoming.value, idPath);

            // filter out "block" conflicting options
            // when showCheckbox, we want to show the selected option too
            return showCheckbox ? resolution === 'block' && !selfConflict : resolution === 'block' || selfConflict;
          })
        );
      }),
    [allResources, filterOption, idPath, resource, showCheckbox, values, filterableOptions],
  );

  const saveRefCallback = useCallback(element => saveRef(id, element), [id, saveRef]);

  const handleMouseOver = useCallback(
    (evt, optionId) => {
      if (optionId !== highlightedChild?.id) {
        // remove previous highlighted and set new highlighted
        onSetHighlighted(evt, {pathArr: [...pathArr, id], newHighlightedId: optionId});
      }
    },
    [id, onSetHighlighted, highlightedChild, pathArr],
  );

  const handleClick = useCallback(
    (evt, optionId) => {
      const option = getOptionById(allOptionsRef.current, optionId, idPath);

      const optionIsSelected = Boolean(
        selectedValuesInResource.some(value => getOptionId(value, idPath) === getOptionId(option, idPath)),
      );

      if (optionIsSelected) {
        // If value is already selected then unselect it on click
        return onOptionUnselect(evt, new Map([[id, [option]]]));
      }

      onOptionSelect(evt, {resourceId: id, value: option, idPath});

      // Return focus to input after an option is selected by mouse click
      onReturnFocusToInput();
    },
    [id, idPath, selectedValuesInResource, onOptionSelect, onOptionUnselect, onReturnFocusToInput],
  );

  const handleKeyDown = useCallback(
    evt => {
      if ((evt.keyCode === KEY_RETURN || evt.keyCode === KEY_TAB) && highlightedChildRef.current) {
        domUtils.clickElement(highlightedChildRef.current.element, evt);
      }
    },
    [highlightedChildRef],
  );

  useLayoutEffect(() => {
    //code tag #register/unregister
    // Note: Use useLayoutEffect to prevent execution overlap between unregister and register of resources with same id
    // For e.g. If we change resource position to sticky when switching between advanced/simplified mode then
    // there may be an overlap in register and unregister execution
    registerHandlers(id, {setHighlightedChild, resetHighlightedChild, keyDown: handleKeyDown}); //Register
  }, [registerHandlers, id, setHighlightedChild, resetHighlightedChild, handleKeyDown]);

  useEffect(() => {
    (async () => {
      try {
        resource.validate?.(query, resource);

        if (fetchIsNotNeeded) {
          setError(null);

          return;
        }

        fetchTaskRef.current = fetcher.fork(fetchResource, {resource, query, keyword, values});

        const response = await fetchTaskRef.current;

        if (typeof response === 'string' && response.includes('CANCEL')) {
          return;
        }

        if (fetchTaskRef.current) {
          // checking fetchTaskRef.current prevents react state update on an unmounted component
          // fetchTaskRef.current is cleared in the cleanup function on unmount
          fetchTaskRef.current = null;
          setOptionsObj(response);
          setError(null);
        }
      } catch (error) {
        fetchTaskRef.current = null;
        setOptionsObj({});
        setError(error.message ?? error);
      }
    })();

    return () => {
      if (fetchTaskRef.current) {
        fetcher.cancel(fetchTaskRef.current);
        fetchTaskRef.current = null;
      }
    };
  }, [query, keyword, values, resource, fetcher, fetchIsNotNeeded]);

  useEffect(() => {
    // Resolve parent promise with new highlighted element
    if (fetchTaskRef.current || _.isEmpty(searchParams)) {
      // options loading is in progress
      return;
    }

    const {onSearchDone, onSearchReject} = searchParams;

    if (error || !query) {
      return onSearchReject?.();
    }

    const {id, optionProps: {idPath, textPath} = {}} = resource;

    const {createOptions, partialOption} = optionsObj;

    if (typeof onSearchDone === 'function') {
      const suggestionResult = {id};

      const createOrPartialOptions = [...(createOptions ?? []), partialOption].filter(Boolean);

      if (filteredOptions.length > 0 || createOrPartialOptions.length > 0) {
        // If only option displayed is to Add new object then simply pass suggestion as empty string
        // because we do not want to add create new hint text as suggestion
        suggestionResult.suggestion = getSuggestionText(query, filteredOptions, textPath) ?? '';

        let foundMatch = filteredOptions[0];

        if (noFilter) {
          const searchIndex = prepareSearchIndex({
            options: filteredOptions.map(option => ({
              ...(typeof option === 'string' ? {} : option),
              value: getOptionText(option, textPath),
            })),
            indexOptions: {document: {id: 'value', index: ['value'], store: true}},
          });

          foundMatch = getSearchResult(searchIndex, query, {enrich: true})[0];
        }

        if (foundMatch || createOrPartialOptions.length > 0) {
          const primaryMatch = {
            id: getOptionId(foundMatch ?? createOrPartialOptions[0], idPath),
            text: getOptionText(foundMatch ?? createOrPartialOptions[0], textPath),
          };

          if (primaryMatch.text.toLowerCase().includes(query.toLowerCase())) {
            suggestionResult.primaryMatch = primaryMatch;
          }
        }

        suggestionResult.pathArr = [...pathArr, id];
        suggestionResult.isCreateOrPartial = filteredOptions.length === 0;
        suggestionResult.isSticky = sticky;
      }

      onSearchDone(suggestionResult);
    }

    return () => onSearchReject?.();
  }, [
    error,
    optionsObj,
    filteredOptions,
    pathArr,
    query,
    resource,
    searchParams,
    selectedValuesInResource,
    values,
    noFilter,
    sticky,
  ]);

  useEffect(() => {
    // Resolve loading done to optionPanel to set initial width
    if (!optionsLoaded) {
      //Loading is in progress
      return;
    }

    onInitialLoadDone?.();

    return onInitialLoadReject?.();
  }, [optionsLoaded, onInitialLoadDone, onInitialLoadReject]);

  // number of filtered options will be subtracted from the total and matched count
  let matchedTotal = filteredOptions.length - filterableOptions.length; // negative offset as a result of filter

  if (Array.isArray(dataProviderOptions)) {
    matchedTotal += dataProviderOptions.length;
  } else if (dataProviderOptions?.num_matches) {
    matchedTotal += dataProviderOptions.num_matches;
  }

  if (Array.isArray(staticsOptions)) {
    matchedTotal += staticsOptions.length;
  } else if (staticsOptions?.num_matches) {
    matchedTotal += staticsOptions.num_matches;
  }

  const matchedDisplayed = filteredOptions.length;

  matchedTotal = Math.max(matchedDisplayed, matchedTotal);

  const title = (
    <div data-tid="comp-selector-title">
      {formatTitle?.(name, {matchedDisplayed, matchedTotal}) ??
        (!shouldShowRecentlyUsed || query
          ? matchedTotal
            ? query
              ? intl('ObjectSelector.MatchedCount', {name, matchedDisplayed, matchedTotal})
              : intl('ObjectSelector.MatchCount', {name, count: matchedDisplayed, total: matchedTotal})
            : name
          : intl('Common.RecentlyUsed', {name}))}
    </div>
  );

  const subTitle =
    !showSubtitle || query
      ? null
      : // Options are contructed based on user input, for e.g. port and/or protocol
        intl(
          typeof resource.statics === 'function'
            ? 'ObjectSelector.TypeToShowObject'
            : 'ObjectSelector.TypeToSearchObject',
          {
            object: name,
          },
        );

  const style = {};

  if (template) {
    style.display = 'grid';
    style.gridTemplate = template;
  }

  if (isGridArea) {
    style.gridArea = id;
  }

  allOptionsRef.current = [
    optionsObj.partialOption,
    ...(query && (optionsObj.createOptions ?? [])),
    ...filteredOptions,
  ].filter(option => Boolean(option) || option === 0);

  const showLoadingSkeleton = // Show loading skeleton only for initial load, skip for statics and recently used render
    allOptionsRef.current.length === 0 &&
    ((fetchTaskRef.current === undefined && !fetchIsNotNeeded) ||
      Boolean(fetchTaskRef.current && dataProvider && onInitialLoadDone));

  const infoPanelContent =
    typeof infoPanel === 'function'
      ? infoPanel({resource, query, keyword, values, options: allOptionsRef.current})
      : infoPanel;

  const renderInfoPanel = () =>
    infoPanelContent || error ? (
      <InfoPanel title={title} showTitle={showTitle} theme={theme} themePrefix="drawer-">
        {infoPanelContent}
        {error && <Notifications>{[{type: 'error', message: error}]}</Notifications>}
      </InfoPanel>
    ) : showTitle ? (
      <div className={theme.infoPanel}>
        {title}
        <div className={theme.resourceSubtitle}>{subTitle}</div>
      </div>
    ) : null;

  const renderOptions = () => (
    <div
      data-tid="comp-selector-listresource-options"
      className={cx(theme.listResource, {
        [styleUtils.gapInline]: horizontal,
        [styleUtils.gapHorizontalWrap]: horizontal,
      })}
    >
      {allOptionsRef.current.map(option => {
        // Set selected to boolean value in for checkbox options i.e. multiple highlighted and noCheckbox is false
        // Otherwise set it undefined to skip rendering checkbox in option
        const isSelected = Boolean(
          selectedValuesInResource.some(value => getOptionId(value, idPath) === getOptionId(option, idPath)),
        );

        const optionProps = {
          tid: 'results-item',
          theme,
          saveRef: saveChildRef,
          onClick: handleClick,
          onMouseOver: handleMouseOver,
          dropdownTippyInstance: props.dropdownTippyInstance,
        };

        optionProps.id = getOptionId(option, idPath);

        optionProps.onMouseLeave = optionProps.id === highlightedChild?.id ? onMouseLeave : undefined;
        optionProps.highlighted = optionProps.id === highlightedChild?.id;
        // Set checked prop to render a checkbox in case of MultiSelect with showCheckbox and when option is not create new option
        optionProps.checked = !optionProps.isCreate && allowMultipleSelection && showCheckbox ? isSelected : undefined;
        optionProps.insensitive = insensitive;

        if (optionProps.checked === undefined && isSelected) {
          if (visibilityWhenSelected === 'insensitive') {
            optionProps.insensitive = true;
          } else if (visibilityWhenSelected === 'disabled') {
            optionProps.disabled = true;
          } else if (visibilityWhenSelected === 'hidden' || optionsObj.history) {
            optionProps.hidden = true;
          }
        }

        // Option hint text i.e. Substring matching query are highlighted with bold style
        const optionText = option.isPartial ? (
          <span className={theme.partialOption}>{`${option.value} (${intl('ObjectSelector.ShowAllMatches')})`}</span>
        ) : (
          getHighlightedText({query, text: getOptionText(option, textPath), bold: true})
        );

        const formattedText = (() => {
          if (option.isCreate) {
            return (
              <div className={theme.createOption}>
                <Icon name="add" theme={theme} themePrefix="createOption-" />
                {optionText}
              </div>
            );
          }

          const renderPill = typeof isPill === 'function' ? isPill(option) : isPill;

          if (renderPill || sticky) {
            const props = {
              theme,
              ...(typeof pillProps === 'function'
                ? pillProps(option, resource, values)
                : {...pillProps, insensitive: optionProps.insensitive, disabled: optionProps.disabled}),
              noContextualMenu: true,
            };

            if (props.disabled) {
              Object.assign(props, {themePrefix: 'pillDisabled-'});
            }

            return <Pill {...props}>{optionText}</Pill>;
          }

          return optionText;
        })();

        const optionCallbackArgs = {
          option,
          optionProps,
          formattedText,
          values,
          resource,
          onSelect: onOptionSelect,
          setCategories,
          onReturnFocusToInput,
          onBack,
        };

        optionProps.text = format?.(optionCallbackArgs) ?? formattedText;

        const tooltipProps =
          typeof resource.optionProps?.tooltipProps === 'function'
            ? resource.optionProps?.tooltipProps(option, resource)
            : resource.optionProps?.tooltipProps;

        if (tooltipProps) {
          const {appearWhen, content, ...restProps} = tooltipProps;

          if (!appearWhen || values.has(appearWhen)) {
            optionProps.tooltip = typeof content === 'function' ? content(optionCallbackArgs) : content;
            optionProps.tooltipProps = restProps;
          }
        }

        return <Option key={option.sortId ?? optionProps.id} {...optionProps} />;
      })}
    </div>
  );

  const content = (() => {
    if (sticky) {
      return allOptionsRef.current.length > 0 ? renderOptions() : null;
    }

    const infoPanel = renderInfoPanel();

    if (showLoadingSkeleton) {
      return (
        <div>
          {infoPanel}
          <motion.div
            animate={{opacity: [null, 0.3, 1]}}
            transition={{repeat: Infinity, duration: 1.5, ease: 'linear'}}
            data-tid="comp-selector-loading"
          >
            {_.times(pathArr.includes(CATEGORYPANEL_ID) ? 1 : 5, index => (
              <div key={index}>
                <div className={theme.loadingLineLong} />
                <div className={theme.loadingLine} />
              </div>
            ))}
          </motion.div>
        </div>
      );
    }

    if (allOptionsRef.current.length) {
      return (
        <>
          {infoPanel}
          {renderOptions()}
          {shouldShowRecentlyUsed && <div className={theme.categoryInfo}>{intl('ObjectSelector.RecentlyUsed')}</div>}
        </>
      );
    }

    if (infoPanel || !noEmptyBanner) {
      return (
        <div>
          {infoPanel}
          {!noEmptyBanner && (
            <Banner type="plain" theme={theme} themePrefix="emptyMessage-">
              {emptyBannerContent ?? (query ? intl('ObjectSelector.NoMatchingResults') : intl('Common.NoData'))}
            </Banner>
          )}
        </div>
      );
    }

    return null;
  })();

  return (
    <div
      ref={saveRefCallback}
      {...(_.isEmpty(style) ? {} : {style})}
      data-tid={`comp-selector-listresource-${_.kebabCase((name ?? id).toLowerCase())}`}
      aria-live="polite"
      aria-busy={!optionsLoaded}
    >
      {content}
    </div>
  );
}
