/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {edge} from 'api/apiUtils';

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_DAY = 86_400;
const SECONDS_PER_MONTH = 2_592_000;
const HOURS_PER_DAY = 24;
const HOURS_PER_WEEK = 168;

export const getSecondsFromUnit = unit => {
  switch (unit) {
    case 'minutes':
      return SECONDS_PER_MINUTE;
    case 'hours':
      return SECONDS_PER_HOUR;
    case 'days':
      return SECONDS_PER_DAY;
    case 'months':
      return SECONDS_PER_MONTH;
    default:
      return 1;
  }
};

export const secondsToUnit = (seconds, unit) => {
  const num = Number(seconds);

  return Math.floor(num / unit);
};

export const unitOptions = createSelector([], () => [
  {value: 'seconds', label: intl('Common.Seconds')},
  {value: 'minutes', label: intl('Common.Minutes')},
  {value: 'hours', label: intl('Common.Hours')},
  {value: 'days', label: intl('Common.Days')},
  {value: 'months', label: intl('OfflineTimers.Months')},
]);

export const uninstallUnitOptions = createSelector([], () => [
  {value: 'hours', label: intl('Common.Hours')},
  {value: 'days', label: intl('Common.Days')},
]);

export const disconnectOptions = createSelector([], () => [
  {value: '3600', label: intl('OfflineTimers.OneHourWait', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: '-1', label: intl('OfflineTimers.NeverCleanUp')},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const disconnectEndpointOptions = createSelector([], () => [
  {value: '86400', label: intl('OfflineTimers.OneDayWait', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: '-1', label: intl('OfflineTimers.NeverCleanUp')},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const disconnectNotificationOptions = createSelector([], () => [
  {value: '-1', label: intl('OfflineTimers.OneQuarterWait', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const disconnectNotificationEndpointOptions = createSelector([], () => [
  {value: '-1', label: intl('OfflineTimers.DisableEndpoint', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const goodbyeOptions = createSelector([], () => [
  {value: '900', label: intl('OfflineTimers.FifteenMinutes', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: '-1', label: intl('OfflineTimers.NeverCleanUp')},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const goodbyeEndpointOptions = createSelector([], () => [
  {value: '86400', label: intl('OfflineTimers.OneDayWait', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: '-1', label: intl('OfflineTimers.NeverCleanUp')},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const uninstallOptions = createSelector([], () => [
  {
    value: '168',
    label: intl('OfflineTimers.SevenDayUninstallWait', {default: edge ? '' : `(${intl('Common.Default')})`}),
  },
  {value: 'custom', label: intl('OfflineTimers.CustomTimeout')},
]);

export const getSecondsFromValues = (number, unit) => {
  let numSeconds;

  if (unit === 'seconds') {
    numSeconds = 1;
  } else if (unit === 'minutes') {
    numSeconds = SECONDS_PER_MINUTE;
  } else if (unit === 'hours') {
    numSeconds = SECONDS_PER_HOUR;
  } else if (unit === 'days') {
    numSeconds = SECONDS_PER_DAY;
  } else if (unit === 'months') {
    numSeconds = SECONDS_PER_MONTH;
  }

  return numSeconds * number;
};

export const getHoursFromValues = (number, unit) => {
  let numHours;

  if (unit === 'hours') {
    numHours = 1;
  } else if (unit === 'days') {
    numHours = HOURS_PER_DAY;
  }

  return numHours * number;
};

export const getDaysFromHours = hours => {
  const num = Number(hours);

  if (hours % HOURS_PER_DAY === 0) {
    return {time: Math.floor(num / HOURS_PER_DAY), unit: 'days'};
  }

  return {time: hours || 0, unit: 'hours'};
};

export const getUnitFromSeconds = seconds => {
  if (!seconds) {
    return {time: seconds || 0, unit: 'seconds'};
  }

  if (seconds % SECONDS_PER_MONTH === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_MONTH), unit: 'months'};
  }

  if (seconds % SECONDS_PER_DAY === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_DAY), unit: 'days'};
  }

  if (seconds % SECONDS_PER_HOUR === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_HOUR), unit: 'hours'};
  }

  if (seconds % SECONDS_PER_MINUTE === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_MINUTE), unit: 'minutes'};
  }

  return {time: seconds || 0, unit: 'seconds'};
};

export const getCustomTimeout = (time, unit) => {
  if (unit === 'seconds') {
    return intl('OfflineTimers.SecondsNumber', {count: time});
  }

  if (unit === 'minutes') {
    return intl('OfflineTimers.MinutesNumber', {count: time});
  }

  if (unit === 'hours') {
    return intl('OfflineTimers.HoursNumber', {count: time});
  }

  if (unit === 'days') {
    return intl('OfflineTimers.DaysNumber', {count: time});
  }

  if (unit === 'months') {
    return intl('OfflineTimers.MonthsNumber', {count: time});
  }
};

export const getSecondsToTimeout = seconds => {
  if (!seconds) {
    return intl('OfflineTimers.SecondsNumber', {count: 0});
  }

  if (seconds % SECONDS_PER_MONTH === 0) {
    return intl('OfflineTimers.MonthsNumber', {count: secondsToUnit(seconds, SECONDS_PER_MONTH)});
  }

  if (seconds % SECONDS_PER_DAY === 0) {
    return intl('OfflineTimers.DaysNumber', {count: secondsToUnit(seconds, SECONDS_PER_DAY)});
  }

  if (seconds % SECONDS_PER_HOUR === 0) {
    return intl('OfflineTimers.HoursNumber', {count: secondsToUnit(seconds, SECONDS_PER_HOUR)});
  }

  if (seconds % SECONDS_PER_MINUTE === 0) {
    return intl('OfflineTimers.MinutesNumber', {count: secondsToUnit(seconds, SECONDS_PER_MINUTE)});
  }

  return intl('OfflineTimers.SecondsNumber', {count: seconds});
};

export const getHoursToTimeout = hours => {
  if (!hours) {
    return intl('OfflineTimers.HoursNumber', {count: 0});
  }

  if (hours % HOURS_PER_DAY === 0) {
    return intl('OfflineTimers.DaysNumber', {count: Math.floor(hours / HOURS_PER_DAY)});
  }

  return intl('OfflineTimers.HoursNumber', {count: hours});
};

// expects value to be in seconds, as per workload_settings API
export const getDisconnectString = (seconds, edge) => {
  if (seconds === 3600) {
    return intl('OfflineTimers.OneHourWait', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (seconds === -1) {
    return intl('OfflineTimers.NeverCleanUp');
  }

  return getSecondsToTimeout(seconds);
};

export const getEndpointDisconnectString = (seconds, edge) => {
  if (seconds === 86_400) {
    return intl('OfflineTimers.OneDayWait', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (seconds === -1) {
    return intl('OfflineTimers.NeverCleanUp');
  }

  return getSecondsToTimeout(seconds);
};

// expects value to be in seconds, as per workload_settings API
export const getGoodbyeString = (seconds, edge) => {
  if (seconds === 900) {
    return intl('OfflineTimers.FifteenMinutes', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (seconds === -1) {
    return intl('OfflineTimers.NeverCleanUp');
  }

  return getSecondsToTimeout(seconds);
};

export const getEndpointGoodbyeString = (seconds, edge) => {
  if (seconds === 86_400) {
    return intl('OfflineTimers.OneDayWait', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (seconds === -1) {
    return intl('OfflineTimers.NeverCleanUp');
  }

  return getSecondsToTimeout(seconds);
};

// expects value to be in seconds, as per workload_settings API
export const getDisconnectNotificationString = (seconds, edge) => {
  if (seconds === -1) {
    return intl('OfflineTimers.OneQuarterWait', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  return getSecondsToTimeout(seconds);
};

export const getDisconnectEndpointNotificationString = (seconds, edge) => {
  if (seconds === -1) {
    return intl('OfflineTimers.DisableEndpoint', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  return getSecondsToTimeout(seconds);
};

export const disconnectDescriptionList = createSelector([], () => ({
  3600: [intl('OfflineTimers.Wait1Hour'), intl('OfflineTimers.CleanUpQuarantineWorkloads1Hour')],
  [-1]: [intl('OfflineTimers.NeverDisconnectOrQuarantine'), intl('OfflineTimers.KeepAllIPsAndNeverAutoRemove')],
  custom: [intl('OfflineTimers.WaitForSpecifiedTime'), intl('OfflineTimers.CleanUpQuarantineWorkloadsSpecificTime')],
}));

export const disconnectEndpointDescriptionList = createSelector([], () => ({
  86_400: [intl('OfflineTimers.Wait1Day'), intl('OfflineTimers.CleanUpQuarantineWorkloadsEndpoint')],
  [-1]: [intl('OfflineTimers.NeverDisconnectOrQuarantineEndpoint'), intl('OfflineTimers.KeepAllIPsAndNeverAutoRemove')],
  custom: [
    intl('OfflineTimers.WaitForSpecifiedTimeEndpoint'),
    intl('OfflineTimers.CleanUpQuarantineWorkloadsEndpointSpecificTime'),
  ],
}));

export const getDisconnectDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let disconnectValue;
  let disconnectString;

  if (unit) {
    disconnectString = getCustomTimeout(time, unit);
    disconnectValue = getSecondsFromValues(time, unit);
  } else {
    disconnectString = getDisconnectString(newTime, edge);
    disconnectValue = disconnectString;
  }

  const key = newTime !== -1 && newTime !== 3600 ? 'custom' : newTime;

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: disconnectValue,
    list: disconnectDescriptionList()[key],
  };
};

export const getEndpointDisconnectDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let disconnectValue;
  let disconnectString;

  if (unit) {
    disconnectString = getCustomTimeout(time, unit);
    disconnectValue = getSecondsFromValues(time, unit);
  } else {
    disconnectString = getEndpointDisconnectString(newTime, edge);
    disconnectValue = disconnectString;
  }

  const key = newTime !== -1 && newTime !== 86_400 ? 'custom' : newTime;

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: disconnectValue,
    list: disconnectEndpointDescriptionList()[key],
  };
};

export const goodbyeDescriptionList = createSelector([], () => ({
  900: [intl('OfflineTimers.ListenGoodbye'), intl('OfflineTimers.FifteenMinutesCleanup')],
  [-1]: [
    intl('OfflineTimers.IgnoreGoodbyeMessages'),
    intl('OfflineTimers.KeepAllIPsAndNeverAutoCleanup'),
    intl('OfflineTimers.RequiresManualRemoval'),
  ],
  custom: [intl('OfflineTimers.ListenGoodbye'), intl('OfflineTimers.WaitForSpecifiedTimeGoodbye')],
}));

export const goodbyeEndpointDescriptionList = createSelector([], () => ({
  86_400: [intl('OfflineTimers.ListenGoodbyeEndpoint'), intl('OfflineTimers.OneDayCleanup')],
  [-1]: [
    intl('OfflineTimers.IgnoreGoodbyeMessagesEndpoint'),
    intl('OfflineTimers.KeepAllIPsAndNeverAutoCleanupEndpoint'),
    intl('OfflineTimers.RequiresManualRemoval'),
  ],
  custom: [intl('OfflineTimers.ListenGoodbyeEndpoint'), intl('OfflineTimers.WaitForSpecifiedTimeGoodbyeEndpoint')],
}));

export const getGoodbyeDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let goodbyeValue;
  let goodbyeString;

  if (unit) {
    goodbyeString = getCustomTimeout(time, unit);
    goodbyeValue = getSecondsFromValues(time, unit);
  } else {
    goodbyeString = getGoodbyeString(newTime, edge);
    goodbyeValue = goodbyeString;
  }

  const key = newTime !== -1 && newTime !== 900 ? 'custom' : newTime;

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: goodbyeValue,
    list: goodbyeDescriptionList()[key],
    note: key === -1 ? intl('OfflineTimers.DecommissionIpCleanupNote') : null,
  };
};

export const getEndpointGoodbyeDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let goodbyeValue;
  let goodbyeString;

  if (unit) {
    goodbyeString = getCustomTimeout(time, unit);
    goodbyeValue = getSecondsFromValues(time, unit);
  } else {
    goodbyeString = getEndpointGoodbyeString(newTime, edge);
    goodbyeValue = goodbyeString;
  }

  const key = newTime !== -1 && newTime !== 86_400 ? 'custom' : newTime;

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: goodbyeValue,
    list: goodbyeEndpointDescriptionList()[key],
    note: key === -1 ? intl('OfflineTimers.DecommissionIpCleanupEndpointNote') : null,
  };
};

export const disconnectNotificationDescriptionList = createSelector([], () => ({
  [-1]: [
    intl('OfflineTimers.WaitOneQuarter'),
    intl('OfflineTimers.NeverTimeoutOrQuarantine'),
    intl('OfflineTimers.DisconnectTimer20minOrLess'),
  ],
  custom: [intl('OfflineTimers.WaitForSpecifiedNotification'), intl('OfflineTimers.CustomNotificationRequirement')],
}));

export const disconnectNotificationeEndpointDescriptionList = createSelector([], () => ({
  custom: [
    intl('OfflineTimers.WaitForSpecifiedNotificationEndpoint'),
    intl('OfflineTimers.CustomNotificationRequirement'),
  ],
}));

export const getDisconnectNotificationDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let disconnectNotificationValue;
  let disconnectNotificationString;

  if (unit) {
    disconnectNotificationString = getCustomTimeout(time, unit);
    disconnectNotificationValue = getSecondsFromValues(time, unit);
  } else {
    disconnectNotificationString = getDisconnectNotificationString(newTime, edge);
    disconnectNotificationValue = disconnectNotificationString;
  }

  const key = newTime === -1 ? newTime : 'custom';

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: disconnectNotificationValue,
    list: disconnectNotificationDescriptionList()[key],
    note: key === -1 ? intl('OfflineTimers.DisconnectQuarantineNote') : null,
  };
};

export const getDisconnectNotificationEndpointDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let disconnectNotificationValue;
  let disconnectNotificationString;

  if (unit) {
    disconnectNotificationString = getCustomTimeout(time, unit);
    disconnectNotificationValue = getSecondsFromValues(time, unit);
  } else {
    disconnectNotificationString = getDisconnectEndpointNotificationString(newTime, edge);
    disconnectNotificationValue = disconnectNotificationString;
  }

  const key = newTime === -1 ? newTime : 'custom';

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: disconnectNotificationValue,
    list: disconnectNotificationeEndpointDescriptionList()[key],
    note: key === -1 ? intl('OfflineTimers.DecommissionIpCleanupEndpointNote') : null,
  };
};

export const getUninstallString = (hours, edge) => {
  if (!hours) {
    return intl('OfflineTimers.HoursNumber', {count: 0});
  }

  if (hours === HOURS_PER_WEEK) {
    return intl('OfflineTimers.SevenDayUninstallWait', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (hours % HOURS_PER_DAY === 0) {
    return intl('OfflineTimers.DaysNumber', {count: Math.floor(hours / HOURS_PER_DAY)});
  }

  return intl('OfflineTimers.HoursNumber', {count: hours});
};

export const uninstallDescriptionList = createSelector([], () => ({
  [HOURS_PER_WEEK]: [
    intl('OfflineTimers.WillWaitSevenDaysUninstall'),
    intl('OfflineTimers.UninstallIfHeartBeatInSevenDays'),
    intl('OfflineTimers.MustManuallyUninstallVenAfterSevenDays'),
  ],
  custom: [
    intl('OfflineTimers.WaitForSpecifiedAfterUnpairedNotification'),
    intl('OfflineTimers.SpecifiedVenHeartbeatIntervalSideNote'),
    intl('OfflineTimers.SpecifiedTimeVenRecordRemovedSideNote'),
  ],
}));

export const getUninstallDescription = (obj, edge) => {
  const {time} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;
  const uninstallValue = getUninstallString(time, edge);
  const key = newTime === HOURS_PER_WEEK ? newTime : 'custom';

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: uninstallValue,
    list: uninstallDescriptionList()[key],
  };
};

export const isValidMaxWaitTime = ({uninstall, inputValue, timeUnit, max}) => {
  return (uninstall ? getHoursFromValues(inputValue, timeUnit) : getSecondsFromValues(inputValue, timeUnit)) <= max;
};

export const isValidWaitTime =
  ({min, max, uninstall}, timeUnit) =>
  inputValue => {
    let isValid = false;
    const value = parseInt(inputValue, 10);
    const timer = {uninstall, inputValue, timeUnit, max};

    if (Number.isNaN(value)) {
      return isValid;
    }

    switch (timeUnit) {
      case 'seconds':
        isValid = value >= min && isValidMaxWaitTime(timer);
        break;
      case 'minutes':
        isValid = value * SECONDS_PER_MINUTE >= min && isValidMaxWaitTime(timer);
        break;
      case 'hours':
        isValid = (uninstall ? value >= min : value * SECONDS_PER_HOUR >= min) && isValidMaxWaitTime(timer);
        break;
      case 'days':
        isValid =
          (uninstall ? value * HOURS_PER_DAY >= min : value * SECONDS_PER_HOUR >= min) && isValidMaxWaitTime(timer);
        break;
      case 'months':
        isValid = value * SECONDS_PER_MONTH >= min && isValidMaxWaitTime(timer);
        break;
      default:
        isValid = value >= 0;
        break;
    }

    return isValid;
  };

const isCustomValid = (
  disconnectTimeoutValue,
  disconnectNotificationTimeoutValue,
  inputValue,
  compareValue,
  isDisconnectTimer,
) => {
  if (disconnectTimeoutValue === '-1' || disconnectNotificationTimeoutValue === '-1') {
    return true;
  }

  if (disconnectTimeoutValue === '3600') {
    return isDisconnectTimer ? inputValue > compareValue : inputValue < disconnectTimeoutValue;
  }

  return isDisconnectTimer ? inputValue > compareValue : inputValue < compareValue;
};

const isCustomEndpointValid = (
  disconnectTimeoutValue,
  disconnectNotificationTimeoutValue,
  inputValue,
  compareValue,
  isDisconnectTimer,
) => {
  if (disconnectTimeoutValue === '-1' || disconnectNotificationTimeoutValue === '-1') {
    return true;
  }

  if (disconnectTimeoutValue === '86400') {
    return isDisconnectTimer ? inputValue > compareValue : inputValue < disconnectTimeoutValue;
  }

  return isDisconnectTimer ? inputValue > compareValue : inputValue < compareValue;
};

export function isValidDisconnect() {
  return (inputValue, {parent}) => {
    const {
      disconnectTimeout,
      disconnectNotificationTimeout,
      customDisconnectUnit,
      customDisconnectNotification,
      customDisconnectNotificationUnit,
    } = parent;

    const disconnectValue = parseInt(inputValue, 10);
    const disconnectValueInSeconds = getSecondsFromValues(disconnectValue, customDisconnectUnit?.value);
    const customDisconnectNotificationValue = parseInt(customDisconnectNotification, 10);
    const customDisconnectNotificationValueInSeconds = getSecondsFromValues(
      customDisconnectNotificationValue,
      customDisconnectNotificationUnit.value,
    );

    return isCustomValid(
      disconnectTimeout?.value,
      disconnectNotificationTimeout?.value,
      disconnectTimeout?.value === '3600' ? parseInt(disconnectTimeout?.value, 10) : disconnectValueInSeconds,
      customDisconnectNotificationValueInSeconds,
      true,
    );
  };
}

export function isValidEndpointDisconnect() {
  return (inputValue, {parent}) => {
    const {
      endpointDisconnectTimeout,
      endpointDisconnectNotificationTimeout,
      customDisconnectUnit,
      customDisconnectNotification,
      customDisconnectNotificationUnit,
    } = parent;

    const disconnectValue = parseInt(inputValue, 10);
    const disconnectValueInSeconds = getSecondsFromValues(disconnectValue, customDisconnectUnit?.value);
    const customDisconnectNotificationValue = parseInt(customDisconnectNotification, 10);
    const customDisconnectNotificationValueInSeconds = getSecondsFromValues(
      customDisconnectNotificationValue,
      customDisconnectNotificationUnit.value,
    );

    return isCustomEndpointValid(
      endpointDisconnectTimeout?.value,
      endpointDisconnectNotificationTimeout?.value,
      endpointDisconnectTimeout?.value === '86400'
        ? parseInt(endpointDisconnectTimeout?.value, 10)
        : disconnectValueInSeconds,
      customDisconnectNotificationValueInSeconds,
      true,
    );
  };
}

export function isValidNotification() {
  return (inputValue, {parent}) => {
    const {
      disconnectTimeout,
      disconnectNotificationTimeout,
      customDisconnectUnit,
      customDisconnect,
      customDisconnectNotificationUnit,
    } = parent;

    const notificationValue = parseInt(inputValue, 10);
    const notificationValueInSeconds = getSecondsFromValues(notificationValue, customDisconnectNotificationUnit?.value);
    const customDisconnectValue = parseInt(customDisconnect, 10);
    const customDisconnectValueInSeconds = getSecondsFromValues(customDisconnectValue, customDisconnectUnit.value);

    return isCustomValid(
      disconnectTimeout?.value,
      disconnectNotificationTimeout?.value,
      notificationValueInSeconds,
      customDisconnectValueInSeconds,
      false,
    );
  };
}

export function isValidEndpointNotification() {
  return (inputValue, {parent}) => {
    const {
      endpointDisconnectTimeout,
      endpointDisconnectNotificationTimeout,
      customDisconnectUnit,
      customDisconnect,
      customDisconnectNotificationUnit,
    } = parent;

    const notificationValue = parseInt(inputValue, 10);
    const notificationValueInSeconds = getSecondsFromValues(notificationValue, customDisconnectNotificationUnit?.value);
    const customDisconnectValue = parseInt(customDisconnect, 10);
    const customDisconnectValueInSeconds = getSecondsFromValues(customDisconnectValue, customDisconnectUnit.value);

    return isCustomEndpointValid(
      endpointDisconnectTimeout?.value,
      endpointDisconnectNotificationTimeout?.value,
      notificationValueInSeconds,
      customDisconnectValueInSeconds,
      false,
    );
  };
}
