/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component, createRef} from 'react';
import {connect} from 'utils/redux';
import {webStorageUtils} from 'utils';
import {isKubernetesSupported} from 'containers/App/AppState';
import {AppContext} from 'containers/App/AppUtils';
import {Icon, MenuDelimiter, MenuItem} from 'components';
import {HeaderMenu} from 'containers';
import {getMainMenu} from './MainMenuState';
import styles from './MainMenu.css';
import {isAPIAvailable} from 'api/apiUtils';

const tid = 'comp-navbar-';

@connect(getMainMenu)
export default class MainMenu extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.iconRef = createRef();
    this.saveIconRef = this.saveIconRef.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleMapSelect = this.handleMapSelect.bind(this);
    this.handleAppMapSelect = this.handleAppMapSelect.bind(this);
    this.handleVulnerabilityMapSelect = this.handleVulnerabilityMapSelect.bind(this);
    this.handleIlluminationPlusSelect = this.handleIlluminationPlusSelect.bind(this);

    if (__ANTMAN__) {
      this.state = {
        menuOpened: false,
      };

      this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }
  }

  getRBACMenuItems() {
    const {isEdge, authenticationSettingsIsEnabled, accessRestrictionIsEnabled} = this.props;

    return (
      <MenuItem text={intl('RBAC.AccessManagement')} data-tid={`${tid}sub-rbac`}>
        {!__MSP__ && !__ANTMAN__ && (
          <MenuItem link="rbac.roles.global" text={intl('RBAC.GlobalRoles')} data-tid={`${tid}pages-rbac-global`} />
        )}
        {!__MSP__ && !__ANTMAN__ && !isEdge && (
          <MenuItem link="rbac.roles.scope" text={intl('Common.Scopes')} data-tid={`${tid}pages-rbac-scope`} />
        )}
        {!__MSP__ && !__ANTMAN__ && !isEdge && <MenuDelimiter />}
        <MenuItem
          link="rbac.users.external.groups"
          text={intl('Users.ExternalGroups')}
          data-tid={`${tid}pages-groups`}
        />
        <MenuItem
          link="rbac.users.external"
          text={intl('Users.ExternalUsers')}
          data-tid={`${tid}pages-users-external`}
          isActive={['rbac.users.external.list', 'rbac.users.external.detail']}
        />
        <MenuItem link="rbac.users.local" text={intl('Users.LocalUsers')} data-tid={`${tid}pages-users`} />
        {!__MSP__ && !isEdge && (
          <MenuItem
            link="rbac.serviceAccounts.list"
            text={intl('Common.ServiceAccounts')}
            data-tid={`${tid}pages-serviceaccount`}
          />
        )}
        {!__MSP__ && <MenuDelimiter />}
        <MenuItem
          link="rbac.users.activity"
          text={intl('RBAC.UsersActivity.UserActivity')}
          data-tid={`${tid}useractivity`}
        />
        {!__MSP__ && <MenuDelimiter />}
        {authenticationSettingsIsEnabled && (
          <MenuItem
            link="authenticationSettings"
            text={intl('Common.Authentication')}
            data-tid={`${tid}pages-authentication-settings`}
          />
        )}
        {!__MSP__ && !__ANTMAN__ && accessRestrictionIsEnabled && (
          <MenuItem
            link="accessRestrictions.list"
            text={intl('AccessRestriction.AccessRestrictionTitle')}
            data-tid={`${tid}pages-access-restriction`}
          />
        )}
      </MenuItem>
    );
  }

  saveIconRef(icon) {
    this.iconRef.current = icon?.element;
  }

  handleIlluminationPlusSelect(evt) {
    const mapRouteParams = webStorageUtils.getSessionItem('mapRouteParams') || {
      id: undefined,
      consuming: undefined,
      providing: undefined,
      offset: undefined,
    };

    this.context.navigate({evt, to: 'illumination', params: mapRouteParams});
  }

  handleMapSelect(evt) {
    const mapRoute = _.get(webStorageUtils.getSessionItem('MapPageStore'), 'mapRoute', {});

    let to;
    let params;

    if (mapRoute.prevtype) {
      to = 'map.prevlevel';
      params = mapRoute;
    } else if (mapRoute.type) {
      to = 'map.level';
      params = mapRoute;
    } else {
      to = 'map';
    }

    this.context.navigate({evt, to, params});
  }

  handleAppMapSelect(evt, version) {
    const {defaultAppMap} = this.props;
    const mapRoute = _.get(webStorageUtils.getSessionItem('MapPageStore'), 'appMapRoute', {});
    const appMapType = webStorageUtils.getItem('recent_app_map_type', {session: true, parse: false});
    const recentIlluminationPlusId = webStorageUtils.getSessionItem('recent_app_map_id');

    if ((!appMapType && defaultAppMap === 'illuminationPlus') || appMapType === 'illuminationPlus') {
      if (recentIlluminationPlusId) {
        this.context.navigate({
          evt,
          to: 'appGroups.detail.illumination',
          params: {id: recentIlluminationPlusId, go: undefined, offset: 0},
        });
      } else {
        this.context.navigate({evt, to: 'appMap'});
      }

      return;
    }

    let to;
    let params;

    if (version !== 'vulnerability') {
      webStorageUtils.setSessionItem('MapPageStore', {appMapVersion: 'policy'});
    }

    webStorageUtils.setSessionItem('app_group_list', 'recents');

    if (mapRoute.prevtype) {
      to = 'appMap.prevlevel';
      params = mapRoute;
    } else if (mapRoute.type) {
      to = 'appMap.level';
      params = mapRoute;
    } else {
      to = 'appMap';
    }

    this.context.navigate({evt, to, params});
  }

  handleVulnerabilityMapSelect(evt) {
    webStorageUtils.setSessionItem('MapPageStore', {appMapVersion: 'vulnerability'});
    this.handleAppMapSelect(evt, 'vulnerability');
  }

  handleMenuToggle() {
    this.setState(state => ({menuOpened: !state.menuOpened}));
  }

  isSettingsEnabled() {
    const {
      eventsConfigIsEnabled,
      securitySettingsIsEnabled,
      venOperationsEnabled,
      authenticationSettingsIsEnabled,
      policySettingsIsEnabled,
      offlineTimersIsEnabled,
      flowCollectionIsEnabled,
      isCoreServicesEnabled,
      isEdge,
      apiKeySettingsIsEnabled,
      isLabelSettingsPageEnabled,
    } = this.props;

    return (
      !__MSP__ &&
      (isEdge ||
        [
          eventsConfigIsEnabled,
          securitySettingsIsEnabled,
          venOperationsEnabled,
          authenticationSettingsIsEnabled,
          policySettingsIsEnabled,
          offlineTimersIsEnabled,
          flowCollectionIsEnabled,
          isAPIAvailable('settings_core_services.update') && isCoreServicesEnabled,
          apiKeySettingsIsEnabled,
          isLabelSettingsPageEnabled,
        ].some(isEnabled => isEnabled))
    );
  }

  infrastructureIsEnabled() {
    const {loadBalancerIsEnabled, containerClustersIsEnabled, secureGatewayIsEnabled, networkIsEnabled, switchEnabled} =
      this.props;

    return [
      loadBalancerIsEnabled,
      containerClustersIsEnabled,
      secureGatewayIsEnabled,
      networkIsEnabled,
      switchEnabled,
    ].some(isEnabled => isEnabled);
  }

  // Pass content as function to prevent creating react elements at the beginning, create only when dropdown is open
  // New tags should exist for 3 months after their release
  // Preview tags should exist as long as the feature is in preview mode
  renderContent() {
    const {
      isUserOwner,
      userIsWithReducedScope,
      vens,
      explorerIsEnabled,
      supportUrl,
      trafficIsEnabled,
      isNetworkEnforcementNodeEnabled,
      isRansomwareReadinessEnabled,
      isReportingEnabled,
      userIsHealthEnabled,
      illuminationMapIsEnabled,
      loadBalancerIsEnabled,
      containerClustersIsEnabled,
      secureGatewayIsEnabled,
      networkIsEnabled,
      switchEnabled,
      eventsIsEnabled,
      segmentationIsTemplatesEnabled,
      pairingProfilesIsEnabled,
      supportReportsIsEnabled,
      pceSupportBundlesIsEnabled,
      eventsConfigIsEnabled,
      securitySettingsIsEnabled,
      venOperationsEnabled,
      policySettingsIsEnabled,
      offlineTimersIsEnabled,
      venLibraryIsEnabled,
      flowCollectionIsEnabled,
      illuminationApiIsEnabled,
      isCoreServicesEnabled,
      isLabelSettingsPageEnabled,
      isLabelSettingsEnabled,
      isUserWorkloadManagerOnly,
      coreServicesSettings,
      isEdge,
      isCrowdstrike,
      apiKeySettingsIsEnabled,
      enforcementBoundaryEnabled,
      myManagedTenantsIsEnabled,
      isAppGroupsEnabled,
      illuminationClassicEnabled,
      defaultIllumination,
    } = this.props;

    if (__ANTMAN__) {
      return (
        <>
          {myManagedTenantsIsEnabled && (
            <MenuItem
              link="mymanagedtenants.list"
              text={intl('MSP.ManagedTenants')}
              data-tid={`${tid}pages-managedtenants`}
            />
          )}
          <MenuItem link="landing" text={intl('Common.Home')} data-tid={`${tid}pages-home`} />
          {!userIsWithReducedScope && (
            <MenuItem
              text={intl('Common.Dashboard')}
              badge={__TARGET__ === 'core' ? 'preview' : null}
              link="dashboard"
              data-tid={`${tid}pages-dashboard`}
            />
          )}
          {!__MSP__ && illuminationApiIsEnabled && illuminationMapIsEnabled && (
            <MenuItem
              text={intl('IlluminationMap.IlluminationClassic')}
              onSelect={this.handleMapSelect}
              data-tid={`${tid}pages-map`}
            />
          )}
          {illuminationApiIsEnabled && illuminationMapIsEnabled && (
            <MenuItem
              onSelect={this.handleIlluminationPlusSelect}
              badge="new"
              text={intl('Common.IlluminationPlus')}
              data-tid={`${tid}pages-illuminationplus`}
            />
          )}
          {!__MSP__ && explorerIsEnabled && (
            <MenuItem link="explorer" text={intl('Common.Explorer')} data-tid={`${tid}pages-traffic-analyzer`} />
          )}
          {!__MSP__ && !isEdge && !userIsWithReducedScope && (
            <MenuItem link="rulesets" text={intl('Rulesets.AndRules')} data-tid={`${tid}sub-rulesets`}>
              <MenuItem link="rulesets" data-tid={`${tid}pages-rulesets`} text={intl('Common.Rulesets')} />
              <MenuItem
                link="policygenerator"
                text={intl('PolicyGenerator.PolicyGenerator')}
                data-tid={`${tid}pages-policygenerator`}
              />
            </MenuItem>
          )}

          {isEdge && (
            <>
              {!isCrowdstrike && (
                <MenuItem
                  link={{to: 'outboundpolicy'}}
                  text={intl('Policy.Organization')}
                  data-tid={`${tid}pages-outbound-policy`}
                />
              )}
              <MenuDelimiter />
            </>
          )}

          {!__MSP__ && (
            <MenuItem
              text={isCrowdstrike ? intl('Common.Workloads') : intl('Common.WorkloadManagement')}
              data-tid={`${tid}sub-workloadmanagement`}
            >
              <MenuItem link="workloads.list" text={intl('Common.Workloads')} data-tid={`${tid}pages-workloads`} />
              {!isCrowdstrike && (
                <MenuItem link="workloads.vens" text={intl('Common.VENS')} data-tid={`${tid}pages-vens`} />
              )}
            </MenuItem>
          )}

          {!__MSP__ && (
            <MenuItem text="Policy Objects" data-tid={`${tid}sub-policy`}>
              <MenuItem link="services" text={intl('Common.Services')} data-tid={`${tid}pages-services`} />
              {!isEdge && <MenuItem link="labels" text={intl('Common.Labels')} data-tid={`${tid}pages-labels`} />}
              <MenuItem
                link="iplists"
                text={intl(isEdge ? 'IPLists.Mixin.Ranges' : 'Common.IPLists')}
                data-tid={`${tid}pages-iplists`}
              />
              {!isEdge && (
                <MenuItem link="labelGroups" text={intl('Labels.Groups')} data-tid={`${tid}pages-labelgroups`} />
              )}
            </MenuItem>
          )}

          {this.isSettingsEnabled() && (
            <MenuItem text={intl('Common.Settings')} data-tid={`${tid}sub-settings`}>
              {isUserOwner && this.getRBACMenuItems()}
              <MenuItem
                link="corporatepublicips"
                text={intl('GlobalNetwork.GlobalIPs')}
                data-tid={`${tid}pages-global-ips`}
              />
              {isEdge && !isCrowdstrike && (
                <MenuItem
                  link={{to: 'venoperations'}}
                  text={__ATR__ ? intl('VEN.VENOperation') : intl('Settings.IKECertificates')}
                  data-tid={__ATR__ ? `${tid}pages-venoperations` : `${tid}pages-ikecertificates`}
                />
              )}
              {!isEdge && userIsHealthEnabled && eventsConfigIsEnabled ? (
                <MenuItem
                  link="events.config"
                  text={intl('Common.EventSettings')}
                  data-tid={`${tid}pages-eventconfig`}
                />
              ) : null}
              {flowCollectionIsEnabled && !isEdge && (
                <MenuItem
                  link="flowCollectionFilters"
                  text={intl('Common.FlowCollectionFilters')}
                  data-tid={`${tid}pages-flow-collection`}
                />
              )}
              {isLabelSettingsPageEnabled && (
                <MenuItem
                  link={{to: 'labelsettings'}}
                  text={intl('Common.LabelSettings')}
                  data-tid={`${tid}pages-labelsettings`}
                />
              )}
              {!isEdge && securitySettingsIsEnabled && (
                <MenuItem
                  link={{to: 'securitysettings'}}
                  text={intl('Common.Security')}
                  data-tid={`${tid}pages-securitysettings`}
                />
              )}
              {!isEdge && isCoreServicesEnabled && (
                <MenuItem
                  link={{to: 'coreservicesettings'}}
                  text={intl('CoreServices.CoreServices')}
                  data-tid={`${tid}pages-coreservicessettings`}
                />
              )}
              {!isEdge && isAPIAvailable('essential_service_rules.get') && (
                <MenuItem
                  link="essentialservicerules"
                  text={intl('Settings.EssentialServiceRules')}
                  data-tid={`${tid}pages-essentialservicerules`}
                />
              )}
              {!isEdge && venOperationsEnabled && (
                <MenuItem
                  link={{to: 'venoperations'}}
                  text={__ATR__ ? intl('VEN.VENOperation') : intl('Settings.IKECertificates')}
                  data-tid={__ATR__ ? `${tid}pages-venoperations` : `${tid}pages-ikecertificates`}
                />
              )}
              {!isEdge && isAPIAvailable('trusted_proxy_ips.get') && (
                <MenuItem
                  link={{to: 'trustedproxy'}}
                  text={intl('TrustedProxy.TrustedProxyIp')}
                  data-tid={`${tid}pages-trustedproxylist`}
                />
              )}
              {(isEdge && isUserOwner) || policySettingsIsEnabled ? (
                <MenuItem link="policySettings" text={intl('Policy.Settings')} data-tid={`${tid}policy-settings`} />
              ) : null}
              {apiKeySettingsIsEnabled && !isEdge && (
                <MenuItem
                  link="apiKeySettings"
                  text={intl('APIKeySettings.APIKeySettings')}
                  data-tid={`${tid}apikey-settings`}
                />
              )}
              {offlineTimersIsEnabled && !isEdge && (
                <MenuItem
                  link="offlineTimers"
                  text={intl('OfflineTimers.OfflineTimers')}
                  data-tid={`${tid}offline-timers`}
                />
              )}
            </MenuItem>
          )}
          {!__MSP__ && <MenuDelimiter />}

          <MenuItem text={intl('Common.AdditionalTools')} data-tid={`${tid}sub-additional-tools`}>
            {eventsIsEnabled && (
              <MenuItem
                link="events"
                text={intl('Common.Events')}
                data-tid={`${tid}pages-orgevents`}
                isActive={['events.list', 'events.detail']}
              />
            )}
            {!__MSP__ && !isCrowdstrike && supportReportsIsEnabled && (
              <MenuItem
                link="supportBundles.ven"
                text={intl('SupportReports.PageName')}
                data-tid={`${tid}pages-vensupportbundle`}
              />
            )}
            <MenuItem link="userGroups" text={intl('Common.UserGroups')} data-tid={`${tid}pages-usergroups`} />
            {venLibraryIsEnabled && vens?.length > 0 && !isCrowdstrike && (
              <MenuItem link="venLibrary" text={intl('VEN.Library')} data-tid={`${tid}pages-vens-library`} />
            )}
            {!isEdge && pairingProfilesIsEnabled && (
              <MenuItem
                link="pairingProfiles"
                text={intl('PairingProfiles.Profiles')}
                data-tid={`${tid}pages-pairingprofiles`}
              />
            )}
            <MenuItem
              text={intl('Common.RuleSearch')}
              link={{to: 'ruleSearch', params: {pversion: 'draft'}}}
              data-tid={`${tid}pages-rulesearch`}
            />
            {!__MSP__ && !isEdge && isAPIAvailable('reports.create') && isReportingEnabled && (
              <MenuItem
                link="reporting.downloads.list"
                text={intl('Common.Reports')}
                badge={isEdge ? 'preview' : null}
                data-tid={`${tid}pages-reporting`}
              />
            )}
            {(!__MSP__ &&
              isAppGroupsEnabled &&
              illuminationApiIsEnabled &&
              (trafficIsEnabled ? (
                <MenuItem text={intl('Common.AppGroups')} data-tid={`${tid}sub-app-map`}>
                  <MenuItem
                    text={intl('Common.AppGroupMap')}
                    onSelect={this.handleAppMapSelect}
                    data-tid={`${tid}pages-app-group-map`}
                  />
                  <MenuItem
                    link="appGroupsList"
                    text={intl('Common.AppGroupsList')}
                    data-tid={`${tid}pages-app-groups`}
                  />
                </MenuItem>
              ) : (
                <MenuItem link="appGroupsList" text={intl('Common.AppGroups')} data-tid={`${tid}pages-app-groups`} />
              ))) ||
              null}
          </MenuItem>

          {!__MSP__ && __DEV__ && (
            <>
              <MenuDelimiter />

              <MenuItem link="components.list" text="Components" />
              <MenuItem link="components.forms" text="Components Forms" />
              <MenuItem text="Page Transitions">
                <MenuItem link="testloader1.testloader2.testloader3" text="Three subroutes, all prefetched by router" />
                <MenuItem text="Three subroutes, first handles sub two prefetch on entry">
                  <MenuItem link="testloader1h.testloader2.testloader3" text="First two" />
                  <MenuItem link="testloader1h.testloader2alt.testloader3alt" text="Second two" />
                </MenuItem>
                <MenuItem text="Three subroutes, first handles sub two prefetch on child transition">
                  <MenuItem link="testloader1ht.testloader2.testloader3" text="First two" />
                  <MenuItem link="testloader1ht.testloader2alt.testloader3alt" text="Second two" />
                </MenuItem>
                <MenuItem text="Three subroutes, first handles sub two prefetch always">
                  <MenuItem link="testloader1ha.testloader2.testloader3" text="First two" />
                  <MenuItem link="testloader1ha.testloader2.testloader3throw" text="First two, second throws" />
                  <MenuItem link="testloader1ha.testloader2alt.testloader3alt" text="Second two" />
                </MenuItem>
              </MenuItem>
            </>
          )}
        </>
      );
    }

    const isVensStatisticsAvailable = isAPIAvailable('vens.statistics');
    const isReportsTimeSeriesStatisticsAvailable =
      isRansomwareReadinessEnabled && isAPIAvailable('reports.time_series_statistics');

    return (
      <>
        {myManagedTenantsIsEnabled && (
          <MenuItem
            link="mymanagedtenants.list"
            text={intl('MSP.ManagedTenants')}
            data-tid={`${tid}pages-managedtenants`}
          />
        )}
        {(__ANTMAN__ || __TARGET__ === 'core') &&
          (isVensStatisticsAvailable || isReportsTimeSeriesStatisticsAvailable) &&
          (this.context.isNewUI ? (
            <MenuItem text={intl('Common.Dashboard')} data-tid={`${tid}sub-dashboard`}>
              {isVensStatisticsAvailable && (
                <MenuItem
                  text={intl('Common.VENS')}
                  badge={__TARGET__ === 'core' ? 'new' : null}
                  link="dashboard"
                  data-tid={`${tid}pages-venstats`}
                />
              )}
              {isRansomwareReadinessEnabled && isReportsTimeSeriesStatisticsAvailable && (
                <MenuItem
                  text={intl('RansomwareDashboard.RansomwareProtection')}
                  badge={__TARGET__ === 'core' ? 'new' : null}
                  link="ransomwareDashboard"
                  data-tid={`${tid}pages-ransomware-dashboard`}
                />
              )}
            </MenuItem>
          ) : (
            <MenuItem text={intl('Common.Dashboard')} data-tid={`${tid}sub-dashboard`}>
              {isVensStatisticsAvailable && (
                <MenuItem
                  text={intl('Common.VENS')}
                  badge={__TARGET__ === 'core' ? 'new' : null}
                  link="dashboard"
                  data-tid={`${tid}pages-venstats`}
                />
              )}
              {isRansomwareReadinessEnabled && isReportsTimeSeriesStatisticsAvailable && (
                <MenuItem
                  text={intl('RansomwareDashboard.RansomwareProtection')}
                  badge={__TARGET__ === 'core' ? 'new' : null}
                  link="ransomwareDashboard"
                  data-tid={`${tid}pages-ransomware-dashboard`}
                />
              )}
            </MenuItem>
          ))}
        {isEdge && <MenuItem link="landing" text={intl('Common.Groups')} data-tid={`${tid}pages-dashboard`} />}
        {!__MSP__ && explorerIsEnabled && isLabelSettingsEnabled && !isUserWorkloadManagerOnly && (
          <MenuItem
            onSelect={this.handleIlluminationPlusSelect}
            badge="new"
            text={intl('Common.IlluminationPlus')}
            data-tid={`${tid}pages-illuminationplus`}
          />
        )}
        {!__MSP__ &&
          illuminationApiIsEnabled &&
          illuminationMapIsEnabled &&
          (defaultIllumination ? defaultIllumination === 'enabled' : illuminationClassicEnabled) && (
            <MenuItem
              text={intl('IlluminationMap.IlluminationClassic')}
              onSelect={this.handleMapSelect}
              data-tid={`${tid}pages-map`}
            />
          )}
        {(!__MSP__ &&
          isAppGroupsEnabled &&
          illuminationApiIsEnabled &&
          (trafficIsEnabled ? (
            <MenuItem text={intl('Common.AppGroups')} data-tid={`${tid}sub-app-map`}>
              <MenuItem
                text={intl('Common.AppGroupMap')}
                onSelect={this.handleAppMapSelect}
                data-tid={`${tid}pages-app-group-map`}
              />
              <MenuItem link="appGroupsList" text={intl('Common.AppGroupsList')} data-tid={`${tid}pages-app-groups`} />
            </MenuItem>
          ) : (
            <MenuItem link="appGroupsList" text={intl('Common.AppGroups')} data-tid={`${tid}pages-app-groups`} />
          ))) ||
          null}
        {!__MSP__ && explorerIsEnabled && (
          <MenuItem link="explorer" text={intl('Common.Explorer')} data-tid={`${tid}pages-traffic-analyzer`} />
        )}
        {!__MSP__ && !isEdge && isAPIAvailable('reports.create') && isReportingEnabled && (
          <MenuItem
            link="reporting.downloads.list"
            text={intl('Common.Reports')}
            badge={isEdge ? 'preview' : null}
            data-tid={`${tid}pages-reporting`}
          />
        )}
        {!__MSP__ && trafficIsEnabled && illuminationApiIsEnabled && (
          <MenuItem
            link="policygenerator"
            text={intl('PolicyGenerator.PolicyGenerator')}
            data-tid={`${tid}pages-policygenerator`}
          />
        )}
        {!__MSP__ && !isEdge && enforcementBoundaryEnabled && (
          <MenuItem
            link="boundaries.list"
            data-tid={`${tid}pages-boundaries-enforcement-rules`}
            text={intl('Workloads.EnforcementBoundaries')}
          />
        )}
        {!__MSP__ && !isEdge && !userIsWithReducedScope && (
          <MenuItem link="rulesets" text={intl('Rulesets.AndRules')} data-tid={`${tid}sub-rulesets`}>
            <MenuItem link="rulesets" data-tid={`${tid}pages-rulesets`} text={intl('Common.Rulesets')} />
            <MenuItem
              text={intl('Common.RuleSearch')}
              link={{to: 'ruleSearch', params: {pversion: 'draft'}}}
              data-tid={`${tid}pages-rulesearch`}
            />
          </MenuItem>
        )}

        {isEdge && (
          <>
            {!isCrowdstrike && (
              <MenuItem
                link={{to: 'outboundpolicy'}}
                text={intl('Policy.Organization')}
                data-tid={`${tid}pages-outbound-policy`}
              />
            )}
            <MenuDelimiter />
          </>
        )}

        {!__MSP__ && (
          <MenuItem
            text={isCrowdstrike ? intl('Common.Workloads') : intl('Common.WorkloadManagement')}
            data-tid={`${tid}sub-workloadmanagement`}
          >
            <MenuItem link="workloads.list" text={intl('Common.Workloads')} data-tid={`${tid}pages-workloads`} />
            {!isEdge && isKubernetesSupported && (
              <MenuItem
                link="workloads.containers"
                text={intl('Common.ContainerWorkloads')}
                data-tid={`${tid}pages-container-workloads`}
              />
            )}
            {!isCrowdstrike && (
              <MenuItem link="workloads.vens" text={intl('Common.VENS')} data-tid={`${tid}pages-vens`} />
            )}
            {!isEdge && pairingProfilesIsEnabled && (
              <MenuItem
                link="pairingProfiles"
                text={intl('PairingProfiles.Profiles')}
                data-tid={`${tid}pages-pairingprofiles`}
              />
            )}
            {venLibraryIsEnabled && vens?.length > 0 && !isCrowdstrike && (
              <MenuItem link="venLibrary" text={intl('VEN.Library')} data-tid={`${tid}pages-vens-library`} />
            )}
          </MenuItem>
        )}

        {!__MSP__ && !isEdge && <MenuDelimiter />}

        {!__MSP__ && (
          <MenuItem text="Policy Objects" data-tid={`${tid}sub-policy`}>
            <MenuItem link="services" text={intl('Common.Services')} data-tid={`${tid}pages-services`} />
            <MenuItem
              link="iplists"
              text={intl(isEdge ? 'IPLists.Mixin.Ranges' : 'Common.IPLists')}
              data-tid={`${tid}pages-iplists`}
            />
            {!isEdge && <MenuItem link="labels" text={intl('Common.Labels')} data-tid={`${tid}pages-labels`} />}
            <MenuItem link="userGroups" text={intl('Common.UserGroups')} data-tid={`${tid}pages-usergroups`} />
            {!isEdge && (
              <>
                <MenuItem link="labelGroups" text={intl('Labels.Groups')} data-tid={`${tid}pages-labelgroups`} />
                {isKubernetesSupported && (
                  <MenuItem
                    link="virtualServices"
                    text={intl('Common.VirtualServices')}
                    data-tid={`${tid}pages-virtualservices`}
                  />
                )}
                <MenuItem
                  link="virtualServers"
                  text={intl('Common.VirtualServers')}
                  data-tid={`${tid}pages-virtualserver`}
                />
                {(isEdge ? !segmentationIsTemplatesEnabled : segmentationIsTemplatesEnabled) && (
                  <MenuItem
                    link="segmentationTemplates"
                    text={intl('SegmentationTemplates.SegmentationTemplates')}
                    data-tid="pages-segmentationtemplates"
                  />
                )}
              </>
            )}
          </MenuItem>
        )}

        {!__MSP__ && !isEdge && this.infrastructureIsEnabled() && (
          <MenuItem text={intl('Menu.Infrastructure')} data-tid={`${tid}sub-network`}>
            {isAPIAvailable('settings_core_services.update') && coreServicesSettings === 'enabled' && (
              <MenuItem
                link="coreservices.list"
                text={intl('CoreServices.CoreServices')}
                data-tid={`${tid}pages-coreservices`}
              />
            )}
            {loadBalancerIsEnabled && (
              <MenuItem
                link="loadBalancers.list"
                text={intl('Menu.LoadBalancers')}
                data-tid={`${tid}pages-loadbalancers`}
              />
            )}
            {containerClustersIsEnabled && (
              <MenuItem
                link="containerClusters.list"
                text={intl('Menu.ContainerClusters', {multiple: true})}
                data-tid={`${tid}pages-containerClusters`}
              />
            )}
            <>
              {secureGatewayIsEnabled && (
                <MenuItem
                  link="secureGateways.list"
                  text={intl('SecureGateway.SecureConnect')}
                  data-tid={`${tid}pages-secureGateways`}
                />
              )}
              {networkIsEnabled && (
                <MenuItem
                  link="networks.list"
                  text={intl('Menu.Networks', {multiple: true})}
                  data-tid={`${tid}pages-networks`}
                />
              )}
              {switchEnabled && isNetworkEnforcementNodeEnabled && (
                <MenuItem link="switches" text={intl('Menu.Switches')} data-tid={`${tid}pages-switches`} />
              )}
            </>
            {!isEdge && (
              <MenuItem
                link={{href: 'https://lumos1.illum.io', target: '_blank'}}
                text={intl('Common.CloudSecure')}
                data-tid={`${tid}cloudsecure`}
              />
            )}
          </MenuItem>
        )}

        {!__MSP__ && <MenuDelimiter />}

        {!__MSP__ && !userIsWithReducedScope && (
          <MenuItem text={intl('Common.Provision')} data-tid={`${tid}sub-provision`}>
            <MenuItem link="pending" text={intl('Provision.DraftChanges')} data-tid={`${tid}pages-provisioning`} />
            <MenuItem
              link="versions"
              text={intl('Provision.Versions', {multiple: true})}
              data-tid={`${tid}pages-versions`}
            />
          </MenuItem>
        )}
        {!__MSP__ && <MenuDelimiter />}

        {isUserOwner && this.getRBACMenuItems()}

        {this.isSettingsEnabled() && (
          <MenuItem text={intl('Common.Settings')} data-tid={`${tid}sub-settings`}>
            <MenuItem
              link="corporatepublicips"
              text={intl('GlobalNetwork.GlobalIPs')}
              data-tid={`${tid}pages-global-ips`}
            />
            {isEdge && !isCrowdstrike && (
              <MenuItem
                link={{to: 'venoperations'}}
                text={intl('VEN.VENOperation')}
                data-tid={`${tid}pages-venoperations`}
              />
            )}
            {!isEdge && userIsHealthEnabled && eventsConfigIsEnabled ? (
              <MenuItem link="events.config" text={intl('Common.EventSettings')} data-tid={`${tid}pages-eventconfig`} />
            ) : null}
            {flowCollectionIsEnabled && !isEdge && (
              <MenuItem
                link="flowCollectionFilters"
                text={intl('Common.FlowCollectionFilters')}
                data-tid={`${tid}pages-flow-collection`}
              />
            )}
            {isLabelSettingsPageEnabled && (
              <MenuItem
                link={{to: 'labelsettings'}}
                text={intl('Common.LabelSettings')}
                data-tid={`${tid}pages-labelsettings`}
              />
            )}
            {!isEdge && securitySettingsIsEnabled && (
              <MenuItem
                link={{to: 'securitysettings'}}
                text={intl('Common.Security')}
                data-tid={`${tid}pages-securitysettings`}
              />
            )}
            {!isEdge && isCoreServicesEnabled && (
              <MenuItem
                link={{to: 'coreservicesettings'}}
                text={intl('CoreServices.CoreServices')}
                data-tid={`${tid}pages-coreservicessettings`}
              />
            )}
            {!isEdge && isAPIAvailable('essential_service_rules.get') && (
              <MenuItem
                link="essentialservicerules"
                text={intl('Settings.EssentialServiceRules')}
                data-tid={`${tid}pages-essentialservicerules`}
              />
            )}
            {!isEdge && venOperationsEnabled && (
              <MenuItem
                link={{to: 'venoperations'}}
                text={intl('VEN.VENOperation')}
                data-tid={`${tid}pages-venoperations`}
              />
            )}
            {!isEdge && isAPIAvailable('trusted_proxy_ips.get') && (
              <MenuItem
                link={{to: 'trustedproxy'}}
                text={intl('TrustedProxy.TrustedProxyIp')}
                data-tid={`${tid}pages-trustedproxylist`}
              />
            )}
            {(isEdge && isUserOwner) || policySettingsIsEnabled ? (
              <MenuItem link="policySettings" text={intl('Policy.Settings')} data-tid={`${tid}policy-settings`} />
            ) : null}
            {apiKeySettingsIsEnabled && !isEdge && (
              <MenuItem
                link="apiKeySettings"
                text={intl('APIKeySettings.APIKeySettings')}
                data-tid={`${tid}apikey-settings`}
              />
            )}
            {offlineTimersIsEnabled && !isEdge && (
              <MenuItem
                link="offlineTimers"
                text={intl('OfflineTimers.OfflineTimers')}
                data-tid={`${tid}offline-timers`}
              />
            )}
          </MenuItem>
        )}
        {!__MSP__ && <MenuDelimiter />}

        <MenuItem text={intl('Common.Troubleshooting')} data-tid={`${tid}sub-troubleshooting`}>
          {!__MSP__ && !isCrowdstrike && explorerIsEnabled && (
            <MenuItem
              link="blockedTraffic"
              text={intl('BlockedTraffic.Name')}
              data-tid={`${tid}pages-blockedtraffic`}
            />
          )}

          {eventsIsEnabled && (
            <MenuItem
              link="events"
              text={intl('Common.Events')}
              data-tid={`${tid}pages-orgevents`}
              isActive={['events.list', 'events.detail']}
            />
          )}
          {!__MSP__ && <MenuItem link="reports" text={intl('Exports.PageName')} data-tid={`${tid}pages-reports`} />}
          {!__MSP__ && !isCrowdstrike && supportReportsIsEnabled && (
            <MenuItem
              link="supportBundles.ven"
              text={intl('SupportReports.PageName')}
              data-tid={`${tid}pages-vensupportbundle`}
            />
          )}
          {!__MSP__ && pceSupportBundlesIsEnabled && (
            <MenuItem
              link="supportBundles.pce"
              text={intl('PCESupportBundles.PageName')}
              data-tid={`${tid}pages-pcesupportbundle`}
            />
          )}
          {!__MSP__ && !userIsWithReducedScope && !isEdge && (
            <MenuItem link="connectivity" text={intl('Policy.Check')} data-tid={`${tid}pages-connectivity`} />
          )}
          <MenuItem
            link={{params: {showVersion: true}, replace: true}}
            text={intl('Common.ProductVersion')}
            data-tid={`${tid}about`}
          />
        </MenuItem>

        <MenuItem
          link={{href: supportUrl, target: '_blank'}}
          text={intl('Common.Support')}
          data-tid={`${tid}pages-support`}
        />

        {!__MSP__ && __DEV__ && (
          <>
            <MenuDelimiter />

            <MenuItem link="components.list" text="Components" />
            <MenuItem link="components.forms" text="Components Forms" />
            <MenuItem text="Page Transitions">
              <MenuItem link="testloader1.testloader2.testloader3" text="Three subroutes, all prefetched by router" />
              <MenuItem text="Three subroutes, first handles sub two prefetch on entry">
                <MenuItem link="testloader1h.testloader2.testloader3" text="First two" />
                <MenuItem link="testloader1h.testloader2alt.testloader3alt" text="Second two" />
              </MenuItem>
              <MenuItem text="Three subroutes, first handles sub two prefetch on child transition">
                <MenuItem link="testloader1ht.testloader2.testloader3" text="First two" />
                <MenuItem link="testloader1ht.testloader2alt.testloader3alt" text="Second two" />
              </MenuItem>
              <MenuItem text="Three subroutes, first handles sub two prefetch always">
                <MenuItem link="testloader1ha.testloader2.testloader3" text="First two" />
                <MenuItem link="testloader1ha.testloader2.testloader3throw" text="First two, second throws" />
                <MenuItem link="testloader1ha.testloader2alt.testloader3alt" text="Second two" />
              </MenuItem>
            </MenuItem>
          </>
        )}
      </>
    );
  }

  render() {
    const headerMenu = (
      <HeaderMenu
        arrowPointsTo={this.iconRef}
        icon={<Icon ref={this.saveIconRef} name="menu" />}
        theme={styles}
        tid="pages"
        {...(__ANTMAN__ && {
          onOpen: this.handleMenuToggle,
          onClose: this.handleMenuToggle,
        })}
      >
        {this.renderContent}
      </HeaderMenu>
    );

    return headerMenu;
  }
}
