/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import apiSaga from 'api/apiSaga';
import {call, all, put, select, spawn} from 'redux-saga/effects';
import gridSaga from 'components/Grid/GridSaga';
import {fetchDefaultServices} from 'containers/Service/ServiceSaga';
import {fetchServiceFacet} from 'containers/Service/List/ServiceListSaga';
import {fetchIPListFacet} from 'containers/IPList/List/IPListListSaga';
import {getOrgId} from 'containers/User/UserState';
import {
  getOutboundAllowRulesetId,
  getAllowRulesDetail,
  getDenyRulesDetail,
  getChangeSubsets,
  getAllServicesAllIPsHref,
} from './OutboundPolicyState';
import {allowRulesGridSettings, denyRulesGridSettings, getSelectorSettings} from './OutboundPolicyConfig';
import {getParameterizedPath, schemaMethodsMap} from 'api/apiUtils';
import {hrefUtils} from 'utils';
import * as generalUtils from '@thor/utils';
import {RedirectError} from 'errors';
import {fetchPending} from 'containers/Provisioning/ProvisioningSaga';

// this is the ruleset in which all outbound rules live
// you can CRUD rules within ruleset, but the ruleset can't be deleted or modified
const ALLOW_RULESET_ID = 'outbound_allow_ruleset';

export function* fetchOutboundAllowRuleset() {
  yield call(apiSaga, 'rule_sets.get_instance', {
    query: {representation: 'rule_set_services_labels_and_names'},
    params: {pversion: 'draft', rule_set_id: ALLOW_RULESET_ID},
    *onDone({data}) {
      yield put({type: 'OUTBOUND_POLICY_ALLOWRULESET', data});
    },
  });
}

export function* fetchRules({type = 'allow', force = false, pversion = 'draft', filter = {}, query} = {}) {
  const {staticValues} = getSelectorSettings();
  let outboundAllowRulesetHref;
  const payload = {};

  if (type === 'allow') {
    const outboundAllowRulesetId = yield select(getOutboundAllowRulesetId);

    outboundAllowRulesetHref = getParameterizedPath({
      path: schemaMethodsMap.get('rule_sets.get_instance').path,
      orgId: yield select(getOrgId),
      params: {rule_set_id: outboundAllowRulesetId, pversion},
    });

    payload.rule_set = {href: outboundAllowRulesetHref};
  }

  if (filter.labels) {
    payload.consumers = filter.labels.map(({href}) => ({label: {href}}));
  }

  if (filter.all_groups) {
    payload.consumers ??= [];
    payload.consumers.push({actors: 'ams'});
  }

  if (!_.isEmpty(query)) {
    payload.consumers ??= [];
    payload.consumers.push({label: {href: query[0]?.href ?? query?.xxxlabels?.[0]?.[0]}});
  }

  if (filter.ip_lists) {
    payload.providers = filter.ip_lists.map(({href}) => ({ip_list: {href}}));
  }

  if (filter.services || filter.portProto) {
    if (filter.services) {
      payload.ingress_services = filter.services.map(({href}) => ({href}));
    }

    if (filter.portProto) {
      payload.ingress_services = [...(payload.ingress_services ?? []), ...filter.portProto.map(({detail}) => detail)];
    }

    if (type === 'allow') {
      payload.exact_service_match = false;
    }
  }

  if (filter.networkType) {
    payload.network_type = staticValues[filter.networkType[0].categoryKey][filter.networkType[0].value];
  }

  const apiMethodName =
    type === 'allow' ? 'sec_policy_rule_search.create' : 'sec_policy_enforcement_boundary_search.create';

  const [{data: draftRules}, {data: pversionRules}, {data: prevPversionRules} = {}] = yield all([
    call(apiSaga, apiMethodName, {
      // No Caching for unfiltered draft rules to enable re-rendering the view if any one of the dependencies e.g. service/ip range has draft changes
      ignoreCodes: [404],
      params: {pversion: 'draft'},
      data: type === 'allow' ? {rule_set: {href: outboundAllowRulesetHref}} : {},
    }),
    call(apiSaga, apiMethodName, {
      ignoreCodes: [404],
      cache: !force, // Cache other data so that any pagination /row capacity changes does not refetch api
      params: {pversion},
      data: payload,
    }),
    ...(pversion === 'draft'
      ? [
          call(apiSaga, apiMethodName, {
            ignoreCodes: [404],
            cache: !force, // Cache other data so that any pagination /row capacity changes does not refetch api
            params: {pversion: 'active'},
            data: type === 'allow' ? {rule_set: {href: outboundAllowRulesetHref}} : {},
          }),
        ]
      : []),
  ]);

  const cached = type === 'allow' ? yield select(getAllowRulesDetail) : yield select(getDenyRulesDetail);

  const data = {
    draftRules,
    pversionRules,
    prevPversionRules,
  };

  // Set the reducer for draftRules, activeRules, oldPversionRules
  // Edit, View pages
  if (
    !cached ||
    draftRules !== cached.draftRules ||
    pversionRules !== cached.pversionRules ||
    prevPversionRules !== cached.prevPversionRules
  ) {
    yield put({type: `OUTBOUND_POLICY_${type.toUpperCase()}_RULES`, data});
  }

  return data;
}

export function* createAllowRule({pversion = 'draft', ...newRule}) {
  const outboundAllowRulesetId = yield select(getOutboundAllowRulesetId);
  let error;

  try {
    yield call(apiSaga, 'sec_rules.create', {
      params: {
        pversion,
        rule_set_id: outboundAllowRulesetId,
      },
      data: {
        enabled: true,
        resolve_labels_as: {providers: ['workloads'], consumers: ['workloads']},
        ...newRule,
      },
      *onDone() {
        yield call(fetchOutboundPolicyPage.refetch);
      },
    });
  } catch (err) {
    const errData = _.get(err, 'data[0]');

    error = (errData && errData.message) || err.message;
  }

  return error;
}

export function* createDenyRule({pversion = 'draft', ...newRule}) {
  let error;

  try {
    yield call(apiSaga, 'enforcement_boundaries.create', {
      params: {
        pversion,
      },
      data: {
        name: generalUtils.randomString(8), // enforcement boundaries requires a name - not useful for outbound policy
        ...newRule,
      },
      *onDone() {
        yield call(fetchOutboundPolicyPage.refetch);
      },
    });
  } catch (err) {
    const errData = _.get(err, 'data[0]');

    error = (errData && errData.message) || err.message;
  }

  return error;
}

export function* updateAllowRule({href: ruleHref, ...updatedRule}) {
  const outboundAllowRulesetId = yield select(getOutboundAllowRulesetId);
  let error;

  try {
    yield call(apiSaga, 'sec_rule.update', {
      params: {
        pversion: 'draft',
        rule_set_id: outboundAllowRulesetId,
        sec_rule_id: hrefUtils.getId(ruleHref),
      },
      data: {
        enabled: true,
        resolve_labels_as: {providers: ['workloads'], consumers: ['workloads']},
        ...updatedRule,
      },
      *onDone() {
        yield call(fetchOutboundPolicyPage.refetch);
      },
    });
  } catch (err) {
    const errData = _.get(err, 'data[0]');

    error = (errData && errData.message) || err.message;
  }

  return error;
}

export function* updateDenyRule({href, ...updatedRule}) {
  let error;

  try {
    yield call(apiSaga, 'enforcement_boundary.update', {
      params: {
        pversion: 'draft',
        enforcement_boundary_id: hrefUtils.getId(href),
      },
      data: {
        ...updatedRule,
      },
      *onDone() {
        yield call(fetchOutboundPolicyPage.refetch);
      },
    });
  } catch (err) {
    const errData = _.get(err, 'data[0]');

    error = (errData && errData.message) || err.message;
  }

  return error;
}

export function* deleteAllowRules({hrefs}) {
  const outboundAllowRulesetId = yield select(getOutboundAllowRulesetId);

  const removed = [];
  const errors = new Map();

  yield all(
    hrefs.map(function* (href) {
      try {
        // Note: When deleting enforcement_boundary all version is 'draft'
        yield call(apiSaga, 'sec_rule.delete', {
          params: {
            pversion: 'draft',
            sec_rule_id: hrefUtils.getId(href),
            rule_set_id: outboundAllowRulesetId,
          },
        });
        removed.push(href);
      } catch (err) {
        const errData = _.get(err, 'data[0]');
        const message = (errData && errData.message) || err.message;
        const hrefErrors = errors.get(message) || [];

        hrefErrors.push(href);
        errors.set(message, hrefErrors);
      }
    }),
  );

  return {removed, errors};
}

export function* deleteDenyRules({hrefs}) {
  const removed = [];
  const errors = new Map();

  yield all(
    hrefs.map(function* (href) {
      try {
        // Note: When deleting enforcement_boundary all version is 'draft'
        yield call(apiSaga, 'enforcement_boundary.delete', {
          params: {pversion: 'draft', enforcement_boundary_id: hrefUtils.getId(href)},
        });
        removed.push(href);
      } catch (err) {
        const errData = _.get(err, 'data[0]');
        const message = (errData && errData.message) || err.message;
        const hrefErrors = errors.get(message) || [];

        hrefErrors.push(href);
        errors.set(message, hrefErrors);
      }
    }),
  );

  return {removed, errors};
}

export function* fetchAllServicesAllIPsHrefs() {
  let allServicesAllIPsHrefObj = yield select(getAllServicesAllIPsHref);

  if (_.isEmpty(allServicesAllIPsHrefObj)) {
    const [serviceData, ipRangeData] = yield all([
      call(fetchServiceFacet, {
        autocomplete: true,
        query: {facet: 'name', query: intl('Common.AllServices')},
        params: {pversion: 'active'},
      }),
      call(fetchIPListFacet, {
        autocomplete: true,
        query: {facet: 'name', query: intl('IPLists.Any')},
        params: {pversion: 'active'},
      }),
    ]);

    allServicesAllIPsHrefObj = {
      allIPsHref: ipRangeData?.data.matches[0]?.href,
      allServicesHref: serviceData?.data.matches[0]?.href,
    };
    yield put({type: 'GET_ALL_SERVICES_AND_ANYIP', data: allServicesAllIPsHrefObj});
  }

  return allServicesAllIPsHrefObj;
}

// get service definitions for "suggested services" category in selector dropdown
export function* fetchOutboundPolicyPage(route, refetch = false, options = {}) {
  const pversion = route.params.pversion;
  const query = {};
  const {customScope} = options;

  if (customScope) {
    // no scope for detail page
    query.xxxlabels = [customScope.map(obj => obj.href)];
  }

  // Redirect to draft if pversion is invalid
  const validPversion =
    pversion === 'active' || pversion === 'draft' || (Number.isInteger(Number(pversion)) && pversion > 0);

  if (!validPversion) {
    throw new RedirectError({params: {pversion: 'draft'}, proceedFetching: true});
  }

  // Fetch helper data
  yield spawn(fetchDefaultServices);
  yield spawn(fetchPending);
  yield spawn(fetchAllServicesAllIPsHrefs);

  yield call(gridSaga, {
    route,
    settings: allowRulesGridSettings,
    filterMap: getSelectorSettings().filterMap,
    *onSaga({filterParams}) {
      const filter = filterParams.isEmpty ? undefined : filterParams.valid;

      // onSaga returns the length for pagination
      const {pversionRules = []} = yield call(fetchRules, {
        route,
        type: 'allow',
        pversion,
        filter,
        query,
        force: refetch,
      });

      return pversionRules.length;
    },
  });

  yield call(gridSaga, {
    route,
    settings: denyRulesGridSettings,
    filterMap: getSelectorSettings().filterMap,
    *onSaga({filterParams}) {
      const filter = filterParams.isEmpty ? undefined : filterParams.valid;

      const {pversionRules = []} = yield call(fetchRules, {
        route,
        type: 'deny',
        pversion,
        filter,
        query,
        force: refetch,
      });

      return pversionRules.length;
    },
  });

  if (pversion === 'active') {
    const {objectsToProvision} = yield select(getChangeSubsets);

    if (_.isEmpty(objectsToProvision)) {
      throw new RedirectError({
        params: {...route.params, pversion: 'draft'},
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    }
  }
}
