/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import stringify from 'safe-stable-stringify';
import intl from 'intl';
import {Pill, Form} from 'components';
import {object, boolean, array} from 'yup';
import styleUtils from 'utils.css';
import styles from './SecuritySettings.css';
import attributeListStyles from 'components/AttributeList/AttributeList.css';
import {sortAndStringifyArray} from '@thor/utils';
import {createSelector} from 'reselect';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';
import {getSortedLabelsAndLabelGroups} from 'containers/Label/LabelSettings/LabelSettingsUtils';

const modifyScopeSchemasObject = createSelector([], () => ({
  labels: array().required(intl('Firewall.ScopeRequired')),
}));
const baseProtectionSchemas = object({
  ipv6: Form.Radio.schema.oneOf(['allowed', 'blocked']).required(),
  ipv6_mode: Form.Radio.schema.oneOf(['policy_based', 'block_all']).required(),
});
const scopeAndLabelsSchemas = object({
  scopeKeysChanged: boolean().oneOf([true]),
});
const ikeAuthTypeSchemas = object({
  ipsecAuthority: Form.Radio.schema.oneOf(['psk', 'certificate']).required(),
});
const modifyScopeInitValues = {
  labels: [],
};

export const baseProtectionInitValues = {
  ipv6: 'allowed',
  ipv6_mode: 'policy_based',
};

export const scopeAndLabelsInitValues = {
  scopeKeysChanged: false,
};

export const ikeAuthTypeInitValues = {
  ipsecAuthority: 'psk',
};

export const policyStateOptions = createSelector([], () => [
  {value: 'all', label: intl('Common.All'), subText: intl('Firewall.Coexistence.BuildTestEnforced')},
  {value: 'enforced', label: intl('Common.Enforced')},
  {value: 'illuminated', label: intl('Common.Illuminated'), subText: intl('Firewall.Coexistence.BuildTest')},
]);

export const primaryFirewallOptions = createSelector([], () => [
  {
    value: 'yes',
    label: intl('Common.Yes'),
    subText: intl('Firewall.Coexistence.SetIllumioAsPrimaryFirewall'),
  },
  {value: 'no', label: intl('Common.No'), subText: intl('Firewall.Coexistence.SetIllumioAsNotPrimaryFirewall')},
]);

export const sectionConfig = createSelector([], () => ({
  general: {text: intl('Common.General'), schemas: baseProtectionSchemas, initialValues: baseProtectionInitValues},
  ikeAuth: {
    text: intl('Settings.IKEAuthentication'),
    schemas: ikeAuthTypeSchemas,
    initialValues: ikeAuthTypeInitValues,
  },
  staticscopes: {
    text: intl('Policy.Configuration'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  firewallcoexistence: {
    text: intl('Firewall.Coexistence.Title'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object({
      ...modifyScopeSchemasObject(),
      policyState: object()
        .test('is-policy-state-option', intl('Settings.Edit.ModifyScope.Errors.PolicyStateOption'), item =>
          policyStateOptions().includes(item),
        )
        .nullable(),
      primaryFirewall: object()
        .test('is-primary-firewall', intl('Settings.Edit.ModifyScope.Errors.PrimaryFirewall'), item =>
          primaryFirewallOptions().includes(item),
        )
        .nullable()
        .required(intl('Settings.Edit.ModifyScope.PrimaryFirewallRequired')),
    }),
    scopeFormInitialValues: {
      ...modifyScopeInitValues,
      policyState: policyStateOptions()[0],
      primaryFirewall: primaryFirewallOptions()[0],
    },
  },
  loopbackinterfaces: {
    text: intl('Settings.LoopbackInterfaces.Title'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  blockaction: {
    text: intl('Common.BlockAction'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  containers: {
    text: intl('Common.Containers'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  ipforwarding: {
    text: intl('Settings.IPForwarding.Title'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
}));

//All Roles, All Applications, All Locations, All Environments
export const allCategoryLabel = <Pill.Label all />;

const staticPolicyReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Policy.UpdateMode'),
    tid: 'updatemode',
    value: intl('Settings.Edit.PolicyConfiguration.PolicyUpdateModeDesc'),
  },
]);
const firewallCoexReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Common.Default'),
    tid: 'updatemode',
    value: intl('Firewall.Coexistence.DefaultMode'),
    secondary: <div className={styles.value}>{intl('Firewall.Coexistence.ModeDesc')}</div>,
  },
]);
const blockActionReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.BlockAction.BlockedConnectionAction'),
    tid: 'updatemode',
    value: intl('Settings.BlockAction.BlockedConnectionActionDesc'),
  },
]);
const loopbackReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.LoopbackInterfaces.Title'),
    tid: 'updatemodeloopback',
    value: <div className={styles.value}>{intl('Settings.LoopbackInterfaces.LoopbackInterfacesDescription')}</div>,
  },
]);
const containersReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.Containers.ContainerInheritHostPolicy'),
    tid: 'updatemode',
    value: <div className={styles.value}>{intl('Settings.Containers.ContainerInheritHostPolicyDesc')}</div>,
  },
]);
const ipforwardingReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.IPForwarding.Title'),
    tid: 'updatemode',
    value: <div className={styles.value}>{intl('Settings.IPForwarding.Description')}</div>,
  },
]);

export const scopeReadOnlyAttributes = createSelector([], () => ({
  staticscopes: {
    startRows: staticPolicyReadOnlyAttributes(),
    scopeLabel: intl('Common.Scopes'),
    add: {
      title: intl('Labels.AddScopeForStaticPolicy'),
    },
    edit: {
      title: intl('Labels.EditScopeForStaticPolicy'),
    },
    duplicatedMsg: intl('Firewall.DuplicatedScopesAndLabelsForPolicyState'),
    allConfirmationMsg: intl('Firewall.StaticPolicyAllConfirmation'),
    errorMsg: intl('Firewall.PolicyStateAllError'),
  },
  firewallcoexistence: {
    startRows: firewallCoexReadOnlyAttributes(),
    scopeLabel: intl('Firewall.Coexistence.Title'),
    add: {
      title: intl('Firewall.Coexistence.AddScopeForFirewallCoexistence'),
    },
    edit: {
      title: intl('Firewall.Coexistence.EditScopeForFirewallCoexistence'),
    },
    duplicatedMsg: intl('Firewall.DuplicatedScopesAndLabelsForFirewallCoexistence'),
    errorMsg: intl('Firewall.CoexistenceAllError'),
    allConfirmationMsg: intl('Firewall.Coexistence.AllConfirmation'),
    emptyMsg: intl('Firewall.Coexistence.NoData'),
  },

  loopbackinterfaces: {
    startRows: loopbackReadOnlyAttributes(),
    scopeLabel: intl('Common.Scopes'),
    add: {
      title: intl('Settings.LoopbackInterfaces.AddScopeForLoopbackInterfaces'),
    },
    edit: {
      title: intl('Settings.LoopbackInterfaces.EditScopeForLoopbackInterfaces'),
    },
    allConfirmationMsg: intl('Settings.LoopbackInterfaces.AllConfirmation'),
    duplicatedMsg: intl('Settings.LoopbackInterfaces.DuplicatedScopesAndLabels'),
    errorMsg: intl('Settings.LoopbackInterfaces.ConfigAllError'),
    emptyMsg: intl('Settings.LoopbackInterfaces.NoData'),
  },
  containers: {
    startRows: containersReadOnlyAttributes(),
    scopeLabel: intl('Common.Scopes'),
    add: {
      title: intl('Settings.Containers.AddScopeForContainerInheritHostPolicy'),
    },
    edit: {
      title: intl('Settings.Containers.EditScopeForContainerInheritHostPolicy'),
    },
    allConfirmationMsg: intl('Settings.Containers.AllConfirmation'),
    duplicatedMsg: intl('Settings.Containers.DuplicatedScopesAndLabels'),
    errorMsg: intl('Settings.Containers.ConfigAllError'),
  },
  blockaction: {
    startRows: blockActionReadOnlyAttributes(),
    scopeLabel: intl('Common.Scopes'),
    add: {
      title: intl('Settings.BlockAction.AddScopeForRejectConnections'),
    },
    edit: {
      title: intl('Settings.BlockAction.EditScopeForRejectConnections'),
    },
    duplicatedMsg: intl('Settings.BlockAction.DuplicatedScopesAndLabels'),
    allConfirmationMsg: intl('Settings.BlockAction.AllConfirmation'),
  },
  ipforwarding: {
    startRows: ipforwardingReadOnlyAttributes(),
    scopeLabel: intl('Common.Scopes'),
    add: {
      title: intl('Settings.IPForwarding.AddScope'),
    },
    edit: {
      title: intl('Settings.IPForwarding.EditScope'),
    },
    duplicatedMsg: intl('Settings.IPForwarding.DuplicatedScopesAndLabels'),
    allConfirmationMsg: intl('Settings.IPForwarding.AllConfirmation'),
    emptyMsg: intl('Settings.IPForwarding.NoData'),
  },
}));

/**
 * Get Page header subtitle e.g. header for policy configuration tab is 'Security - Policy Configuration'
 */
export const getHeaderSubTitle = section =>
  ({
    general: intl('Common.General'),
    staticscopes: intl('Common.StaticPolicy'),
    firewallcoexistence: intl('Firewall.Coexistence.Title'),
    secureconnect: intl('Settings.SecureConnect'),
    containers: intl('Settings.Containers.ContainersPolicy'),
    blockaction: intl('Common.BlockAction'),
    ipforwarding: intl('Settings.IPForwarding.Title'),
    loopbackinterfaces: intl('Settings.LoopbackInterfaces.Title'),
  }[section]);

export const allScopeKey = 'all_workloads';

/**
 * Orders labels by href and concatenates them to create a unique key
 * @param labels
 * @returns {string}
 */
const getRowKey = labels => {
  if (!labels?.length) {
    return '';
  }

  if (labels?.length === 1 && labels[0].value === intl('Workloads.All')) {
    return allScopeKey;
  }

  return _.orderBy(labels, 'href')
    .map(label => label.href)
    .join(',');
};

/**
 * Generate Grid key and labels for the scope in static policy and firewall coexistence
 * Scope is an array of Label or Label Group objects such as {label: {href: '/orgs/1/labels/1', key: 'role', value: 'Web'}},
 * or {label_group: {href: '/orgs/1/labels/1', key: 'role', name: 'Web'}}
 * Api does not provide a scope label if its value is 'All'
 */
const generateStaticPolicyRow = scope => {
  const labelsFlat = scope.map(({label, label_group}) => label || label_group);

  let labels = [];

  if (scope?.length) {
    labels = [['labelsAndLabelGroups', labelsFlat]];
  } else {
    labels = [['allWorkloads', [{value: intl('Workloads.All'), href: allScopeKey}]]];
  }

  return {
    key: getRowKey(labelsFlat),
    selectable: true,
    data: {
      labels,
      labelsMap: getSortedLabelsAndLabelGroups(labelsFlat).reduce(
        (map, label) => map.set(label.key || allScopeKey, label),
        new Map(),
      ),
    },
  };
};

/**
 * Generate Grid key and labels for the scope in static policy and firewall coexistence
 * Scope is an array of Label or Label Group objects such as {label: {href: '/orgs/1/labels/1', key: 'role', value: 'Web'}},
 * or {label_group: {href: '/orgs/1/labels/1', key: 'role', name: 'Web'}}
 * Api only provides label object for named labels
 */
const generateFirewallCoexistenceRow = item => {
  const state = item.workload_mode || 'all';

  let labels = [];

  if (item.scope?.length) {
    labels = [['labelsAndLabelGroups', item.scope]];
  } else {
    labels = [['allWorkloads', [{value: intl('Workloads.All'), href: allScopeKey}]]];
  }

  const labelKeys = getRowKey(item.scope);

  return {
    key: `${labelKeys},${state},${item.illumio_primary ? 'yes' : 'no'}`,
    selectable: true,
    data: {
      labels,
      labelsMap: getMappedLabelsForGrid(item.scope),
      state,
      primaryFirewall: item.illumio_primary,
    },
  };
};

export const getDataCenterDesc = ({type, html = false}) =>
  ({
    strict_brn: intl('Settings.WorkloadsHostedInCloud', {className: styleUtils.bold}, {html}),
    single_private_brn: intl('Settings.WorkloadsHostedInPrivate', {className: styleUtils.bold}, {html}),
    cloud_private_brn: intl('Settings.WorkloadsHostedInCloudWithPrivate', {className: styleUtils.bold}, {html}),
    single_brn: intl('Settings.WorkloadsHostedInPrivateOnly', {className: styleUtils.bold}, {html}),
    disabled: intl('Settings.OrgDataCenterIsDisabled', {className: styleUtils.bold}, {html}),
  }[type]);

export const formatNewScopeRow = ({values, section} = {}) => {
  const {policyState, primaryFirewall} = values;

  const labels = values.labels?.[0]?.[1] || [];

  const derivedLabelsKey = getRowKey(labels);
  let key;

  if (section === 'firewallcoexistence') {
    //Include primary firewall in key as two rows will be added with status (create, delete) if primary firewall is modified
    key = `${derivedLabelsKey},${policyState.value},${primaryFirewall.value}`;
  } else {
    key = derivedLabelsKey;
  }

  return {
    key,
    selectable: true,
    data: {
      labels: values.labels,
      labelsMap: getSortedLabelsAndLabelGroups(labels).reduce(
        (map, label) => map.set(label.key || allScopeKey, label),
        new Map(),
      ),
      status: 'create',
      highlightGreen: true,
      action: true,
      ...(section === 'firewallcoexistence' && {
        state: policyState.value,
        primaryFirewall: primaryFirewall.value === 'yes',
      }),
    },
  };
};

export const formatInitialItemByType = (labelObj, labelType) => {
  if (_.isEmpty(labelObj)) {
    return [];
  }

  const initialItem = {href: labelObj.href, key: labelType};

  if (labelObj.group) {
    initialItem.categoryName = intl('Labels.Groups');
    initialItem.categoryKey = 'label_groups';
    initialItem.name = labelObj.value;
  } else {
    initialItem.categoryName = intl('Common.Labels');
    initialItem.categoryKey = labelObj.href.includes('all_') ? 'all' : 'labels';
    initialItem.value = labelObj.value;
  }

  return [{...initialItem}];
};

export const formatLabelFromSelection = (type, labelObj) => {
  const isGroup = labelObj.categoryKey === 'label_groups';

  return {
    key: type,
    group: isGroup,
    href: labelObj.href || `all_${type}/`,
    value: isGroup ? labelObj.name : labelObj.value,
  };
};

export const formatStaticPolicyScopesFromGridRows = rows =>
  rows.reduce((result, row) => {
    // Omit passing any items with delete status to the BE; otherwise, two items (original and updated) will be sent.
    if (row.data.status === 'delete') {
      return result;
    }

    const rawLabels = row.data.labels?.[0]?.[1];
    const labels = rawLabels.reduce((scope, item) => {
      if (item.href.includes('label_groups')) {
        scope.push({label_group: {href: item.href}});
      } else {
        if (item.href.includes('all_')) {
          //Skip all labels in api payload
          return scope;
        }

        scope.push({label: {href: item.href}});
      }

      return scope;
    }, []);

    result.push(labels);

    return result;
  }, []);

export const formatFirewallCoexistenceFromGridRows = rows =>
  rows.reduce((result, row) => {
    // Omit passing any items with delete status to the BE; otherwise, two items (original and updated) will be sent.
    if (row.data.status === 'delete') {
      return result;
    }

    const rawLabels = row.data.labels?.[0]?.[1];

    const labels = rawLabels.reduce((scope, item) => {
      if (item.href.includes('all_')) {
        //Skip all labels in api payload
        return scope;
      }

      scope.push({href: item.href});

      return scope;
    }, []);

    result.push({
      scope: labels,
      workload_mode: row.data.state === 'all' ? undefined : row.data.state,
      illumio_primary: row.data.primaryFirewall,
    });

    return result;
  }, []);

export const formatTabPanelParams = (section, mode) => ({to: 'securitysettings.section', params: {section, mode}});

export const hasRowsChanged = (initialRows, rows) =>
  initialRows.length !== rows.length ||
  initialRows
    .map(row => row.key + row.data.status)
    .sort()
    .join(',') !==
    rows
      .map(row => row.key)
      .sort()
      .join(',');

export const modifyScopeFormBreakpoints = [
  {props: {className: attributeListStyles.sizeS}},
  {maxWidth: 510, props: {className: attributeListStyles.sizeXS}},
];

/**
 * Stable stringify nested array in scope labels are received in different orders from backend
 */
const stringifyScope = scopeRows =>
  sortAndStringifyArray(
    scopeRows?.map(scopeLabels =>
      sortAndStringifyArray(scopeLabels.map(labelObj => (labelObj.label || labelObj.label_group)?.href)),
    ),
  );

/**
 * Stable stringify nested array and objects in firewallCoex as scope labels are received in different orders from backend
 */
const stringifyFirewallCoexElements = firewallRows =>
  firewallRows
    .map(item =>
      stringify({
        ...item,
        scope: item.scope.map(scopeItem => stringify(scopeItem)).sort(),
      }),
    )
    .sort()
    .join(',');

const getSettingsByVersion = settings =>
  Object.entries(settings).reduce((result, [key, value]) => {
    //remove null, href, update_type and updated_at for comparision between versions
    if (value !== null && key !== 'href' && key !== 'update_type' && key !== 'updated_at') {
      if (key === 'firewall_coexistence') {
        //object with array of array prop
        result[key] = stringifyFirewallCoexElements(value);
      } else {
        result[key] = value;
      }
    }

    return result;
  }, {});

export const hasDraft = settings =>
  settings && stringify(getSettingsByVersion(settings.draft)) !== stringify(getSettingsByVersion(settings.active));

const getInitialRowsByMode = (mode, activeRows = [], draftRows = []) => {
  switch (mode) {
    case 'edit':
    case 'draft': //Merge Active and Draft rows with 'Addition Pending'/'Deletion Pending' provision status
      const scopes = [];
      const draftItems = {};
      const activeItems = {};

      for (const draftRow of draftRows) {
        draftItems[draftRow.key] = draftRow;
      }

      for (const activeRow of activeRows) {
        activeItems[activeRow.key] = activeRow;
      }

      for (const draftRow of draftRows) {
        const scope = {...draftRow};

        scope.data = {...scope.data, action: true};

        if (!activeItems[scope.key]) {
          // If the draft static policy scope is not there in the active
          // collection of static policy scopes, it is "Addition Pending"
          scope.data.status = 'create';
        }

        scopes.push(scope);
      }

      for (const activeRow of activeRows) {
        // Add all the rows which are in the active collection of static policy
        // scopes, but not in the draft collection. These are "Deletion Pending"
        if (!draftItems[activeRow.key]) {
          scopes.push({
            ...activeRow,
            selectable: false,
            data: {...activeRow.data, status: 'delete', action: false},
          });
        }
      }

      return scopes;
    default:
      return activeRows;
  }
};

const getStaticScopeInitRows = (mode, {active, draft} = {}) => {
  const activeStaticPolicy = active ? active.static_policy_scopes.map(scope => generateStaticPolicyRow(scope)) : [];
  const draftStaticPolicy = draft ? draft.static_policy_scopes.map(scope => generateStaticPolicyRow(scope)) : [];

  return getInitialRowsByMode(mode, activeStaticPolicy, draftStaticPolicy);
};

const getFirewallCoexInitRows = (mode, {active, draft} = {}) => {
  const activeFirewall = active ? active.firewall_coexistence.map(item => generateFirewallCoexistenceRow(item)) : [];
  const draftFirewall = draft ? draft.firewall_coexistence.map(item => generateFirewallCoexistenceRow(item)) : [];

  return getInitialRowsByMode(mode, activeFirewall, draftFirewall);
};

const getLoopbackInterfacesInitRows = (mode, {active, draft} = {}) => {
  const activeLoopback = active
    ? active.loopback_interfaces_in_policy_scopes.map(item => generateStaticPolicyRow(item))
    : [];
  const draftLoopback = draft
    ? draft.loopback_interfaces_in_policy_scopes.map(item => generateStaticPolicyRow(item))
    : [];

  return getInitialRowsByMode(mode, activeLoopback, draftLoopback);
};

const getContainersInitRows = (mode, {active, draft} = {}) => {
  const activeContainer = active
    ? active.containers_inherit_host_policy_scopes &&
      active.containers_inherit_host_policy_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];
  const draftContainer = draft
    ? draft.containers_inherit_host_policy_scopes &&
      draft.containers_inherit_host_policy_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];

  return getInitialRowsByMode(mode, activeContainer, draftContainer);
};

const getBlockActionInitRows = (mode, {active, draft} = {}) => {
  const activeBlockAction = active
    ? active.blocked_connection_reject_scopes &&
      active.blocked_connection_reject_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];
  const draftBlockAction = draft
    ? draft.blocked_connection_reject_scopes &&
      draft.blocked_connection_reject_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];

  return getInitialRowsByMode(mode, activeBlockAction, draftBlockAction);
};

const getIPForwardingInitRows = (mode, {active, draft} = {}) => {
  const activeRows = active?.ip_forwarding_enabled_scopes?.map(scope => generateStaticPolicyRow(scope)) ?? [];
  const draftRows = draft?.ip_forwarding_enabled_scopes?.map(scope => generateStaticPolicyRow(scope)) ?? [];

  return getInitialRowsByMode(mode, activeRows, draftRows);
};

export const getInitialRows = (mode, section, securitySettings) => {
  if (!securitySettings) {
    return [];
  }

  switch (section) {
    case 'staticscopes':
      return getStaticScopeInitRows(mode, securitySettings);
    case 'firewallcoexistence':
      return getFirewallCoexInitRows(mode, securitySettings);
    case 'containers':
      return getContainersInitRows(mode, securitySettings);
    case 'loopbackinterfaces':
      return getLoopbackInterfacesInitRows(mode, securitySettings);
    case 'blockaction':
      return getBlockActionInitRows(mode, securitySettings);
    case 'ipforwarding':
      return getIPForwardingInitRows(mode, securitySettings);
    default:
      return [];
  }
};

export const getModifiedSections = ({active, draft} = {}) => {
  const modified = {};

  if (active && draft) {
    if (draft.allow_ipv6 !== active.allow_ipv6) {
      modified.ip = true;
    }

    if (draft.ipv6_mode !== active.ipv6_mode) {
      modified.ipv6Mode = true;
    }

    if (draft.network_detection_mode !== active.network_detection_mode) {
      modified.connectivity = true;
    }

    if (stringifyScope(draft.static_policy_scopes) !== stringifyScope(active.static_policy_scopes)) {
      modified.staticPolicy = true;
    }

    if (
      stringifyFirewallCoexElements(active.firewall_coexistence) !==
      stringifyFirewallCoexElements(draft.firewall_coexistence)
    ) {
      modified.firewallCoexistence = true;
    }

    if (
      stringifyScope(active.loopback_interfaces_in_policy_scopes) !==
      stringifyScope(draft.loopback_interfaces_in_policy_scopes)
    ) {
      modified.loopbackInterfaces = true;
    }

    if (
      stringifyScope(draft.containers_inherit_host_policy_scopes) !==
      stringifyScope(active.containers_inherit_host_policy_scopes)
    ) {
      modified.containers = true;
    }

    if (
      stringifyScope(draft.blocked_connection_reject_scopes) !== stringifyScope(active.blocked_connection_reject_scopes)
    ) {
      modified.blockaction = true;
    }

    if (stringifyScope(draft.ip_forwarding_enabled_scopes) !== stringifyScope(active.ip_forwarding_enabled_scopes)) {
      modified.ipforwarding = true;
    }

    if (draft.ike_authentication_type !== active.ike_authentication_type) {
      modified.ikeAuth = true;
    }
  }

  return modified;
};

export const isScopeDefault = key => {
  const firstKey = key.includes(',') ? key.slice(0, key.indexOf(',')) : key;

  return firstKey === allScopeKey || key === `${allScopeKey},all,yes` || key === `${allScopeKey},all,no`;
};

export const isScopeEmpty = key => {
  const firstKey = key.includes(',') ? key.slice(0, key.indexOf(',')) : key;

  return !firstKey;
};

/**
 * This method checks for duplicate scopes or scopes that contain 'all' default options.
 *
 * The two sections on Security Settings that use it are: Policy Configuration (staticscopes) and Firewall
 * Coexistence (firewallcoexistence).
 *
 * For staticscopes the check is fairly trivial since the permutations are minimal. firewallcoexistence on the otherhand has edge-cases
 * to be mindful of such as the following:
 *
 * - If 'all' options are selected assign true to result.all so that we display a warning which will
 * prevent any more scopes to be added.
 *
 * - If 'all' is selected for Policy State on a scope, do not allow the same base scopes to be added since 'all' overrides 'enforced' and
 * 'illuminated'.
 */
export const isScopeDuplicateOrAll = ({rows, newRow, section, initialValues, isEdit}) => {
  const result = {};

  let initialRow;

  if (isEdit) {
    initialRow = formatNewScopeRow({values: initialValues, section});
  }

  result.dupe = rows.some(row => {
    if (row.data?.status === 'delete') {
      return false;
    }

    // selected nothing
    if (isScopeEmpty(newRow.key)) {
      return false;
    }

    // selected "All Workloads" which already exists in the grid
    // row.key === allScopeKey: row has "All Workloads"
    // !row.key:                row with "All Workloads" comes back as empty after save
    if (isScopeDefault(newRow.key) && (!row.key || row.key === allScopeKey)) {
      return true;
    }

    if (row.key === newRow.key) {
      return true;
    }

    // handle special all case for firewallcoexistance and blockaction
    if (section === 'firewallcoexistence') {
      // avoid checking against the inital row on edit, since it's base values will be the same
      if (initialRow && initialRow.key === row.key) {
        return false;
      }

      // key is in format of '/orgs/1/labels/31,/orgs/1/labels/7,all,yes'
      const halfRowKey = row.key.slice(0, row.key.lastIndexOf(',') + 1);
      const halfNewRowKey = newRow.key.slice(0, newRow.key.lastIndexOf(',') + 1);

      if (halfRowKey === halfNewRowKey) {
        return true;
      }

      if (row.data.state === 'all') {
        if (
          halfRowKey.replace(',all,', ',enforced,') === halfNewRowKey ||
          halfRowKey.replace(',all,', ',illuminated,') === halfNewRowKey
        ) {
          return true;
        }
      } else if (newRow.data.state === 'all') {
        if (
          halfNewRowKey.replace(',all,', ',enforced,') === halfRowKey ||
          halfNewRowKey.replace(',all,', ',illuminated,') === halfRowKey
        ) {
          return true;
        }
      }
    }

    return false;
  });

  // When user chooses All Workloads show warning message
  if (isScopeDefault(newRow.key) && rows.length > 0) {
    // Skip this if no scopes are present initially
    result.all = true;
  }

  return result;
};

export const getNetworkDetectionModeValue = networkDetectionMode => {
  if (!networkDetectionMode) {
    return intl('Settings.NotNetworkDetectionMode');
  }

  return networkDetectionMode === 'single_private_brn'
    ? intl('Settings.PrivateDataCenter')
    : intl('Settings.PublicCloudWithSNAT');
};
