/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {StatusIcon} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';
import {populateFacetCategory} from 'containers/Selector/GridFilter/GridFilterUtils';

export const resourceType = 'slbs';
export const getSelectorSettings = createSelector([], () => ({
  facetMap: {
    name: intl('Common.Name'),
    description: intl('Common.Description'),
  },
  filterMap: {
    name: intl('Common.Name'),
    description: intl('Common.Description'),
  },
}));

export const categories = createSelector([], () => {
  return [
    populateFacetCategory({id: 'name', name: intl('Common.Name'), resourceType}),
    populateFacetCategory({id: 'description', name: intl('Common.Description'), resourceType}),
    populateFacetCategory({id: 'device_type', name: intl('Common.DeviceType'), resourceType}),
    {
      id: 'has_virtual_server',
      name: intl('Common.VirtualServers'),
      resources: {
        has_virtual_server: {
          noSubtitle: true,
          statics: [
            {id: 'true', value: intl('Common.InUse')},
            {id: 'false', value: intl('Common.NotInUse')},
          ],
        },
      },
    },
    {
      id: 'num_devices',
      name: intl('LoadBalancers.Detail.NumberOfDevices'),
      resources: {
        num_devices: {
          noSubtitle: true,
          statics: [
            {id: '1', value: intl('LoadBalancers.DeviceNumber', {count: 1})},
            {id: '2', value: intl('LoadBalancers.DeviceNumber', {count: 2})},
          ],
        },
      },
    },
    {
      id: 'status',
      name: intl('Common.Status'),
      resources: {
        status: {
          noSubtitle: true,
          statics: [
            {id: 'active', value: intl('Common.Active')},
            {id: 'pending', value: intl('Common.Pending')},
            {id: 'error', value: intl('Common.Error')},
          ],
        },
      },
    },
  ];
});

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'loadbalancerslist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Common.Status'),
      value: ({row}) => row.status,
      format: ({value}) => (value ? <StatusIcon status={value} /> : null),
    },
    inUse: {
      header: intl('Common.VirtualServers'),
      value: ({row}) => row.inUse,
      format: ({value}) => (value ? intl('Common.InUse') : null),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
    deviceType: {
      header: intl('Common.DeviceType'),
      value: 'device_type',
    },
    deviceNumber: {
      header: intl('LoadBalancers.Detail.NumberOfDevices'),
      value: ({row}) => row.data.devices.length,
      format: ({value}) => intl('LoadBalancers.DeviceNumber', {count: value}),
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['inUse'], size: 'minmax(50px, auto)'},
      {columns: ['name'], size: 'minmax(100px, auto)'},
      {columns: ['deviceType'], size: 'minmax(75px, auto)'},
      {columns: ['deviceNumber'], size: 'minmax(75px, auto)'},
      {columns: ['desc'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['inUse'], size: 'minmax(50px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['deviceType'], size: 'minmax(75px, auto)'},
            {columns: ['deviceNumber'], size: 'minmax(75px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['inUse'], size: 'minmax(50px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['deviceType'], size: 'minmax(75px, auto)'},
          {columns: ['deviceNumber'], size: 'minmax(75px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['inUse'], size: 'minmax(50px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['deviceType'], size: 'minmax(75px, auto)'},
            {columns: ['deviceNumber'], size: 'minmax(75px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['inUse'], size: 'minmax(50px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['deviceType'], size: 'minmax(75px, auto)'},
          {columns: ['deviceNumber'], size: 'minmax(75px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'inUse'], size: 'minmax(100px, auto)'},
            {columns: ['deviceType'], size: 'minmax(75px, auto)'},
            {columns: ['deviceNumber', 'desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'inUse'], size: 'minmax(100px, auto)'},
          {columns: ['deviceType'], size: 'minmax(75px, auto)'},
          {columns: ['deviceNumber', 'desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'inUse'], size: 'minmax(100px, auto)'},
            {columns: ['deviceType'], size: 'minmax(75px, auto)'},
            {columns: ['deviceNumber', 'desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'inUse'], size: 'minmax(100px, auto)'},
          {columns: ['deviceType'], size: 'minmax(75px, auto)'},
          {columns: ['deviceNumber', 'desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes', 'status'], size: 'max-content'},
            {columns: ['name', 'inUse', 'deviceType'], size: 'minmax(100px, auto)'},
            {columns: ['deviceNumber', 'desc'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes', 'status'], size: 'max-content'},
          {columns: ['name', 'inUse', 'deviceType'], size: 'minmax(100px, auto)'},
          {columns: ['deviceNumber', 'desc'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
