/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {UNAUTHORIZED} from 'http-status-codes';
import {call, cancel, delay, spawn, put, select, cancelled, type SagaGenerator} from 'typed-redux-saga/macro';
import apiCall, {type APICallOptions, type APICallResponse} from './api';
import {APIError} from 'errors';
import {getOrgId} from 'containers/User/UserState';
import {cachedResponses, pendingCalls, generateKey} from './apiCache';
import type {SchemaMethodsKey} from './schema';
import type {StrictEffect} from 'redux-saga/effects';

type SagaYieldable = Promise<unknown> | StrictEffect;

export interface APISagaOptions {
  cacheSalt?: string;
  checkPending?: boolean;
  cache?: boolean;
  cacheMaxAge?: number;
  cacheResponseOnCacheFalse?: boolean;
  compareResponseOnCacheInvalidation?: boolean;
  cacheDropMethodOnCacheFalse?: boolean;
  beforeFetch?: Promise<unknown> | StrictEffect | ((params: {cacheKey: string}) => Generator);
  afterFetch?:
    | Promise<unknown>
    | StrictEffect
    | ((response: APICallResponse, options: {cacheKey: string}) => SagaGenerator<unknown>);
  onCache?:
    | Promise<unknown>
    | StrictEffect
    | ((response: APICallResponse, options: {afterFetchResult: unknown; cacheKey: string}) => SagaGenerator<unknown>);
  onPending?: Promise<unknown> | StrictEffect | ((params: {cacheKey: string}) => Generator);
  onDone?:
    | Promise<unknown>
    | StrictEffect
    | ((
        response: APICallResponse | undefined,
        options: {afterFetchResult: unknown; onCacheResult: unknown; cacheKey: string},
      ) => SagaGenerator<unknown>);
  logoutOn401?: boolean;
}

/**
 * Saga wrapper of apiCall to handle pendings, cache response, cancel fetch if saga is cancelled and fire some actions if needed
 *
 * @param {String} name - Name of the api method, in 'class.method' notation, like 'workloads.get_instance'
 *
 * @param {Object}  options - Options. Those described below are taken by apiSaga itself, others are passed down to api.js/fetcher.js
 *
 * @param {boolean} [options.checkPending=true] - Whether request to api should not be called if the same request is already happening.
 *                                                Response for the consequent api calls will be taken from the first request to api.
 * @param {boolean} [options.cache=false] - Whether response of api call should taken from cache or put into cache.
 * @param {boolean} [options.cacheMaxAge=60_000] - Maximum lifetime of cached response. Default is 1min.
 * @param {boolean} [options.cacheResponseOnCacheFalse=true] - If we pass cache:false at some point to invalidate cache
 *                                                that means usually we call that method with cache:true.
 *                                                That means it is highly possible that next call will be done with cache:true again.
 *                                                If that option is true, calling method with cache:false invalidates cache (if exists)
 *                                                and put new response to cache again.
 *                                                Use case: when we delete item from list page we remove cached list from cache,
 *                                                but the next call will be done with force flag (cache:false) and still need to be cached.
 * @param {boolean} [options.compareResponseOnCacheInvalidation=true] - If we have cached version, but cache:false,
 *                                                we have to invalidate existing cache. If this options is true,
 *                                                we deeply compare response of previously cached version (that we are dropping)
 *                                                with new response, and if they are equal, we return previously cached response instead.
 *                                                That is important optimization when using redux, when reducer use payload from response,
 *                                                and will not cause selectors recalculation if pointer in memory stays the same.
 *                                                For instance, that will not cause grid rerender on all list page,
 *                                                on refresh button click, if response content from server is the same.
 * @param {boolean} [options.cacheDropMethodOnCacheFalse=true] - If we pass cache:false at some point to invalidate cache, we might already
 *                                                have several cached responses for that method with different request parameters.
 *                                                Usually if you dropping cache for current parameters you need to invalidate for other
 *                                                parameters as well, because result for differen parameter can be a subset of each other
 *                                                (for instance refreshing filtered list should drop cache of more general not filtered one)
 * @param {String} [options.cacheSalt] - If you have several different sagas calling the same api endpoint
 *                                  with cache:true but with different beforeFetch/afterFetch hooks,
 *                                  then only hooks of the first saga will be called, others will take response from cache and skip hooks.
 *                                  To isolate cache of those saga calls, you can pass `cacheSalt` that will be mixed with cache key.
 *
 * @param {*} [options.beforeFetch] - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                                    Will be called right before fetch call, if fetch is really called (no cache, no pending).
 *                                    Do not expect return value in case of function.
 *
 * @param {*} [options.afterFetch] - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                                   Will be called right after fetch call, if fetch is really called (no cache, no pending).
 *                                   In case of function takes api response as argument and
 *                                   can replace response if returns not undefined value (that response will be cached if cache=true).
 *
 * @param {*} [options.onCache] - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                                Will be called if response is taken from cache.
 *                                In case of function takes cached response as argument and
 *                                can replace response if returns not undefined value.
 *
 * @param {*} [options.onPending] - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                                  Will be called if saga is taken from pending list and will take response from it.
 *                                  In case of function does not expect return value.
 *
 * @param {*} [options.onDone] - Generator function or any other value that can be yielded (for instance, effect or promise).
 *                               Will be called in any case (cache/no-cache).
 *                               In case of function takes response as argument and can replace response if returns not undefined value.
 *
 * @param {*} [options.logoutOn401=true] - Whether apiSaga should put logout action when api responds with '401 Unauthorized'
 *
 * @returns {IterableIterator<*>}
 */
export default function* apiSaga(
  name: SchemaMethodsKey,
  options: APICallOptions & APISagaOptions = {},
  // TODO: we should infer the return type of an API by the input schema key type
  // TODO: `SagaGenerator` doesn't allow yielding promises but typed-redux-saga need
  // it to properly infer the result for `call` effects so we'll have to
  // use `SagaGenerator` as the return type and assert yielded promises as `StrictEffect`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): SagaGenerator<any> {
  const {
    cacheSalt,
    checkPending = true,
    cache /* It's important to not set default value as 'false', because it's not binary. undefined, false, true are all values */,
    cacheMaxAge,
    cacheResponseOnCacheFalse = true,
    compareResponseOnCacheInvalidation = true,
    cacheDropMethodOnCacheFalse = true,
    beforeFetch,
    afterFetch,
    onCache,
    onPending,
    onDone,
    logoutOn401 = true,
    ...apiOptions
  } = options;

  // Generate cache key using safe-stable-stringify
  const cacheKey = generateKey(name, apiOptions, cacheSalt);

  // Try to find cached response for that saga first
  let {response, afterFetchResult} = cachedResponses.find(cacheKey) || {};
  let result = afterFetchResult === undefined ? response : afterFetchResult;
  let onCacheResult: unknown;
  let responseCacheToCompare: APICallResponse | undefined;

  if (response !== undefined) {
    if (cache) {
      // If cached result exists, call 'onCache' hook, which result (if returned) can replace cached one
      if (onCache) {
        onCacheResult =
          typeof onCache === 'function'
            ? yield* call(onCache, response, {afterFetchResult, cacheKey})
            : yield onCache as StrictEffect;

        if (onCacheResult !== undefined) {
          result = onCacheResult;
        }
      }

      // And call 'onDone' hook, which result (if returned) can replace cached one
      yield* callOnDone();

      return result;
    }

    if (compareResponseOnCacheInvalidation) {
      responseCacheToCompare = response;
    }

    // If cached response exists, but 'cache' options is false, we need to drop item from cache
    response = undefined;
    result = undefined;
    cachedResponses.remove(cacheKey);
  }

  // Drop all other cached responses for method if cacheDropMethodOnCacheFalse is true
  if (cache === false && cacheDropMethodOnCacheFalse) {
    cachedResponses.removeByMethodName(name);
  }

  // Try to find already pending call with the same parameters first
  let pending = pendingCalls.find(cacheKey);
  let apiTask = pending ? pending.apiTask : null;
  // Fetch will be really called if 'checkPending' is false or true but with not found pending call
  const fetchInitiator = !checkPending || !pending;

  if (fetchInitiator) {
    // If we start fetching, call 'beforeFetch' hook, its return value doesn't matter
    if (beforeFetch) {
      typeof beforeFetch === 'function' ? yield* call(beforeFetch, {cacheKey}) : yield beforeFetch as StrictEffect; // eslint-disable-line no-unused-expressions
    }

    // Pass orgId, not all methods need it, but pass it anyway
    apiOptions.orgId = yield* select(getOrgId);

    // Start api call using spawn to skip waiting for it (we will be waiting later using 'apiTask.done')
    // and be able to put it into the pending list.
    // Use spawn instead of fork to be able to catch errors if we need.
    // Pass afterFetch hook into it, so result of calling that hook (if returned) would go to other waiters of pending
    apiTask = yield* spawn(apiCaller, name, apiOptions, {
      cacheKey,
      responseCacheToCompare,
      afterFetch,
      afterFetchResult,
      logoutOn401,
    });

    // Always add to pending list even if checkPending is false, in case the next apiSaga call comes with checkPending=true
    // If there are pendings already for the same request, but checkPending is false (that is why we are here)
    // we are just replacing previous pendings with the new one, which is correct, since previous already took apiTask from old pending list
    pending = {apiTask, count: 1};
    pendingCalls.add(cacheKey, pending);
  } else {
    // Increment number of items depended on that apiTask
    pending!.count++;

    if (onPending) {
      // If 'checkPending' is true, pending with found and `onPending` hook exists, call it, its return value doesn't matter
      typeof onPending === 'function' ? yield* call(onPending, {cacheKey}) : yield onPending as StrictEffect; // eslint-disable-line no-unused-expressions
    }
  }

  try {
    // Now it's time to wait for the apiTask, whether it was started here or found in pending list
    //
    // Again, we are yielding a promise so it's out of typed-redux-saga' hand and need need to manually type the NextType
    const apiTaskPromise = apiTask!.toPromise();

    ({response, afterFetchResult} = (yield apiTaskPromise as unknown as StrictEffect<APICallerResult>) as Awaited<
      typeof apiTaskPromise
    >);

    result = afterFetchResult === undefined ? response : afterFetchResult;

    // Add to cache if fetch was started here and 'cache' is true
    // And also put to cache if it is force fetch,
    // to make next possible force fetch also get from previous cached response
    if (fetchInitiator && (cache === true || (cache === false && cacheResponseOnCacheFalse))) {
      cachedResponses.add(cacheKey, {cacheKey, cacheSalt, response, afterFetchResult}, cacheMaxAge);
    }

    // Finally call 'onDone' hook, which result (if returned) can replace apiSaga result
    yield* callOnDone();

    return result;
  } finally {
    // Decrement counter of waiting sagas
    pending!.count--;

    if (!pending!.count) {
      // If there is no other sagas waiting for that apiTask anymore, drop it from peding list
      pendingCalls.remove(cacheKey);

      // If this saga has also been cancelled, abort fetch request as well
      if (yield* cancelled()) {
        yield* cancel(apiTask!);
      }
    }
  }

  function* callOnDone(): SagaGenerator<void> {
    if (onDone) {
      // If onDone is a function, call it and pass `result` to it, that could be result of afterFetch, if both methods specified
      const onDoneResult =
        typeof onDone === 'function'
          ? yield* call(onDone, response, {afterFetchResult, onCacheResult, cacheKey})
          : yield onDone as StrictEffect;

      if (onDoneResult !== undefined) {
        result = onDoneResult;
      }
    }
  }
}

export interface APICallerResult {
  response: APICallResponse;
  afterFetchResult: unknown;
}

function* apiCaller(
  name: SchemaMethodsKey,
  options: APICallOptions,
  {
    cacheKey,
    responseCacheToCompare,
    afterFetch,
    afterFetchResult: afterFetchResultToCompare,
    logoutOn401,
  }: {
    cacheKey: string;
    responseCacheToCompare?: APICallResponse;
    afterFetch: APISagaOptions['afterFetch'];
    afterFetchResult: unknown;
    logoutOn401: boolean;
  },
): Generator<SagaYieldable, APICallerResult> {
  let request;
  let afterFetchResult: unknown;

  try {
    request = yield* call(apiCall, name, options);

    // Promise isn't assignable to StrictEffect
    const response = (yield request.promise) as Awaited<typeof request.promise>;

    if (responseCacheToCompare) {
      // If data is equal with previously cached response (that we invalidated),
      // use that data from previously cached response to keep pointer in memory the same (for redux optimization)
      if (_.isEqual(response.data, responseCacheToCompare.data)) {
        response.data = responseCacheToCompare.data;
      }

      if (_.isEqual(response.count, responseCacheToCompare.count)) {
        response.count = responseCacheToCompare.count;
      }
    }

    if (afterFetch) {
      afterFetchResult =
        typeof afterFetch === 'function' ? yield* call(afterFetch, response, {cacheKey}) : yield afterFetch;

      // Also check afterFetchResult with previous one, and assign previous to the new one if content is equal to keep reference
      if (_.isEqual(afterFetchResult, afterFetchResultToCompare)) {
        afterFetchResult = afterFetchResultToCompare;
      }
    }

    return {response, afterFetchResult};
  } catch (error) {
    if (error instanceof APIError) {
      // If it's 401 code and logoutOn401 option is true, automatically logout user
      if (error.statusCode === UNAUTHORIZED && logoutOn401) {
        yield* put({type: 'LOGOUT', url: error.response?.headers?.get('x-redirect')});
        yield* delay(1e4); // Because redirection is not instant

        // Redirection above should ensure following codes unreachable
        // by throwing an error we avoid returning anything
        throw error;
      }
    }

    throw error;
  } finally {
    // If apiSaga was cancelled by caller saga, abort fetch request as well.
    // In that case thrown AbortError from fetcher.js->api.js will not be caught by this apiCall,
    // since we already passed catch block here, and that exactly what we want
    if (yield* cancelled()) {
      request?.abort();
    }
  }
}

export {pendingCalls as apiPendingCalls};
export {cachedResponses as apiCachedResponses};
