/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent, createRef, Fragment} from 'react';
import {object, string} from 'yup';
import {connect} from 'utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {hrefUtils} from 'utils';
import {createSecPolicies, createAllSecPolicies, revertSecPolicies, fetchProvision} from './ProvisionSaga';
import {getProvisionPopupContent} from './ProvisionState';
import {
  Button,
  GridLocal,
  Tally,
  StatusIcon,
  AttributeList,
  Form,
  ModalMachine,
  Notifications,
  TypedMessages,
} from 'components';
import styles from './Provision.css';
import stylesUtils from 'utils.css';
import {getHrefParams, isAPIAvailable} from 'api/apiUtils';
import {assign} from 'xstate';
import PubSub from 'pubsub';
import {getProvisionCounts, formatCountsForTally, getProvisionCountsTotal} from '../ProvisioningUtils';
import cx from 'classnames';

const dependencyRowHighLight = {className: styles.rowToDependency};
const attributeStyle = {key: styles.attributeKey};

@connect(getProvisionPopupContent)
export default class ProvisionButtons extends PureComponent {
  static contextType = AppContext;

  static propTypes = {
    modalOnly: PropTypes.bool,
    objectsToProvision: PropTypes.object.isRequired,
    objectsToRevert: PropTypes.object,
    counter: PropTypes.number,
    counterRevert: PropTypes.number,
    counterProvision: PropTypes.number,
    provisionProps: PropTypes.object, // object with custom props for provision button, will be merged with defaultProvisionProps
    revertProps: PropTypes.object, // object with custom props for revert button, will be merged with defaultRevertProps

    unsavedWarningData: PropTypes.object,
    unsavedWarningTitle: PropTypes.string,
    unsavedWarningMessage: PropTypes.string,
    revertWarningTitle: PropTypes.string,

    onButtonHover: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
  };

  static defaultProps = {
    onButtonHover: _.noop,
    onCancel: _.noop,
    onDone: _.noop,
    unsavedWarningTitle: '',
    unsavedWarningMessage: '',
    revertWarningTitle: '',
  };

  static getEmptyState = (context, props) => ({
    error: null,
    revert: false,
    provisionAll: false,
    loading: false,
    executing: false,
    showModal: Boolean(props?.modalOnly),
    extraPropsKeyMapSelected: new Map(),
    extraPropsKeyMapDependency: new Map(),
    context,
  });

  constructor(props, context) {
    super(props, context);

    this.state = ProvisionButtons.getEmptyState(context, props);
    this.initials = {note: ''};
    this.schemas = object({
      note: string().when(() =>
        this.props.requireProvisionNote ? string().trim().required(Form.emptyMessage) : string().trim(),
      ),
    });

    this.closeRef = createRef();

    this.handleButtonLeave = this.handleButtonLeave.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleRevertClick = this.handleRevertClick.bind(this);
    this.handleRevertEnter = this.handleRevertEnter.bind(this);
    this.handleProvisionClick = this.handleProvisionClick.bind(this);
    this.handleProvisionEnter = this.handleProvisionEnter.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOverSelected = this.handleMouseOverSelected.bind(this);
    this.handleMouseLeaveSelected = this.handleMouseLeaveSelected.bind(this);
    this.handleMouseOverDependency = this.handleMouseOverDependency.bind(this);
    this.handleMouseLeaveDependency = this.handleMouseLeaveDependency.bind(this);
    this.handleRevert = this.handleRevert.bind(this);
    this.handleProvision = this.handleProvision.bind(this);
    this.handleProvisionAll = this.handleProvisionAll.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.provideModalMachineConfig = this.provideModalMachineConfig.bind(this);
    this.handleButtonInProgress = this.handleButtonInProgress.bind(this);
    this.fetchProvisionObjects = this.fetchProvisionObjects.bind(this);
    this.handleProgressDone = this.handleProgressDone.bind(this);
    this.handleOnDone = this.handleOnDone.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Make components sensitive to prop change only if it's not in loading state,
    // to prevent content jumps until loading is complete
    if (!prevState.executing && !prevState.loading) {
      const {onButtonHover, onCancel, onDone, ...nextState} = mixThemeWithProps(styles, nextProps);

      // Sometimes objectsToRevert can be different than objectsToProvision.
      // e.g. in Group page revert on group should not revert dependencies
      nextState.objectsToRevert = nextProps.objectsToRevert ?? nextState.objectsToProvision;

      // Addition Pending groups in edge may not be reverted
      if (nextProps.edgeEnabled && prevState.revert) {
        if (nextProps.dependencyGridRows.length) {
          nextState.dependencyGridRows = nextProps.dependencyGridRows.filter(
            row => row.data.typeLabel !== intl('Common.Group') || row.data.update_type !== 'create',
          );
        }

        if (nextProps.selectedGridRows.length) {
          nextState.selectedGridRows = nextProps.selectedGridRows.filter(
            row => row.data.typeLabel !== intl('Common.Group') || row.data.update_type !== 'create',
          );
        }

        const counts = getProvisionCounts(
          [...nextState.selectedGridRows, ...nextState.dependencyGridRows],
          nextState.outboundAllowRulesetId,
        );

        nextState.tallyItems = [
          {children: 'Total : ', count: getProvisionCountsTotal(counts)},
          ...formatCountsForTally(counts),
        ];
      }

      nextState.provisionAll = nextProps.dependencyGridRows.length > 500;

      // Refresh hover state on dependency and selected grids if any item in selected grid is removed by the user
      if (nextProps.selectedGridRows !== prevState.selectedGridRows) {
        nextState.extraPropsKeyMapDependency = new Map();
        nextState.extraPropsKeyMapSelected = new Map();
      }

      return nextState;
    }

    if (prevState.executing && !prevState.loading) {
      return {loading: true, loadingError: false};
    }

    return null;
  }

  componentDidMount() {
    this.mounted = true;

    // Let pages navigate to a page with ProvisionButtons and instruct it to open the modal immediately.
    // In order to do that the previous page can publish a 'Provisioning.Open' topic to PubSub.
    // For example, when we press Save and Provision in the Group Wizard in Edge,
    // we want to navigate to that Group detail page and trigger the provisioning modal right away.
    this.provisionModalToken = PubSub.subscribe('Provisioning.Open', () => this.handleProvisionClick(), {
      // If exists, take the event that was already published, for example before navigation to this page
      getLast: true,
      // Remove this subscription after the first notification triggered
      once: true,
      // Prevent the event from notifying it further,
      // to make sure it will not pick up the same event over and over when navigating to this page again
      stopNotify: true,
    });
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.state.onLoaderDone) {
      // If unmounting while button still finishing its loading,
      // call onLoaderDone to unblock handleButtonClick or handleDone and free up memory
      this.state.onLoaderDone();
    }

    if (this.provisionModalToken) {
      PubSub.unsubscribe(this.provisionModalToken);
    }

    this.storeUnsubscribe?.();
  }

  handleProvisionEnter(evt) {
    this.props.onButtonHover(evt, 'enter', 'provision');
  }

  handleRevertEnter(evt) {
    this.props.onButtonHover(evt, 'enter', 'revert');
  }

  handleButtonLeave(evt) {
    // Notify parent on leave only when modal is not shown,
    // so parent can drop highlight if user moved out cursor and haven't opened modal
    if (!this.state.showModal) {
      this.props.onButtonHover(evt, 'leave');
    }
  }

  handleProvisionClick() {
    this.handleButtonClick();
  }

  handleRevertClick() {
    this.handleButtonClick({revert: true});
  }

  async handleButtonClick({revert = false} = {}) {
    const {formIsDirty, resetForm} = this.context.store.prefetcher;

    if (formIsDirty) {
      // Cancel confirmation is mounted if form has changes
      const answer = await new Promise(resolve =>
        PubSub.publish('UNSAVED.WARNING', {resolve, ...this.props.unsavedWarningData}),
      );

      if (answer === 'cancel') {
        return;
      }
    }

    if (typeof resetForm === 'function') {
      resetForm();
    }

    // Reset dirty flag
    PubSub.publish('FORM.DIRTY', {dirty: false, resetForm}, {immediate: true});

    this.setState({showModal: true, revert});
  }

  handleClick(evt, row) {
    this.context.navigate({
      evt,
      target: '_blank',
      to: row.data.route,
      params: {
        ...(row.data.object_type === 'firewall_settings'
          ? {}
          : {id: hrefUtils.getId(row.key), pversion: getHrefParams(row.key).pversion}),
        ...row.data.param,
      },
    });
  }

  handleClose(evt) {
    this.props.onButtonHover(evt, 'leave');
    this.setState(ProvisionButtons.getEmptyState(this.context), () => {
      this.props.onCancel?.(evt);
    });
  }

  handleMouseOverDependency(evt, row) {
    this.setState({
      extraPropsKeyMapSelected: new Map(row.requiredBy.map(entity => [entity.key, dependencyRowHighLight])),
    });
  }

  handleMouseLeaveDependency() {
    this.setState({extraPropsKeyMapSelected: new Map()});
  }

  handleMouseOverSelected(evt, row) {
    this.setState({
      extraPropsKeyMapDependency: new Map(row.dependencies.map(entity => [entity, dependencyRowHighLight])),
    });
  }

  handleMouseLeaveSelected() {
    this.setState({extraPropsKeyMapDependency: new Map()});
  }

  handleProvision() {
    const items = [...this.state.selectedGridRows, ...this.state.dependencyGridRows];

    return this.context.fetcher.spawn(createSecPolicies, {items, note: this.formik?.values.note});
  }

  handleRevert() {
    const {selectedGridRows, dependencyGridRows} = this.state;

    return this.context.fetcher.spawn(revertSecPolicies, {items: [...selectedGridRows, ...dependencyGridRows]});
  }

  handleProvisionAll() {
    return this.context.fetcher.spawn(createAllSecPolicies, {note: this.formik?.values.note});
  }

  handleButtonInProgress() {
    const {revert} = this.state;

    if (revert) {
      this.setState({openingRevert: true});
    } else {
      this.setState({openingProvision: true});
    }
  }

  handleProgressDone({onProgressDone}) {
    this.setState({onLoaderDone: onProgressDone, openingRevert: false, openingProvision: false});
  }

  async handleOnDone() {
    await this.props.onDone();
  }

  fetchProvisionObjects() {
    const {fetcher} = this.context;
    const {revert, objectsToProvision, objectsToRevert} = this.state;

    const selection = {
      operation: revert ? 'revert' : 'commit',
      change_subset: revert ? {...objectsToRevert} : {...objectsToProvision},
    };

    return fetcher.fork(fetchProvision, {selection});
  }

  provideModalMachineConfig(defaultConfig) {
    const extraStates = {
      states: {
        modal: {
          initial: 'fetchProvision',
          states: {
            fetchProvision: {
              entry: ['handleButtonInProgress', 'createProgressPromise'],
              initial: 'fetch',
              states: {
                fetch: {
                  invoke: {
                    src: 'fetchProvisionObjects',
                    onDone: {
                      actions: 'handleProgressDone',
                      target: 'completeFetch',
                    },
                  },
                },
                completeFetch: {
                  invoke: {
                    src: 'resolveProgressPromise',
                    onDone: 'complete',
                  },
                },
                complete: {
                  type: 'final',
                },
              },
              onDone: 'open',
            },
            open: {
              initial: 'initial',
              on: {
                SUBMIT: {
                  target: 'submitting',
                  actions: assign({
                    stateArgs: (ctx, {args}) => args,
                    selectedGridRowsToProvision: () => this.state.selectedGridRows,
                    dependencyGridRowsToProvision: () => this.state.dependencyGridRows,
                    tallyItemsToProvision: () => this.state.tallyItems,
                    provisionInProgress: true,
                  }),
                },
              },
              states: {
                initial: {
                  always: [
                    {
                      target: 'revert',
                      cond: () => this.state.revert,
                      actions: assign({processService: () => this.handleRevert}),
                    },
                    {
                      target: 'provisionAll',
                      cond: () => this.state.provisionAll,
                      actions: assign({processService: () => this.handleProvisionAll}),
                    },
                    {target: 'provision', actions: assign({processService: () => this.handleProvision})},
                  ],
                },
                revert: {},
                provision: {},
                provisionAll: {},
              },
            },
          },
        },
      },
    };

    return _.merge(defaultConfig, extraStates);
  }

  renderModal() {
    const {
      selectedGridSettings,
      dependencyGridSettings,
      dependencyGridRows,
      theme,
      revert,
      extraPropsKeyMapSelected,
      extraPropsKeyMapDependency,
      hasListenOnlyMember,
      openingProvision,
      openingRevert,
      onLoaderDone,
    } = this.state;

    const {isCSFrame, unsavedWarningTitle, unsavedWarningMessage, revertWarningTitle} = this.props;

    const unableToRevert = dependencyGridRows.some(dependency => dependency.data.update_type !== 'delete');
    const unableToProvision = dependencyGridRows.some(dependency => !dependency.data.caps.includes('provision'));

    return (
      <ModalMachine
        onClose={this.handleClose}
        modalProps={{
          full: true,
          ...(isCSFrame && {maxWidth: 450, maxHeight: 200}),
        }}
        customConfig={this.provideModalMachineConfig}
        initialContext={{
          openingProvision,
          onLoaderDone,
          openingRevert,
        }}
        actions={{
          handleButtonInProgress: this.handleButtonInProgress,
          handleProgressDone: this.handleProgressDone,
        }}
        services={{
          fetchProvisionObjects: this.fetchProvisionObjects,
          process: ctx => ctx.processService(),
          onProcessDone: this.handleOnDone,
        }}
        skipStates={['modal.fetchProvision']}
      >
        {({matches, context}, service) => {
          this.storeUnsubscribe = this.context.store.subscribe(() => {
            if (service.initialized && matches('modal.open') && this.state.selectedGridRows.length === 0) {
              service.send('CANCEL');
            }
          });

          const formProps = {
            enableReinitialize: true,
            schemas: this.schemas,
            initialValues: this.initials,
            onSubmit: _.noop,
            allowLeaveOnDirty: true,
          };

          const content = (provisionWarningMessage, formik) => {
            this.formik = formik;

            const selectedGridRows = context.provisionInProgress
              ? context.selectedGridRowsToProvision
              : this.state.selectedGridRows;
            const dependencyGridRows = context.provisionInProgress
              ? context.dependencyGridRowsToProvision
              : this.state.dependencyGridRows;

            return (
              <Fragment key="content">
                {context.notifications.length > 0 && <Notifications>{context.notifications}</Notifications>}
                {isCSFrame ? null : (
                  <div data-tid="provision-review-selected">
                    <GridLocal
                      offset="0px"
                      settings={selectedGridSettings}
                      rows={selectedGridRows}
                      onClick={this.handleClick}
                      theme={theme}
                      extraPropsKeyMap={extraPropsKeyMapSelected}
                      onMouseOver={this.handleMouseOverSelected}
                      onMouseLeave={this.handleMouseLeaveSelected}
                    />
                  </div>
                )}
                {dependencyGridRows.length > 0 && !isCSFrame && (
                  <div className={theme.provisionDependencies} data-tid="provision-dependencies">
                    <div className={theme.provisionPageHelp}>
                      <strong data-tid="provision-dependencies-title">
                        {intl(revert ? 'Provision.Revert.Dependencies' : 'Provision.Dependencies')}
                      </strong>
                      <span className={theme.provisionPageHelpSub} data-tid="provision-dependencies-sub">
                        {((revert && !unableToRevert) || (!revert && !unableToProvision)) && (
                          <>
                            <StatusIcon status="warning" theme={theme} themePrefix="warning-" />
                            <span data-tid="provision-dependencies-sub-title">
                              {intl(revert ? 'Provision.Revert.FollowingItems' : 'Provision.FollowingItemsAlso')}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                    <GridLocal
                      offset="0px"
                      settings={dependencyGridSettings}
                      rows={dependencyGridRows}
                      onClick={this.handleClick}
                      theme={theme}
                      extraPropsKeyMap={extraPropsKeyMapDependency}
                      onMouseOver={this.handleMouseOverDependency}
                      onMouseLeave={this.handleMouseLeaveDependency}
                    />
                  </div>
                )}
                <AttributeList theme={attributeStyle}>
                  {[
                    ...(isCSFrame
                      ? []
                      : [
                          {
                            tid: 'summary',
                            key: <Form.Label name="summary" title={intl('Common.Summary')} />,
                            value: (
                              <Tally
                                justify="left"
                                tallyItems={
                                  context.provisionInProgress ? context.tallyItemsToProvision : this.state.tallyItems
                                }
                                theme={theme}
                              />
                            ),
                          },
                        ]),
                    ...(revert || isCSFrame
                      ? []
                      : [
                          {
                            tid: 'provision-note',
                            key: <Form.Label name="note" title={intl('Provision.Note')} />,
                            value: (
                              <Form.Textarea name="note" placeholder={intl('Provision.Note')} tid="note" size="full" />
                            ),
                          },
                        ]),
                    {
                      tid: 'provision-info',
                      value: (
                        <div data-tid="provision-confirm">
                          {provisionWarningMessage && (
                            <div className={theme.warningBlock}>
                              <StatusIcon status="warning" theme={theme} themePrefix="warning-" />
                              {provisionWarningMessage}
                            </div>
                          )}
                          {!revert && (
                            <div>
                              <StatusIcon status="info" theme={theme} themePrefix="info-" />
                              {isCSFrame ? unsavedWarningMessage : intl('Provision.ProvisioningPushesToVEN')}
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                </AttributeList>
                {((revert && unableToRevert) || (!revert && unableToProvision)) && (
                  <div data-tid="provision-error">
                    <Notifications>
                      {[
                        {
                          type: 'warning',
                          title: revert ? intl('Provision.Revert.Unable') : intl('Provision.Unable'),
                          message: revert
                            ? `${intl('Provision.Revert.SelectedItemsHaveDependencies')} ${intl(
                                'Provision.Revert.RemoveOrSelectDependencies',
                              )}`
                            : intl('Provision.FollowingItemsNeed'),
                        },
                      ]}
                    </Notifications>
                  </div>
                )}
              </Fragment>
            );
          };

          const confirmProps = {
            progressCompleteWithCheckmark: true,
            onProgressDone: context.onProgressDone,
            progress: matches('modal.submitting.progress'),
          };
          const footer = (title, contentMessage, tid, confirmText) => ({
            children: (send, options) =>
              unableToProvision || (revert && unableToRevert) ? (
                <Button tid="ok" text={intl('Common.OK')} onClick={_.partial(send, {type: 'CANCEL'})} />
              ) : (
                <>
                  <Button
                    tid="cancel"
                    noFill
                    text={intl('Common.Cancel')}
                    onClick={_.partial(send, {type: 'CANCEL'})}
                  />
                  <Button
                    tid={tid}
                    text={confirmText}
                    onClick={_.partial(send, {type: 'SUBMIT', args: [title, contentMessage, tid, confirmText]})}
                    disabled={options.isValid === false && this.props.requireProvisionNote && !revert}
                    {...confirmProps}
                  />
                </>
              ),
          });

          const listenOnlyMemberMessage = hasListenOnlyMember && (
            <div className={theme.warningMessage}>
              <div>{intl('Provision.ListenOnlyMember')}</div>
            </div>
          );

          const renderModalState = (title, contentMessage, tid, confirmText) => ({
            formProps,
            header: {props: {title}},
            content: {children: (send, formik) => content(contentMessage, formik)},
            footer: footer(title, contentMessage, tid, confirmText),
          });

          if (matches('modal.open.provision')) {
            return renderModalState(
              isCSFrame ? unsavedWarningTitle : intl('Provision.SelectedItems'),
              listenOnlyMemberMessage,
              'provision',
              isCSFrame ? intl('Provision.ConfirmSave') : intl('Provision.Confirm'),
            );
          }

          if (matches('modal.open.provisionAll')) {
            return renderModalState(
              isCSFrame ? intl('Provision.ConfirmChanges') : intl('Provision.SelectedItems'),
              intl('Provision.ProvisioningPushesToVENWithoutDependencies'),
              'provisionall',
              intl('Provision.All'),
            );
          }

          if (matches('modal.open.revert')) {
            return renderModalState(
              isCSFrame ? revertWarningTitle : intl('Provision.Revert.DiscardChangesToItems'),
              !unableToRevert && intl('Provision.RevertingDiscardDraft'),
              'revert',
              intl('Provision.Revert.Now'),
            );
          }

          if (matches('modal.submitting')) {
            return renderModalState(...context.stateArgs);
          }

          if (matches('modal.error')) {
            return {
              header: {
                props: isCSFrame
                  ? {title: revert ? intl('Provision.Revert.DiscardRulesetChanges') : intl('Provision.ConfirmChanges')}
                  : {title: revert ? intl('Provision.Revert.DiscardChangesToItems') : intl('Provision.SelectedItems')},
              },
              content: (
                <TypedMessages>
                  {[
                    {
                      icon: 'error',
                      content: context.errors?.message,
                    },
                  ]}
                </TypedMessages>
              ),
              footer: [{type: 'CLOSE', buttonProps: {text: intl('Common.Close')}}],
            };
          }
        }}
      </ModalMachine>
    );
  }

  render() {
    const {isCSFrame} = this.props;
    // Default cancel button looks like link with Cancel text
    const defaultProvisionProps = {
      icon: 'provision',
      color: 'primary',
      tid: 'provision',
      text: isCSFrame ? intl('Common.Save') : intl('Common.Provision'),
    };
    // Default confirm button has Confirm text
    const defaultRevertProps = {
      icon: 'revert',
      color: 'standard',
      tid: 'revert',
      text: intl('Common.Revert'),
    };

    const {
      counter,
      counterProvision,
      counterRevert,
      objectsToProvision,
      objectsToRevert,
      userIsReadOnly,
      theme,
      error,
      openingProvision,
      openingRevert,
      showModal,
      onLoaderDone,
      provisionProps,
      revertProps,
      routeName,
      modalOnly,
    } = this.state;

    let provisionDisabled =
      _.isEmpty(objectsToProvision) ||
      Object.keys(objectsToProvision).some(objType => !objectsToProvision[objType].length);

    if (routeName.includes('securitysettings')) {
      provisionDisabled ||= !isAPIAvailable('firewall_settings.update');
    }

    const revertDisabled =
      _.isEmpty(objectsToRevert) || Object.keys(objectsToRevert).some(objType => !objectsToRevert[objType].length);

    return (
      <div className={cx(stylesUtils.gap, stylesUtils.gapHorizontal, {[styles.hide]: modalOnly})}>
        <Button
          textIsHideable
          theme={theme}
          counter={counterProvision ?? counter}
          insensitive={openingRevert}
          disabled={counterProvision === 0 || !isAPIAvailable('sec_policies.create') || provisionDisabled}
          progress={openingProvision}
          progressError={Boolean(error)}
          onProgress100={onLoaderDone}
          onMouseEnter={provisionDisabled ? undefined : this.handleProvisionEnter}
          onMouseLeave={provisionDisabled ? undefined : this.handleButtonLeave}
          onClick={this.handleProvisionClick}
          {...defaultProvisionProps}
          {...provisionProps}
        />

        {!this.props.hideRevertButton && (
          <Button
            textIsHideable
            theme={theme}
            counter={userIsReadOnly ? 0 : counterRevert ?? counter}
            counterColor="yellow"
            insensitive={!userIsReadOnly && openingProvision}
            disabled={counterRevert === 0 || !isAPIAvailable('sec_policies.delete') || revertDisabled}
            progress={openingRevert}
            progressError={Boolean(error)}
            onProgress100={onLoaderDone}
            onMouseEnter={revertDisabled ? undefined : this.handleRevertEnter}
            onMouseLeave={revertDisabled ? undefined : this.handleButtonLeave}
            onClick={this.handleRevertClick}
            {...defaultRevertProps}
            {...revertProps}
          />
        )}
        {showModal && this.renderModal()}
      </div>
    );
  }
}
