/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {connect} from 'utils/redux';
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {Form, Badge, Modal, TypedMessages, Link} from 'components';
import stylesUtils from 'utils.css';
import cx from 'classnames';
import {isEnhancedDataCollectionEnabled, isEdge, isCrowdstrike} from 'containers/App/AppState';
import styles from '../PairingProfile/Item/View/PairingProfileView.css';

const specificVisibility = ['flow_summary', 'enhanced_data_collection'];

@connect(state => ({
  hasEnhancedDataCollection: isEnhancedDataCollectionEnabled(state),
  edgeEnabled: isEdge(state),
  crowdstrikeEnabled: isCrowdstrike(state),
}))
export default class EnforcementBoundariesVisibilityForm extends Component {
  static propTypes = {
    // name of the form property
    name: PropTypes.string.isRequired,
    // hasEnhancedDataCollection is a licensed feature
    hasEnhancedDataCollection: PropTypes.bool,
    // enforcementMode is selective enforcement mode
    enforcementMode: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      closeAlertErrorModal: false,
    };

    this.showEnhanced = this.showEnhanced.bind(this);
    this.handleAlertModal = this.handleAlertModal.bind(this);
    this.getVisibility = this.getVisibility.bind(this);
    this.onHandleWhenBecomesTrueFalse = this.onHandleWhenBecomesTrueFalse.bind(this);
    this.onHandleWhen = this.onHandleWhen.bind(this);
  }

  // Visibility
  getVisibility() {
    const {hasEnhancedDataCollection, name, enforcementMode, edgeEnabled, crowdstrikeEnabled} = this.props;
    const {closeAlertModal} = this.state;

    const classNameContent = styles.content;

    const radioGroupsProps = {
      name,
      whenBecomesTrue: this.onHandleWhenBecomesTrueFalse,
      whenBecomesFalse: this.onHandleWhenBecomesTrueFalse,
      when: this.onHandleWhen,
    };

    // When enforcement mode is not idle then radio options will show
    const showOption = enforcementMode !== 'idle';

    // When enforcement mode is not full then certain visibility level cannot be selected
    const disabled = enforcementMode !== 'full' || crowdstrikeEnabled;

    let ele = null;

    // Note: Enhanced Data Collection is only available when User has a license if they don't then radio button is disabled.
    if (showOption) {
      ele = (
        <>
          <Form.RadioGroup {...radioGroupsProps}>
            <Form.Radio
              tid="visibility-flow-off"
              value="flow_off"
              disabled={disabled}
              label={intl('Common.Off')}
              subLabel={
                edgeEnabled ? intl('Map.FlowVisibility.NoneDetailEdge') : intl('Map.FlowVisibility.NoDetailDesc')
              }
            />
            <Form.Radio
              tid="visibility-flow-drops"
              value="flow_drops"
              disabled={disabled}
              label={intl('Common.Blocked')}
              subLabel={
                edgeEnabled ? intl('Map.FlowVisibility.BlockTrafficEdge') : intl('Map.FlowVisibility.BlockTraffic')
              }
            />
            <Form.Radio
              tid="visibility-flow-summary"
              value="flow_summary"
              label={intl('Map.FlowVisibility.BlockPlusAllowed')}
              subLabel={
                edgeEnabled
                  ? enforcementMode === 'full'
                    ? intl('Map.FlowVisibility.BlockAllowedEdgeEnforce')
                    : intl('Map.FlowVisibility.BlockAllowedEdge')
                  : intl('Map.FlowVisibility.BlockAllowed')
              }
            />
            {!edgeEnabled && (
              <Form.Radio
                tid="visibility-enhanced_data_collection"
                value="enhanced_data_collection"
                label={this.showEnhanced()}
                preventInsensitive={!hasEnhancedDataCollection}
                disabled={!hasEnhancedDataCollection}
                subLabel={intl('Map.FlowVisibility.EnhancedDataCollectionDesc')}
              />
            )}
          </Form.RadioGroup>
          {!hasEnhancedDataCollection && closeAlertModal && this.showInfo()}
        </>
      );
    } else {
      ele = (
        <div className={stylesUtils.gapXSmall}>
          <div>{intl('Common.Limited')}</div>
          {edgeEnabled ? <div className={classNameContent}>{intl('Map.FlowVisibility.LimitedEdge')}</div> : null}
        </div>
      );
    }

    return ele;
  }

  handleAlertModal() {
    this.setState(state => ({closeAlertModal: !state.closeAlertModal}));
  }

  // Set specific radio value during a toggle between enforcement mode when is true->false, false->true by updating the form with
  // setFieldValue()
  onHandleWhenBecomesTrueFalse(form, field) {
    const {enforcementMode} = this.props;
    const {name, value} = field;

    const visbilityLevelInitial = form.initialValues[name];

    // Important to maintain the 'touched[name]: true' thus don't need to call setFieldTouched()

    // 1) When enforcement mode is 'selective or visibility_only' there are only two available choices for
    // visibility_levels: 'Blocked+Allowed ('flow_summary'), 'Blocked+Allowed ('flow_summary') or 'Enhanced Data Collection' ('enhanced_data_collection') to be set.
    // 2) When enforcement mode is 'full' then all visibility levels radios are available to be set
    if (['selective', 'visibility_only'].includes(enforcementMode)) {
      if (specificVisibility.includes(value)) {
        form.setFieldValue(name, value);
      } else if (
        specificVisibility.includes(visbilityLevelInitial) &&
        enforcementMode === form.initialValues.enforcementMode.value
      ) {
        // Set back the initial value
        form.setFieldValue(name, visbilityLevelInitial);
      } else {
        // Reset to empty when visibility_level doesn't match the proper enforcement mode
        form.setFieldValue(name, '');
      }
    } else if (enforcementMode === 'full') {
      // Either show the current selected value or initial value
      form.setFieldValue(name, value || visbilityLevelInitial);
    }
  }

  // Handle when() toggle[true, false] depending on enforcement and visibility level selection
  // Note: Don't call setFieldValue() in this method
  onHandleWhen(name, value, form) {
    const {enforcementMode} = this.props;

    // When a initialValues.visibility_level exist and doesn't match the current visibility_level
    // and the selected enforcementMode matches initialValues.enforcementMode then return 'true' to indicate user is re-selecting
    // the initial enforcementMode and visibility_level to reset back in onHandleWhenBecomesTrueFalse()
    if (
      form.initialValues[name] &&
      form.initialValues[name] !== value &&
      enforcementMode === form.initialValues.enforcementMode.value
    ) {
      return true;
    }

    // When enforcementMode is selective or visibility_only
    if (['selective', 'visibility_only'].includes(enforcementMode)) {
      // When value is not a specific visibility level then return false
      // When value is a specific visibility level then return true
      return specificVisibility.includes(value);
    }

    // When initial value matches current selected enforcement value or value exist
    if (form.initialValues.enforcementMode.value === enforcementMode || value) {
      return true;
    }

    return false;
  }

  showInfo() {
    const buttonProps = {
      tid: 'close',
      text: intl('Common.Close'),
    };

    const link = (
      <Link href="https://www.illumio.com/contact-us" target="_blank">
        {intl('Map.FlowVisibility.IllumioEnhancedDataContact')}
      </Link>
    );

    return (
      <Modal.Alert
        buttonProps={buttonProps}
        noCloseIcon
        small
        title={intl('Map.FlowVisibility.EnhancedDataCollection')}
        onClose={this.handleAlertModal}
      >
        <TypedMessages>
          {[
            {
              content: (
                <>
                  {intl('Map.FlowVisibility.IllumioEnhancedDataDesc')}
                  <br />
                  {link}
                </>
              ),
            },
          ]}
        </TypedMessages>
      </Modal.Alert>
    );
  }

  showEnhanced() {
    const {hasEnhancedDataCollection} = this.props;
    let element;

    if (hasEnhancedDataCollection) {
      element = intl('Map.FlowVisibility.EnhancedDataCollection');
    } else {
      element = (
        <div onClick={this.handleAlertModal} className={cx(stylesUtils.gapMedium, stylesUtils.gapHorizontal)}>
          <div>{intl('Map.FlowVisibility.EnhancedDataCollection')}</div>
          <Badge type="new">{intl('Common.LearnMore')}</Badge>
        </div>
      );
    }

    return element;
  }

  render() {
    return this.getVisibility();
  }
}
