/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'utils/redux';
import * as PropTypes from 'prop-types';
import {MultiItemSelect, SingleItemSelect} from '../Selectors';
import {reactUtils} from 'utils';
import {getDisplayNamesWithFixedTypes} from 'containers/Label/LabelSettings/LabelSettingState';

const labelHintMap = {
  role: intl('Labels.RoleHint'),
  app: intl('Labels.ApplicationHint'),
  env: intl('Labels.EnvironmentHint'),
  loc: intl('Labels.LocationHint'),
  allow: intl('Labels.AllowLabelsHnt'),
};

const areLabelsEqual = (initialValues = [], newValues = []) =>
  initialValues.length === newValues.length &&
  initialValues
    .map(label => label.href)
    .sort()
    .join(',') ===
    newValues
      .map(label => label.href)
      .sort()
      .join(',');

@connect(state => ({labelTypesNameObj: getDisplayNamesWithFixedTypes(state)}))
export default class FormLabelSelectorOld extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired, // label type, aka key
    name: PropTypes.string.isRequired, // name of the form field
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    tid: PropTypes.string.isRequired,
    size: PropTypes.string,
    multiple: PropTypes.bool, // true = accept more than one value
    errorWithoutTouch: PropTypes.bool, // true means red border and message even if untouched.
    // formik's methods
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    handleResetProperties: PropTypes.func,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  };

  static defaultProps = {
    handleResetProperties: _.noop,
    multiple: false,
    errorWithoutTouch: false,
  };

  constructor(props) {
    super(props);

    // formik only does reference compare, use the original value if href is the same
    this.initValue = props.value;
    this.initHref = props.value ? props.value.href : undefined;
    this.state = {selected: props.value};
    this.handleLabelSelectorChange = this.handleLabelSelectorChange.bind(this);
  }

  handleLabelSelectorChange(selection = []) {
    const {name, setFieldValue, setFieldTouched, multiple} = this.props;
    let selected;

    if (multiple) {
      selected = selection;

      if (areLabelsEqual(selected, this.initValue)) {
        selected = this.initValue;
      }
    } else {
      selected = selection[0];

      if (selected?.href === this.initHref) {
        selected = this.initValue;
      }
    }

    setFieldTouched(name, true);
    setFieldValue(name, selected);
    reactUtils.setStateAsync({selected}, this);
  }

  render() {
    const {name, tid, size, multiple, value, errors, touched, errorWithoutTouch, labelTypesNameObj, ...selectorProps} =
      this.props;
    const type = this.props.type || name;
    const {selected} = this.state;

    Object.assign(selectorProps, {
      initialItems: _.isEmpty(selected) ? [] : Array.isArray(selected) ? selected : [selected],
      showResultsFooter: true,
      placeholder: multiple && !_.isEmpty(value) ? '' : labelHintMap[multiple ? 'allow' : name], // value can be empty array
      objects: [{type: 'labels', key: type}],
      onSelectionChange: this.handleLabelSelectorChange,
      categories: [{value: `${labelTypesNameObj[type]} Label`, categoryKey: 'labels'}],
      showMatchingResults: true,
    });

    // An error border will be displayed by default if the name for this input appears as a key in the errors object.
    const error = errorWithoutTouch || (_.get(touched, name) === true && _.has(errors, name));
    // An error message will be displayed if the value for this inputs key is not undefined.
    const errorMessage = error && _.get(errors, name);

    if (errorMessage) {
      selectorProps.errorMessage = errorMessage;
    }

    return (
      <div data-tid={`comp-combobox-${tid}`}>
        {multiple ? (
          <MultiItemSelect hideCategory={false} {...selectorProps} />
        ) : (
          <SingleItemSelect {...selectorProps} />
        )}
      </div>
    );
  }
}
