/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {UserName} from 'containers';
import {enforcementModeView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {populateFacetCategory, populateLabelsCategories} from 'containers/Selector/GridFilter/GridFilterUtils';
import {createSelector} from 'reselect';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {venType} from 'containers/PairingProfile/PairingProfileUtils';
import {edge} from 'api/apiUtils';

export const resourceType = 'pairing_profiles';

export const categories = createSelector(
  [getDisplayNames, getTypeInitialRegExp],
  (labelTypesNameObj, labelTypeInitialRegExp) => [
    populateFacetCategory({id: 'name', name: intl('Common.Name'), resourceType}),
    ...populateLabelsCategories({resourceType, hasNoLabels: true, labelTypesNameObj, labelTypeInitialRegExp}),
    populateFacetCategory({id: 'description', name: intl('Common.Description'), resourceType}),
    ...(edge
      ? []
      : [
          {
            id: 'ven_type',
            name: intl('VEN.EnforcementNodeType'),
            resources: {
              ven_type: {
                statics: [
                  {id: 'server', value: venType.server.name},
                  {id: 'endpoint', value: venType.endpoint.name},
                  {id: 'specified_during_activation', value: venType.specified_during_activation.name},
                ],
              },
            },
          },
        ]),
  ],
);

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([getLabelsColumn], labelsColumn => ({
  id: 'pairingprofilelist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('PairingProfiles.Status'),
      value: 'enabled',
      format: ({value}) => (value ? intl('Common.Running') : intl('Common.Stopped')),
    },
    venType: {
      header: intl('VEN.EnforcementNodeType'),
      value: 'ven_type',
      format: ({value}) => venType[value]?.name,
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    state: {
      header: intl('Common.Enforcement'),
      value: ({row}) => row.data?.enforcement_mode,
      format: ({value}) => <span data-tid="elem-text">{enforcementModeView()[value]?.name}</span>,
    },
    labels: labelsColumn,
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
    lastUse: {
      isDate: 'L_HH_mm_ss',
      header: intl('PairingProfiles.LastUse'),
      value: 'last_pairing_at',
      format: ({value}) => value || intl('Common.Never'),
    },
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to cellFormat function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to cellFormat function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to cellFormat function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'minmax(100px, auto)'},
      {columns: ['venType'], size: 'minmax(150px, auto)'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['state'], size: 'max-content'},
      {columns: ['labels'], size: 'minmax(200px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(100px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(100px, auto)'},
      {columns: ['lastUse'], size: 'minmax(100px, auto)'},
      {columns: ['desc'], size: 'minmax(80px, auto)'},
    ],
    {
      maxWidth: 1280,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status', 'state', 'venType'], size: 'minmax(120px, auto)'},
            {columns: ['labels'], size: 'minmax(200px, auto)'},
            {columns: ['updatedAt', 'updatedBy', 'lastUse'], size: 'minmax(100px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status', 'state', 'venType'], size: 'minmax(120px, auto)'},
          {columns: ['labels'], size: 'minmax(200px, auto)'},
          {columns: ['updatedAt', 'updatedBy', 'lastUse'], size: 'minmax(100px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 980,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status', 'state', 'venType'], size: 'minmax(120px, auto)'},
            {columns: ['labels'], size: 'minmax(200px, auto)'},
            {columns: ['updatedAt', 'updatedBy', 'lastUse', 'desc'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status', 'state', 'venType'], size: 'minmax(120px, auto)'},
          {columns: ['labels'], size: 'minmax(200px, auto)'},
          {columns: ['updatedAt', 'updatedBy', 'lastUse', 'desc'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'status', 'state', 'venType'], size: 'minmax(100px, auto)'},
            {columns: ['labels'], size: 'minmax(120px, auto)'},
            {columns: ['updatedAt', 'updatedBy', 'lastUse', 'desc'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'status', 'state', 'venType'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(120px, auto)'},
          {columns: ['updatedAt', 'updatedBy', 'lastUse', 'desc'], size: 'minmax(140px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {
              columns: ['name', 'status', 'updatedAt', 'updatedBy', 'lastUse', 'desc', 'venType'],
              size: 'minmax(140px, auto)',
            },
            {columns: ['labels'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {
            columns: ['name', 'status', 'updatedAt', 'updatedBy', 'lastUse', 'desc', 'venType'],
            size: 'minmax(140px, auto)',
          },
          {columns: ['labels'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
