/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import type {LinkProps} from '../../Link/Link';
import Pill, {type PillProps} from '../Pill';
import {hrefUtils} from 'utils';
import type {Group as GroupData} from 'illumio';

export type GroupProps = PillProps & {
  // Group object
  value: GroupData;
  // Option to designate which query params to pass to a Group view
  queryParams?: {
    pversion?: number | string;
    tab?: string;
  };
  link?: LinkProps;
};

export default class Group extends PureComponent<GroupProps> {
  element: HTMLElement | null | undefined = null;
  constructor(props: GroupProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  render() {
    const {
      value: {id, href, name, isAdmin},
      children,
      queryParams = {pversion: 'draft', tab: 'inboundpolicy'},
      link = {},
      ...pillProps
    } = this.props;

    Object.assign(pillProps, {
      group: true,
      ...(isAdmin && {icon: 'admin'}),
      ...(!pillProps.onClick && {
        link: {to: 'groups.view', params: {group: id || hrefUtils.getId(href), ...queryParams}, ...link},
      }),
      ref: this.saveRef,
    });

    if (!pillProps.noContextualMenu) {
      pillProps.contextualType ??= intl('Common.Group');
      pillProps.contextualCopyValue ??= name ?? id ?? hrefUtils.getId(href);
    }

    return <Pill {...pillProps}>{name || children}</Pill>;
  }
}
