/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect, type ConnectedProps} from 'utils/redux';
import styles from './RollingUpgradeReminderPopup.css';
import {ReminderPopup} from 'components';
import {isHealthEnabled} from 'containers/User/UserState';
import {getRemainingRollingUpgradeCount, getRollingUpgradeCount} from 'containers/Health/HealthState';

const connector = connect(state => ({
  healthIsEnabled: isHealthEnabled(state),
  rollingUpgradeCount: getRollingUpgradeCount(state),
  remainingRollingUpgradeCount: getRemainingRollingUpgradeCount(state),
}));

type ReminderPopupsProps = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

export default connector(
  class ReminderPopups extends Component<ReminderPopupsProps> {
    constructor(props: ReminderPopupsProps) {
      super(props);
      this.renderMessage = this.renderMessage.bind(this);
    }

    renderMessage() {
      const {rollingUpgradeCount, remainingRollingUpgradeCount} = this.props;
      const completedRollingUpgradeCount =
        rollingUpgradeCount > remainingRollingUpgradeCount ? rollingUpgradeCount - remainingRollingUpgradeCount : 0;

      return (
        <>
          <div className={styles.completed}>{intl('Health.CompletedCount', {count: completedRollingUpgradeCount})}</div>
          <div className={styles.pending}>{intl('Health.PendingCount', {count: remainingRollingUpgradeCount})}</div>
        </>
      );
    }

    render() {
      const {healthIsEnabled, rollingUpgradeCount, onClose} = this.props;
      const healthLink = healthIsEnabled ? 'health.list' : undefined;

      return (
        <ReminderPopup
          tid="rolling-upgrade"
          title={intl('Health.PceInRollingUpgrade')}
          message={this.renderMessage}
          type="warning"
          duration={{time: rollingUpgradeCount, unit: intl('Common.TotalPCEs')}}
          onClose={onClose}
          link={healthLink}
          linkLabel={intl('Health.ViewPCEHealth')}
          theme={styles}
        />
      );
    }
  },
);
