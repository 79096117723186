/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {hasOptionalColumns} from 'components/Grid/GridUtils';
import {getVenName, venStatuses, getVenConditions, getActivationType, venTypeMap, getVenVersion} from '../VenUtils';
import {DateTimeCustomPicker, VersionRangePicker, DateTimeSingleCustomPicker} from 'components';
import {edge} from 'api/apiUtils';
import {createSelector} from 'reselect';
import {getLabelsColumn} from 'containers/Label/LabelConfig';
import {
  populateAutoCompleteCategory,
  populateFacetCategory,
  populateLabelsCategories,
} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {getSecuritySettings} from 'containers/SecuritySettings/SecuritySettingsState';
import {getWorkloadsIgnoreScope} from 'containers/Workload/List/WorkloadListState';
import {isUserScoped} from 'containers/User/UserState';
import {getRouteParams} from 'containers/App/AppState';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';

export const resourceType = 'vens';

const fromPresets = [
  {name: 'anytime', label: 'Anytime', value: null},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const validateDisconnectedBefore = val => val <= new Date();

const toPresets = [
  {name: 'now', label: 'Now', value: new Date()},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const populateDateTimePickerResources = id => ({
  [id]: {
    type: 'container',
    container: DateTimeCustomPicker,
    containerProps: {
      noBorder: true,
      fromPresets,
      toPresets,
      getContainerProps: ({onDone, onCancel}) => ({
        onClose: onCancel,
        onSave: onDone,
      }),
    },
    enableFocusLock: true,
  },
});

const getStaticPolicyScopes = createSelector(
  getSecuritySettings,
  securitySettings => securitySettings.draft && securitySettings.draft.static_policy_scopes,
);

export const categories = createSelector(
  [
    getClusters,
    getStaticPolicyScopes,
    getWorkloadsIgnoreScope,
    getLocalFQDN,
    isUserScoped,
    getRouteParams,
    getTypeInitialRegExp,
    getDisplayNames,
  ],
  (
    clusters,
    staticPolicyScopes,
    ignoreScope,
    localFQDN,
    userIsScoped,
    routeParams,
    labelTypeInitialRegExp,
    labelTypesNameObj,
  ) => {
    return [
      populateFacetCategory({id: 'name', name: intl('Common.Name'), resourceType}),
      ...(edge && routeParams.tab === 'venlist'
        ? []
        : populateLabelsCategories({
            resourceType: 'agent_infos',
            hasNoLabels: !edge,
            labelTypeInitialRegExp,
            labelTypesNameObj,
          })),
      ...(!userIsScoped && !edge && !__ANTMAN__
        ? [
            populateAutoCompleteCategory({
              id: 'container_clusters',
              name: intl('VEN.ContainerCluster'),
              resourceType: 'container_clusters',
            }),
          ]
        : []),
      populateFacetCategory({id: 'description', name: intl('Common.Description'), resourceType}),
      populateFacetCategory({id: 'hostname', name: intl('Common.Hostname'), resourceType}),
      populateFacetCategory({id: 'os', name: intl('Common.OS'), resourceType}),
      populateFacetCategory({id: 'ip_address', name: intl('IPLists.IPAddresses'), resourceType}),
      {
        id: 'status',
        name: intl('Common.Status'),
        resources: {
          status: {
            noSubtitle: true,
            statics: [
              {id: 'active', value: intl('Common.Active')},
              ...(edge ? [] : [{id: 'suspended', value: intl('Workloads.Status.Suspended')}]),
              {id: 'stopped', value: intl('Common.Stopped')},
              {id: 'uninstalled', value: intl('Common.Uninstalled')},
            ],
          },
        },
      },
      ...(edge
        ? []
        : [
            {
              id: 'ven_type',
              name: __ANTMAN__ ? intl('Workloads.RemoveDialog.Content.Type.Header') : intl('VEN.EnforcementNodeType'),
              resources: {
                ven_type: {
                  noSubtitle: true,
                  statics: [
                    {id: 'server', value: __ANTMAN__ ? intl('Common.Server') : intl('VEN.VEN')},
                    !__ANTMAN__ && {id: 'containerized', value: intl('VEN.CVEN')},
                    {id: 'endpoint', value: __ANTMAN__ ? intl('Edge.Endpoint') : intl('VEN.EVEN')},
                  ],
                },
              },
            },
          ]),
      {
        id: 'upgrade_pending',
        name: intl('VEN.Upgrade.UpgradePending'),
        resources: {
          upgrade_pending: {
            noSubtitle: true,
            statics: [
              {id: 'true', value: intl('Common.True')},
              {id: 'false', value: intl('Common.False')},
            ],
          },
        },
      },
      ...(staticPolicyScopes && staticPolicyScopes.length
        ? [
            {
              id: 'security_policy_update_mode',
              name: intl('Policy.UpdateMode'),
              resources: {
                security_policy_update_mode: {
                  noSubtitle: true,
                  statics: [
                    {id: 'static', value: intl('Workloads.StaticWorkloads')},
                    {id: 'adaptive', value: intl('Workloads.AdaptiveWorkloads')},
                  ],
                },
              },
            },
          ]
        : []),
      {
        id: 'health',
        name: intl('VEN.Health'),
        resources: {
          health: {
            noSubtitle: true,
            statics: [
              {id: 'error', value: intl('Common.Error')},
              {id: 'warning', value: intl('Common.Warning')},
              {id: 'healthy', value: intl('Common.Healthy')},
            ],
          },
        },
      },
      {
        id: 'condition',
        name: intl('VEN.Condition'),
        resources: {
          condition: {
            noSubtitle: true,
            statics: [
              {id: 'agent.clone_detected', value: intl('EventUtils.VENCloneDetected')},
              {id: 'agent.missing_heartbeats_after_upgrade', value: intl('EventUtils.VENMissingHeartbeatAfterUpgrade')},
              {id: 'agent.missed_heartbeats', value: intl('EventUtils.VENMissedHeartbeatsCheck')},
              {id: 'agent.upgrade_time_out', value: intl('EventUtils.VENUpgradeTimeOut')},
              {id: 'agent.too_many_interfaces_reported', value: intl('EventUtils.VENHasTooManyInterfacesReported')},
              ...(__ATR__ ? [{id: 'authentication_recovery', value: intl('EventUtils.VENAuthenticationMode')}] : []),
            ],
          },
        },
      },
      ...(__ATR__
        ? [
            {
              id: 'activation_type',
              name: intl('VEN.ActivationType'),
              resources: {
                activation_type: {
                  noSubtitle: true,
                  statics: [
                    {id: 'pairing_key', value: intl('Common.PairingKey')},
                    {id: 'kerberos', value: intl('VEN.Kerberos')},
                    {id: 'ca_signed_certificate', value: intl('VEN.CaCertificate')},
                    {id: 'self_signed_certificate', value: intl('VEN.SelfCertificate')},
                  ],
                },
              },
            },
          ]
        : []),
      ...(!ignoreScope && clusters.length > 1
        ? [
            {
              id: 'active_pce_fqdn',
              name: intl('Common.PCE'),
              resources: {
                active_pce_fqdn: {
                  noSubtitle: true,
                  statics: clusters.reduce((result, cluster) => {
                    if (cluster) {
                      const label =
                        cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn;

                      result.push({id: cluster.fqdn, value: label});
                    }

                    return result;
                  }, []),
                },
              },
            },
          ]
        : []),
      ...(staticPolicyScopes && staticPolicyScopes.length
        ? [
            {
              id: 'security_policy_received_at',
              name: intl('Workloads.PolicyLastReceived'),
              resources: populateDateTimePickerResources('security_policy_received_at'),
            },
          ]
        : []),
      {
        id: 'last_heartbeat_at',
        name: intl('Workloads.HeartbeatLastReceived'),
        resources: populateDateTimePickerResources('last_heartbeat_at'),
      },
      {
        id: 'disconnected_before',
        name: intl('VEN.DisconnectedBefore'),
        maxColumns: 'auto',
        resources: {
          disconnected_before: {
            type: 'container',
            container: DateTimeSingleCustomPicker,
            containerProps: {
              fromPresets: toPresets,
              onValidate: validateDisconnectedBefore,
              getContainerProps: ({onDone, onCancel}) => ({
                onClose: onCancel,
                onSave: onDone,
              }),
              noBorder: true,
            },
            enableFocusLock: true,
          },
        },
      },
      {
        id: 'version',
        name: intl('Common.Version'),
        resources: {
          version: {
            type: 'container',
            optionProps: {textPath: 'value'},
            container: VersionRangePicker,
            containerProps: {
              noBorder: true,
              getContainerProps: ({onDone, onCancel}) => ({
                onClose: onCancel,
                onSave: onDone,
                categoryKey: 'version',
              }),
            },
            enableFocusLock: true,
          },
        },
      },
    ];
  },
);

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

const defaultTemplates = [
  [
    {columns: ['checkboxes'], size: 'max-content'},
    {columns: ['state'], size: 'minmax(100px, auto)'},
    {columns: ['conditions'], size: 'min-content'},
    {columns: ['name'], size: 'minmax(50px, auto)'},
    {columns: ['hostname'], size: 'minmax(50px, auto)'},
    {columns: ['version'], size: 'minmax(100px, auto)'},
    {columns: ['labels'], size: 'minmax(200px, auto)'},
    {columns: ['family'], size: 'minmax(75px, auto)'},
    {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
    {columns: ['ventype'], size: 'minmax(75px, auto)'},
  ],
  {
    maxWidth: 1366,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['conditions'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(50px, auto)'},
          {columns: ['hostname'], size: 'minmax(50px, auto)'},
          {columns: ['version'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(200px, auto)'},
          {columns: ['family'], size: 'minmax(75px, auto)'},
          {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
          {columns: ['ventype'], size: 'minmax(75px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(100px, auto)'},
        {columns: ['conditions'], size: 'min-content'},
        {columns: ['name'], size: 'minmax(50px, auto)'},
        {columns: ['hostname'], size: 'minmax(50px, auto)'},
        {columns: ['version'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(200px, auto)'},
        {columns: ['family'], size: 'minmax(75px, auto)'},
        {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
        {columns: ['ventype'], size: 'minmax(75px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1152,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['conditions'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(50px, auto)'},
          {columns: ['hostname'], size: 'minmax(50px, auto)'},
          {columns: ['version'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(200px, auto)'},
          {columns: ['family'], size: 'minmax(75px, auto)'},
          {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
          {columns: ['ventype'], size: 'minmax(75px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(100px, auto)'},
        {columns: ['conditions'], size: 'min-content'},
        {columns: ['name'], size: 'minmax(50px, auto)'},
        {columns: ['hostname'], size: 'minmax(50px, auto)'},
        {columns: ['version'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(200px, auto)'},
        {columns: ['family'], size: 'minmax(75px, auto)'},
        {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
        {columns: ['ventype'], size: 'minmax(75px, auto)'},
      ];
    },
  },
  {
    maxWidth: 960,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(75px, auto)'},
          {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55x, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(75px, auto)'},
        {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55px, auto)'},
      ];
    },
  },
  {
    maxWidth: 800,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(75px, auto)'},
          {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(75px, auto)'},
        {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes', 'state', 'conditions', 'heartbeat', 'ventype'], size: 'max-content'},
          {columns: ['name', 'hostname', 'version', 'family'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(150px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes', 'state', 'conditions', 'heartbeat', 'ventype'], size: 'max-content'},
        {columns: ['name', 'hostname', 'version', 'family'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(150px, auto)'},
      ];
    },
  },
];

const atrTemplates = [
  [
    {columns: ['checkboxes'], size: 'max-content'},
    {columns: ['state'], size: 'minmax(100px, auto)'},
    {columns: ['conditions'], size: 'min-content'},
    {columns: ['name'], size: 'minmax(50px, auto)'},
    {columns: ['hostname'], size: 'minmax(50px, auto)'},
    {columns: ['version'], size: 'minmax(100px, auto)'},
    {columns: ['activationtype'], size: 'minmax(100px, auto)'},
    {columns: ['labels'], size: 'minmax(150px, auto)'},
    {columns: ['family'], size: 'minmax(75px, auto)'},
    {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
    {columns: ['ventype'], size: 'minmax(75px, auto)'},
  ],
  {
    maxWidth: 1366,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['conditions'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(50px, auto)'},
          {columns: ['hostname'], size: 'minmax(50px, auto)'},
          {columns: ['version'], size: 'minmax(100px, auto)'},
          {columns: ['activationtype'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(150px, auto)'},
          {columns: ['family'], size: 'minmax(75px, auto)'},
          {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
          {columns: ['ventype'], size: 'minmax(75px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(100px, auto)'},
        {columns: ['conditions'], size: 'min-content'},
        {columns: ['name'], size: 'minmax(50px, auto)'},
        {columns: ['hostname'], size: 'minmax(50px, auto)'},
        {columns: ['version'], size: 'minmax(100px, auto)'},
        {columns: ['activationtype'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(150px, auto)'},
        {columns: ['family'], size: 'minmax(75px, auto)'},
        {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
        {columns: ['ventype'], size: 'minmax(75px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1152,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['conditions'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(50px, auto)'},
          {columns: ['hostname'], size: 'minmax(50px, auto)'},
          {columns: ['version'], size: 'minmax(100px, auto)'},
          {columns: ['activationtype'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(75px, auto)'},
          {columns: ['family'], size: 'minmax(75px, auto)'},
          {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
          {columns: ['ventype'], size: 'minmax(75px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(100px, auto)'},
        {columns: ['conditions'], size: 'min-content'},
        {columns: ['name'], size: 'minmax(50px, auto)'},
        {columns: ['hostname'], size: 'minmax(50px, auto)'},
        {columns: ['version'], size: 'minmax(100px, auto)'},
        {columns: ['activationtype'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(75px, auto)'},
        {columns: ['family'], size: 'minmax(75px, auto)'},
        {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
        {columns: ['ventype'], size: 'minmax(75px, auto)'},
      ];
    },
  },
  {
    maxWidth: 960,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state', 'conditions', 'activationtype'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(75px, auto)'},
          {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55x, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state', 'conditions', 'activationtype'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(75px, auto)'},
        {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55px, auto)'},
      ];
    },
  },
  {
    maxWidth: 800,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state', 'conditions', 'activationtype'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(75px, auto)'},
          {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['state', 'conditions', 'activationtype'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(75px, auto)'},
        {columns: ['family', 'heartbeat', 'ventype'], size: 'minmax(55px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template(columns) {
      if (hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes', 'state', 'conditions', 'heartbeat', 'ventype'], size: 'max-content'},
          {columns: ['name', 'hostname', 'version', 'family', 'activationtype'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(150px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes', 'state', 'conditions', 'heartbeat', 'ventype'], size: 'max-content'},
        {columns: ['name', 'hostname', 'version', 'family', 'activationtype'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(150px, auto)'},
      ];
    },
  },
];

export const gridSettings = createSelector([getLabelsColumn], labelsColumn => ({
  id: 'venslist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    state: {
      header: intl('Common.Status'),
      value: ({row}) => venStatuses()[row.data.status],
      sort: ({row}) => row.data.status,
    },
    conditions: {
      header: intl('VEN.Health'),
      value: ({row}) => getVenConditions(row.data.conditions, 'list'),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => getVenName(row.data),
    },
    hostname: {
      linky: true,
      header: intl('Common.Hostname'),
      value: ({row}) => row.data?.hostname,
      optional: true,
    },
    version: {
      header: intl('Common.Version'),
      value: ({row}) => getVenVersion(row.data),
    },
    ...(__ATR__ && {
      activationtype: {
        header: intl('VEN.ActivationType'),
        value: ({row}) => getActivationType(row.data.activation_type),
        optional: true,
      },
    }),
    labels: labelsColumn,
    family: {
      header: intl('Common.OS'),
      value: ({row}) => row.data.os_id,
    },
    heartbeat: {
      header: intl('VEN.LastHeartbeat'),
      value: 'last_heartbeat_at',
      optional: true,
      isDate: true,
    },
    ventype: {
      header: __ANTMAN__ ? intl('Workloads.RemoveDialog.Content.Type.Header') : intl('VEN.EnforcementNodeType'),
      value: ({row}) => venTypeMap.get(row.data.ven_type),
    },
  },

  templates: __ATR__ ? atrTemplates : defaultTemplates,
}));
