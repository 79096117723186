/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import styles from 'antman/containers/TemplatesWizard/TemplatesWizard.css';
import {Pill, StatusIcon} from 'components';
import {getConsumerProviderArrowDirection} from 'containers/App/AppUtils';
import {getRuleOptionsPills} from 'containers/Ruleset/Item/RulesetItemUtils';

export const templateGridSettings = createSelector([], () => ({
  id: 'ruleTemplateGrid',
  columns: {
    status: {
      header: intl('Common.Status'),
      value: ({row}) => row.enabled,
      format: ({value}) => (value ? intl('Common.Enabled') : intl('Common.Disabled')),
      sortable: false,
    },
    source: {
      header: intl('Common.Source'),
      value: ({row}) => row,
      format: ({value}) => {
        return (
          <div className={styles.pillGap}>
            <Pill.Endpoint value={value} type="consumers" />
          </div>
        );
      },
      sortable: false,
    },
    services: {
      header: intl('Common.Services'),
      value: ({row}) => row,
      format: ({value: {ingress_services, sec_connect}}) => {
        const pills = ingress_services.map((service, index) => (
          <Pill.ServiceDiff insensitive value={service} key={index} serviceProps={{showPorts: 'all'}} />
        ));

        if (sec_connect) {
          pills.push(
            <Pill.Diff
              key="sec_connect"
              value={[
                {
                  key: intl('Common.SecureConnect'),
                  pill: <Pill icon="secure-connect">{intl('Common.SecureConnect')}</Pill>,
                },
              ]}
            />,
          );
        }

        return <div className={styles.pillGap}>{pills}</div>;
      },
      sortable: false,
    },
    arrow: {
      value: null,
    },
    destination: {
      header: intl('Common.Destination'),
      value: ({row}) => row,
      format: ({value}) => {
        return (
          <div className={styles.pillGap}>
            <Pill.Endpoint value={value} type="providers" />
          </div>
        );
      },
      sortable: false,
    },
    ruleOptions: {
      header: intl('Rulesets.Rules.RuleOptions'),
      value: ({row}) => row,
      format: ({value}) => <Pill.Diff noDiff value={getRuleOptionsPills(value)} />,
      sortable: false,
    },
    action: {
      header: intl('Common.Action'),
      value: ({row}) => row.type,
      format: ({value}) => (
        <StatusIcon
          status={value === 'allow' ? 'check' : 'deny'}
          label={value === 'allow' ? 'Allow' : 'Deny'}
          noTextColor
        />
      ),
      sortable: false,
    },
  },
  templates: [
    {
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        // TODO: add responsiveness once more rule templates come in - hard to tell where the ideal breakpoints without more data
        const source = [{columns: ['source'], size: '3fr'}];
        const destination = [
          {columns: ['destination'], size: '3fr'},
          {columns: ['services'], size: '2fr'},
        ];

        // this config is shared across two grids - we need to be explicit about template sizing, so there is visual
        // consistency across both grids
        return [
          {columns: ['status'], size: 'max-content'},
          ...(this.reverseProviderConsumer ? source : destination),
          {columns: ['arrow'], size: 'max-content'},
          ...(this.reverseProviderConsumer ? destination : source),
          {columns: ['ruleOptions'], size: '2fr'},
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 760,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const source = [{columns: ['source'], size: 'minmax(150px, 3fr)'}];
        const destination = [{columns: ['destination', 'services'], size: 'minmax(150px, 3fr)'}];

        return [
          {columns: ['status'], size: 'max-content'},
          ...(this.reverseProviderConsumer ? source : destination),
          {columns: ['arrow'], size: 'max-content'},
          ...(this.reverseProviderConsumer ? destination : source),
          {columns: ['action'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 540,
      getData() {
        return {
          arrow: getConsumerProviderArrowDirection(this.reverseProviderConsumer, 'horizontal'),
        };
      },
      template() {
        const source = ['source'];
        const destination = ['destination', 'services'];

        return [
          {
            columns: [
              'status',
              ...(this.reverseProviderConsumer ? source : destination),
              'arrow',
              ...(this.reverseProviderConsumer ? destination : source),
              'action',
            ],
            size: 'auto',
          },
        ];
      },
    },
  ],
}));
