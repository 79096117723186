/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {type ReactNode, type KeyboardEvent, useCallback, useState, useMemo, useEffect} from 'react';
import {Modal} from 'components';
import styles from './ExpandableList.css';
import {mixThemeWithProps} from '@css-modules-theme/react';
import type {ReactStrictNode} from 'utils/types';
import type {MouseEventLike} from 'utils/dom';

export interface ExpandableListProps<Value> {
  /** Number of values visible at first */
  initialCount?: number;

  /** Max Number of values to expand in place */
  showInModalFrom?: number;

  /** Array of all the values */
  values: Value[];
  key?: number | string;

  /** Title of the modal */
  title?: string;
  theme?: Record<string, string>;
  children?: (item: Value, index: number, arr?: Value[]) => ReactNode;
  verboseShowMore?: boolean;
  onToggleMore?: (state: boolean) => void;
  onToggleModal?: (state: boolean) => void;
}

export default function ExpandableList<T>(props: ExpandableListProps<T>): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const {
    theme,
    title,
    values = [],
    initialCount = 3,
    showInModalFrom = 25,
    onToggleMore,
    onToggleModal,
    children,
    verboseShowMore = false,
  } = mixThemeWithProps(styles, props);

  const handleToggle = useCallback(
    (event: MouseEventLike) => {
      event.stopPropagation();
      setExpanded(!expanded);

      if (onToggleModal && values.length >= showInModalFrom) {
        onToggleModal(!expanded);
      }
    },
    [onToggleModal, showInModalFrom, values, expanded],
  );

  const handleKeyUp = useCallback((evt: KeyboardEvent) => {
    if (evt.key === ' ' || evt.key === 'Enter') {
      setExpanded(true); // Emulate click on Space and Enter
    }
  }, []);

  const [initialValues, expandedValues] = useMemo(() => {
    let initialValues: ReactNode[] = [];
    const expandedValues: ReactNode[] = [];

    if (values.length < initialCount && !children) {
      initialValues = values as ReactStrictNode[];
    } else {
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        const targetValues = i < initialCount ? initialValues : expandedValues;

        targetValues[i] = children ? children(value, i, values) : (value as ReactStrictNode[]);

        // no need to populate arrays without expanded clicked
        if (i >= initialCount && !expanded) {
          break;
        }
      }
    }

    return [initialValues, expandedValues];
  }, [values, initialCount, expanded, children]);

  useEffect(() => {
    if (onToggleMore) {
      onToggleMore(expanded);
    }
  }, [expanded, onToggleMore]);

  return (
    <div className={theme.expandableList}>
      {initialValues}
      {expanded && values.length < showInModalFrom && expandedValues}
      {values.length > initialCount && (
        <div
          key="more"
          onClick={handleToggle}
          onKeyUp={handleKeyUp}
          className={theme.more}
          tabIndex={0}
          data-tid={`expandable-list-${expanded ? 'less' : 'more'}`}
        >
          {expanded && initialCount < showInModalFrom
            ? intl('Common.ShowLess')
            : intl(verboseShowMore ? 'Common.CountMoreLabels' : 'Common.CountMore', {
                count: values.length - initialCount,
              })}
        </div>
      )}
      {expanded && values.length >= showInModalFrom && (
        <Modal.Alert key="modal" title={title} gap="gapXSmall" onClose={handleToggle}>
          {initialValues}
          {expandedValues}
        </Modal.Alert>
      )}
    </div>
  );
}
