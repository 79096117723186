/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useEffect, useLayoutEffect, useCallback, useMemo, useRef} from 'react';
import {KEY_RETURN, KEY_TAB} from 'keycode-js';
import {useDeepCompareMemo} from 'utils/react';
import {
  CATEGORYPANEL_ID,
  categorySuggestionRegex,
  getSuggestionText,
  prepareSearchIndex,
  getSearchResult,
} from '../SelectorUtils';
import {useFilialPiety} from '../SelectorFormatUtils';
import Category from './Category';

export default function CategoryPanel(props) {
  const {
    theme,
    saveRef,
    activeCategory,
    noCategoryPanel,
    categories,
    query,
    registerHandlers,
    onClick,
    onSetHighlighted,
    ...restProps
  } = props;

  const {
    childrenPropsMap,
    saveChildRef,
    registerChildHandlers,
    highlightedChild,
    setHighlightedChild,
    resetHighlightedChild,
  } = useFilialPiety();

  const highlightedChildRef = useRef();
  const childrenPropsMapRef = useRef();
  const onClickRef = useRef();

  highlightedChildRef.current = highlightedChild;
  childrenPropsMapRef.current = childrenPropsMap;
  onClickRef.current = onClick;

  const saveRefCallback = useCallback(element => saveRef(CATEGORYPANEL_ID, element), [saveRef]);

  const {onSearchDone, onSearchReject} = useDeepCompareMemo(props.searchPromises?.[CATEGORYPANEL_ID] ?? {});
  const pathArr = useDeepCompareMemo(props.pathArr);

  const searchIndex = useMemo(
    () =>
      prepareSearchIndex({
        options: categories.filter(({displayResourceAsCategory, hidden}) => !displayResourceAsCategory && !hidden),
        idPath: 'id',
        textPath: 'name',
      }),
    [categories],
  );

  const handleKeyDown = useCallback((evt, {pathArr = []} = {}) => {
    if (childrenPropsMapRef.current.get(pathArr[0])?.keyDown && pathArr.length) {
      // For open in place categories keyDown event is handled by resource child
      // pass keyDown event to resource component
      return childrenPropsMapRef.current.get(pathArr[0]).keyDown(evt, {pathArr: pathArr.slice(1)});
    }

    if ((evt.keyCode === KEY_RETURN || evt.keyCode === KEY_TAB) && highlightedChildRef.current) {
      onClickRef.current(evt, highlightedChildRef.current.id);
    }
  }, []);

  useLayoutEffect(() => {
    registerHandlers(CATEGORYPANEL_ID, {
      setHighlightedChild,
      resetHighlightedChild,
      keyDown: handleKeyDown,
    });
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, handleKeyDown]);

  useEffect(() => {
    if (onSearchDone) {
      const suggestionResult = {id: CATEGORYPANEL_ID};

      if (categorySuggestionRegex.test(query)) {
        // Look for suggestion only if query string includes 'in' keyword
        const categoryHintText = query.split(categorySuggestionRegex)[2].trimStart();

        const matchingCategories = getSearchResult(searchIndex, categoryHintText, {enrich: true, limit: 1});

        if (categoryHintText && matchingCategories.length) {
          suggestionResult.suggestion = getSuggestionText(categoryHintText, matchingCategories);
          suggestionResult.primaryMatch = {
            id: matchingCategories[0].id,
            text: matchingCategories[0].name,
          };
          suggestionResult.pathArr = [...pathArr, CATEGORYPANEL_ID];
        }
      }

      onSearchDone(suggestionResult);
    }

    return () => onSearchReject?.();
  }, [query, onSearchDone, onSearchReject, searchIndex, pathArr]);

  const handleMouseOver = useCallback(
    (evt, categoryId) => {
      if (categoryId !== highlightedChildRef.current?.id) {
        // remove previous highlighted and set new highlighted
        onSetHighlighted(evt, {pathArr: [...pathArr, CATEGORYPANEL_ID], newHighlightedId: categoryId});
      }
    },
    [onSetHighlighted, pathArr],
  );

  return (
    <div
      className={cx(theme.categoryPanel, {[theme.hide]: noCategoryPanel})}
      ref={saveRefCallback}
      onKeyDown={handleKeyDown}
      data-tid="comp-selector-categorypanel"
    >
      {categories.map((category, index) =>
        category.divider ? (
          <div key={index} className={cx(theme.divider, theme.margin)} data-tid="comp-sectiondivider" />
        ) : (
          <Category
            {...restProps}
            query={query}
            pathArr={[...pathArr, CATEGORYPANEL_ID]}
            key={category.id}
            saveRef={saveChildRef}
            theme={theme}
            category={category}
            categories={categories}
            registerHandlers={registerChildHandlers}
            active={category.id === activeCategory.id}
            highlighted={category.id === highlightedChild?.id}
            onKeyDown={handleKeyDown}
            onMouseOver={handleMouseOver}
            onClick={onClick}
            onSetHighlighted={onSetHighlighted}
          />
        ),
      )}
    </div>
  );
}
